import { SAMPLEROLE } from './role.constant';
import { TAGS } from './tag.constant';
import { SAMPLETIMEZONE } from './timezone.constant';

export const SAMPLEUSER = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  contactEmail: '',
  auth0Id: '',
  isActive: true,
  isLocked: false,
  isEmailVerified: true,
  profilePic: '',
  timezoneId: -1,
  timezone: SAMPLETIMEZONE,
  notes: '',
  lastActive: '',
  systemRoleId: -1,
  structureId: 0,
  role: SAMPLEROLE,
  tags: [TAGS],
  createdAt: '',
  updatedAt: '',
  siblingNodeId: 0,
  siblingNodeName: '',
  siblingAttributes: []
};

export const SAMPLEUSERHELPERTEXT = {
  firstNameHelperText: '',
  lastNameHelperText: '',
  EmailHelperText: '',
  nodeNameHelperText: '',
};

export const SAMPLEIDENTITIES = {
  connection: ''
};

export const SAMPLEAUTH0USER = {
  blocked: false,
  email_verified: false,
  email: '',
  given_name: '',
  family_name: '',
  name: '',
  updated_at: '',
  picture: '',
  account_enabled: false,
  azure_id: '',
  identities: [SAMPLEIDENTITIES],
};

export const SAMPLE_LOGIN_USER = {
  userName: '',
  profilePic: ''
};

export const CONTAIN_ONLY_VALID_CHARACTERS_MESSAGE = 'Invalid character or characters included in ';
export const CHARACTER_LENGTH_MESSAGE = 'Character limit exceeded. The system accept up to 64 characters';
export const INVALID_EMAIL_MESSAGE = 'Invalid email format';
export const EMAIL_ALREADY_EXISTS_MESSAGE = 'This email already exist in Auth0';
export const CONTAIN_ONLY_ALPHABETICS_NUMBERS_AND_CHARACTERS_MESSAGE =
  'Field only accept alphanumerical letters and specific characters(A-Z, a-z, 0-9, !@#$&-) include in ';
export const CHARACTER_CUSTOM_LENGTH_MESSAGE = 'Character limit exceeded. The system accept up to ';
export const NODE_NAME_CANNOT_EMPTY = 'Node Name field cannot be empty';