import { Ref } from 'react';
import { SizeProps } from '../../../@types';

export enum TextFieldVariantProps {
  Filled = 'filled',
  Outlined = 'outlined',
  Standard = 'standard'
}

export enum TextFieldTypeProps {
  String = 'string',
  Number = 'number'
}

export interface TextFieldProps {
  required?: boolean;
  multiline?: boolean;
  placeholder?: string;
  className?: string;
  variant?: TextFieldVariantProps;
  rows?: number;
  label?: string;
  defaultValue?: string;
  value?: string | number;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  fullWidth?: boolean;
  onChange?: (text: string) => void;
  onBlur?: (text: string) => void;
  InputLabelProps?: object;
  InputProps?: object;
  inputProps?: object;
  size?: SizeProps;
  inputRef?: Ref<any>;
  type?: TextFieldTypeProps;
  onKeyDown?: (event: any) => void;
}
