import React, { useCallback, useEffect, useState } from 'react';
import { GridFeatureModeConstant, GridFilterModel, GridSelectionModel, GridSortModel, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ColumnSelectionItem } from '../../../components/templates/ColumnSelection';
import { CustomDataGrid } from '../../../components/layouts/CustomDataGrid';
import { Link } from '../../../components/atoms/Link';
import { ShipmentListResponse } from '../../../@types/shipment.type';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { HorizontalAlignProps, InlineGroup } from '../../../components/atoms/InlineGroup';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { useGetAllShipments } from '../../../queries/shipment-query';
import { ActionPermissions, CustomPermission, ListType } from '../../../@types';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { ShipmentTypes } from '../../../configs/enums';
import { PrintLabelEntityTypes } from '../../../@types/print-label.type';
import { PrintLabel } from '../../../components/molecules/PintLabel';
import { GridContainer, Wrapper } from '../../../styled/common.styles';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { checkListPagetFilterAvailability, formatDate } from '../../../utils/common';

export const ShipmentList: React.FC = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [shipments, getShipments] = useState<ShipmentListResponse>();
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const columnSelectionItems: Array<ColumnSelectionItem> = [
    {
      title: 'Shipment',
      value: [
        {
          title: 'Shipment ID',
          field: 'id',
          value: true
        },
        {
          title: 'Type',
          field: 'shipmentTypeCode',
          value: true
        },
        {
          title: 'Status',
          field: 'shipmentStatusCode',
          value: true
        },
        {
          title: 'Created At',
          field: 'createdAt',
          value: true
        },
        {
          title: 'Created By',
          field: 'createdByName',
          value: true
        },
        {
          title: 'Updated At',
          field: 'updatedAt',
          value: true
        },
        {
          title: 'Updated By',
          field: 'updatedByName',
          value: true
        },
        {
          title: 'Completed At',
          field: 'completedAt',
          value: true
        },
        {
          title: 'Source',
          field: 'sourceName',
          value: true
        },
        {
          title: 'Destination',
          field: 'destinationName',
          value: true
        },
        {
          title: 'Client/Contract',
          field: 'clientContractNames',
          value: true
        },
        {
          title: 'Notes',
          field: 'notes',
          value: true
        }
      ]
    },
    {
      title: 'Reference #',
      value: [
        {
          title: 'Reference 1',
          field: 'referenceNumber1',
          value: true
        },
        {
          title: 'Reference 2',
          field: 'referenceNumber2',
          value: true
        },
        {
          title: 'Reference 3',
          field: 'referenceNumber3',
          value: true
        }
      ]
    },
    {
      title: 'Totals',
      value: [
        {
          title: 'Quantity Total',
          field: 'qtyTotal',
          value: true
        },
        {
          title: 'Quantity Received',
          field: 'qtyReceived',
          value: true
        },
        {
          title: 'Quantity Pending',
          field: 'qtyPending',
          value: true
        }
      ]
    },
    {
      title: 'Freight',
      value: [
        {
          title: 'Courier Name',
          field: 'courierName',
          value: true
        },
        {
          title: 'Connote No.',
          field: 'connoteNo',
          value: true
        },
        {
          title: 'Latest Tracking Event',
          field: 'latestTrackingStatus',
          value: true
        }
      ]
    },
    {
      title: 'Attributes',
      value: [
        {
          title: 'Expected Arrival Date',
          field: 'arrivalDate',
          value: true
        },
        {
          title: 'Shipping Cost',
          field: 'shippingCost',
          value: true
        }
      ]
    },
    {
      title: 'Labels',
      value: [
        {
          title: 'Print Label',
          field: 'printLabel',
          value: true
        }
      ]
    }
  ];

  const [selectedColumnSelectionItems, setSelectedColumnSelectionItems] = useState<ColumnSelectionItem[]>(columnSelectionItems);

  const getAllShipments = useGetAllShipments({ filterModel, page, pageSize, sortModel }, selectedColumnSelectionItems);
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Shipment ID',
      width: 100,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value.toString(),
      renderCell: (params: GridRenderCellParams) => {
        if (!isUserHasPermission(ActionPermissions.Shipment_View_Add_Stock, getUserPermissionsQuery.data) && params.row.shipmentTypeCode === ShipmentTypes.AddStock) {
          return params.row.id;
        } else if (!isUserHasPermission(ActionPermissions.Shipment_View_Return, getUserPermissionsQuery.data) && params.row.shipmentTypeCode === ShipmentTypes.Return) {
          return params.row.id;
        } else if (!isUserHasPermission(ActionPermissions.Shipment_View_Dispatch, getUserPermissionsQuery.data) && (params.row.shipmentTypeCode === ShipmentTypes.Dispatch || params.row.shipmentTypeCode === ShipmentTypes.InternalTransfer)) {
          return params.row.id;
        } else if (params.row.shipmentTypeCode === ShipmentTypes.Return) {
          return <Link href={`shipments/return/${params.row.id}`}>{params.row.id}</Link>;
        } else {
          return <Link href={`shipments/${params.row.id}`}>{params.row.id}</Link>;
        }
      }
    },
    {
      field: 'shipmentTypeCode',
      headerName: 'Type',
      width: 150,
      type: 'singleSelect',
      valueOptions: [
        { value: 'add-stock', label: 'Add Stock' },
        { value: 'dispatch', label: 'Dispatch' },
        { value: 'return', label: 'Return' },
        { value: 'internal-transfer', label: 'Internal Transfer' }
      ],
      renderCell: (params: GridRenderCellParams) => params.row.shipmentTypeName
    },
    {
      field: 'shipmentStatusCode',
      headerName: 'Status',
      width: 150,
      type: 'singleSelect',
      valueOptions: [
        { value: 'pending', label: 'Pending' },
        { value: 'partial', label: 'Partial' },
        { value: 'complete', label: 'Complete' },
        { value: 'cancelled', label: 'Cancelled' },
        { value: 'in-transit', label: 'In Transit' },
        { value: 'received', label: 'Received' }
      ],
      renderCell: (params: GridRenderCellParams) => params.row.shipmentStatusName
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'date',
      width: 150,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.createdAt, '')
    },
    {
      field: 'createdByName',
      headerName: 'Created By',
      type: 'string',
      width: 200
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      type: 'date',
      width: 150,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.updatedAt, '')
    },
    {
      field: 'updatedByName',
      headerName: 'Updated By',
      type: 'string',
      width: 200
    },
    {
      field: 'completedAt',
      headerName: 'Completed At',
      type: 'date',
      width: 150,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.completedAt, '')
    },
    {
      field: 'sourceName',
      headerName: 'Source',
      type: 'string',
      width: 200
    },
    {
      field: 'destinationName',
      headerName: 'Destination',
      type: 'string',
      width: 200
    },
    {
      field: 'clientContractNames',
      headerName: 'Client/Contract',
      type: 'string',
      width: 200
    },
    {
      field: 'notes',
      headerName: 'Notes',
      type: 'string',
      width: 300
    },
    {
      field: 'referenceNumber1',
      headerName: 'Reference 1',
      type: 'string',
      width: 150
    },
    {
      field: 'referenceNumber2',
      headerName: 'Reference 2',
      type: 'string',
      width: 150
    },
    {
      field: 'referenceNumber3',
      headerName: 'Reference 3',
      type: 'string',
      width: 150
    },
    {
      field: 'qtyTotal',
      headerName: 'Quantity Total',
      width: 150,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
    },
    {
      field: 'qtyReceived',
      headerName: 'Quantity Received',
      width: 150,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
    },
    {
      field: 'qtyPending',
      headerName: 'Quantity Pending',
      width: 150,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
    },
    {
      field: 'courierName',
      headerName: 'Courier Name',
      type: 'string',
      width: 200
    },
    {
      field: 'connoteNo',
      headerName: 'Connote No.',
      type: 'string',
      width: 200
    },
    {
      field: 'latestTrackingStatus',
      headerName: 'Latest Tracking Event',
      type: 'string',
      width: 200
    },
    {
      field: 'arrivalDate',
      headerName: 'Expected Arrival Date',
      type: 'date',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.arrivalDate, '')
    },
    {
      field: 'shippingCost',
      headerName: 'Shipping Cost',
      width: 150,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value?.toString()
    },
    {
      field: 'printLabel',
      headerName: 'Print Label',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <PrintLabel entityId={params.row.id} entityType={PrintLabelEntityTypes.Shipment}/>
        );
      },
      filterable: false
    }
  ]);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setFilterModel({ ...filterModel });
  }, []);
  
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleSelectionModelChange = useCallback((selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  }, []);

  TabHandler(`List Shipments | ${PLATFORM_NAME}`);

  useEffect(() => {
    getAllShipments.data && getShipments(getAllShipments.data);
  }, [getAllShipments.data, getAllShipments.isRefetching]);

  useEffect(() => {
    if (filterModel.items.length === 0 || checkListPagetFilterAvailability(filterModel) || sortModel?.some(sort => sort.field)) {
      getAllShipments.refetch();  
    }
  }, [filterModel, sortModel]);

  useEffect(() => {
    if(page || pageSize) {
      getAllShipments.refetch();
    }
  }, [page, pageSize, selectedColumnSelectionItems]);

  const handleColumnsChange = (items: ColumnSelectionItem[]) => {
    setSelectedColumnSelectionItems(items);
  };

  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            List Shipments
          </Typography>
        </InlineGroup>
        <GridContainer>
          <CustomDataGrid
            columns={columns}
            rows={shipments?.data || []}
            rowCount={shipments?.total}
            exportFileName={ListType.ShipmentList}
            enableCSVExport={isUserHasPermission(ActionPermissions.Shipment_View_Search, permissions)}
            columnSelectionItems={selectedColumnSelectionItems}
            filterMode={GridFeatureModeConstant.server}
            filterModel={filterModel}
            onFilterModelChange={(filterModel: GridFilterModel) => {
              onFilterChange(filterModel);
            }}
            handleColumnsChange={handleColumnsChange}
            loading={getAllShipments.isLoading || getAllShipments.isRefetching}
            rowsPerPageOptions={[25, 50, 100]}
            pagination={true}
            page={page}
            pageSize={pageSize}
            paginationMode={GridFeatureModeConstant.server}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            sortModel={sortModel}
            sortingMode={GridFeatureModeConstant.server}
            onSortModelChange={handleSortModelChange}
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectionModelChange}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  parentNodeId: false,
                  referenceNumbers: false,
                  status: false,
                },
              },
            }}
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true }
              }
            }}
            getRowHeight={() => 'auto'}
            setColumns={setColumns}
          />
        </GridContainer>
      </Wrapper>
    </PageContainer>
  );
};