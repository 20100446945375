import React, { useEffect, useState } from 'react';
import { FormControl, Stack } from '@mui/material';
import { 
  Typography, 
  TypographyFontWeightProps, 
  TypographyVariantProps 
} from '../../../../../../components/atoms/Typography';
import { DropdownItem } from '../../../../../../components/atoms/SelectMenu';
import { LiveSearchListItem } from '../../../../../../components/atoms/LiveSearchBox';
import { LocationSearchItem } from '../../../../../../components/molecules/LocationSearchItem';
import { AutocompleteTagType } from '../../../../../../components/organisms/Tags';
import { useGetNextServicesStatuses } from '../../../../../../queries/service-status-query';
import { getAllTags } from '../../../../../../queries/tags-query';
import { useSearchAllocationNodes, useSearchParts, useSubLocationsForCreateServices } from '../../../../../../queries/live-search-query';
import { isEmptyString } from '../../../../../../utils/common';
import { EntityType } from '../../../../../../configs/enums';
import { ActionPermissions, CustomPermission, LocationNodeData, ParameterDataFormParameterType, Tag } from '../../../../../../@types';
import { ParameterFormFragmentProps } from './ParameterFormFragment.props';
import { 
  StyledStack, 
  StyledSelectMenu, 
  StyledLiveSearchBox, 
  StyledDateTimePicker,
  StyledTags 
} from './ParameterFormFragment.styles';
import { PartParameterFormFragment } from './PartParameterFormFragment/PartParameterFormFragment';
import { COLORS } from '../../../../../../configs/colors';
import { isUserHasPermission } from '../../../../../../configs/permissions';
import { useGetUserSystemPermissions } from '../../../../../../queries/user-query';

export const ParameterFormFragment: React.FC<ParameterFormFragmentProps> = ({
  preSelectedData,
  isError,
  service,
  value,
  onChange,
  partActionData
}) => {
  const [presetSuggestionOptions, setPresetSuggestionOptions] = useState<Array<DropdownItem>>([]);
  const [localValue, setLocalValue] = useState('');
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [selectedTags, setSelectedTags] = useState<(AutocompleteTagType | string)[]>([]);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  
  const nextServicesStatusesQuery = useGetNextServicesStatuses(service.serviceStatusCode, service.serviceTypeCode);
  const searchAllocationNodes = useSearchAllocationNodes();
  const subLocationsForCreateServicesQuery = useSubLocationsForCreateServices(true);
  const searchParts = useSearchParts();
  const userTagsQuery = getAllTags(EntityType.TYPE_SERVICES);
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    const preselectAvailable = presetSuggestionOptions?.find((obj) => obj.value === preSelectedData?.presetSuggestion1);
    if (preselectAvailable) {
      onChange(preselectAvailable.value);
      setLocalValue(preselectAvailable.value);
    }
  }, [presetSuggestionOptions, preSelectedData?.presetSuggestion1]);

  useEffect(() => {
    if (nextServicesStatusesQuery.data && preSelectedData?.parameterType === ParameterDataFormParameterType.Status) {
      const statusData: Array<DropdownItem> = nextServicesStatusesQuery.data.possibleNextStatuses.map((obj) => ({
        label: obj.name,
        value: obj.code
      }));
      setPresetSuggestionOptions(statusData);
    }
  }, [nextServicesStatusesQuery.data, preSelectedData?.parameterType]);

  useEffect(() => {
    // Set previously selected allocation if available
    if (preSelectedData?.parameterType === ParameterDataFormParameterType.PrimaryAllocation || preSelectedData?.parameterType === ParameterDataFormParameterType.SecondaryAllocation) {
      searchAllocationNodes.mutateAsync(preSelectedData?.presetSuggestion1.name).then((result: Array<LocationNodeData>) => {
        const findSelectedAllocation = result.find((obj) => obj.code === preSelectedData?.presetSuggestion1.code);
        if (findSelectedAllocation) {
          onChange(findSelectedAllocation);
        }
      });
    }
  }, [preSelectedData?.parameterType, preSelectedData?.presetSuggestion1]);

  useEffect(() => {
    if (userTagsQuery.data) {
      setAllTags(userTagsQuery.data);
    }
  }, [userTagsQuery.data]);

  useEffect(() => {
    if (preSelectedData?.parameterType === ParameterDataFormParameterType.SetTag) {
      setSelectedTags(preSelectedData.presetSuggestion1 || []);
      setNewTags(preSelectedData.presetSuggestion2 || []);
    }
  }, []);

  useEffect(() => {
    if (preSelectedData?.parameterType === ParameterDataFormParameterType.SetTag) {
      onChange({ selectedTags, newTags });
    }
  }, [selectedTags, newTags]);

  return (
    <StyledStack spacing={2}>
      <Typography
        variant={TypographyVariantProps.Body2} 
        fontWeight={TypographyFontWeightProps.Bold}
      >
        Set Parameter
      </Typography>
      {preSelectedData?.parameterType === ParameterDataFormParameterType.Status &&
        <StyledSelectMenu
          key={localValue}
          id="parameter-form-fragment-field-options"
          label="Status"
          items={presetSuggestionOptions}
          selectedValue={value}
          onChange={(val) => onChange(val)}
          validate={isError && (!value || isEmptyString(value))}
        />
      }
      {((preSelectedData?.parameterType === ParameterDataFormParameterType.PrimaryAllocation && isUserHasPermission(ActionPermissions.Service_Edit_Allocation_Primary, permissions)) || 
        (preSelectedData?.parameterType === ParameterDataFormParameterType.SecondaryAllocation) && isUserHasPermission(ActionPermissions.Service_Edit_Allocation_Secondary, permissions)) &&
        <StyledLiveSearchBox
          key={value}
          title={preSelectedData?.parameterType} 
          timeOffset={400}
          value={value?.name || ''} 
          onClearValue={() => onChange('')}
          onChange={(val) => onChange(val)}
          onApiInvoke={async (name) => {
            return await searchAllocationNodes.mutateAsync(name);
          }} 
          renderItem={(props: any, option: any) => { 
            return (
              <LiveSearchListItem {...props}>
                <LocationSearchItem data={option} />
              </LiveSearchListItem>
            );
          }}
          isError={isError && !value}
        />
      }
      {preSelectedData?.parameterType === ParameterDataFormParameterType.UpdateSubLocation &&
        <StyledLiveSearchBox
          key={value}
          title={preSelectedData?.parameterType} 
          timeOffset={400}
          value={value?.name || ''} 
          onClearValue={() => onChange('')}
          onChange={(val) => onChange(val)}
          onApiInvoke={async () => {
            const locations = await subLocationsForCreateServicesQuery.mutateAsync(service.mainLocationNodeId);
            const sublocations = locations.filter((location: any) => !location.isMain);
            return sublocations;
          }}
          renderItem={(props: any, option: any) => { 
            return (
              <LiveSearchListItem {...props}>
                <LocationSearchItem data={option} />
              </LiveSearchListItem>
            );
          }}
          isError={isError && !value}
        />
      }
      {preSelectedData?.parameterType === ParameterDataFormParameterType.ReportedSerial &&
        <StyledLiveSearchBox
          key={value}
          title={preSelectedData?.parameterType} 
          timeOffset={400}
          value={value?.name || ''} 
          onClearValue={() => onChange('')}
          onChange={(val) => onChange(val)}
          onApiInvoke={async (name) => {
            return searchParts.mutateAsync({ name });
          }}
          renderItem={(props: any, option: any) => { 
            return (
              <LiveSearchListItem {...props}>
                <LocationSearchItem data={option} />
              </LiveSearchListItem>
            );
          }}
          isError={isError && !value}
        />
      }
      {preSelectedData?.parameterType === ParameterDataFormParameterType.ScheduledDate &&
        <StyledDateTimePicker
          label="Scheduled Date/Time"
          disablePast={true}
          value={value}
          onChange={(val) => onChange(val)}
          error={isError && (!value || isEmptyString(value))}
        />
      }
      {preSelectedData?.parameterType === ParameterDataFormParameterType.SetTag &&
      <Stack>
        <FormControl
          fullWidth
          error={isError && !selectedTags.length && !newTags.length}
        >
          <StyledTags
            key={value}
            allTags={allTags}
            selectedTags={selectedTags}
            newTags={newTags}
            setSeletedTags={(val: (string | AutocompleteTagType)[]) => setSelectedTags(val)}
            setNewTags={(val: Tag[]) => setNewTags(val)}
          />
        </FormControl>
        {isError && !selectedTags.length && !newTags.length && <Typography textColor={COLORS.DarkRed} variant={TypographyVariantProps.Body2}>*Required</Typography>}
      </Stack>
      }
      {preSelectedData?.parameterType === ParameterDataFormParameterType.Part && partActionData?.value.partId &&
       <PartParameterFormFragment
         service={service}
         partParameterData={preSelectedData.partParameter}
         isError={isError}
         value={value}
         onChange={(partData) => onChange(partData)}
         partActionData={partActionData}
       />
      }
    </StyledStack>
  );
};
