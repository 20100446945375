import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonVariantProps } from '../../components/atoms/Button';
import { Typography, TypographyVariantProps } from '../../components/atoms/Typography';
import { HorizontalAlignProps, InlineGroup } from '../../components/atoms/InlineGroup';
import { PageContainer } from '../../components/atoms/PageContainer';
import { StyledBox } from './ReplenishmentConfigs.styles';
import { ReplenishmentConfigList } from './ReplenishmentConfigList/ReplenishmentConfigList';
import { Modal } from '../../components/atoms/Modal';
import { AddEditReplenishmentConfigFragment } from './AddEditReplenishmentConfigFragment';
import { Wrapper } from '../../styled/common.styles';
import { PLATFORM_NAME } from '../../configs/common';
import { TabHandler } from '../../handlers/TabHandler';
import { CloseIcon } from '../../components/atoms/CloseIcon';

export const ReplenishmentConfigs: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleClose = (event?: any, reason?: string) => {
    if (reason !== 'backdropClick') setOpen(false);
  };

  const handleAdd = (event: any) => {
    event.stopPropagation();
    setOpen(true);
  };

  TabHandler(`List Config | ${PLATFORM_NAME}`);
  
  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            List Config
          </Typography>
          <Button variant={ButtonVariantProps.Primary} startIcon={<AddIcon />} onClick={handleAdd}>
            ADD REPLENISHMENT CONFIG
          </Button>
        </InlineGroup>
        <ReplenishmentConfigList />
      </Wrapper>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledBox>
          <CloseIcon handleClose={handleClose} />
          <AddEditReplenishmentConfigFragment 
            isOpen={open}
            isEdit={false}
            onClose={handleClose}
          />
        </StyledBox>
      </Modal>
    </PageContainer>
  );
};