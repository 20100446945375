import { TaskType } from '../@types/task.type';
import { convertHyphenTextToReadText } from '../utils/common';

export const TASK_ITEMS = Object.values(TaskType).map(type => ({
  value: type,
  label: convertHyphenTextToReadText(type)
}));

export const SAMPLE_TASK_CARD = {
  id: 0,
  taskTypeCode: TaskType.LABOUR_TIME,
  serviceId: 0,
  taskData: {},
  notes: ''
};

export const SAMPLE_TASK_TIME_CARD_HELPERTEXT = {
  durationHelperText: '',
  notesHelperText: ''
};

export const SAMPLE_TASK_QUOTE_VALUE_CARD_HELPERTEXT = {
  collectionHelperText: '',
  issuedHelperText: '',
  contactHelperText: '',
  notesHelperText: ''
};