import React, { useEffect, useState } from 'react';
import { Alert, CardActions, Grid, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { ButtonColorProps, ButtonVariantProps } from '../../../../../components/atoms/Button';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../../../../components/atoms/Typography';
import { TextField, TextFieldTypeProps } from '../../../../../components/atoms/TextField';
import { ImagePopup, ImagePopupSlide } from '../../../../../components/atoms/ImagePopup';
import { PartTypeActionSearch } from '../../../../../components/molecules/PartTypeActionSearch';
import { PartTypeActionSHFData } from '../../../../../components/molecules/PartTypeActionSHFData';
import { PartTypeActionNote } from '../../../../../components/molecules/PartTypeActionNote';
import { PartTypeLocationItemData } from '../../../../../components/molecules/PartTypeLocationItem';
import { PartLocationSelectPopup } from '../../../../../components/organisms/PartLocationSelectPopup';
import { useGetUploadedImages } from '../../../../../queries/uploadedfiles-query';
import { useGetPartLocationDataByServiceId } from '../../../../../queries/part-query';
import { ActionPermissions, CustomPermission, PartTypeActionLiveSearchItem, PartTypeActionLiveSearchItemCategoryCode } from '../../../../../@types';
import { Folders } from '../../../../../@types/uploaded-files.type';
import { isEmptyString } from '../../../../../utils/common';
import { PartActionRemoveCardProps } from './PartActionRemoveCard.props';
import { 
  DefaultCard, 
  StyledCardContent,
  CardTitle,
  CloseWrapper,
  SHFDataWrapper,
  StyledButton,
  SelectButton
} from './PartActionRemoveCard.styles';
import { useGetUserSystemPermissions } from '../../../../../queries/user-query';
import { isUserHasPermission } from '../../../../../configs/permissions';
import { Action, PartActionType, PartActionValidateType } from '../../../../../@types/partAction.type';
import { partActionConditionValidation } from '../../../../../utils/part-action';
import { LOCATION_CANNOT_EMPTY } from '../../../../../constants/partAction';
import { useSubLocationsForCreateServices } from '../../../../../queries/live-search-query';
import { COLORS } from '../../../../../configs/colors';

export const PartActionRemoveCard: React.FC<PartActionRemoveCardProps> = ({
  service,
  value,
  helperText,
  onChange,
  onSave,
  onExec,
  onClose,
  setHelperText
}) => {
  const [isHavingPermission, setIsHavingPermission] = useState(true);
  const [isSearchMode, setIsSearchMode] = useState(true);
  const [isSerialized, setIsSerialized] = useState(false);
  const [showSerialSearch, setShowSerialSearch] = useState(false);
  const [isSelectLocationPopup, setIsSelectLocationPopup] = useState(false);
  const [partImages, setPartImages] = useState<Array<ImagePopupSlide>>([]);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [siteHierarchyWarning, setSiteHierarchyWarning] = useState<string>('');
  const [underClientStructureWarning, setUnderClientStructureWarning] = useState<string>('');

  const uploadedImagesQuery = useGetUploadedImages(value.outValue?.partTypeId || 0, Folders.PartTypesImages);
  const partLocationDataByServiceIdQuery = useGetPartLocationDataByServiceId(value.outValue?.partTypeId || 0, service.id);
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const subLocationsForCreateServicesQuery = useSubLocationsForCreateServices();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    const outValidations = async () => {
      if (value?.outValue?.isInSiteHierarchy === false && value?.outValue?.serial) {
        const subLocationsResult = await subLocationsForCreateServicesQuery.mutateAsync(value.outValue?.locationId);
        const main = subLocationsResult?.find(subLocation => subLocation.isMain);
    
        const partParentNode = main && main.id !== value.outValue?.locationId ? `${main.name} /` : '';
        const serviceBaseNode = service.subLocation ? `/ ${service.subLocation}` : '';

        setSiteHierarchyWarning(`Warning: ${value?.outValue?.serial} is not at ${service.mainLocation} ${serviceBaseNode}, but at ${partParentNode} ${value.outValue?.location}. This is an unexpected part location; please confirm the correct part has been selected prior to proceeding. installed in ${value?.outValue?.name}: ${value?.outValue?.serial}`);
      } else {
        setSiteHierarchyWarning('');
      } 
      
      if (value?.outValue?.isUnderClientStructure === false && value?.outValue?.serial) {
        const baseOrParentnode = service.subLocation ? service.subLocation : service.mainLocation;
        setUnderClientStructureWarning(`Warning: ${value?.outValue?.serial} is not at ${baseOrParentnode}, but at ${value.outValue?.location}.  Please confirm the correct part has been selected prior to proceeding.`);
      } else {
        setUnderClientStructureWarning('');
      }
    };

    outValidations();
  }, [value?.outValue?.isInSiteHierarchy, value?.outValue?.isUnderClientStructure, value?.outValue?.serial]);

  useEffect(() => {
    setIsHavingPermission(isUserHasPermission(ActionPermissions.Service_Edit_Part_Actions_Exceptional_Creation, permissions));
  }, [permissions]);

  useEffect(() => {
    value?.outValue?.name && setIsSearchMode(isEmptyString(value?.outValue?.name));
    value?.outValue?.category && setIsSerialized(value?.outValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent || value.outValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice);
    value.outValue?.partTypeId && uploadedImagesQuery.refetch();
  }, [value?.outValue?.name, value?.outValue?.category, value.outValue?.partTypeId]);

  useEffect(() => {
    setShowSerialSearch(value?.outValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent || value.outValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice);
  }, [value?.outValue?.category]);

  useEffect(() => {
    if (uploadedImagesQuery.data) {
      const images: Array<ImagePopupSlide> = uploadedImagesQuery.data.map((obj) => ({
        url: obj.url
      }));
      setPartImages(images);
    }
  }, [uploadedImagesQuery.data]);

  const handlePartTypeActionSearch = async (liveSearchSelectedItem: PartTypeActionLiveSearchItem) => {
    if (liveSearchSelectedItem.isPartCreation) {
      onChange({
        newPartSerial: liveSearchSelectedItem.name,
        isPartCreation: true,
        action: Action.OUT,
        partActionType: PartActionType.REMOVE,
        value: value.value,
        inValue: value.inValue,
        outValue: value.outValue,
      });
    } else {
      setHelperText({
        ...helperText,
        out: {
          ...helperText.out,
          partTypeHelperText: '',
          partSerialHelperText: ''
        },
      });

      if (liveSearchSelectedItem.serial1) {
        onChange({
          value: value.value,
          inValue: value.inValue,
          outValue: {
            ...value.outValue,
            id: value.outValue?.id || 0,
            partTypeId: liveSearchSelectedItem.partTypeId,
            partId: liveSearchSelectedItem.partId,
            name: liveSearchSelectedItem.partTypeName,
            category: liveSearchSelectedItem.categoryCode,
            location: liveSearchSelectedItem.locationName,
            locationId: liveSearchSelectedItem.locationId,
            condition: liveSearchSelectedItem.conditionCode,
            conditionName: liveSearchSelectedItem?.partCondition?.name,
            serial: liveSearchSelectedItem.serial1,
            sw: liveSearchSelectedItem.softwareVersion,
            hw: liveSearchSelectedItem.hardwareVersion,
            fw: liveSearchSelectedItem.firmwareVersion,
            ft: liveSearchSelectedItem.fleetTagName,
            isInSiteHierarchy: liveSearchSelectedItem.isInSiteHierarchy,
            isUnderClientStructure: liveSearchSelectedItem.isUnderClientStructure
          }
        });
      } else {
        onChange({
          value: value.value,
          inValue: value.inValue,
          outValue: {
            ...value.outValue,
            id: value.outValue?.id || 0,
            partTypeId: liveSearchSelectedItem.partTypeId,
            ns_partId: liveSearchSelectedItem.partId,
            name: liveSearchSelectedItem.partTypeName,
            category: liveSearchSelectedItem.categoryCode,
            location: liveSearchSelectedItem.locationName,
            condition: liveSearchSelectedItem.conditionCode,
            conditionName: liveSearchSelectedItem?.partCondition?.name,
            serial: '',
            sw: liveSearchSelectedItem.softwareVersion,
            hw: liveSearchSelectedItem.hardwareVersion,
            fw: liveSearchSelectedItem.firmwareVersion,
            ft: liveSearchSelectedItem.fleetTagName
          }
        });
      }
    }
  };


  const handlePartTypeSerialSearch = (val: PartTypeActionLiveSearchItem) => {
    if (val.isPartCreation) {
      onChange({
        newPartSerial: val.name,
        isPartCreation: true,
        action: Action.IN,
        partActionType: PartActionType.REMOVE,
        value: value.value,
        inValue: value.inValue,
        outValue: value.outValue,
      });
    } else {
      setHelperText({
        ...helperText,
        out: {
          ...helperText.out,
          partTypeHelperText: '',
          partSerialHelperText: ''
        },
      });

      const updatedValue = {
        value: value.value,
        outValue: {
          ...value.outValue,
          id: value.outValue?.id || 0,
          ns_partId: NaN,
          partId: val.partId,
          location: val.locationName,
          locationId: val.locationId,
          serial: val.serial1,
          condition: val.conditionCode,
          conditionName: val?.partCondition?.name,
          sw: val.softwareVersion,
          hw: val.hardwareVersion,
          fw: val.firmwareVersion,
          ft: val.fleetTagName,
          isInSiteHierarchy: val.isInSiteHierarchy,
          isUnderClientStructure: val.isUnderClientStructure
        },
        inValue: value.inValue
      };
      onChange(updatedValue);
    }
  };

  const handlePartTypeLocationSelect = (val: PartTypeLocationItemData) => {
    setHelperText({
      ...helperText,
      out: { 
        ...helperText.out,
        locationHelperText: ''
      },
    });

    const updatedValue = {
      value: value.value,
      outValue: {
        ...value.outValue,
        partId: NaN,
        serial: '',
        id: value.outValue?.id || 0,
        ns_partId: val.ns_partId,
        location: val.locationName,
        condition: val.conditionCode,
        conditionName: val?.partCondition?.name,
        ft: val.fleetTagName
      },
      inValue: value.inValue
    };
  
    onChange(updatedValue);
  };
  
  return (
    <DefaultCard>
      <StyledCardContent>
        <CardTitle>Remove</CardTitle>
        <CloseWrapper onClick={onClose}>
          <CloseRounded htmlColor={COLORS.White} />
        </CloseWrapper>
        <Stack direction="column" spacing={1.5} width="100%">
          <Stack direction="row" justifyContent="space-between" spacing={1} marginTop={1} width="100%">
            <Stack direction="column" spacing={2} width="100%">
              <PartTypeActionSearch
                title={showSerialSearch ? 'Part Type Search' : 'Part/Part Type Input'}
                serviceData={service}
                locationId={service.subLocationNodeId || service.mainLocationNodeId}
                value={value?.outValue?.name || ''}
                partActionType={PartActionType.REMOVE}
                error={!!helperText.out.partTypeHelperText}
                helperText={helperText.out.partTypeHelperText}
                onChange={handlePartTypeActionSearch}
                searchType={showSerialSearch ? 'part-type' : 'part-and-part-type'}
              />
              {showSerialSearch &&
              <PartTypeActionSearch
                title="Part Serial Search"
                locationId={service.subLocationNodeId || service.mainLocationNodeId}
                serialiseType={value.outValue?.category}
                serviceData={service}
                partTypeId={`${value?.outValue?.partTypeId}`}
                value={value.outValue?.serial || ''} 
                partActionType={PartActionType.REMOVE}
                error={!!helperText.out.partSerialHelperText}
                helperText={helperText.out.partSerialHelperText}
                onChange={handlePartTypeSerialSearch}
                searchType="part"
              />
              }
            </Stack>
            {isSearchMode || <ImagePopup title="Photos" images={partImages} />}
          </Stack>
          {isSearchMode || 
            <>
              {isSerialized ||
                <>
                  <SelectButton 
                    variant={ButtonVariantProps.Secondary} 
                    color={ButtonColorProps.Success}
                    onClick={() => setIsSelectLocationPopup(true)}
                  >
                    Select Location
                  </SelectButton>
                  {!!helperText.out.locationHelperText && 
                    <Grid item xs={12}>
                      <Alert severity="error">{LOCATION_CANNOT_EMPTY}</Alert>
                    </Grid>
                  }
                </>
              }
              {isSerialized ?
                <SHFDataWrapper>
                  <PartTypeActionSHFData sw={value?.outValue?.sw || '_'} hw={value?.outValue?.hw || '_'} fw={value?.outValue?.fw || '_'} />
                </SHFDataWrapper>
                :
                <TextField 
                  label="Qty"
                  type={TextFieldTypeProps.Number}
                  value={value?.outValue?.qty}
                  inputProps={{ type: 'number', min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={(val) =>{
                    onChange({
                      value: value.value,
                      outValue: {
                        ...value.outValue,
                        id: value.outValue?.id || 0,
                        qty: Number(val)
                      },
                      inValue: value.inValue
                    });
                  }}
                />
              }
              {value.outValue?.condition && 
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    COND:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value.outValue?.conditionName || value.outValue?.condition}</Typography>
                </Stack>
              }
              {value.outValue?.location &&
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    LOC:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value.outValue?.location}</Typography>
                </Stack>
              }
              {value.outValue?.ft &&
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    FT:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value.outValue?.ft}</Typography>
                </Stack>
              }
              <Grid item xs={12}>
                <PartTypeActionNote
                  value={value?.outValue?.notes || ''}
                  onChange={(note) => onChange({
                    value: value.value,
                    outValue: {
                      ...value.outValue,
                      id: value.outValue?.id || 0,
                      notes: note
                    },
                    inValue: value.inValue
                  })}
                />
              </Grid>
              {value?.outValue?.condition && value?.outValue?.serial && partActionConditionValidation(PartActionValidateType.REMOVE, value.outValue.condition, value.outValue.serial) &&
                <Grid item xs={12}>
                  <Alert severity="warning">{partActionConditionValidation(PartActionValidateType.REMOVE, value.outValue.condition, value.outValue.serial)}</Alert>
                </Grid>
              }
              {siteHierarchyWarning &&
                <Grid item xs={12}>
                  <Alert severity="warning">{siteHierarchyWarning}</Alert>
                </Grid>
              }
              {underClientStructureWarning &&
                <Grid item xs={12}>
                  <Alert severity="warning">{underClientStructureWarning}</Alert>
                </Grid>
              }
            </>
          }
        </Stack>
      </StyledCardContent>

      {isHavingPermission && (isSearchMode ||
        <CardActions>
          <Stack direction="row-reverse" spacing={1} width="100%">
            <StyledButton 
              variant={ButtonVariantProps.Primary} 
              color={ButtonColorProps.Error}
              onClick={onExec}
            >
                Exec
            </StyledButton>
            <StyledButton 
              variant={ButtonVariantProps.Primary} 
              color={ButtonColorProps.Success}
              onClick={onSave}
            >
              {value.outValue?.actionId ? 'Update' : 'Save'}
            </StyledButton>
          </Stack>
        </CardActions>
      )}
      <PartLocationSelectPopup
        open={isSelectLocationPopup}
        options={partLocationDataByServiceIdQuery.data || []}
        onSelect={handlePartTypeLocationSelect}
        onClose={() => setIsSelectLocationPopup(false)}
      />
    </DefaultCard>
  );
};