import React, { useEffect, useState } from 'react';
import { Grid, Stack, Divider, TableContainer, Table, TableBody, Snackbar, Alert, AlertTitle, Box } from '@mui/material';
import { Add } from '@mui/icons-material';
import { ContentWrapperBox, Footer, HeadingWrapper, StyledButton } from '../SKURuleList.styles';
import { Typography, TypographyVariantProps } from '../../../../components/atoms/Typography';
import { TextField } from '../../../../components/atoms/TextField';
import { DropdownItem, SelectMenu } from '../../../../components/atoms/SelectMenu';
import { Switch } from '../../../../components/atoms/Switch';
import { SelectionButton } from '../../../../components/molecules/SelectionButton';
import { Button, ButtonVariantProps } from '../../../../components/atoms/Button';
import { SKUDataType, PartTypeConfigType } from '../../../../@types/sku.type';
import { ClientContractData, SKUPopupFragmentProps } from './SKURulePopupFragment.props';
import { ColorProps, NodeType } from '../../../../@types';
import { SKUPropertySelectionItemRowFragment } from './SKUPropertySelectionItemRowFragment';
import { useCreateSKUConfig, useGetSKUConfigById, useGetSKUConfigByName, useUpdateSKUConfig } from '../../../../queries/sku-config-query';
import { SerializationType, StructureTypeCategory } from '../../../../configs/enums';
import { BrandSelector } from '../../../../components/molecules/BrandSelector';
import { useGetNodeChildrenByNodeType, useGetStructuresByTypeCategoryCodeAndTypeCodeForReplenishment } from '../../../../queries/structure-query';
import { SAMPLE_CLIENT_CONTRACT_DATA, SAMPLE_SKU_CONFIG, SERIALISATION_TYPE_DROPDOWN_ITEMS, SKU_CONFIG_CONSTANTS } from '../../../../constants/sku';
import { isDropDownEmpty, isEmptyString } from '../../../../utils/common';
import { serialisationChecker } from '../../../../utils/replenishment';
import { CloseIcon } from '../../../../components/atoms/CloseIcon';
import { UNDEFINED_TREE_FIRST_ITEM } from '../../../../constants/common';

export const SKURulePopupFragment: React.FC<SKUPopupFragmentProps> = ({
  skuConfigId,
  isEdit,
  skuNonEditableData,
  onClose,
  onSuccessMessage
}) => {
  const anyItem = [{ value: 'any', label: 'Any' }];
  const [skuConfigData, setSkuConfigData] = useState<SKUDataType>(SAMPLE_SKU_CONFIG);
  const [rowFormData, setRowFormData] = useState<Array<PartTypeConfigType>>([]);
  const [rowFormDataIndex, setRowFormDataIndex] = useState(-1);
  const [openParentSelector, setOpenParentSelector] = useState(false);
  const [clients, setClients] = useState<DropdownItem[]>([]);
  const [contracts, setContracts] = useState<DropdownItem[]>(anyItem);
  const [selectedParentId, setSelectedParentId] = useState('');
  const [queryNodeType, setQueryNodeType] = useState('client');
  const [clientContractData, setClientContractData] = useState<ClientContractData>(SAMPLE_CLIENT_CONTRACT_DATA);
  const [nodeId, setNodeId] = useState<number>(0);
  const [isSerialised, setIsSerialised] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [showWarningToast, setShowWarningToast] = useState(false);
  const [isNameUnique, setIsNameUnique] = useState<boolean>(false);
  const isEmptySerialisation = isDropDownEmpty(skuConfigData?.serializationType || '-1');
  
  const getStructuresByTypeCategoryCodeAndTypeCode = useGetStructuresByTypeCategoryCodeAndTypeCodeForReplenishment(StructureTypeCategory.System, NodeType.Client);
  const getNodeChildrenByNodeType = useGetNodeChildrenByNodeType(selectedParentId, queryNodeType);
  const createSKUConfigQuery = useCreateSKUConfig();
  const getSKUConfigByIdQuery = useGetSKUConfigById(skuConfigId || 0);
  const getSKUConfigByNameQuery = useGetSKUConfigByName({ name: skuConfigData.name, id: skuConfigId });
  const updateSKUConfig = useUpdateSKUConfig();

  useEffect(() => {
    if (getSKUConfigByIdQuery.data) {
      setSkuConfigData(getSKUConfigByIdQuery.data);
      setNodeId(getSKUConfigByIdQuery.data?.nodeId || 0);
    }
    setIsSerialised(serialisationChecker(skuNonEditableData?.serialisation) === SerializationType.Serialised);
  }, [getSKUConfigByIdQuery.data]);

  useEffect(() => {
    if (getSKUConfigByNameQuery.data) {
      setIsNameUnique(getSKUConfigByNameQuery.data.isExist);
    }
  }, [getSKUConfigByNameQuery.data]);

  useEffect(() => {
    if (getStructuresByTypeCategoryCodeAndTypeCode.data) {
      setClients(getStructuresByTypeCategoryCodeAndTypeCode.data.map(item => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive })));
    }

  }, [getStructuresByTypeCategoryCodeAndTypeCode.data]);

  useEffect(() => {
    if (getNodeChildrenByNodeType.data && queryNodeType === NodeType.Contract) {
      setContracts(anyItem.concat(getNodeChildrenByNodeType.data.map((item: any) => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive }))));
    }
  }, [getNodeChildrenByNodeType.data, getNodeChildrenByNodeType.isRefetching]);

  useEffect(() => {
    if (selectedParentId && queryNodeType) {
      getNodeChildrenByNodeType.refetch();
    }
  }, [selectedParentId, queryNodeType]);

  useEffect(() => {
    rowFormData.length && setRowFormData([]);
  }, [isSerialised]);
 
  const handleAddNew = () => {
    setRowFormData([
      ...rowFormData,
      {
        id: rowFormDataIndex,
        partTypeId: rowFormDataIndex,
        partTypeName: '',
        softwareVersions: [],
        firmwareVersions: [],
        hardwareRevisionVersions: [],
        fleetTags: []
      }
    ]);
    setRowFormDataIndex(rowFormDataIndex -1);
  };
    
  const onSave = async () => {
    const hasInvalidPartType = rowFormData.some(item => item.partTypeId < 0);
    const hasPartTypeZero = rowFormData.some(item => item.partTypeId === 0);
    const hasPartTypeZeroInPartTypeConfig = skuConfigData?.partTypeConfig?.some(item => item.partTypeId === 0);

    if (isNameUnique) {
      setWarningMessage(SKU_CONFIG_CONSTANTS.NAME_EXIST_ERROR_MESSAGE);
      setShowWarningToast(true);
      return;
    }

    // Name mandate validation
    if (!isEdit && (isEmptyString(skuConfigData?.name) || !skuConfigData?.name)) {
      setWarningMessage(SKU_CONFIG_CONSTANTS.NAME_ERROR_MESSAGE);
      setShowWarningToast(true);
      return;
    }

    if (!isEdit && (isEmptySerialisation || !skuConfigData?.serializationType)) {
      setWarningMessage(SKU_CONFIG_CONSTANTS.SERIALISATION_ERROR_MESSAGE);
      setShowWarningToast(true);
      return;
    }

    if (nodeId === 0) {
      setWarningMessage(SKU_CONFIG_CONSTANTS.CLIENT_CONTRACT_ERROR_MESSAGE);
      setShowWarningToast(true);
      return;
    }

    if (!isEdit && !rowFormData.length) {
      setWarningMessage(SKU_CONFIG_CONSTANTS.PART_TYPE_CONFIG_ERROR_MESSAGE);
      setShowWarningToast(true);
      return;
    }

    if (hasInvalidPartType || hasPartTypeZero || hasPartTypeZeroInPartTypeConfig) {
      setWarningMessage(SKU_CONFIG_CONSTANTS.PART_TYPE_ERROR_MESSAGE);
      setShowWarningToast(true);
      return;
    } 

    if (isSerialised && rowFormData.some((item) => !item.softwareVersions?.length)) {
      setWarningMessage(SKU_CONFIG_CONSTANTS.SOFTWARE_VERSION_ERROR_MESSAGE);
      setShowWarningToast(true);
      return;
    }

    if (isSerialised && rowFormData.some((item) => !item.firmwareVersions?.length)) {
      setWarningMessage(SKU_CONFIG_CONSTANTS.FIRMWARE_VERSION_ERROR_MESSAGE);
      setShowWarningToast(true);
      return;
    }

    if (isSerialised && rowFormData.some((item) => !item.hardwareRevisionVersions?.length)) {
      setWarningMessage(SKU_CONFIG_CONSTANTS.HARDWARE_REVISION_VERSION_ERROR_MESSAGE);
      setShowWarningToast(true);
      return;
    }

    if (rowFormData.some((item) => !item.fleetTags?.length)) {
      setWarningMessage(SKU_CONFIG_CONSTANTS.FLEET_TAG_ERROR_MESSAGE);
      setShowWarningToast(true);
      return;
    }

    const updatedSKUConfigData = { ...skuConfigData, partTypeConfig: skuConfigData?.partTypeConfig || [] };
    if (rowFormData.length > 0) {
      const newRowData: Array<PartTypeConfigType> = rowFormData.map((obj: PartTypeConfigType) => {
        delete obj.id;
        delete obj.partTypeName;
        return obj;
      });
      updatedSKUConfigData.partTypeConfig?.push(...newRowData);
    }

    delete updatedSKUConfigData.serializationType;
    if (skuConfigId !== 0) {
      updatedSKUConfigData.id = skuConfigId;
      await updateSKUConfig.mutateAsync(updatedSKUConfigData);
      onSuccessMessage({ isTrue: true, message: SKU_CONFIG_CONSTANTS.UPDATE_MESSAGE });
    } else {
      updatedSKUConfigData.nodeId = nodeId;
      await createSKUConfigQuery.mutateAsync(updatedSKUConfigData);
      onSuccessMessage({ isTrue: true, message: SKU_CONFIG_CONSTANTS.CREATE_MESSAGE });
    }

    onClose();
  };

  const onChangeNodeType = (nodeType: NodeType, parentId: string) => {
    setSelectedParentId(parentId);
    setQueryNodeType(nodeType);
  };

  const handleNameExist = async () => {
    await getSKUConfigByNameQuery.refetch();
  };

  return (
    <>
      <ContentWrapperBox>
        <CloseIcon handleClose={onClose} />
        <HeadingWrapper variant={TypographyVariantProps.H6}>
          {`${isEdit ? 'Edit' : 'Add'} SKU`} 
        </HeadingWrapper>
        <Grid container>
          <Grid mt={1} xs={3} mr={4} ml={2}>
            <TextField
              label="Name"
              fullWidth
              required={true}
              value={skuConfigData?.name || ''}
              onChange={(value) => {
                setSkuConfigData({ ...skuConfigData, name: value });
              }}
              onBlur={handleNameExist}
            />
          </Grid>
          <Grid xs={3} mt={1}>
            <SelectMenu
              id="serializationType-select"
              label="Serialisation Type *"
              required={true}
              disabled={isEdit}
              items={SERIALISATION_TYPE_DROPDOWN_ITEMS}
              selectedValue={isEdit ? serialisationChecker(skuNonEditableData?.serialisation) : skuConfigData?.serializationType || '-1'}
              onChange={(value) => {
                setSkuConfigData({ ...skuConfigData, serializationType: value === '-1' ? '' : value });
                setIsSerialised(value === SerializationType.Serialised);
              }}
              optionalLabelEnabled={true}
            />
          </Grid>
          <Grid mt={2} ml={4}>
            <Switch 
              checked={skuConfigData?.isActive}
              color={ColorProps.Success} 
              onChange={(event) => {
                setSkuConfigData({ ...skuConfigData, isActive: event.target.checked });
              }}
            /> isActive
          </Grid>
        </Grid>
        <Grid container>
          <Grid mt={2} xs={5} ml={2} mr={4}>
            <SelectionButton
              label="Client Selection *"
              value={isEdit ? `${skuNonEditableData?.clientContract}` : `${clientContractData?.clientContractHierarchy}`}
              onClick={() => {
                !isEdit && setOpenParentSelector(true);
              }}
              disabled={isEdit}
            />
          </Grid>
        </Grid>
        <br/>
        <Stack direction="row" justifyContent="space-between" margin={1}>
          <Typography>Part Type Config List</Typography>
          {(!isEmptySerialisation || skuNonEditableData?.serialisation) && nodeId !== 0 && 
          <Button variant={ButtonVariantProps.Secondary} onClick={handleAddNew} startIcon={<Add/>}>
            Add
          </Button>
          } 
        </Stack>
        <Divider/>
        <br/>
        {(!isEmptySerialisation || skuNonEditableData?.serialisation) && nodeId !== 0 && 
          <TableContainer>
            <Table size="small">
              <TableBody>
                {skuConfigData?.partTypeConfig?.map((val: PartTypeConfigType, index) => 
                  <SKUPropertySelectionItemRowFragment 
                    key={index}
                    value={val}
                    isSerialized={isSerialised}
                    nodeId={nodeId}
                    onChange={(val) => {
                      const partTypeConfig = skuConfigData?.partTypeConfig?.map((obj, id) => id === index ? val : obj);
                      partTypeConfig && setSkuConfigData({ 
                        ...skuConfigData, 
                        partTypeConfig
                      });
                    }}
                  />
                )}
                {rowFormData?.map((val: PartTypeConfigType, index) => 
                  <SKUPropertySelectionItemRowFragment 
                    key={index}
                    isNew={true}
                    value={val}
                    isSerialized={isSerialised}
                    nodeId={nodeId}
                    onChange={(val) => {
                      const updatedFormData = rowFormData?.map((obj) => obj.id === val.id ? val : obj);
                      updatedFormData && setRowFormData(updatedFormData);
                    }}
                    onCancel={() => {
                      const filteredFormData = rowFormData?.filter((obj) => obj.id !== val.id);
                      filteredFormData && setRowFormData(filteredFormData);
                    }}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        }
        <Grid container mb={2}>
          <Footer>
            <StyledButton variant={ButtonVariantProps.Secondary} onClick={onClose}>Cancel</StyledButton>
            <StyledButton variant={ButtonVariantProps.Primary} onClick={onSave}>Save</StyledButton>
          </Footer>
        </Grid>
        <BrandSelector
          open={openParentSelector}
          handleClose={() => {
            setOpenParentSelector(false);
          }}
          onChangeNodeType={onChangeNodeType}
          handleParentSelection={() => {
            setOpenParentSelector(false);
          }}
          onChange={(client: string, contract: string, brand: string, parentHierarchy: string) => {
            setClientContractData({
              clientId: client,
              contractId: contract,
              clientContractHierarchy: parentHierarchy === UNDEFINED_TREE_FIRST_ITEM ? '' : parentHierarchy
            });
            setNodeId(contract === 'any' ? parseInt(client) : parseInt(contract));
          }}
          handleClear={() => {
            setClientContractData({
              clientId: '',
              contractId: '',
              clientContractHierarchy: ''
            });
          }}
          clients={clients}
          contracts={contracts}
          brands={[]}
          clientId={parseInt(clientContractData?.clientId)}
          contractId={parseInt(clientContractData?.contractId)}
          allFieldsRequired={false}
          isBrandEnabled={false}
        />
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right', }} open={showWarningToast} autoHideDuration={4000} onClose={() => setShowWarningToast(false)}>
          <Alert onClose={() => setShowWarningToast(false)} severity="warning">
            <AlertTitle>Warning</AlertTitle>
            <Box>{warningMessage}</Box>
          </Alert>
        </Snackbar>
      </ContentWrapperBox>
    </>
  );
};
