import React, { useEffect, useState } from 'react';
import { ServiceActionBar } from './ServiceActionBar';
import { ServiceActionBarFragmentProps } from './ServiceActionBarFragment.props';
import { useGetUserSystemPermissions } from '../../../../queries/user-query';
import { isUserHasPermission } from '../../../../configs/permissions';
import { useGetNextServicesStatuses, useSaveServiceStatus } from '../../../../queries/service-status-query';
import { ActionPermissions, PartTypeActionLiveSearchSerialiseType, ServiceNodeAllocationData, ServiceStatusCode, Technician } from '../../../../@types';
import { useGetServiceCheckInData, useGetTechnicianListByLocationNodeId } from '../../../../queries/service-checkin-checkout-query';
import { useUpdateServiceAllocation } from '../../../../queries/service-query';
import { AllocationSubmitData } from '../../../../@types/allocation.type';
import { PartConditionOptions } from '../../../../@types/part.type';
import { useAddPartAction, useDeletePartActionById, useGetPartActionByServiceId, useUpdatePartAction } from '../../../../queries/partAction-query';
import { Snackbar } from '../../../../components/atoms/Snackbar';
import { useCreateAndUpdateTask, useDeleteTaskById, useGetTaskByServiceId } from '../../../../queries/task-query';
import { TaskCard } from '../../../../@types/task.type';
import { CustomisePartActionCard, PartActionType } from '../../../../@types/partAction.type';
import { getPartTypeActionLiveSearchSerialiseType } from '../../../../utils/part-type';

export const ServiceActionBarFragment: React.FC<ServiceActionBarFragmentProps> = ({
  data,
  onCheckIn,
  onCheckOut,
  onChangeInterceptedFormsObject,
  closeCheckInCheckOut,
  checkInData,
  checkOutData,
  isCheckIn,
  isCheckOut
}) => {
  const [newServiceType, setNewServiceType] = useState('-1');
  const [newServiceTypeNote, setNewServiceTypeNote] = useState('');
  const [isCheckInEnabled, setIsCheckInEnabled] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isCheckOutEnabled, setIsCheckOutEnabled] = useState(false);
  const [technicians, setTechnicians] = useState<Technician[]>([]);
  const [partActionCardDataset, setPartActionCardDataset] = useState<CustomisePartActionCard[]>([]);
  const [taskCardDataset, setTaskCardDataset] = useState<TaskCard[]>([]);
  const [partActionData, setPartActionData] = useState<CustomisePartActionCard>();

  const getUserSystemPermissionsQuery = useGetUserSystemPermissions();
  const nextServicesStatusesQuery = useGetNextServicesStatuses(data.serviceStatusCode, data.serviceTypeCode);
  const saveServiceStatusQuery = useSaveServiceStatus(data.id);
  const getServiceCheckIn = useGetServiceCheckInData(data.id);
  const updateServiceAllocationQuery = useUpdateServiceAllocation(data.id);
  const createAndUpdateTaskQuery = useCreateAndUpdateTask(data.id);
  const getTechnicianListByLocationNodeId = useGetTechnicianListByLocationNodeId(data.mainLocationNodeId || 0);
  const addPartActionQuery = useAddPartAction(data.id);
  const updatePartActionQuery = useUpdatePartAction(data.id);
  const getPartActionsByServiceId = useGetPartActionByServiceId(data.id);
  const getTasksByServiceIdQuery = useGetTaskByServiceId(data.id);
  const deletePartActionById = useDeletePartActionById(data.id);
  const deleteTaskById = useDeleteTaskById(data.id);

  useEffect(() => {
    data.id && getTasksByServiceIdQuery.refetch();
  }, [data.id]);

  useEffect(() => {
    if (getTasksByServiceIdQuery.data) {
      const taskData = [ ...getTasksByServiceIdQuery.data, ...taskCardDataset];
      const uniqueTaskData = [...new Map(taskData.map((taskItem) => [taskItem.id, taskItem])).values()];

      setTaskCardDataset(uniqueTaskData);
    }
  }, [getTasksByServiceIdQuery.data]);

  useEffect(() => {
    getTechnicianListByLocationNodeId.data && setTechnicians(getTechnicianListByLocationNodeId.data);
  }, [getTechnicianListByLocationNodeId.data]);

  useEffect(() => {
    if (data.serviceStatusCode && data.serviceTypeCode) {
      nextServicesStatusesQuery.refetch();
    }
  }, [data]);

  useEffect(() => {
    const permissions = getUserSystemPermissionsQuery.data;
    const isTechnicianCheckedIn = getServiceCheckIn?.data?.some((check: any) => check.checkOutTime === null);
    const isCheckinAvailable = data.serviceType[0].isCheckInAvailable;

    if (permissions && data) {
      const isCheckInCheckOutPermission = isUserHasPermission(ActionPermissions.Service_Edit_Checkin_Checkout_Allow, permissions);

      // CheckIn
      switch (data?.serviceStatusCode) {
        case ServiceStatusCode.New:
        case ServiceStatusCode.OnTheWay:
        case ServiceStatusCode.OnSite:
          setIsCheckInEnabled(isCheckInCheckOutPermission && isCheckinAvailable && !(isCheckInCheckOutPermission && isTechnicianCheckedIn));
          break;
        default:
          setIsCheckInEnabled(false);
      }

      // CheckOut
      setIsCheckOutEnabled(isCheckInCheckOutPermission && isTechnicianCheckedIn);
    }
  }, [getUserSystemPermissionsQuery.data, getServiceCheckIn.data, data]);

  const handleNewServiceStatusSubmit = (byPassInteractionCheck?: boolean) => {
    if (newServiceType !== '-1') {
      const newStatus = {
        nextStatus: newServiceType,
        note: newServiceTypeNote,
        byPassInteractionCheck
      };

      saveServiceStatusQuery.mutateAsync(newStatus);
    }
  };

  useEffect(()=> {
    getPartActionsByServiceId.refetch();
  },[]);

  useEffect(() => {
    if (getPartActionsByServiceId.data) { 
      const array: CustomisePartActionCard[] = [];       
      let definedIndex = 1;         
      getPartActionsByServiceId.data.forEach((data) => {
        if (data.actionTypeCode === PartActionType.REPLACE) {
          const inValue = {
            id: definedIndex,
            partTypeId: data.installedOrMaintainedPartData?.partTypeId,  
            actionId: data.id,
            name: data.installedOrMaintainedPartData?.partTypeName,
            sw: data.installedOrMaintainedPartData?.softwareVersion,
            hw: data.installedOrMaintainedPartData?.hardwareVersion,
            fw: data.installedOrMaintainedPartData?.firmwareVersion,
            ft: data.installedOrMaintainedPartData?.fleetTag,
            serial: data.installedOrMaintainedPartData?.partSerial1,
            partId: data.installedOrMaintainedPartData?.partId,
            ns_partId: data.installedOrMaintainedPartData?.ns_partId,
            qty: data.installedOrMaintainedPartData?.qty,
            category: data.installedOrMaintainedPartData?.partTypeCatagoryCode,
            condition: data.installedOrMaintainedPartData?.condition,
            location: data.installedOrMaintainedPartData?.location,
            notes: data.notes,
            type: data.actionTypeCode,
            images: [],
            isInSiblingHierarchy: data.installedOrMaintainedPartData?.isInSiblingHierarchy
          };

          const outValue = {
            id: definedIndex,
            partTypeId: data.removedPartData?.partTypeId,
            actionId: data.id,
            name: data.removedPartData?.partTypeName,
            sw: data.removedPartData?.softwareVersion,
            hw: data.removedPartData?.hardwareVersion,
            fw: data.removedPartData?.firmwareVersion,
            ft: data.removedPartData?.fleetTag,
            serial: data.removedPartData?.partSerial1,
            partId: data.removedPartData.partId,
            ns_partId: data.removedPartData.ns_partId,
            qty: data.removedPartData?.qty,
            category: data.removedPartData?.partTypeCatagoryCode,
            condition: data.removedPartData?.condition,
            location: data.removedPartData?.location,
            locationId: data.removedPartData?.locationId,
            notes: data.notes,
            type: data.actionTypeCode,
            images: [],
            isInSiteHierarchy: data.removedPartData?.isInSiteHierarchy,
            isUnderClientStructure: data.removedPartData?.isUnderClientStructure
          };
          array.push({
            value: inValue,
            inValue: inValue,
            outValue: outValue
          });
        } else if ( data.actionTypeCode === PartActionType.CLEAN ) {
          const saveValue = {
            id: definedIndex,
            partTypeId: data.installedOrMaintainedPartData?.partTypeId,
            actionId: data.id,
            name: data.installedOrMaintainedPartData?.partTypeName,
            sw: data.installedOrMaintainedPartData?.softwareVersion,
            hw: data.installedOrMaintainedPartData?.hardwareVersion,
            fw: data.installedOrMaintainedPartData?.firmwareVersion,
            ft: data.installedOrMaintainedPartData?.fleetTag,
            serial: data.installedOrMaintainedPartData?.partSerial1,
            partId: data.installedOrMaintainedPartData?.partId,
            ns_partId: data.installedOrMaintainedPartData?.ns_partId,
            qty: data.installedOrMaintainedPartData?.qty,
            category: data.installedOrMaintainedPartData?.partTypeCatagoryCode,
            condition: data.installedOrMaintainedPartData?.condition,
            location: data.installedOrMaintainedPartData?.location,
            notes: data.notes,
            type: data.actionTypeCode || '',
            images: []
          };
          array.push({
            value: saveValue,
            inValue: saveValue,
            outValue: saveValue
          });
          
        } else if ( data.actionTypeCode === PartActionType.TEST ) {
          const saveValue = {
            id: definedIndex,
            partTypeId: data.installedOrMaintainedPartData?.partTypeId,
            actionId: data.id,
            name: data.installedOrMaintainedPartData?.partTypeName,
            sw: data.installedOrMaintainedPartData?.softwareVersion,
            hw: data.installedOrMaintainedPartData?.hardwareVersion,
            fw: data.installedOrMaintainedPartData?.firmwareVersion,
            ft: data.installedOrMaintainedPartData?.fleetTag,
            serial: data.installedOrMaintainedPartData?.partSerial1,
            partId: data.installedOrMaintainedPartData?.partId,
            ns_partId: data.installedOrMaintainedPartData?.ns_partId,
            qty: data.installedOrMaintainedPartData?.qty,
            category: data.installedOrMaintainedPartData?.partTypeCatagoryCode,
            condition: data.installedOrMaintainedPartData?.condition,
            location: data.installedOrMaintainedPartData?.location,
            notes: data.notes,
            type: data.actionTypeCode || '',
            images: []
          };
          array.push({
            value: saveValue,
            inValue: saveValue,
            outValue: saveValue
          });
          
        } else if (data.actionTypeCode === PartActionType.REMOVE) {
          const outValue = {
            id: definedIndex,
            partTypeId:data.removedPartData?.partTypeId,
            actionId: data.id,
            name: data.removedPartData?.partTypeName,
            sw: data.removedPartData?.softwareVersion,
            hw: data.removedPartData?.hardwareVersion,
            fw: data.removedPartData?.firmwareVersion,
            ft: data.removedPartData?.fleetTag,
            serial: data.removedPartData?.partSerial1,
            qty: data.removedPartData?.qty,
            partId: data.removedPartData?.partId,
            ns_partId: data.removedPartData?.ns_partId,
            condition: data.removedPartData?.condition,
            category: data.removedPartData?.partTypeCatagoryCode,
            location: data.removedPartData?.location,
            locationId: data.removedPartData?.locationId,
            notes: data.notes,
            type: data.actionTypeCode,
            images: [],
            isInSiteHierarchy: data.removedPartData?.isInSiteHierarchy,
            isUnderClientStructure: data.removedPartData?.isUnderClientStructure
          };
          array.push({
            value: outValue,
            inValue: outValue,
            outValue: outValue
          });
        } else if (data.actionTypeCode === PartActionType.DOA) {
          const outValue = {
            id: definedIndex,
            partTypeId: data.removedPartData?.partTypeId,
            actionId: data.id,
            name: data.removedPartData?.partTypeName,
            sw: data.removedPartData?.softwareVersion,
            hw: data.removedPartData?.hardwareVersion,
            fw: data.removedPartData?.firmwareVersion,
            ft: data.removedPartData?.fleetTag,
            serial: data.removedPartData?.partSerial1,
            partId: data.removedPartData?.partId,
            ns_partId: data.removedPartData?.ns_partId,
            qty: data.removedPartData?.qty,
            category: data.removedPartData?.partTypeCatagoryCode,
            condition: data.removedPartData?.condition,
            location: data.removedPartData?.location,
            locationId: data.removedPartData?.locationId,
            notes: data.notes,
            type: data.actionTypeCode,
            images: [],
            isInSiteHierarchy: data.removedPartData?.isInSiteHierarchy,
            isUnderClientStructure: data.removedPartData?.isUnderClientStructure
          };
          array.push({
            value: outValue,
            inValue: outValue,
            outValue: outValue
          });
        } else if (data.actionTypeCode === PartActionType.INSTALL) {
          const saveValue = {
            id: definedIndex,
            partTypeId: data.installedOrMaintainedPartData?.partTypeId,
            actionId: data.id,
            name: data.installedOrMaintainedPartData?.partTypeName,
            sw: data.installedOrMaintainedPartData?.softwareVersion,
            hw: data.installedOrMaintainedPartData?.hardwareVersion,
            fw: data.installedOrMaintainedPartData?.firmwareVersion,
            ft: data.installedOrMaintainedPartData?.fleetTag,
            serial: data.installedOrMaintainedPartData?.partSerial1,
            qty: data.installedOrMaintainedPartData?.qty,
            partId: data.installedOrMaintainedPartData?.partId,
            ns_partId: data.installedOrMaintainedPartData?.ns_partId,
            category: data.installedOrMaintainedPartData?.partTypeCatagoryCode,
            condition: data.installedOrMaintainedPartData?.condition,
            location: data.installedOrMaintainedPartData?.location,
            notes: data.notes,
            type: data.actionTypeCode,
            images: [],
            isInSiblingHierarchy: data.installedOrMaintainedPartData?.isInSiblingHierarchy,
          };
          array.push({
            value: saveValue,
            inValue: saveValue,
            outValue: saveValue
          });
        } else {
          const saveValue = {
            id: definedIndex,
            partTypeId: data.installedOrMaintainedPartData?.partTypeId,
            actionId: data.id,
            name: data.installedOrMaintainedPartData?.partTypeName,
            sw: data.installedOrMaintainedPartData?.softwareVersion,
            hw: data.installedOrMaintainedPartData?.hardwareVersion,
            fw: data.installedOrMaintainedPartData?.firmwareVersion,
            ft: data.installedOrMaintainedPartData?.fleetTag,
            serial: data.installedOrMaintainedPartData?.partSerial1,
            qty: data.installedOrMaintainedPartData?.qty,
            partId: data.installedOrMaintainedPartData?.partId,
            ns_partId: data.installedOrMaintainedPartData?.ns_partId,
            category: data.installedOrMaintainedPartData?.partTypeCatagoryCode,
            condition: data.installedOrMaintainedPartData?.condition,
            location: data.installedOrMaintainedPartData?.location,
            notes: data.notes,
            type: data.actionTypeCode,
            images: [],
          };
          array.push({
            value: saveValue,
            inValue: saveValue,
            outValue: saveValue
          });
        }
        definedIndex = definedIndex + 1;
      });

      const newDataSet = partActionCardDataset && partActionCardDataset.filter((obj) => !obj.value.actionId);
      setPartActionCardDataset([...array, ...newDataSet]);
    }
  }, [getPartActionsByServiceId.data, data.id]);

  const handleOnAllocationChange = (allocation: AllocationSubmitData) => {
    const allocationData: ServiceNodeAllocationData = {
      serviceId: data.id,
      userDepotAllocationNodeId: allocation.nodeId,
      allocationTypeCode: allocation.allocationTypeCode
    };
    updateServiceAllocationQuery.mutateAsync(allocationData);
  };

  const onChangePartActionCard = (updatedPartActionCardDataset: CustomisePartActionCard[]) => {
    setPartActionCardDataset(updatedPartActionCardDataset);
    getPartActionsByServiceId.refetch();
  };

  const handleSavePartActionCard = async (partAction: CustomisePartActionCard) => {
    if(partAction.value.type === PartActionType.REMOVE) {
      const outValue = {
        id: partAction.outValue?.id,
        partTypeId: partAction.outValue?.partTypeId,
        name: partAction.outValue?.name,
        sw: partAction.outValue?.sw,
        hw: partAction.outValue?.hw,
        fw: partAction.outValue?.fw,
        ft: partAction.outValue?.ft,
        serial: partAction.outValue?.serial,
        qty: partAction.outValue?.qty,
        category: partAction.outValue?.category,
        condition: PartConditionOptions.FAULTY,
        location: partAction.outValue?.location,
        locationId: partAction.outValue?.locationId,
        notes: partAction.outValue?.notes,
        type: partAction.outValue?.type,
        images: [],
        partId: partAction.outValue?.partId,
        ns_partId: partAction.outValue?.ns_partId,
        isInSiteHierarchy: partAction.outValue?.isInSiteHierarchy,
        isUnderClientStructure: partAction.outValue?.isUnderClientStructure
      };
      if (partAction.value.actionId) {
        await updatePartActionQuery.mutateAsync({
          id: partAction.outValue?.actionId,
          data: {
            actionTypeCode: partAction.value.type,
            serviceId: data.id,
            installedOrMaintainedPartData: {},
            removedPartData: outValue,
            notes: partAction.outValue?.notes,
            execute: false
          }
        });
      } else {
        await addPartActionQuery.mutateAsync({
          actionTypeCode: partAction.outValue?.type,
          serviceId: data.id,
          installedOrMaintainedPartData: {},
          removedPartData: outValue,
          notes: partAction.outValue?.notes,
          execute: false
        });
        
        const newDataSet = partActionCardDataset && partActionCardDataset.filter((obj) => obj.value.id !== partAction.value.id);
        setPartActionCardDataset(newDataSet);
      }
    } else if (partAction.value.type === PartActionType.REPLACE) {
      const serialiseType = getPartTypeActionLiveSearchSerialiseType(partAction.inValue?.category);
      const inValue = {
        id: partAction.inValue?.id,
        partTypeId: partAction.inValue?.partTypeId,
        name: partAction.inValue?.name,
        sw: partAction.inValue?.sw,
        hw: partAction.inValue?.hw,
        fw: partAction.inValue?.fw,
        ft: partAction.inValue?.ft,
        serial: partAction.inValue?.serial,
        qty: partAction.inValue?.qty,  
        condition: serialiseType === PartTypeActionLiveSearchSerialiseType.Serialised ? PartConditionOptions.RESERVED : partAction.inValue?.condition,
        location: partAction.inValue?.location,
        type: partAction.inValue?.type,
        notes: partAction.outValue?.notes,
        images: [],
        partId: partAction.inValue?.partId,
        ns_partId: partAction.inValue?.ns_partId,
        category: partAction.inValue?.category,
        isInSiblingHierarchy: partAction.inValue?.isInSiblingHierarchy
      };
      const outValue = {
        id: partAction.outValue?.id,
        partTypeId: partAction.outValue?.partTypeId,
        name: partAction.outValue?.name,
        sw: partAction.outValue?.sw,
        hw: partAction.outValue?.hw,
        fw: partAction.outValue?.fw,
        ft: partAction.outValue?.ft,
        serial: partAction.outValue?.serial,
        qty: partAction.outValue?.qty,
        category: partAction.outValue?.category,
        condition: partAction.outValue?.condition,
        location: partAction.outValue?.location,
        locationId: partAction.outValue?.locationId,
        notes: partAction.outValue?.notes,
        type: partAction.outValue?.type,
        images: [],
        partId: partAction.outValue?.partId,
        ns_partId: partAction.outValue?.ns_partId,
        isInSiteHierarchy: partAction.outValue?.isInSiteHierarchy,
        isUnderClientStructure: partAction.outValue?.isUnderClientStructure
      };
      if (partAction.value.actionId) {
        await updatePartActionQuery.mutateAsync({
          id: partAction.value.actionId,
          data: {
            actionTypeCode: partAction.value.type,
            serviceId: data.id,
            installedOrMaintainedPartData: inValue,
            removedPartData: outValue,
            notes: partAction.outValue?.notes,
            execute: false
          }
        });
      } else {
        await addPartActionQuery.mutateAsync({
          actionTypeCode: partAction.value.type,
          serviceId: data.id,
          installedOrMaintainedPartData: inValue,
          removedPartData: outValue,
          notes: partAction.outValue?.notes,
          execute: false
        });
        
        const newDataSet = partActionCardDataset && partActionCardDataset.filter((obj) => obj.value.id !== partAction.value.id);
        setPartActionCardDataset(newDataSet);
      }
    } else if (partAction.value.type === PartActionType.CLEAN){
      const value = {
        id: partAction.value.id,
        partTypeId: partAction.value.partTypeId,
        name: partAction.value.name,
        sw: partAction.value.sw,
        hw: partAction.value.hw,
        fw: partAction.value.fw,
        ft: partAction.value.ft,
        serial: partAction.value.serial,
        qty: partAction.value.qty,
        category: partAction.value.category,
        condition: partAction.value.condition,
        location: partAction.value.location,
        notes: partAction.value.notes,
        type: partAction.value.type,
        images: [],
        partId: partAction.value.partId,
        ns_partId: partAction.value.ns_partId
      };
      if (partAction.value.actionId) {
        await updatePartActionQuery.mutateAsync({
          id: partAction.value.actionId,
          data: {
            actionTypeCode: partAction.value.type,
            serviceId: data.id,
            installedOrMaintainedPartData: value,
            removedPartData: {},
            notes: partAction.value.notes,
            execute: false
          }
        });
      } else {
        await addPartActionQuery.mutateAsync({
          actionTypeCode: partAction.value.type,
          serviceId: data.id,
          installedOrMaintainedPartData: value,
          removedPartData: {},
          notes: partAction.value.notes,
          execute: false
        });
        
        const newDataSet = partActionCardDataset && partActionCardDataset.filter((obj) => obj.value.id !== partAction.value.id);
        setPartActionCardDataset(newDataSet);
      }
    } else if (partAction.value.type === PartActionType.TEST){
      const value = {
        id: partAction.value.id,
        partTypeId: partAction.value.partTypeId,
        name: partAction.value.name,
        sw: partAction.value.sw,
        hw: partAction.value.hw,
        fw: partAction.value.fw,
        ft: partAction.value.ft,
        serial: partAction.value.serial,
        qty: partAction.value.qty,
        category: partAction.value.category,
        condition: partAction.value.condition,
        location: partAction.value.location,
        notes: partAction.value.notes,
        type: partAction.value.type,
        images: [],
        partId: partAction.value.partId,
        ns_partId: partAction.value.ns_partId
      };
      if (partAction.value.actionId) {
        await updatePartActionQuery.mutateAsync({
          id: partAction.value.actionId,
          data: {
            actionTypeCode: partAction.value.type,
            serviceId: data.id,
            installedOrMaintainedPartData: value,
            removedPartData: {},
            notes: partAction.value.notes,
            execute: false
          }
        });
      } else {
        await addPartActionQuery.mutateAsync({
          actionTypeCode: partAction.value.type,
          serviceId: data.id,
          installedOrMaintainedPartData: value,
          removedPartData: {},
          notes: partAction.value.notes,
          execute: false
        });
        
        const newDataSet = partActionCardDataset && partActionCardDataset.filter((obj) => obj.value.id !== partAction.value.id);
        setPartActionCardDataset(newDataSet);
      }
    } else if (partAction.value.type === PartActionType.DOA) {
      const outValue = {
        id: partAction.outValue?.id,
        partTypeId: partAction.outValue?.partTypeId,
        name: partAction.outValue?.name,
        sw: partAction.outValue?.sw,
        hw: partAction.outValue?.hw,
        fw: partAction.outValue?.fw,
        ft: partAction.outValue?.ft,
        serial: partAction.outValue?.serial,
        qty: partAction.outValue?.qty,
        category: partAction.outValue?.category,
        condition: PartConditionOptions.DOA,
        location: partAction.outValue?.location,
        locationId: partAction.outValue?.locationId,
        notes: partAction.outValue?.notes,
        type: partAction.outValue?.type,
        images: [],
        partId: partAction.outValue?.partId,
        ns_partId: partAction.outValue?.ns_partId,
        isInSiteHierarchy: partAction.outValue?.isInSiteHierarchy,
        isUnderClientStructure: partAction.outValue?.isUnderClientStructure
      };
      if (partAction.value.actionId) {
        await updatePartActionQuery.mutateAsync({
          id: partAction.value.actionId,
          data: {
            actionTypeCode: partAction.value.type,
            serviceId: data.id,
            installedOrMaintainedPartData: {},
            removedPartData: outValue,
            notes: partAction.outValue?.notes,
            execute: false
          }
        });
      } else {
        await addPartActionQuery.mutateAsync({
          actionTypeCode: partAction.value.type,
          serviceId: data.id,
          installedOrMaintainedPartData: {},
          removedPartData: outValue,
          notes: partAction.outValue?.notes,
          execute: false
        });
        
        const newDataSet = partActionCardDataset && partActionCardDataset.filter((obj) => obj.value.id !== partAction.value.id);
        setPartActionCardDataset(newDataSet);
      }
    } else if (partAction.value.type === PartActionType.INSTALL) {
      const serialiseType = getPartTypeActionLiveSearchSerialiseType(partAction.value.category);
      const value = {
        id: partAction.value.id,
        partTypeId: partAction.value.partTypeId,
        name: partAction.value.name,
        sw: partAction.value.sw,
        hw: partAction.value.hw,
        fw: partAction.value.fw,
        ft: partAction.value.ft,
        serial: partAction.value.serial,
        qty: partAction.value.qty,
        category: partAction.value.category,
        condition: serialiseType === PartTypeActionLiveSearchSerialiseType.Serialised ? PartConditionOptions.RESERVED : partAction.value.condition,
        location: partAction.value.location,
        notes: partAction.value.notes,
        type: partAction.value.type,
        images: [],
        partId: partAction.value.partId,
        ns_partId: partAction.value.ns_partId,
        isInSiblingHierarchy: partAction.value.isInSiblingHierarchy
      };
      if (partAction.value.actionId) {
        await updatePartActionQuery.mutateAsync({
          id: partAction.value.actionId,
          data: {
            actionTypeCode: partAction.value.type,
            serviceId: data.id,
            installedOrMaintainedPartData: value,
            removedPartData: {},
            notes: partAction.value.notes,
            execute: false
          }
        });
      } else {
        await addPartActionQuery.mutateAsync({
          actionTypeCode: partAction.value.type,
          serviceId: data.id,
          installedOrMaintainedPartData: value,
          removedPartData: {},
          notes: partAction.value.notes,
          execute: false
        });
        const newDataSet = partActionCardDataset && partActionCardDataset.filter((obj) => obj.value.id !== partAction.value.id);
        setPartActionCardDataset(newDataSet);
      }
    } else {
      const value = {
        id: partAction.value.id,
        partTypeId: partAction.value.partTypeId,
        name: partAction.value.name,
        sw: partAction.value.sw,
        hw: partAction.value.hw,
        fw: partAction.value.fw,
        ft: partAction.value.ft,
        serial: partAction.value.serial,
        qty: partAction.value.qty,
        category: partAction.value.category,
        condition: partAction.value.condition,
        location: partAction.value.location,
        notes: partAction.value.notes,
        type: partAction.value.type,
        images: [],
        partId: partAction.value.partId,
        ns_partId: partAction.value.ns_partId
      };
      if (partAction.value.actionId) {
        await updatePartActionQuery.mutateAsync({
          id: partAction.value.actionId,
          data: {
            actionTypeCode: partAction.value.type,
            serviceId: data.id,
            installedOrMaintainedPartData: value,
            removedPartData: {},
            notes: partAction.value.notes,
            execute: false
          }
        });
      } else {
        await addPartActionQuery.mutateAsync({
          actionTypeCode: partAction.value.type,
          serviceId: data.id,
          installedOrMaintainedPartData: value,
          removedPartData: {},
          notes: partAction.value.notes,
          execute: false
        });
        const newDataSet = partActionCardDataset && partActionCardDataset.filter((obj) => obj.value.id !== partAction.value.id);
        setPartActionCardDataset(newDataSet);
      }
    }

    getPartActionsByServiceId.refetch();
    setToastMessage('Succesfully Saved');
    setOpenToast(true);
  };

  const handleExecPartActionCard = async(partAction: CustomisePartActionCard) => {
    setPartActionData(partAction);
    if (partAction.value.type === PartActionType.REMOVE) {
      const outValue = {
        id: partAction.outValue?.id,
        partTypeId: partAction.outValue?.partTypeId,
        name: partAction.outValue?.name,
        sw: partAction.outValue?.sw,
        hw: partAction.outValue?.hw,
        fw: partAction.outValue?.fw,
        ft: partAction.outValue?.ft,
        serial: partAction.outValue?.serial,
        qty: partAction.outValue?.qty,
        category: partAction.outValue?.category,
        condition: PartConditionOptions.FAULTY,
        location: partAction.outValue?.location,
        notes: partAction.outValue?.notes,
        type: partAction.outValue?.type,
        images: [],
        partId: partAction.outValue?.partId,
        ns_partId: partAction.outValue?.ns_partId
      };
      if (partAction.value.actionId) {
        await updatePartActionQuery.mutateAsync({
          id: partAction.outValue?.actionId,
          data: {
            actionTypeCode: partAction.outValue?.type,
            serviceId: data.id,
            installedOrMaintainedPartData: {},
            removedPartData: outValue,
            notes: partAction.value.notes,
            execute: true
          }
        });
      } else {
        await addPartActionQuery.mutateAsync({
          actionTypeCode: partAction.outValue?.type,
          serviceId: data.id,
          installedOrMaintainedPartData: {},
          removedPartData: outValue,
          notes: partAction.outValue?.notes,
          execute: true
        });
      }
    } else if (partAction.value.type === PartActionType.REPLACE) {
      const inValue = {
        id: partAction.inValue?.id,
        partTypeId: partAction.inValue?.partTypeId,
        name: partAction.inValue?.name,
        sw: partAction.inValue?.sw,
        hw: partAction.inValue?.hw,
        fw: partAction.inValue?.fw,
        ft: partAction.inValue?.ft,
        serial: partAction.inValue?.serial,
        qty: partAction.inValue?.qty,
        category: partAction.inValue?.category,
        condition: partAction.inValue?.condition,
        location: partAction.inValue?.location,
        notes: partAction.outValue?.notes,
        type: partAction.inValue?.type,
        images: [],
        partId: partAction.inValue?.partId,
        ns_partId: partAction.inValue?.ns_partId
      };
      const outValue = {
        id: partAction.outValue?.id,
        partTypeId: partAction.outValue?.partTypeId,
        name: partAction.outValue?.name,
        sw: partAction.outValue?.sw,
        hw: partAction.outValue?.hw,
        fw: partAction.outValue?.fw,
        ft: partAction.outValue?.ft,
        serial: partAction.outValue?.serial,
        qty: partAction.outValue?.qty,
        category: partAction.outValue?.category,
        condition: partAction.outValue?.condition,
        location: partAction.outValue?.location,
        notes: partAction.outValue?.notes,
        type: partAction.outValue?.type,
        images: [],
        partId: partAction.outValue?.partId,
        ns_partId: partAction.outValue?.ns_partId
      };
      if (partAction.value.actionId) {
        await updatePartActionQuery.mutateAsync({
          id: partAction.value.actionId,
          data: {
            actionTypeCode: partAction.value.type,
            serviceId: data.id,
            installedOrMaintainedPartData: inValue,
            removedPartData: outValue,
            notes: partAction.outValue?.notes,
            execute: true
          }
        });
      } else {
        await addPartActionQuery.mutateAsync({
          actionTypeCode: partAction.value.type,
          serviceId: data.id,
          installedOrMaintainedPartData: inValue,
          removedPartData: outValue,
          notes: partAction.outValue?.notes,
          execute: true
        });
      }
    } else if (partAction.value.type === PartActionType.CLEAN) {
      const value = {
        id: partAction.value?.id,
        partTypeId: partAction.value.partTypeId,
        name: partAction.value?.name,
        sw: partAction.value?.sw,
        hw: partAction.value?.hw,
        fw: partAction.value?.fw,
        ft: partAction.value?.ft,
        serial: partAction.value?.serial,
        qty: partAction.value?.qty,
        category: partAction.value?.category,
        condition: partAction.value?.condition,
        location: partAction.value?.location,
        notes: partAction.value?.notes,
        type: partAction.value?.type,
        images: [],
        partId: partAction.value?.partId,
        ns_partId: partAction.value?.ns_partId
      };
      if (partAction.value.actionId) {
        await updatePartActionQuery.mutateAsync({
          id: partAction.value.actionId,
          data: {
            actionTypeCode: partAction.value.type,
            serviceId: data.id,
            installedOrMaintainedPartData: value,
            removedPartData: {},
            notes: partAction.value.notes,
            execute: true
          }
        });
      } else {
        await addPartActionQuery.mutateAsync({
          actionTypeCode: partAction.value.type,
          serviceId: data.id,
          installedOrMaintainedPartData: value,
          removedPartData: {},
          notes: partAction.value.notes,
          execute: true
        });
      }
    } else if (partAction.value.type === PartActionType.TEST) {
      const value = {
        id: partAction.value?.id,
        partTypeId: partAction.value.partTypeId,
        name: partAction.value?.name,
        sw: partAction.value?.sw,
        hw: partAction.value?.hw,
        fw: partAction.value?.fw,
        ft: partAction.value?.ft,
        serial: partAction.value?.serial,
        qty: partAction.value?.qty,
        category: partAction.value?.category,
        condition: partAction.value?.condition,
        location: partAction.value?.location,
        notes: partAction.value?.notes,
        type: partAction.value?.type,
        images: [],
        partId: partAction.value?.partId,
        ns_partId: partAction.value?.ns_partId
      };
      if (partAction.value.actionId) {
        await updatePartActionQuery.mutateAsync({
          id: partAction.value.actionId,
          data: {
            actionTypeCode: partAction.value.type,
            serviceId: data.id,
            installedOrMaintainedPartData: value,
            removedPartData: {},
            notes: partAction.value.notes,
            execute: true
          }
        });
      } else {
        await addPartActionQuery.mutateAsync({
          actionTypeCode: partAction.value.type,
          serviceId: data.id,
          installedOrMaintainedPartData: value,
          removedPartData: {},
          notes: partAction.value.notes,
          execute: true
        });
      }
    } else if (partAction.value.type === PartActionType.DOA) {
      const outValue = {
        id: partAction.outValue?.id,
        partTypeId: partAction.outValue?.partTypeId,
        name: partAction.outValue?.name,
        sw: partAction.outValue?.sw,
        hw: partAction.outValue?.hw,
        fw: partAction.outValue?.fw,
        ft: partAction.outValue?.ft,
        serial: partAction.outValue?.serial,
        qty: partAction.outValue?.qty,
        category: partAction.outValue?.category,
        condition: partAction.outValue?.condition,
        location: partAction.outValue?.location,
        notes: partAction.outValue?.notes,
        type: partAction.outValue?.type,
        images: [],
        partId: partAction.outValue?.partId,
        ns_partId: partAction.outValue?.ns_partId
      };
      if (partAction.value.actionId) {
        await updatePartActionQuery.mutateAsync({
          id: partAction.value.actionId,
          data: {
            actionTypeCode: partAction.value.type,
            serviceId: data.id,
            installedOrMaintainedPartData: {},
            removedPartData: outValue,
            notes: partAction.outValue?.notes,
            execute: true
          }
        });
      } else {
        await addPartActionQuery.mutateAsync({
          actionTypeCode: partAction.value.type,
          serviceId: data.id,
          installedOrMaintainedPartData: {},
          removedPartData: outValue,
          notes: partAction.outValue?.notes,
          execute: true
        });
      }
    } else {
      const value = {
        id: partAction.value?.id,
        partTypeId: partAction.value.partTypeId,
        name: partAction.value?.name,
        sw: partAction.value?.sw,
        hw: partAction.value?.hw,
        fw: partAction.value?.fw,
        ft: partAction.value?.ft,
        serial: partAction.value?.serial,
        qty: partAction.value?.qty,
        category: partAction.value?.category,
        condition: partAction.value?.condition,
        location: partAction.value?.location,
        notes: partAction.value?.notes,
        type: partAction.value?.type,
        images: [],
        partId: partAction.value?.partId,
        ns_partId: partAction.value?.ns_partId
      };
      if (partAction.value.actionId) {
        await updatePartActionQuery.mutateAsync({
          id: partAction.value.actionId,
          data: {
            actionTypeCode: partAction.value.type,
            serviceId: data.id,
            installedOrMaintainedPartData: value,
            removedPartData: {},
            notes: partAction.value.notes,
            execute: true
          }
        });

      } else {
        await addPartActionQuery.mutateAsync({
          actionTypeCode: partAction.value.type,
          serviceId: data.id,
          installedOrMaintainedPartData: value,
          removedPartData: {},
          notes: partAction.value.notes,
          execute: true
        });
      }
    }
    const filteredPartActionCardDataset = partActionCardDataset && partActionCardDataset.filter((partActionCardData) => partActionCardData.value.id !== partAction.value.id);
    setPartActionCardDataset(filteredPartActionCardDataset);
    getPartActionsByServiceId.refetch();

    setToastMessage('Succesfully Executed');
    setOpenToast(true);
  };

  const onChangeTaskCard = (updatedTaskCardObject: TaskCard) => {
    const index = taskCardDataset.findIndex((taskCard) => taskCard.id === updatedTaskCardObject.id);
    if (index !== -1) {
      const updatedTaskCardDataset = [...taskCardDataset];

      updatedTaskCardDataset[index] = {
        ...updatedTaskCardDataset[index],
        taskData: updatedTaskCardObject.taskData,
      };

      setTaskCardDataset(updatedTaskCardDataset);
    }
  };

  const onSaveTaskCard = async (taskCardObject: TaskCard) => {
    await createAndUpdateTaskQuery.mutateAsync({
      taskTypeCode: taskCardObject.taskTypeCode,
      serviceId: taskCardObject.serviceId,
      taskData: taskCardObject.taskData,
      taskId: taskCardObject.id,
      execute: false
    });

    await getTasksByServiceIdQuery.refetch();
    
    if (taskCardObject.isNew) {
      const indexToRemove = taskCardDataset.findIndex((taskCard) => taskCard.id === taskCardObject.id);
      if (indexToRemove !== -1) {
        taskCardDataset.splice(indexToRemove, 1);
        setTaskCardDataset(taskCardDataset.filter((taskCard) => taskCard.isNew));
      }
    }

    setToastMessage('Succesfully Saved');
    setOpenToast(true);
  };

  const onExecTaskCard = async (taskCardObject: TaskCard) => {
    await createAndUpdateTaskQuery.mutateAsync({
      taskTypeCode: taskCardObject.taskTypeCode,
      serviceId: taskCardObject.serviceId,
      taskData: taskCardObject.taskData,
      taskId: taskCardObject.id,
      execute: true
    });

    await getTasksByServiceIdQuery.refetch();


    const indexToRemove = taskCardDataset.findIndex((taskCard) => taskCard.id === taskCardObject.id);
    if (indexToRemove !== -1) {
      taskCardDataset.splice(indexToRemove, 1);
      setTaskCardDataset(taskCardObject.isNew ? [...taskCardDataset] : taskCardDataset.filter((taskCard) => taskCard.isNew));
    }

    setToastMessage('Succesfully Executed');
    setOpenToast(true);
  };

  const handleTaskClose = async (taskCardObject: TaskCard) => {
    if (!taskCardObject.isNew) {
      await deleteTaskById.mutateAsync({ id: taskCardObject.id });
      await getTasksByServiceIdQuery.refetch();
    }

    const indexToRemove = taskCardDataset.findIndex((taskCard) => taskCard.id === taskCardObject.id);

    if (indexToRemove !== -1) {
      taskCardDataset.splice(indexToRemove, 1);
      setTaskCardDataset([...taskCardDataset]);
    }
  };
  
  const handlePartActionCardClose = async (card: CustomisePartActionCard) => {
    const filteredPartActionCardDataset = partActionCardDataset.filter((partActionCardData) => partActionCardData.value.id !== card.value.id);
    setPartActionCardDataset(filteredPartActionCardDataset);

    if (card.value.actionId) {
      await deletePartActionById.mutateAsync({ id: card.value.actionId });
      getPartActionsByServiceId.refetch();
    }
  };

  return (
    <>
      <ServiceActionBar
        service={data}
        currentStatusLabel={data.serviceStatus?.name || ''}
        statusOptions={nextServicesStatusesQuery.data?.possibleNextStatuses || []}
        statusValue={newServiceType}
        suggestedOptions={nextServicesStatusesQuery.data?.preferedStatuses || []}
        primaryAllocation={data.primaryAllocation}
        secondaryAllocation={data.secondaryAllocation}
        noteText={newServiceTypeNote}
        isCheckInEnabled={isCheckInEnabled}
        isCheckOutEnabled={isCheckOutEnabled}
        technicians={technicians}
        isHelpdeskUser={data.isHelpdeskUser}
        onChangeStatusValue={(val) => setNewServiceType(val)}
        onNoteText={(val) => setNewServiceTypeNote(val)}
        onSubmit={handleNewServiceStatusSubmit}
        onCheckIn={onCheckIn}
        onCheckOut={onCheckOut}
        onAllocationChange={handleOnAllocationChange}
        partActionCardDataset={partActionCardDataset || []}
        onChangePartActionCard={onChangePartActionCard} 
        onSavePartActionCard={handleSavePartActionCard} 
        onExecPartActionCard={handleExecPartActionCard}
        taskCardDataset={taskCardDataset}
        onChangeTaskCard={onChangeTaskCard}
        onSaveTaskCard={onSaveTaskCard}
        onExecTaskCard={onExecTaskCard}
        onCloseAndChangePopupTaskCard={handleTaskClose}   
        handlePartActionCardClose={handlePartActionCardClose}
        partActionData={partActionData}
        onChangeInterceptedFormsObject={(interactionsAvailable) => onChangeInterceptedFormsObject(interactionsAvailable)}
        closeCheckInCheckOut={closeCheckInCheckOut}
        checkInData={checkInData}
        checkOutData={checkOutData}
        isCheckIn={isCheckIn}
        isCheckOut={isCheckOut}
      />
      <Snackbar
        open={openToast} 
        autoHideDuration={4000} 
        message={toastMessage ? toastMessage : 'Successfully Saved'}
        onClose={() => setOpenToast(false)}
      />
    </>
  );
};