import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Papa from 'papaparse';
import { formatDate, getFormattedDate, isDropDownEmpty } from '../../../utils/common';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { BulkServiceEditorProps, LinkedFieldType, SelectedServiceListDataType } from './BulkServiceEditor.props';
import { ContentWrapperBox, HeadingWrapper, SelectionWrapper, Footer, StyledButton } from './BulkServiceEditor.styles';
import { ButtonVariantProps } from '../../atoms/Button';
import { StyledBox, StyledSelectMenu } from '../../../pages/Services/AddService/AddService.styles';
import { TextField } from '../../atoms/TextField';
import { DateTimePicker } from '../../atoms/DateTimePicker';
import { Checkbox, CheckboxColorProps } from '../../atoms/Checkbox';
import { MiniServiceList } from '../../molecules/MiniServiceList';
import { isInputFieldEmpty } from '../../../utils/services';
import { BulkEditServiceDataType } from '../../../@types/service.type';
import { Snackbar } from '../../atoms/Snackbar';
import { CSVDownload } from '../../molecules/CSVDownload';
import { BULK_SERVICE_EDIT, BULK_SERVICE_EDIT_CHECK, BULK_SERVICE_EDIT_CHECK_EMPTY, SAMPLE_BULK_SERVICE_UPDATE_TYPE } from '../../../constants/service.constant';
import { UploadButton } from '../../molecules/UploadButton';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { ActionPermissions, BulkServiceEdit, BulkServiceEditCheck, BulkServiceEditCheckEmpty, BulkServiceEditValidate, CustomPermission, LocationNodeData } from '../../../@types';
import { LocationSearchItem } from '../../molecules/LocationSearchItem';
import { useSearchAllocationNodes } from '../../../queries/live-search-query';
import { Modal } from '../../atoms/Modal';
import { CloseIcon } from '../../atoms/CloseIcon';
import { isUserHasPermission } from '../../../configs/permissions';
import { useGetUserSystemPermissions } from '../../../queries/user-query';

export const BulkServiceEditor: React.FC<BulkServiceEditorProps> = ({
  open,
  BulkEditServiceDropDownsDataset,
  selectedServices,
  toastMessage,
  parentServiceId,
  handleSave,
  handleClose,
  handleImportedData
}) => {
  const [bulkService, setBulkService] = useState<BulkServiceEdit>(BULK_SERVICE_EDIT);
  const [bulkServiceCheck, setBulkServiceCheck] = useState<BulkServiceEditCheck>(BULK_SERVICE_EDIT_CHECK);
  const [bulkServiceCheckEmpty, setBulkServiceCheckEmpty] = useState<BulkServiceEditCheckEmpty>(BULK_SERVICE_EDIT_CHECK_EMPTY);
  const [filteredCloseGridItems, setFilteredCloseGridItems] = useState<SelectedServiceListDataType[]>([]);
  const [filteredOpenGridItems, setFilteredOpenGridItems] = useState<SelectedServiceListDataType[]>([]);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [isImportData, setIsImportData] = useState<boolean>(false);
  const [importData, setImportData] = useState<object[]>([]);
  const [selectedServicesList, setSelectedServicesList] = useState<object[]>([]);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  let isSave = false;

  const searchAllocationNodes = useSearchAllocationNodes();
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    if (selectedServices) {
      setFilteredCloseGridItems(selectedServices.filter((selectedService) => selectedService.isClosed));
      setFilteredOpenGridItems(selectedServices.filter((selectedService) => !selectedService.isClosed));
      setSelectedServicesList(selectedServices);
    }
  }, [selectedServices]);

  useEffect(() => {
    toastMessage !== '' && setOpenToast(true);
  }, [toastMessage]);

  useEffect(() => {
    if (parentServiceId) {
      setBulkServiceCheck({ ...bulkServiceCheck, isCheckParentServiceId: true });
      setBulkService({ ...bulkService, parentServiceId });
    }
  }, [parentServiceId]);

  const onSave = () => {
    const effectedOpenServiceIdData: number[] = filteredOpenGridItems.length ? filteredOpenGridItems?.map((openItems) => openItems.id) : [];
    const effectedClosedServiceIdData: number[] = filteredCloseGridItems.length ? filteredCloseGridItems?.map((openItems) => openItems.id) : [];

    const validate: BulkServiceEditValidate = {
      validateActionType: isDropDownEmpty(bulkService.actionType),
      validateProcessType: isDropDownEmpty(bulkService.processType),
      validateUserDepotAllocation: isInputFieldEmpty(bulkService.userDepotAllocation),
      validateSecondaryUserDepotAllocation: isInputFieldEmpty(bulkService.secondaryUserDepotAllocation),
      validateScheduledDateTime: isInputFieldEmpty(bulkService.scheduledDateTime),
      validateFollowUpDateTime: isInputFieldEmpty(bulkService.followUpDateTime),
      validateNotes: isInputFieldEmpty(bulkService.notes),
      validateStatus: isDropDownEmpty(bulkService.status),
      validateLinkedField: isDropDownEmpty(bulkService.linkedField)
    };

    let affectedServiceIdsData: any[] = [];

    if (importData.length !== 0){
      affectedServiceIdsData = importData;
    } else {
      affectedServiceIdsData = effectedOpenServiceIdData?.length ? effectedOpenServiceIdData : effectedClosedServiceIdData;
    }

    let bulkServiceUpdateData: BulkEditServiceDataType = {
      affectedServiceIds: affectedServiceIdsData
    };

    if (bulkServiceCheck.isCheckActionType){
      if (validate.validateActionType){
        isSave = false;
        setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyActionType: validate.validateActionType });
      } else {
        isSave = true;
        bulkServiceUpdateData = { ...bulkServiceUpdateData, actionType: bulkService.actionType };
      }
    }

    if (bulkServiceCheck.isCheckProcessType){
      if (validate.validateProcessType){
        isSave = false;
        setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyProcessType: validate.validateProcessType });
      } else {
        isSave = true;
        bulkServiceUpdateData = { ...bulkServiceUpdateData, processType: bulkService.processType };
      }
    }

    if (bulkServiceCheck.isCheckReferenceNo1){
      bulkServiceUpdateData = { ...bulkServiceUpdateData, referenceNo1: bulkService.referenceNo1 };
    }

    if (bulkServiceCheck.isCheckReferenceNo2){
      bulkServiceUpdateData = { ...bulkServiceUpdateData, referenceNo2: bulkService.referenceNo2 };
    }

    if (bulkServiceCheck.isCheckReferenceNo3){
      bulkServiceUpdateData = { ...bulkServiceUpdateData, referenceNo3: bulkService.referenceNo3 };
    }

    if (bulkServiceCheck.isCheckUserDepotAllocation){
      if (validate.validateUserDepotAllocation){
        isSave = false;
        setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyUserDepotAllocation: validate.validateUserDepotAllocation });
      } else {
        isSave = true;
        bulkServiceUpdateData = { ...bulkServiceUpdateData, userDepotAllocation: bulkService.userDepotAllocation };
      }
    }

    if (bulkServiceCheck.isCheckSecondaryUserDepotAllocation){
      if (validate.validateSecondaryUserDepotAllocation){
        isSave = false;
        setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptySecondaryUserDepotAllocation: validate.validateSecondaryUserDepotAllocation });
      } else {
        isSave = true;
        bulkServiceUpdateData = { ...bulkServiceUpdateData, secondaryUserDepotAllocation: bulkService.secondaryUserDepotAllocation };
      }
    }

    if (bulkServiceCheck.isCheckScheduledDateTime){
      if (validate.validateScheduledDateTime){
        isSave = false;
        setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyScheduledDateTime: validate.validateScheduledDateTime });
      } else {
        isSave = true;
        bulkServiceUpdateData = { ...bulkServiceUpdateData, scheduledDateTime: formatDate(bulkService.scheduledDateTime) };
      }
    }

    if (bulkServiceCheck.isCheckFollowUpDateTime){
      if (validate.validateFollowUpDateTime){
        isSave = false;
        setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyFollowUpDateTime: validate.validateFollowUpDateTime });
      } else {
        isSave = true;
        bulkServiceUpdateData = { ...bulkServiceUpdateData, followUpDateTime: getFormattedDate(bulkService.followUpDateTime) };
      }
    }

    if (bulkServiceCheck.isCheckNotes){
      if (validate.validateNotes){
        isSave = false;
        setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyNotes: validate.validateNotes });
      } else {
        isSave = true;
        bulkServiceUpdateData = { ...bulkServiceUpdateData, notes: bulkService.notes };
      }
    }

    if (bulkServiceCheck.isCheckStatus){
      if (validate.validateStatus){
        isSave = false;
        setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyStatus: validate.validateStatus });
      } else {
        isSave = true;
        bulkServiceUpdateData = { ...bulkServiceUpdateData, status: bulkService.status };
      }
    }

    if (bulkServiceCheck.isCheckParentServiceId){
      isSave = true;
      bulkServiceUpdateData = { ...bulkServiceUpdateData, parentServiceId: bulkService.parentServiceId };
    }

    if (bulkServiceCheck.isCheckLinkedField){
      if (validate.validateLinkedField){
        isSave = false;
        setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyLinkedField: validate.validateLinkedField });
      } else {
        isSave = true;
        bulkServiceUpdateData = { ...bulkServiceUpdateData, linkedField: bulkService.linkedField };
      }
    }

    isSave && handleSave(bulkServiceUpdateData);
  };

  const onClear = () => {
    setBulkService(BULK_SERVICE_EDIT);
    setBulkServiceCheck(BULK_SERVICE_EDIT_CHECK);
    setBulkServiceCheckEmpty(BULK_SERVICE_EDIT_CHECK_EMPTY);
    setImportData([]);
    setOpenToast(false);
    setIsImportData(false);
    setFilteredCloseGridItems([]);
    setFilteredOpenGridItems([]);
    setSelectedServicesList([]);
  };

  const handleLinkedFieldActions = (fieldName: string) => {
    if (fieldName) {
      const isCheckField = `isCheck${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`;
      
      setBulkServiceCheck(prevState => ({
        ...prevState,
        [isCheckField]: false,
      }));
      
      setBulkService(prevState => ({
        ...prevState,
        [fieldName]: '',
        linkedField: fieldName,
      }));
    }
  };

  const parseCSV = (value: any) => {
    Papa.parse(value[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (csv: any) {
        const linkedFieldItems: object[] = csv?.data.map((item: any) => {
          return {
            id: parseInt(item.Service_Id),
            value: item.Linked_Field
          };
        });
        setImportData(linkedFieldItems);
        handleImportedData && handleImportedData(csv?.data);
      },
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ContentWrapperBox>
        <CloseIcon handleClose={handleClose} />
        <HeadingWrapper variant={TypographyVariantProps.H6}>
          Bulk Edit Services
        </HeadingWrapper>
        <SelectionWrapper>
          <Grid width="100%">
            <Grid container mb={2} gap={2}>
              <Grid xs={1}>
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={bulkServiceCheck.isCheckActionType}
                  disabled={bulkService.linkedField === LinkedFieldType.ActionType}
                  onChange={(event) => {
                    setBulkServiceCheck({
                      ...bulkServiceCheck,
                      isCheckActionType: event.target.checked || setBulkService({ ...bulkService, actionType: '-1' })
                    });
                  }}
                />
              </Grid>
              <Grid xs={9}>
                <StyledSelectMenu
                  id="action-type-select"
                  required={true}
                  disabled={!bulkServiceCheck.isCheckActionType}
                  label="Action Type"
                  items={BulkEditServiceDropDownsDataset ? BulkEditServiceDropDownsDataset.actionTypeItems : []}
                  selectedValue={bulkService.actionType}
                  onChange={(value) => {
                    setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyActionType: isDropDownEmpty(value) });
                    setBulkService({ ...bulkService, actionType: value });
                  }}
                  validate={!!bulkServiceCheckEmpty.isEmptyActionType}
                  optionalLabelEnabled={true}
                  helperTextEnabled={false}
                />
              </Grid>
            </Grid>
            <Grid container mb={2} gap={2}>
              <Grid xs={1}>
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={bulkServiceCheck.isCheckProcessType}
                  disabled={bulkService.linkedField === LinkedFieldType.ProcessType}
                  onChange={(event) => {
                    setBulkServiceCheck({ 
                      ...bulkServiceCheck, 
                      isCheckProcessType: event.target.checked || setBulkService({ ...bulkService, processType: '-1' }) 
                    });
                  }}
                />
              </Grid>
              <Grid xs={9}>
                <StyledSelectMenu
                  id="process-type-select"
                  required={true}
                  disabled={!bulkServiceCheck.isCheckProcessType}
                  label="Process Type"
                  items={BulkEditServiceDropDownsDataset ? BulkEditServiceDropDownsDataset.processTypeItems : []}
                  selectedValue={bulkService.processType}
                  onChange={(value) => {
                    setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyProcessType: isDropDownEmpty(value) });
                    setBulkService({ ...bulkService, processType: value });
                  }}
                  validate={!!bulkServiceCheckEmpty.isEmptyProcessType}
                  optionalLabelEnabled={true}
                  helperTextEnabled={false}
                />
              </Grid>
            </Grid>
            <Grid container mb={2} gap={2}>
              <Grid xs={1}>
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={bulkServiceCheck.isCheckReferenceNo1}
                  disabled={bulkService.linkedField === LinkedFieldType.ReferenceNo1}
                  onChange={(event) => {
                    setBulkServiceCheck({
                      ...bulkServiceCheck,
                      isCheckReferenceNo1: event.target.checked || setBulkService({ ...bulkService, referenceNo1: '' })
                    });
                  }}
                />
              </Grid>
              <Grid xs={9}>
                <TextField
                  fullWidth
                  disabled={!bulkServiceCheck.isCheckReferenceNo1}
                  label="Reference No 1"
                  value={bulkService.referenceNo1}
                  onChange={(value) => setBulkService({ ...bulkService, referenceNo1: value })}
                />
              </Grid>
            </Grid>
            <Grid container mb={2} gap={2}>
              <Grid xs={1}>
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={bulkServiceCheck.isCheckReferenceNo2}
                  disabled={bulkService.linkedField === LinkedFieldType.ReferenceNo2}
                  onChange={(event) => {
                    setBulkServiceCheck({
                      ...bulkServiceCheck,
                      isCheckReferenceNo2: event.target.checked || setBulkService({ ...bulkService, referenceNo2: '' })
                    });
                  }}
                />
              </Grid>
              <Grid xs={9}>
                <TextField
                  fullWidth
                  disabled={!bulkServiceCheck.isCheckReferenceNo2}
                  label="Reference No 2"
                  value={bulkService.referenceNo2}
                  onChange={(value) => setBulkService({ ...bulkService, referenceNo2: value })}
                />
              </Grid>
            </Grid>
            <Grid container mb={2} gap={2}>
              <Grid xs={1}>
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={bulkServiceCheck.isCheckReferenceNo3}
                  disabled={bulkService.linkedField === LinkedFieldType.ReferenceNo3}
                  onChange={(event) => {
                    setBulkServiceCheck({
                      ...bulkServiceCheck,
                      isCheckReferenceNo3: event.target.checked || setBulkService({ ...bulkService, referenceNo3: '' })
                    });
                  }}
                />
              </Grid>
              <Grid xs={9}>
                <TextField
                  fullWidth
                  disabled={!bulkServiceCheck.isCheckReferenceNo3}
                  label="Reference No 3"
                  value={bulkService.referenceNo3}
                  onChange={(value) => setBulkService({ ...bulkService, referenceNo3: value })}
                />
              </Grid>
            </Grid>
            {isUserHasPermission(ActionPermissions.Service_Edit_Allocation_Primary, permissions) &&
              <Grid container mb={2} gap={2}>
                <Grid xs={1}>
                  <Checkbox
                    color={CheckboxColorProps.Primary}
                    checked={bulkServiceCheck.isCheckUserDepotAllocation}
                    disabled={bulkService.linkedField === LinkedFieldType.UserDepotAllocation}
                    onChange={(event) => {
                      setBulkServiceCheck({
                        ...bulkServiceCheck,
                        isCheckUserDepotAllocation: event.target.checked || setBulkService({ ...bulkService, userDepotAllocation: '' })
                      });
                    }}
                  />
                </Grid>
                <Grid xs={9}>
                  <LiveSearchBox
                    title="User/Depot Allocation"
                    timeOffset={400}
                    value={bulkService.userDepotAllocationName}
                    disabled={!bulkServiceCheck.isCheckUserDepotAllocation}
                    isError={!!bulkServiceCheckEmpty.isEmptyUserDepotAllocation}
                    onClearValue={() => { 
                      setBulkService({
                        ...bulkService,
                        userDepotAllocation: '',
                        userDepotAllocationName: ''
                      });
                    }}
                    renderItem={(props: any, option: any) => {
                      return (
                        <StyledBox heighlightBackground={option.isSuggestedNode}>
                          <LiveSearchListItem {...props}>
                            <LocationSearchItem data={option} />
                          </LiveSearchListItem>
                        </StyledBox>
                      );
                    }}
                    onChange={(locationNodeData: LocationNodeData) => {
                      setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyUserDepotAllocation: isInputFieldEmpty(locationNodeData.name) });
                      setBulkService({
                        ...bulkService,
                        userDepotAllocation: locationNodeData.id.toString(),
                        userDepotAllocationName: locationNodeData.name
                      });
                    }}
                    onApiInvoke={async (name) => {
                      return await searchAllocationNodes.mutateAsync(name);
                    }}
                  />
                </Grid>
              </Grid>
            }
            {isUserHasPermission(ActionPermissions.Service_Edit_Allocation_Secondary, permissions) &&
              <Grid container mb={2} gap={2}>
                <Grid xs={1}>
                  <Checkbox
                    color={CheckboxColorProps.Primary}
                    checked={bulkServiceCheck.isCheckSecondaryUserDepotAllocation}
                    disabled={bulkService.linkedField === LinkedFieldType.SecondaryUserDepotAllocation}
                    onChange={(event) => {
                      setBulkServiceCheck({
                        ...bulkServiceCheck,
                        isCheckSecondaryUserDepotAllocation: event.target.checked || setBulkService({ ...bulkService, secondaryUserDepotAllocation: '' })
                      });
                    }}
                  />
                </Grid>
                <Grid xs={9}>
                  <LiveSearchBox
                    title="Secondary User/Depot Allocation"
                    timeOffset={400}
                    value={bulkService.secondaryUserDepotAllocationName}
                    disabled={!bulkServiceCheck.isCheckSecondaryUserDepotAllocation}
                    isError={!!bulkServiceCheckEmpty.isEmptySecondaryUserDepotAllocation}
                    onClearValue={() => {
                      setBulkService({
                        ...bulkService,
                        secondaryUserDepotAllocation: '',
                        secondaryUserDepotAllocationName: ''
                      });
                    }}
                    renderItem={(props: any, option: any) => {
                      return (
                        <StyledBox heighlightBackground={option.isSuggestedNode}>
                          <LiveSearchListItem {...props}>
                            <LocationSearchItem data={option} />
                          </LiveSearchListItem>
                        </StyledBox>
                      );
                    }}
                    onChange={(locationNodeData: LocationNodeData) => {
                      setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptySecondaryUserDepotAllocation: isInputFieldEmpty(locationNodeData.name) });
                      setBulkService({
                        ...bulkService,
                        secondaryUserDepotAllocation: locationNodeData.id.toString(),
                        secondaryUserDepotAllocationName: locationNodeData.name
                      });
                    }}
                    onApiInvoke={async (name) => {
                      return await searchAllocationNodes.mutateAsync(name);
                    }}
                  />
                </Grid>
              </Grid>
            }
            <Grid container mb={2} gap={2}>
              <Grid xs={1}>
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={bulkServiceCheck.isCheckScheduledDateTime}
                  disabled={bulkService.linkedField === LinkedFieldType.ScheduledDateTime}
                  onChange={(event) => {
                    setBulkServiceCheck({
                      ...bulkServiceCheck,
                      isCheckScheduledDateTime: event.target.checked || setBulkService({ ...bulkService, scheduledDateTime: '' })
                    });
                  }}
                />
              </Grid>
              <Grid xs={9}>
                <DateTimePicker
                  label="Scheduled Date/Time"
                  disablePast={true}
                  disabled={!bulkServiceCheck.isCheckScheduledDateTime}
                  value={bulkService.scheduledDateTime}
                  error={!!bulkServiceCheckEmpty.isEmptyScheduledDateTime}
                  onChange={(value) => {
                    setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyScheduledDateTime: isInputFieldEmpty(value) });
                    setBulkService({ ...bulkService, scheduledDateTime: value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container mb={2} gap={2}>
              <Grid xs={1}>
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={bulkServiceCheck.isCheckFollowUpDateTime}
                  disabled={bulkService.linkedField === LinkedFieldType.FollowUpDateTime}
                  onChange={(event) => {
                    setBulkServiceCheck({
                      ...bulkServiceCheck,
                      isCheckFollowUpDateTime: event.target.checked || setBulkService({ ...bulkService, followUpDateTime: '' })
                    });
                  }}
                />
              </Grid>
              <Grid xs={9}>
                <DateTimePicker
                  label="Follow Up Date/Time"
                  disabled={!bulkServiceCheck.isCheckFollowUpDateTime}
                  value={bulkService.followUpDateTime}
                  error={!!bulkServiceCheckEmpty.isEmptyFollowUpDateTime}
                  onChange={(value) => {
                    setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyFollowUpDateTime: isInputFieldEmpty(value) });
                    setBulkService({ ...bulkService, followUpDateTime: value });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid width="100%">
            <Grid container mb={2} gap={2}>
              <Grid xs={1}>
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={bulkServiceCheck.isCheckNotes}
                  disabled={bulkService.linkedField === LinkedFieldType.Notes}
                  onChange={(event) => {
                    setBulkServiceCheck({
                      ...bulkServiceCheck,
                      isCheckNotes: event.target.checked || setBulkService({ ...bulkService, notes: '' })
                    });
                  }}
                />
              </Grid>
              <Grid xs={9}>
                <TextField
                  fullWidth
                  label="Notes"
                  multiline
                  rows={9}
                  disabled={!bulkServiceCheck.isCheckNotes}
                  value={bulkService.notes}
                  error={!!bulkServiceCheckEmpty.isEmptyNotes}
                  onChange={(value) => {
                    setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyNotes: isInputFieldEmpty(value) });
                    setBulkService({ ...bulkService, notes: value });
                  }}
                />
              </Grid>
            </Grid>
            <Grid container mb={2} gap={2}>
              <Grid xs={1}>
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={bulkServiceCheck.isCheckStatus}
                  disabled={bulkService.linkedField === LinkedFieldType.Status}
                  onChange={(event) => {
                    setBulkServiceCheck({
                      ...bulkServiceCheck,
                      isCheckStatus: event.target.checked || setBulkService({ ...bulkService, status: '-1' })
                    });
                  }}
                />
              </Grid>
              <Grid xs={9}>
                <StyledSelectMenu
                  id="status-select"
                  required={true}
                  disabled={!bulkServiceCheck.isCheckStatus}
                  label="Status"
                  items={BulkEditServiceDropDownsDataset ? BulkEditServiceDropDownsDataset.statusItems : []}
                  selectedValue={bulkService.status}
                  onChange={(value) => {
                    setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyStatus: isDropDownEmpty(value) });
                    setBulkService({ ...bulkService, status: value });
                  }}
                  validate={!!bulkServiceCheckEmpty.isEmptyStatus}
                  optionalLabelEnabled={true}
                  helperTextEnabled={false}
                />
              </Grid>
            </Grid>
            <Grid container mb={2} gap={2}>
              <Grid xs={1}>
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={bulkServiceCheck.isCheckParentServiceId}
                  disabled={bulkService.linkedField === LinkedFieldType.ParentServiceID}
                  onChange={(event) => {
                    setBulkServiceCheck({
                      ...bulkServiceCheck,
                      isCheckParentServiceId: event.target.checked || setBulkService({ ...bulkService, parentServiceId: '' })
                    });
                  }}
                />
              </Grid>
              <Grid xs={9}>
                <TextField
                  fullWidth
                  label="Parent Service Id"
                  value={bulkService.parentServiceId}
                  disabled={!bulkServiceCheck.isCheckParentServiceId}
                  onChange={(value) => setBulkService({ ...bulkService, parentServiceId: value })}
                />
              </Grid>
            </Grid>
            {(selectedServicesList?.length === 0 && !isImportData && isUserHasPermission(ActionPermissions.Service_Bulk_Update_File_Upload, permissions)) && 
              <Grid container mb={2} gap={2} alignItems="center">
                <Grid>
                  <Checkbox
                    color={CheckboxColorProps.Primary}
                    checked={isImportData}
                    onChange={(event) => {
                      setIsImportData(event.target.checked);
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography>Import Data</Typography>
                </Grid>
              </Grid>
            }
            {isImportData && <>
              <Grid container mb={2} gap={2}>
                <Grid xs={1}>
                  <Checkbox
                    color={CheckboxColorProps.Primary}
                    checked={bulkServiceCheck.isCheckLinkedField}
                    onChange={(event) => {
                      setBulkServiceCheck({
                        ...bulkServiceCheck,
                        isCheckLinkedField: event.target.checked || setBulkService({ ...bulkService, linkedField: '-1' })
                      });
                    }}
                  />
                </Grid>
                <Grid xs={9}>
                  <StyledSelectMenu
                    id="linked-field-select"
                    required={true}
                    label="Linked Field"
                    disabled={!bulkServiceCheck.isCheckLinkedField}
                    items={BulkEditServiceDropDownsDataset?.linkedFieldItems || []}
                    selectedValue={bulkService.linkedField}
                    onChange={(value) => {
                      setBulkServiceCheckEmpty({ ...bulkServiceCheckEmpty, isEmptyLinkedField: isDropDownEmpty(value) });
                      handleLinkedFieldActions(value);
                    }}
                    validate={!!bulkServiceCheckEmpty.isEmptyLinkedField}
                    optionalLabelEnabled={true}
                    helperTextEnabled={false}
                  />
                </Grid>
                <Grid xs={9}>
                  <CSVDownload
                    isButton={false}
                    targetObject={SAMPLE_BULK_SERVICE_UPDATE_TYPE}
                    fileName="BulkUpdateCSV"
                    label="Download template CSV"
                  />
                </Grid>
                <Grid xs={9}>
                  <UploadButton
                    fileTypes={['text/csv']}
                    maxFileSize={1024}
                    setFileDetails={async (value: any) => parseCSV(value)}
                  />
                </Grid>
              </Grid>
            </>}
          </Grid>
          <Grid width="100%">
            <MiniServiceList 
              openGridItems={filteredOpenGridItems}
              closeGridItems={filteredCloseGridItems}
              handleOpenGridItemsDelete={(e, id) => setFilteredOpenGridItems(filteredOpenGridItems?.filter((filteredItem) => filteredItem.id !== id))}
              handleCloseGridItemsDelete={(e, id) => setFilteredCloseGridItems(filteredCloseGridItems?.filter((filteredItem) => filteredItem.id !== id))}
            />
          </Grid>
        </SelectionWrapper>
        <Grid>
          <Grid>
            <Snackbar
              open={openToast}
              autoHideDuration={2000}
              message={toastMessage ? toastMessage : 'Successfully Updated'}
              onClose={() => setOpenToast(false)}
            />      
          </Grid>
          <Grid>
            <Footer>
              <StyledButton variant={ButtonVariantProps.Secondary} onClick={onClear}>Clear</StyledButton>
              <StyledButton variant={ButtonVariantProps.Primary} onClick={onSave}>Save</StyledButton>
            </Footer>
          </Grid>
        </Grid>
      </ContentWrapperBox>
    </Modal>
  );
};