import { styled } from '@mui/material/styles';

export const GridContainer = styled('div')((props: { autoHeight: boolean }) =>`
  ${!props.autoHeight && 'height: 92vh;'}
  width: 100%;
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-viewport,
  .MuiDataGrid-row,
  .MuiDataGrid-renderingZone {
    max-height: fit-content !important;
  }
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-cell {
    max-height: fit-content !important;
    overflow: auto;
    white-space: initial !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
`);