import React, { useCallback, useEffect, useState } from 'react';
import { GridColDef, GridFeatureModeConstant, GridFilterModel, GridRenderCellParams, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Edit } from '@mui/icons-material';
import { DataGrid } from '../../../../components/atoms/DataGrid';
import { GridContainer } from '../../../../styled/common.styles';
import { formatDate } from '../../../../utils/common';
import { Button, ButtonVariantProps } from '../../../../components/atoms/Button';
import { Modal } from '../../../../components/atoms/Modal';
import { CloseIcon } from '../../../../components/atoms/CloseIcon';
import { StyledBox } from '../NotificationTemplates.styles';
import { AddEditNotificationTemplateFragment } from '../AddEditNotificationTemplateFragment';
import { useGetAllNotificationTemplatesPaginated } from '../../../../queries/notification-template-query';
import { NotificationTemplatePaginateListResponse, NotificationTemplateType } from '../../../../@types/notificationTamplate.type';
import { NotificationTemplateListProps } from './NotificationTemplateList.props';
import { Grid } from '@mui/material';

export const NotificationTemplateList: React.FC<NotificationTemplateListProps> = ({
  sendGridTemplates,
  editPermission
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [notificationTemplateId, setNotificationTemplateId] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const filterModel: GridFilterModel = { items: [] };
  const [notificationTemplates, setNotificationTemplates] = useState<NotificationTemplatePaginateListResponse>();

  const getAllNotificationTemplatesPaginated = useGetAllNotificationTemplatesPaginated({
    page,
    pageSize,
    filterModel,
    sortModel
  });

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      valueGetter: (params: GridValueGetterParams) => params.row.id
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.name
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      valueGetter: (params: GridValueGetterParams) => params.row.type === NotificationTemplateType.Email ? 'Email' : 'SMS'
    },
    {
      field: 'templateId',
      headerName: 'Template',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => sendGridTemplates?.find((sendGridTemplate) => sendGridTemplate.value === params.row.templateId)?.label || ''
    },
    {
      field: 'isActive',
      headerName: 'Active',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.isActive ? 'Yes' : 'No'
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.createdBy
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.createdAt)
    },
    {
      field: 'edit', 
      headerName: '',
      filterable: false,
      renderCell: (params: GridRenderCellParams) => (<Grid>
        {editPermission && <Button
          variant={ButtonVariantProps.Icon}
          onClick={() => {
            setOpen(true);
            setNotificationTemplateId(params.row.id);
          }}>
          <Edit />
        </Button>}
      </Grid>)
    }
  ];

  useEffect(() => {
    getAllNotificationTemplatesPaginated.data && setNotificationTemplates(getAllNotificationTemplatesPaginated.data);
  }, [getAllNotificationTemplatesPaginated.data]);

  useEffect(() => {
    if (page || pageSize) {
      getAllNotificationTemplatesPaginated.refetch();  
    }
  }, [page, pageSize, sortModel]);

  const handleClose = (event?: any) => {
    if (event?.target?.className.includes('backdrop')) {
      event?.target?.className.includes('backdrop') && setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  return (
    <GridContainer>
      <DataGrid
        columns={columns}
        rows={notificationTemplates?.data || []}
        getRowId={(row) => row?.id}
        disableColumnFilter={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        componentsProps={{
          toolbar: {
            csvOptions: { disableToolbarButton: true },
            printOptions: { disableToolbarButton: true },
            showQuickFilter: false
          }
        }}
        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'super-app-theme--even' : 'super-app-theme--odd' }
        getRowHeight={() => 'auto'}
        onSortModelChange={handleSortModelChange}
        loading={getAllNotificationTemplatesPaginated.isLoading}
        rowsPerPageOptions={[25, 50, 100]}
        pagination={true}
        page={page}
        pageSize={pageSize}
        paginationMode={GridFeatureModeConstant.server}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      />
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledBox>
          <CloseIcon handleClose={handleClose} />
          <AddEditNotificationTemplateFragment 
            isOpen={open}
            isEdit={true}
            notificationTemplateId={notificationTemplateId}
            sendGridTemplates={sendGridTemplates}
            onClose={handleClose}
          />
        </StyledBox>
      </Modal>
    </GridContainer>
  );
};