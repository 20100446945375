import React, { KeyboardEvent, ChangeEvent, useCallback, useEffect, useState } from 'react';
import { GridFeatureModeConstant, GridFilterModel, GridSelectionModel, GridSortModel, getGridSingleSelectOperators, getGridDateOperators, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { GridColDef, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid';
import { Grid, TextField } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { HorizontalAlignProps, InlineGroup } from '../../../components/atoms/InlineGroup';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { ColumnSelectionItem } from '../../../components/templates/ColumnSelection';
import { CustomDataGrid } from '../../../components/layouts/CustomDataGrid';
import { GridContainer, Wrapper } from '../../../styled/common.styles';
import { Link } from '../../../components/atoms/Link';
import { PartListResponse } from '../../../@types/part.type';
import { useGetAllParts } from '../../../queries/part-query';
import { checkListPagetFilterAvailability, formatDate } from '../../../utils/common';
import { ListType, NewReportDataType, OverwriteReportDataType, PropReportDataType, ReportResponse, SizeProps } from '../../../@types';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { SavedReportTypeList } from '../../../configs/enums';
import { useAddNewReport, useGetDefaultReportByReportType, useGetReportByCode, useUpdateReportByCode } from '../../../queries/report-query';
import { generateReportURL } from '../../../utils/report';
import { PrintLabel } from '../../../components/molecules/PintLabel';
import { PrintLabelEntityTypes } from '../../../@types/print-label.type';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { TagCell } from '../../../components/molecules/TagCell';
import { columnSelectionItems } from '../../../constants/part';

export const PartList: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const reportCode = searchParams && searchParams.get('savedReportCode');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [parts, setParts] = useState<PartListResponse>();
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [serialSearch, setSerialSearch] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');

  const [reportName, setReportName] = useState('');
  const [reportViewCode, setReportViewCode] = useState<string>();
  const [reportColumnSelectionItems, setReportColumnSelectionItems] = useState<Array<ColumnSelectionItem>>();
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [newReportData, setNewReportData] = useState<PropReportDataType>();
  const [savedReportMessage, setSavedReportMessage] = useState('');
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [fetchDataGrid, setFetchDataGrid] = useState<boolean>(false);

  const useAddNewReportQuery = useAddNewReport();
  const useOverwriteReportQuery = useUpdateReportByCode(reportCode || '');
  const { data: reportQueryData, refetch: refetchGetReportByCodeQuery } = useGetReportByCode(reportCode || '');
  const getAllParts = useGetAllParts({ filterModel, page, pageSize, sortModel }, serialSearch, reportColumnSelectionItems || columnSelectionItems);
  const defaultReportData = useGetDefaultReportByReportType(SavedReportTypeList.PartList);

  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'assetId',
      headerName: 'Asset ID',
      width: 80,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value?.toString(),
      valueGetter: (params) => params.row.assetId
    },
    {
      field: 'serial1',
      headerName: 'Serial 1',
      renderCell: (params: GridRenderCellParams) => params.row.assetId && <Link href={`/parts/${params.row.assetId}`}>{params.row.serial1}</Link>,
      type: 'string',
      width: 120,
      valueGetter: (params) => params.row.serial1
    },
    {
      field: 'serial2',
      headerName: 'Serial 2',
      type: 'string',
      width: 120,
      valueGetter: (params) => params.row.serial2
    },
    {
      field: 'serial3',
      headerName: 'Serial 3',
      type: 'string',
      width: 120,
      valueGetter: (params) => params.row.serial3
    },
    {
      field: 'conditionName',
      headerName: 'Condition',
      type: 'string',
      width: 100,
      valueGetter: (params) => params.row.partCondition.name
    },
    {
      field: 'timeInCondition',
      headerName: 'Time in Condition',
      type: 'string',
      width: 150,
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row.timeInCondition
    },
    {
      field: 'softwareVersion',
      headerName: 'Software Ver',
      type: 'string',
      width: 200,
      valueGetter: (params) => params.row.softwareVersion
    },
    {
      field: 'firmwareVersion',
      headerName: 'Firmware Ver',
      type: 'string',
      width: 200,
      valueGetter: (params) => params.row.firmwareVersion
    },
    {
      field: 'hardwareVersion',
      headerName: 'Hardware Rev',
      type: 'string',
      width: 200,
      valueGetter: (params) => params.row.hardwareVersion
    },
    {
      field: 'fleetTagName',
      headerName: 'Fleet Tag',
      type: 'string',
      width: 100,
      valueGetter: (params) => params.row.fleetTagName
    },
    {
      field: 'warrantyEnd',
      headerName: 'Warranty End',
      type: 'date',
      width: 150,
      valueGetter: (params) => params.row.warrantyEnd,
      filterOperators: getGridDateOperators().filter(
        (operator) => operator.value !== 'isNotEmpty' && operator.value !== 'isEmpty'
      )
    },
    {
      field: 'serialised',
      headerName: 'Serialised/Consumable',
      type: 'singleSelect',
      valueOptions: [
        { value: true, label: 'Serialised/ Consumable' },
        { value: 'Serialised', label: 'Serialised' },
        { value: 'Consumable', label: 'Consumable' }
      ],
      width: 170,
      valueGetter: (params: GridValueGetterParams) => params.row.serialised
    },
    {
      field: 'tags',
      headerName: 'Tags',
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.tags)
          return (
            <TagCell tags={JSON.parse(params.row.tags)} />
          );
        else
          return '';
      },
    },
    {
      field: 'quantityInStock',
      headerName: 'Qty',
      width: 70,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value?.toString(),
      valueGetter: (params) => params.row.quantityInStock
    },
    {
      field: 'notes',
      headerName: 'Notes',
      type: 'string',
      width: 150,
      valueGetter: (params) => params.row.notes
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'string',
      width: 180,
      valueGetter: (params) => formatDate(params.row.createdAt)
    },
    {
      field: 'createdByName',
      headerName: 'Created By',
      type: 'string',
      width: 150,
      valueGetter: (params) => params.row.createdByName
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      type: 'string',
      width: 180,
      valueGetter: (params) => formatDate(params.row.updatedAt)
    },
    {
      field: 'updatedByName',
      headerName: 'Updated By',
      type: 'string',
      width: 150,
      valueGetter: (params) => params.row.updatedByName
    },
    {
      field: 'partTypeId',
      headerName: 'Part Type ID',
      type: 'string',
      width: 100,
      valueGetter: (params) => params.row.partTypeId
    },
    {
      field: 'partTypeName',
      headerName: 'Part Type Name',
      type: 'string',
      width: 180,
      valueGetter: (params) => params.row.partTypeName
    },
    {
      field: 'partTypeNumber',
      headerName: 'Part No.',
      type: 'string',
      width: 200,
      valueGetter: (params) => params.row.partTypeNumber
    },
    {
      field: 'partTypeTags',
      headerName: 'Part Type Tags',
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.partTypeTags)
          return (
            <TagCell tags={JSON.parse(params.row.partTypeTags)} />
          );
        else
          return '';
      },
    },
    {
      field: 'partTypeIsActive',
      headerName: 'Part Type Active',
      type: 'string',
      width: 150,
      valueGetter: (params) => getPropertyTypeValue(params.row.partTypeIsActive)
    },
    {
      field: 'client',
      headerName: 'Client',
      width: 100,
      valueGetter: (params) => params.row.client
    },
    {
      field: 'contract',
      headerName: 'Contract(s)',
      width: 180,
      valueGetter: (params) => params.row.contract
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 350,
      sortable: false,
      valueGetter: (params) => params.row.location,
      renderCell: (params: GridRenderCellParams) => getPartLocationLinks(params.row.location, params.row.locationIds, params.row.locationNodeTypes)
    },
    {
      field: 'nodeId',
      headerName: 'Node ID',
      width: 100,
      valueGetter: (params) => params.row.nodeId
    },
    {
      field: 'nodeName',
      headerName: 'Node Name',
      width: 150,
      valueGetter: (params) => params.row.nodeName
    },
    {
      field: 'nodeTypeName',
      headerName: 'Node Type',
      width: 120,
      valueGetter: (params) => params.row.nodeTypeName
    },
    {
      field: 'timeInLocation',
      headerName: 'Time in Location',
      width: 150,
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row.timeInLocation
    },
    {
      field: 'partActionLocation',
      headerName: 'Location - Last Out Part Action',
      width: 230,
      filterable: false,
      sortable: false,
      valueGetter: (params) => params.row.partActionLocation
    },
    {
      field: 'printLabel', 
      headerName: '', 
      renderCell: (params: GridRenderCellParams) => ( 
        <PrintLabel entityId={Number(params.row.assetId) || 0} entityType={PrintLabelEntityTypes.Part}/>
      ),
      filterable: false,
      sortable: false
    },
    {
      field: 'locationTypeCode',
      headerName: 'Parent Type Code',
      sortable: false,
      hide: true,
      type: 'singleSelect',
      valueOptions: [
        { value: 'nodeOrPart', label: 'Node/Part' },
        { value: 'node', label: 'Node' },
        { value: 'part', label: 'Part' }
      ],
      filterOperators: getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
      )
    },
  ]);

  TabHandler(`List Parts | ${PLATFORM_NAME}`);

  useEffect(() => {
    getAllParts.data && setParts(getAllParts.data);
  }, [getAllParts.data]);

  useEffect(() => {
    !reportCode && defaultReportData.refetch();
  }, [SavedReportTypeList.PartList]);

  useEffect(() => {
    if (!reportCode && defaultReportData.data && defaultReportData.data?.length > 0) {
      setFilterModel(defaultReportData.data[0].dataGridProperties?.filterModel);
      setSortModel(defaultReportData.data[0].dataGridProperties?.sortModel);
      setReportColumnSelectionItems(defaultReportData.data[0].columnSelection);
      setPageSize(defaultReportData.data[0].dataGridProperties?.pageSize);
    }
  }, [defaultReportData.data, reportCode]);

  useEffect(() => {
    if (reportCode) {
      refetchGetReportByCodeQuery();
      setReportViewCode(reportCode);
    }
  }, [reportCode, fetchDataGrid]);

  useEffect(() => {
    if (reportCode && reportQueryData) {
      document.title = `${reportQueryData.name} (${reportCode}) | List Parts | ${PLATFORM_NAME}`;

      setFilterModel(reportQueryData.dataGridProperties.filterModel);
      setSortModel(reportQueryData.dataGridProperties?.sortModel);
      setReportColumnSelectionItems(reportQueryData.columnSelection);
      setPageSize(reportQueryData.dataGridProperties.pageSize);
      setReportName(reportQueryData.name);
      setIsDefault(reportQueryData.isDefault);
    }
  }, [reportQueryData, reportViewCode]);

  useEffect(() => {
    setNewReportData({
      dataGridProperties: {
        filterModel: filterModel,
        pageSize: pageSize,
        sortModel: sortModel || []
      },
      reportTypeName: SavedReportTypeList.PartList,
      reportName: reportName,
      isDefault: reportQueryData?.isDefault,
      id: reportQueryData?.id,
      reportOwner: reportQueryData?.createdBy,
      columnSelection: reportColumnSelectionItems
    });
  }, [filterModel, pageSize, isDefault, reportName, sortModel]);

  useEffect(() => {
    if(serialSearch || filterModel.items.filter(item => item.columnField !== 'locationTypeCode').length > 0 && checkListPagetFilterAvailability(filterModel) ) {
      getAllParts.refetch();
    }
  }, [page, pageSize, filterModel, sortModel, serialSearch, reportColumnSelectionItems]);

  useEffect(() => {
    if (reportCode && reportQueryData) {
      getAllParts.refetch();
    }
  }, [isDefault, reportCode, reportQueryData]);

  const handleColumnsChange = (items: Array<ColumnSelectionItem>) => {
    setReportColumnSelectionItems(items);
  };

  const savedReport = async (newReportData: NewReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useAddNewReportQuery.mutateAsync(newReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Created');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true);
    navigate(reportURL, { replace: true });
  };

  const overwriteReport = async (overWriteReportData: OverwriteReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useOverwriteReportQuery.mutateAsync(overWriteReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Updated');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true);
    navigate(reportURL, { replace: true });
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setFilterModel(filterModel);
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleSelectionModelChange = useCallback((selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  }, []);

  const getPropertyTypeValue = (propertyType: boolean) => {
    return propertyType ? 'Yes' : 'No';
  };

  const handleSerialSearch = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      setSerialSearch(searchKeyword);
    }
  };

  const getPartLocationLinks = (locationData: string, locationIdData: string, locationNodeTypesData: string) => {
    const locations = locationData?.split(' / ');
    const locationIds = locationIdData?.split(' / ');
    const locationNodeTypes = locationNodeTypesData?.split(' / ');
    const links = 
    <Grid container>
      {locations.map((location, index) => (
        <Grid item key={index}><Link href={locationNodeTypes[index] === 'sibling' ? `configure/users/${locationIds[index]}` : `structure/${locationIds[index]}`}>{location}</Link>{index !== locations.length - 1 && ' / '}</Grid>
      ))}
    </Grid>;
    
    return links;
  };

  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            List Parts
          </Typography>
          <Grid direction="row" xs={4} alignContent="flex-end">
            <TextField
              size={SizeProps.Small}
              placeholder="Serial search"
              value={searchKeyword}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchKeyword(e.target.value.replace(/\s/g, ''))}
              onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => handleSerialSearch(e)}
              autoFocus={true}
            />
          </Grid>
        </InlineGroup>
        <GridContainer>
          <Grid>
            <Snackbar
              open={openToast}
              autoHideDuration={4000}
              message={savedReportMessage ? savedReportMessage : 'Successfully Created'}
              onClose={() => setOpenToast(false)}
            />
          </Grid>
          <CustomDataGrid
            columns={columns}
            rows={parts?.data || []}
            rowCount={parts?.total}
            exportFileName={ListType.PartList}
            enableCSVExport={true}
            columnSelectionItems={reportColumnSelectionItems ? reportColumnSelectionItems : columnSelectionItems}
            filterMode={GridFeatureModeConstant.server}
            onFilterModelChange={(filterModel: GridFilterModel) => {
              onFilterChange(filterModel);
            }}
            loading={getAllParts.isFetching}
            rowsPerPageOptions={[25, 50, 100]}
            pagination={true}
            page={page}
            pageSize={pageSize}
            paginationMode={GridFeatureModeConstant.server}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
            }}
            sortModel={sortModel}
            sortingMode={GridFeatureModeConstant.server}
            onSortModelChange={handleSortModelChange}
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectionModelChange}
            initialState={{
              columns: {
                columnVisibilityModel: {},
              },
            }}
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true }
              }
            }}
            filterModel={filterModel}
            getRowId={(row) => row?.id}
            newReportData={newReportData}
            handleSavedReport={(newReportData: NewReportDataType) => savedReport(newReportData)}
            handleOverwriteReport={(overWriteReportData: OverwriteReportDataType) => overwriteReport(overWriteReportData)}
            enableSavedReport
            reportCode={reportViewCode}
            handleColumnsChange={handleColumnsChange}
            handleRefetch={() => setFetchDataGrid(!fetchDataGrid)}
            getRowHeight={() => 'auto'}
            setColumns={setColumns}
            serialSearch={serialSearch}
          />
        </GridContainer>
      </Wrapper>
    </PageContainer>
  );
};