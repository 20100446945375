import React from 'react';
import { Grid } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { MerchantDamageTemplateFormProps } from './MerchantDamageTemplateForm.props';
import { SerialNumberMenu } from '../../molecules/SerialNumberMenu';
import { UploadButton } from '../../molecules/UploadButton';
import { FontSizeProps } from '../../../@types';
import { COLORS } from '../../../configs/colors';
import { PART_IMAGES_MIME_TYPES } from '../../../configs/envs';

export const MerchantDamageTemplateForm: React.FC<MerchantDamageTemplateFormProps> = ({
  options,
  handleSerialNumberMenu,
  handleUploadedPhotos,
  isError,
  value
}) => {
  return (
    <Grid container spacing={1} marginTop={1} marginBottom={1}>
      <Grid item xs={12}>
        <Typography
          variant={TypographyVariantProps.Body2}
          fontWeight={TypographyFontWeightProps.Bold}
        >
          Merchant Damage Template
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SerialNumberMenu
          options={options}
          onChange={handleSerialNumberMenu}
          error={isError}
          value={value}
        />
      </Grid>
      <Grid container xs={12} mt={2} direction="row" justifyContent="space-between">
        <Grid item>
          <UploadButton fileTypes={PART_IMAGES_MIME_TYPES.split(',')} maxFileSize={30} setFileDetails={handleUploadedPhotos}
            label="Upload Damaged Pictures" icon={<AddPhotoAlternateIcon fontSize={FontSizeProps.Small} />}
          />
        </Grid>
        {isError && !value?.photoKeys && <Grid item><Typography textColor={COLORS.DarkRed} variant={TypographyVariantProps.Body2}>*Required</Typography></Grid>}
      </Grid>
    </Grid>
  );
};