import React, { useEffect, useState } from 'react';
import { GridColDef, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid';
import { Alert, AlertTitle } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { DataGrid } from '../../../../../components/atoms/DataGrid';
import { Button, ButtonVariantProps } from '../../../../../components/atoms/Button';
import { ContentWrapperBox, Footer, GridContainer, StyledButton } from './ShareAccessWithFragment.styles';
import { LiveSearchBox, LiveSearchListItem } from '../../../../../components/atoms/LiveSearchBox';
import { LocationSearchItem } from '../../../../../components/molecules/LocationSearchItem';
import { InlineGroup } from '../../../../../components/atoms/InlineGroup';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../../../../components/atoms/FormControlLabel';
import { Switch } from '../../../../../components/atoms/Switch';
import { Typography, TypographyVariantProps } from '../../../../../components/atoms/Typography';
import { ColorProps, ReportAccessData, SizeProps, LiveSearchResponse } from '../../../../../@types';
import { ShareAccessWithFragmentProps } from './ShareAccessWithFragment.props';
import { useAddNewSavedReportAccess, useUpdateReportAccessDataById } from '../../../../../queries/report-query';
import { useSearchAllNodesByName } from '../../../../../queries/live-search-query';
import { Snackbar } from '../../../../../components/atoms/Snackbar';
import { Box } from '../../../../../components/atoms/Box';

export const ShareAccessWithFragment: React.FC<ShareAccessWithFragmentProps> = ({
  rowData,
  savedData,
  handleRefetch,
}) => {
  const [searchData, setSearchData] = useState<string>();
  const [searchDataId, setSearchDataId] = useState<number>();
  const [tableRowData, setTableRowData] = useState<ReportAccessData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showWarningToast, setShowWarningToast] = useState(false);

  const addNewSavedReportAccess = useAddNewSavedReportAccess();
  const searchNodeQuery = useSearchAllNodesByName();
  const updateTemplateQuery = useUpdateReportAccessDataById();

  useEffect(() => {   
    if (savedData) {
      const array:ReportAccessData[] = [];
      savedData.data.map((row: ReportAccessData) => {
        const newObjectData = {
          id: row.id,
          entityId: row.entityId,
          name: row.name,
          canEdit: row.canEdit,
          canCustomizeColumns: row.canCustomizeColumns,
          canCustomizeSearch: row.canCustomizeSearch,
          isActive: row.isActive
        };
        
        array.push(newObjectData);
      });
      setTableRowData(array);
      setLoading(false);
    }
    setLoading(false);
  }, [savedData]);


  useEffect(() => {
    setLoading(false);
    if (searchData && searchDataId) {
  
      const rowExist = tableRowData?.some((item: ReportAccessData) => item.entityId === searchDataId);
      rowExist && setShowWarningToast(true);
      const newObjectData = {
        entityId: searchDataId,
        name: searchData,
        canEdit: false,
        canCustomizeColumns: false,
        canCustomizeSearch: false,
        isActive: true
      };
      !rowExist && setTableRowData([...tableRowData, newObjectData]);

      setSearchData('');
      setSearchDataId(0);
    }
  }, [searchData, searchDataId]);

  const handleSave = async() => {
    tableRowData.map(async(data: ReportAccessData) => {
      if (data.id) {
        const updatedReportAccessData = {
          id: data.id,
          data: data
        };
        await updateTemplateQuery.mutateAsync(updatedReportAccessData);
        handleRefetch(true);
      } else {
        const newReportAccessData = {
          reportId: rowData?.id,
          data: data
        };
        const response = await addNewSavedReportAccess.mutateAsync(newReportAccessData);
        const index = tableRowData.findIndex((obj: ReportAccessData) => obj.entityId === response.entityId);
        const newDataSet: ReportAccessData[] = tableRowData.filter((obj: ReportAccessData) => obj.entityId !== response.entityId);

        const updatedItem: ReportAccessData = {
          id: response.id,
          entityId: response.entityId,
          name: response.name,
          canEdit: response.canEdit,
          canCustomizeColumns: response.canCustomizeColumns,
          canCustomizeSearch: response.canCustomizeSearch,
          isActive: response.isActive
        };
        newDataSet.splice(index, 0, updatedItem);
        setTableRowData(newDataSet);
        handleRefetch(true);
      }
    });
    setLoading(false);
    setSearchData('');
    setSearchDataId(0);
  };

  const handleDelete = async(params: GridRenderCellParams) => {
    const updatedReportAccessData = {
      id: params.row.id,
      data: {
        canCustomizeColumns: params.row.canCustomizeColumns,
        canCustomizeSearch: params.row.canCustomizeSearch,
        canEdit: params.row.canEdit,
        entityId: params.row.entityId,
        isActive: false
      }
    };

    params.row.id && await updateTemplateQuery.mutateAsync(updatedReportAccessData);
    const newDataSet: ReportAccessData[] = tableRowData.filter((obj: ReportAccessData) => obj.entityId !== params.row.entityId);
    setTableRowData(newDataSet);
    handleRefetch(true);
  };

  const columns: GridColDef[] = [  
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      valueGetter: (params: GridValueGetterParams) => params.row.name
    },
    {
      field: 'permissions', 
      headerName: 'Permissions', 
      width: 470,
      renderCell: (params: GridRenderCellParams) => (
        <InlineGroup
          childGap={20}
          fullWidth
        >
          <FormControlLabel
            control={
              <Switch
                color={ColorProps.Success}
                size={SizeProps.Small}
                checked={tableRowData.find((item: ReportAccessData) => item.entityId === params.row.entityId)?.canEdit || false}
                onChange={(event) => {
                  const updatedData = tableRowData.map((item: ReportAccessData) =>
                    item.entityId === params.row.entityId
                      ? { ...item, canEdit: event.target.checked }
                      : item
                  );
                  setTableRowData(updatedData);
                }}
              />
            }
            label={
              <Typography variant={TypographyVariantProps.Caption} >Can Edit</Typography>
            }
            labelPlacement={FormControlLabelPlacementProps.Top}
          />
          <FormControlLabel
            control={
              <Switch
                color={ColorProps.Success}
                size={SizeProps.Small}
                checked={tableRowData.find((item: ReportAccessData) => item.entityId === params.row.entityId)?.canCustomizeSearch || false}
                onChange={(event) => {
                  const updatedData = tableRowData.map((item: ReportAccessData) =>
                    item.entityId === params.row.entityId
                      ? { ...item, canCustomizeSearch: event.target.checked }
                      : item
                  );
                  setTableRowData(updatedData);
                }}
              />
            }
            label={
              <Typography variant={TypographyVariantProps.Caption} >Can Customize Search</Typography>
            }
            labelPlacement={FormControlLabelPlacementProps.Top}
          />
          <FormControlLabel
            control={
              <Switch
                color={ColorProps.Success}
                size={SizeProps.Small}
                checked={tableRowData.find((item: ReportAccessData) => item.entityId === params.row.entityId)?.canCustomizeColumns || false}
                onChange={(event) => {
                  const updatedData = tableRowData.map((item: ReportAccessData) =>
                    item.entityId === params.row.entityId
                      ? { ...item, canCustomizeColumns: event.target.checked }
                      : item
                  );
                  setTableRowData(updatedData);
                }}
              />
            }
            label={
              <Typography variant={TypographyVariantProps.Caption} >Can Customize Column</Typography>
            }
            labelPlacement={FormControlLabelPlacementProps.Top}
          />
        </InlineGroup>
      )
    },
    {
      field: 'actions', 
      headerName: '', 
      width: 62,
      renderCell: (params: GridRenderCellParams) => params.row.isActive &&
        (<Button
          variant={ButtonVariantProps.Icon}
          onClick={() => handleDelete(params)}>
          <Delete color="error"/>
        </Button>)
    },

  ];

  return (
    <ContentWrapperBox>
      <Grid container ml={2} mt={3}>
        <Grid item xs={10}>
          <LiveSearchBox
            title="User Search"
            timeOffset={400}
            value={searchData || ''}
            onClearValue={() => { 
              setSearchData(''); 
              setSearchDataId(undefined);
            }}
            renderItem={(props: any, option: any) => {
              return (
                <LiveSearchListItem {...props}>
                  <LocationSearchItem data={option} />
                </LiveSearchListItem>
              );
            }}
            onChange={async (liveSearchResponse: LiveSearchResponse) =>{
              setSearchData(liveSearchResponse.name);
              setSearchDataId(liveSearchResponse.id);
            }}
            onApiInvoke={async (searchText) => {
              return searchNodeQuery.mutateAsync(searchText);
            }}
          />
        </Grid>
      </Grid>
      <GridContainer>
        <DataGrid
          columns={columns}
          rows={tableRowData || []}
          loading={loading}
          getRowId={(row) => row?.entityId}
          disableColumnMenu={true}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableDensitySelector={true}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: false
            }
          }}
        />
      </GridContainer>
      <Grid container ml={1} mb={2}>
        <Footer>
          <StyledButton variant={ButtonVariantProps.Primary} onClick={handleSave}>Save</StyledButton>
        </Footer>
      </Grid>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right', }} open={showWarningToast} autoHideDuration={8000} onClose={() => { setShowWarningToast(false); }}>
        <Alert onClose={() => setShowWarningToast(false)} severity="warning">
          <AlertTitle>Warning</AlertTitle>
          <Box>Search Item is already in the list</Box>
        </Alert>
      </Snackbar>
    </ContentWrapperBox>
  );
};