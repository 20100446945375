import React, { useCallback, useEffect, useState } from 'react';
import {
  GridColDef,
  GridFeatureModeConstant,
  GridValueGetterParams, 
  GridRenderCellParams,
  GridFilterModel,
  GridSortModel,
  GridSelectionModel,
  GridValueFormatterParams
} from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Edit } from '@mui/icons-material';
import { CustomDataGrid } from '../../../../components/layouts/CustomDataGrid';
import { Chip } from '../../../../components/atoms/Chip';
import { ColorProps } from '../../../../@types';
import { ColumnSelectionItem } from '../../../../components/templates/ColumnSelection';
import { InteractionsTemplateListProps } from './InteractionsTemplateList.props';
import { formatDate } from '../../../../utils/common';
import { useGetAllInteractionTemplates } from '../../../../queries/interactions-query';
import { TemplateListResponse } from '../../../../@types/interactions.type';
import { GridContainer } from '../../../../styled/common.styles';
import { ISACTIVE_FILTER_DROPDOWN_ITEM_ARRAY } from '../../../../constants/common';
import { PLATFORM_NAME } from '../../../../configs/common';
import { TabHandler } from '../../../../handlers/TabHandler';

export const InteractionsTemplateList: React.FC<InteractionsTemplateListProps> = ({
  handleOpen,
  isRefetch,
  setBackRefetch,
  canEdit
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [refetchStatus, setRefetchStatus] = useState(false);
  const [templateList, setTemplateList] = useState<TemplateListResponse>();
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [editedColumnSelectionItems, setEditedColumnSelectionItems] = useState<Array<ColumnSelectionItem>>();

  const { data, isLoading, refetch: refetchTemplatesData, isRefetching } = useGetAllInteractionTemplates({ filterModel, page, pageSize, sortModel });

  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Template Id',
      width: 100,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value.toString(),
      valueGetter: (params: GridValueGetterParams) => params.row.id
    },
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.name
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'string',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.createdAt)
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      type: 'string',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.createdUser
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      type: 'string',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.updatedAt)
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      type: 'string',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.updatedUser
    },
    {
      field: 'isActive',
      headerName: 'Is Active',
      width: 100,
      type: 'singleSelect',
      valueOptions: ISACTIVE_FILTER_DROPDOWN_ITEM_ARRAY,
      renderCell: (params: GridRenderCellParams) => params.row.isActive ? <Chip label="YES" color={ColorProps.Success} /> : <Chip label="NO" color={ColorProps.Error} />,
      valueGetter: (params: GridValueGetterParams) => params.row.isActive
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: 50,
      flex: 0.1,
      headerAlign: 'center',
      filterable: false,
      renderCell: (params: GridRenderCellParams) => (
        canEdit &&
        <Grid>
          <IconButton aria-label="edit" onClick={() => handleOpen(true,params.row)}>
            <Edit />
          </IconButton>
        </Grid>
      )
    }
  ]);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setFilterModel({ ...filterModel });
  }, []);
  
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleSelectionModelChange = useCallback((selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  }, []);

  const handleColumnsChange = (items: Array<ColumnSelectionItem>) => {
    setEditedColumnSelectionItems(items);
  };

  TabHandler(`Interactions Templates | ${PLATFORM_NAME}`);

  useEffect(() => {
    refetchTemplatesData();
  }, [filterModel, page, pageSize, sortModel]);

  useEffect(() => {
    isRefetch && setRefetchStatus(isRefetch);
  }, [isRefetch, refetchStatus]);

  useEffect(() => {
    refetchStatus && refetchTemplatesData();
  }, [refetchStatus]);

  useEffect(() => {
    if (data) {
      setTemplateList(data);
      setRefetchStatus(false);
      setBackRefetch(false);
    }
  }, [data, isRefetching]);

  const columnSelectionItems: Array<ColumnSelectionItem> = [
    {
      title: 'Template',
      value: [
        {
          title: 'ID',
          field: 'id',
          value: true
        },
        {
          title: 'Name',
          field: 'name',
          value: true
        },
        {
          title: 'Created At',
          field: 'createdAt',
          value: false
        },
        {
          title: 'Created By',
          field: 'createdBy',
          value: true
        },
        {
          title: 'Updated At',
          field: 'updatedAt',
          value: false
        },
        {
          title: 'Updated By',
          field: 'updatedBy',
          value: true
        },
        {
          title: 'Is Active',
          field: 'isActive',
          value: true
        }
      ]
    }
  ];

  return (
    <GridContainer>
      <CustomDataGrid
        columns={columns}
        rows={templateList?.data || []}
        exportFileName=""
        enableCSVExport={false}
        columnSelectionItems={editedColumnSelectionItems || columnSelectionItems}
        filterMode={GridFeatureModeConstant.server}
        onFilterModelChange={(filterModel: GridFilterModel) => onFilterChange(filterModel)}
        loading={isLoading}
        rowCount={templateList?.total}
        rowsPerPageOptions={[25, 50, 100]}
        pagination={true}
        page={page}
        pageSize={pageSize}
        paginationMode={GridFeatureModeConstant.server}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        sortingMode={GridFeatureModeConstant.server}
        onSortModelChange={handleSortModelChange}
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionModelChange}
        handleColumnsChange={handleColumnsChange}
        initialState={{
          columns: {
            columnVisibilityModel: {
              parentNodeId: false,
              referenceNumbers: false,
              status: false,
            },
          },
        }}
        componentsProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true }
          }
        }}
        filterModel={filterModel}
        getRowHeight={() => 'auto'}
        setColumns={setColumns}
      />
    </GridContainer>
  );
};