import { MerchantDamagePartSerialMenuData } from './part.type';

export enum FormFieldType {
  Label = 'Label',
  Text = 'Textbox',
  RichTextbox = 'RichTextbox',
  Dropdown = 'Dropdown',
  Radio = 'Radio',
  Checkbox = 'Checkbox',
  Date = 'Date',
  File = 'File',
  Photo = 'Photo',
  Signature = 'Signature',
  FaultSelection = 'Fault Selection',
  SolutionAction = 'Solution Action',
  PartsRequest = 'Parts Request',
  ServiceCreation = 'Service Creation',
  DecisionPrompt = 'Decision Prompt',
  GetGeoLocation = 'Get Geo-Location',
  EstimatedTimeOfArrival = 'Estimated Time Of Arrival',
  MerchantDamageTemplate = 'Merchant Damage Template',
  ModifyStructure = 'Modify Structure',
  ActionTrigger = 'Action Trigger',
  SetParameter = 'Set Parameter',
  SetParameterNoUI = 'Set Parameter (No UI)',
  GetParameter = 'Get Parameter',
  ServiceSearch = 'Service Search'
}

export interface FormFieldOption {
  id: number;
  title: string;
  value: string;
  isActive: boolean;
  isDefault?: boolean;
}

export interface FormFieldItem {
  id: number;
  title: string;
  isRequired: boolean;
  isError?:boolean;
  options?: Array<FormFieldOption>;
  type: FormFieldType;
  preSelectedData?: any;
  value?: any;
}

export enum FormConditionOption {
  BillableType = 'Billable Type',
  ObservedFaultName = 'Observed Fault (Fault Name)',
  ObservedFaultDescription = 'Observed Fault (Fault Description)',
  PrimaryAllocation = 'Primary Allocation',
  SecondaryAllocation = 'Secondary Allocation',
  MostRecentPartAction = 'Most Recent Part Action',
  MostRecentPartActionPartTypeName = 'Most Recent Part Action Part Type Name',
  ServiceReferenceNo1 = 'Service Reference No. 1',
  ServiceReferenceNo2 = 'Service Reference No. 2',
  ServiceReferenceNo3 = 'Service Reference No. 3',
  SiblingReferenceNo1 = 'Sibling Reference No. 1',
  SiblingReferenceNo2 = 'Sibling Reference No. 2',
  SiblingReferenceNo3 = 'Sibling Reference No. 3'
}

export enum FormConditionCompareOption {
  Equal = 'Equal',
  Wildcard = 'Wildcard'
}

export enum FormConditionJoinOption {
  None = 'None',
  AND = 'AND',
  OR = 'OR'
}

export interface FormConditionItem {
  id: number;
  conditionOption?: string;
  compareOption?: FormConditionCompareOption;
  value: string;
  joinOption?: FormConditionJoinOption;
}


export interface QuestionObject {
  id?: number;
  questionText?: string;
  isYes?: boolean;
  hasValue?: boolean;
}

export interface FormContainerItem {
  id: number;
  fields: Array<FormFieldItem>;
  conditions: Array<FormConditionItem>;
  isDecisionPrompt?: boolean;
  questionData?: QuestionObject;
}

export interface FormObject {
  id: number;
  title: string;
  containers: Array<FormContainerItem>;
  isMandatory?: boolean;
  isRepeatable?: boolean;
  templateDisplayLimit?: number;
  displayCount?: number;
  isDownloadable?: boolean;
  isEditable?: boolean;
  byPassedFormFields?: Array<string>;
  templateId?: number;
  ruleId?: number;
}

export interface InterceptedFormsObject {
  url: string;
  method: string;
  payload: any;
  forms: Array<FormObject>;
  actionTriggers?: Array<FormFieldItem>;
}

export enum ModifyStructureFormModifyStructureType {
  CreateLocation = 'create-location',
  ToggleActive = 'toggle-active'
}

export enum ModifyStructureFormToggleScopeOption {
  SublocationOnly = 'sublocation-only',
  SublocationAndParentIfEmpty = 'sublocation-and-parent-if-empty',
  ServiceLocationAndSublocation = 'service-location-and-sublocation',
  ServiceLocationAndAllChildren = 'service-location-and-all-children'
}

export interface ModifyStructureData {
  structureType: ModifyStructureFormModifyStructureType;
  selectedValue: string;
}

export enum ActionTriggerFormActionType {
  PartAction = 'Part Action',
  Task = 'Task',
  PrePopulatedPartActions = 'Pre-Populated Part Actions'
}

export enum ActionTriggerFormActionValueOptions {
  AllPartsAtThisNode = 'All parts at this node',
  AllPartsAtThisNodeAndChildren = 'All parts at this node and children',
  AllPartsInThisNodeGroup = 'All parts in this node group',
  SiteSchema = 'Site Schema'
}

export interface ActionTriggerData {
  actionType: ActionTriggerFormActionType;
  actionValue: string;
  value: string;
  valueId?: number;
}

export enum ParameterDataFormParameterType {
  Status = 'Status',
  PrimaryAllocation = 'Primary Allocation',
  SecondaryAllocation = 'Secondary Allocation',
  ScheduledDate = 'Scheduled Date',
  SetTag = 'Set Tag',
  UpdateSubLocation = 'Update Sub Location',
  ReportedSerial = 'Reported Serial',
  Part = 'Part'
}

export interface PartParameterData {
  partAttribute?: string;
  movementType?: string;
}

export interface ParameterData {
  parameterType: ParameterDataFormParameterType;
  presetSuggestion1?: any;
  presetSuggestion2?: any;
  partParameter?: PartParameterData;
}

export enum ParameterUIDataFormPartAttributes {
  PartLocation = 'Part Location',
  PartCondition = 'Part Condition',
  PartSoftware = 'Part Software',
  PartFirmware = 'Part Firmware',
  PartHardware = 'Part Hardware Revision',
  PartFleetTag = 'Part Fleet Tag',
}

export enum ParameterUIDataFormPartMovementType {
  In = 'In',
  Out = 'Out'
}

export enum ParameterNoUIDataFormParameterType {
  Billable = 'Billable',
  SLA = 'SLA',
  Part = 'Part'
}

export enum ParameterNoUIDataFormSLAOption {
  Met = 'Met',
  Reviewed = 'Reviewed',
  Mitigated = 'Mitigated'
}

export enum ParameterNoUIDataFormSLAType {
  Any = 'Any',
  Client = 'Client',
  OpsField = 'Ops/Field',
  Logistics = 'Logistics'
}

export enum ParameterNoUIDataFormSLATypeCode {
  Any = 'any',
  Client = 'client',
  OpsField = 'ops-field',
  Logistics = 'ops-logistics'
}

export enum ParameterNoUIDataFormPartAttribute {
  Condition = 'Condition'
}

export enum ParameterNoUIDataFormPartMovementType {
  In = 'In',
  Out = 'Out',
  Swap = 'Swap'
}

export interface ParameterNoUIData {
  parameterType: ParameterNoUIDataFormParameterType;
  billableType?: string;
  slaData?: any;
  partData?: any;
}

export interface ModifyStructureData {
  structureType: ModifyStructureFormModifyStructureType;
  selectedValue: string;
}

export interface MerchantDamageTemplateObject {
  serialData?: MerchantDamagePartSerialMenuData;
  photoKeys?: FormData | Array<string>;
}

export interface MerchantDamageObjectParcer {
  containerId?: number;
  fieldId?: number;
  photos?: File[];
  serialData?: MerchantDamagePartSerialMenuData;
}

export interface GetParameterData {
  fieldTitle: string;
  selectedParameter: string;
}

export enum GetParameterOptions {
  ServiceNodeReference1 = 'Service Node Reference 1',
  ServiceNodeReference2 = 'Service Node Reference 2',
  ServiceNodeReference3 = 'Service Node Reference 3',
  ServiceSubLocNodeReference1 = 'Service Sublocation Node Reference 1',
  ServiceSubLocNodeReference2 = 'Service Sublocation Node Reference 2',
  ServiceSubLocNodeReference3 = 'Service Sublocation Node Reference 3',
  SiblingReference1 = 'Sibling Reference 1',
  SiblingReference2 = 'Sibling Reference 2',
  SiblingReference3 = 'Sibling Reference 3',
  ClientReference1 = 'Client Reference 1',
  ClientReference2 = 'Client Reference 2',
  ClientReference3 = 'Client Reference 3',
  UserFirstLastName = 'User First and Last Name',
  LastINPartActionPartTypeName = 'Last IN Part Action Part Type Name',
  LastINPartActionPartTypeNo = 'Last IN Part Action Part Type No',
  LastINPartActionPartSerialNo = 'Last IN Part Action Part Serial No',
  LastOUTPartActionPartTypeName = 'Last OUT Part Action Part Type Name',
  LastOUTPartActionPartTypeNo = 'Last OUT Part Action Part Type No',
  LastOUTPartActionPartSerialNo = 'Last OUT Part Action Part Serial No'
}

export interface ServiceSearchData {
  serviceTypes?: string[];
  actionTypes?: string[];
  processTypes?: string[];
  restrictSublocation?: boolean;
}