import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import { DropdownItem } from '../components/atoms/SelectMenu';
import { LabelValue } from './common.type';
import { Tag } from './tag.type';
import { PartCondition } from './partType.type';

export interface PartObject {
  id: number;
  partTypeId: number;
  partType: any;
  locationId: number;
  mainNodeId: number;
  nodeName: string;
  locationHierarchy: string;
  nodeList: Node[];
  conditionCode: string;
  conditionName: string;
  condition: PartConditionDropdown;
  softwareVersionId?: number;
  softwareVersion?: string;
  firmwareVersionId?: number;
  firmwareVersion?: string;
  hardwareRevisionId?: number;
  hardwareRevision?: string;
  fleetTagId?: number;
  fleetTag?: LabelValue[];
  serial1: string;
  serial2: string;
  serial3: string;
  notes: string;
  configChnageNotes?: string;
  firstInstalled?: string;
  locationTree: string;
  partTypeTree: string;
  tags: Tag[];
  partConditionHistory: PartConditionHistory;
  partChildrens?: PartChildrenObject[];
  fleetTagName?: string;
  locationName?: string;
  additionalData?: any;
  createdBy?: string;
  createdAt?: string;
}

export interface PartChildrenObject {
  id: number;
  serial1: string;
  serial2: string;
  serial3: string;
  partTypeName: string;
  partTypeNumber: string;
  hasChildren?: boolean;
  condition: string;
  updatedAt: Date;
}

export interface PartLocationObject {
  id: number;
  name: string;
  nodeTypeName: string;
  locationId: number;
  locationTypeCode: string;
  mainNodeId: number;
  nodeName: string;
  locationHierarchy: string;
  nodeList: Node[];
  locationTree: string;
}

export interface UpdatePartLocationObject {
  id: number;
  locationId: number;
  locationTypeCode: string;
  locationHierarchy: string;
}

export interface PartConditionDropdown {
  value: string;
  label: string;
}

export interface SfhOptions {
  value: string;
  label: string;
}

export interface SfhObject {
  softwares: Array<SfhOptions>;
  firmwares: Array<SfhOptions>;
  hardwares: Array<SfhOptions>;
}

export interface Node {
  id: number;
  name: string;
  nodeTypeCode: string;
}

export interface PartList {
  index: number;
  id: number;
  serial1: string;
  partTypeName: string;
  qty?: number;
  serialised?: string;
  partTypeId: number;
  partNumber: string;
  partTypeActive: boolean;
  partTypeTags?: Tag[];
  conditionCode: string;
  locationStructure: string;
  locationId: number;
  locationType: string;
  locationName: string;
  parentLocationId?: number;
  parentLocationType?: string;
  parentLocationName?: string;
  serial2?: string;
  serial3?: string;
  notes?: string;
  softwareVersion?: string;
  firmwareVersion?: string;
  updatedAt: string;
  updatedBy: string;
  tags?: Tag[];
  partTypeClient?: string;
  partCondition: PartCondition;
}

export interface PartListResponse {
  previousPage?: number;
  currentPage?: number;
  nextPage?: number;
  total?: number;
  limit?: number;
  data?: PartList[];
}

export interface PartConditionHistory {
  serviceId?: string;
  lastLocation?: string;
  lastCondition?: string;
}

export enum SearchCode {
  AM_PID = 'AM-PID',
  AM_PTID = 'AM-PTID',
  AM_LID = 'AM-LID',
  AM_SID = 'AM-SID',
  S_HASH = 's#',
  PART = 'Part-',
  NS_PART = 'NSPart-'
}

export interface PartHistoryDataType {
  id: number;
  createdAt: string;
  createdBy: string;
  action: string;
  condition: string;
  toNode: string;
  fromNode: string;
  notes: string;
  serviceId?: number;
  shipmentId?: number;
  historyTypeCode: string;
}

export enum PartLocationType {
  Part = 'part',
  Node = 'node',
}

export enum PartConditionOptions {
  GOOD = 'good',
  FAULTY = 'faulty',
  REPAIR = 'repair',
  DOA = 'doa',
  QUARANTINE = 'quarantine',
  MISSING = 'missing',
  BER = 'ber',
  DESTROYED = 'destroyed',
  RESERVED = 'reserved',
  INSTALLED = 'installed',
  STAGING = 'staging',
  IN_TRANSIT = 'in-transit'
}

export interface PartExistance {
  id: number;
  serial1: string;
  linkedField: string;
  isExist: boolean;
}

export interface NSPartExistance {
  id: number;
  partType: string;
  location: string;
  conditionCode: string;
  fleetTagName: string;
  linkedField: string;
  isExist: boolean;
}

export interface PartBulkUpdate {
  serialsDataSet: Array<PartExistance> | Array<NSPartExistance>;
  smartInput: string;
}

export interface PartManifestSerialList {
  id: number;
  serial1: string;
  serial2: string;
  serial3: string;
  partTypeId: number;
  partTypeName: string;
  locationName: string;
  locationId: number;
  locationTypeCode: string;
  condition: string;
  software: string;
  firmware: string;
  hardwareRev: string;
  fleetTagName: string;
  notes: string;
  isActive: boolean;
}

export interface PartManifestNonSerialList {
  id: number;
  partTypeId: number;
  partTypeName: string;
  partType?: string;
  locationName: string;
  locationId: number;
  location?: string;
  condition: string;
  fleetTagName: string;
  quantityInStock: number;
  isActive: boolean;
}

export interface PartManifest {
  serial: Array<PartManifestSerialList>;
  nonSerial: Array<PartManifestNonSerialList>;
}

export interface NSPartLocationList {
  id: number;
  locationName?: string;
  locationId?: number;
  condition?: string;
  conditionCode?: string;
  fleetTagId?: number;
  fleetTagName?: string;
  quantity?: number;
  updatedAt?: string;
}

export interface SerialisedPartsUpdateSelectionDataType {
  serial1?: string;
  serial2?: string;
  serial3?: string;
  partTypeId?: number;
  notes?: string;
  software?: string;
  firmware?: string;
  hardware?: string;
  fleetTag?: string;
  locationId?: string;
  locationTypeCode?: string;
  structureNodeType?: string;
  conditionCode?: string;
  isActiveLinkedField?: boolean;
  linkedFieldColumn?: string;
}

export interface GeneralPermissionGrantDropdownValueType {
  softwareOptions: Array<DropdownItem>;
  firmwareOptions: Array<DropdownItem>;
  hardwareRevisionOptions: Array<DropdownItem>;
  fleetTagOptions: Array<DropdownItem>;
  conditionCodeOptions: Array<DropdownItem>;
}

export interface BulkUpdatePartObject {
  id: string;
  serial1: string;
  serial2: string;
  serial3: string;
  partTypeId: number;
  partTypeName: string;
  locationName: string;
  locationId: number;
  condition: string;
  software: string;
  firmware: string;
  hardwareRev: string;
  fleetTagName: string;
  notes: string;
  isActive: boolean;
  linkedField: string;
  quantityInStock?: number;
  partType?: string;
  location?: string;
  recordId?: number;
  isExist?: boolean;
}

export interface BulkUpdatePartDataset {
  isSerial: boolean;
  partList: Array<BulkUpdatePartObject>;
  selectedPart: SerialisedPartsUpdateSelectionDataType;
}

export interface BulkUpdatePartItemSelection {
  isExist: boolean;
  id: number;
  serial1: string;
  serial2: string;
  serial3: string;
  partTypeId: number;
  partTypeName: string;
  locationName: string;
  locationId: number;
  conditionCode: string;
  softwareVersion: string;
  firmwareVersion: string;
  hardwareRevision: string;
  fleetTagName: string;
  notes: string;
  linkedField: string;
  recordId: any;
  partType: string;
  location: string;
  quantityInStock: number;
  locationTypeCode: string;
}

export interface PartData {
  id: number;
  firmwareVersion: string;
  fleetTag: string;
  hardwareVersion: string;
  location: string;
  locationId: number;
  ns_partId: number;
  partId: number;
  partSerial1: string;
  partTypeId: number;
  partTypeName: string;
  qty: number;
  softwareVersion: string;
  condition: string;
  partTypeCatagoryCode: string;
  isInSiblingHierarchy?: boolean;
  isInSiteHierarchy?: boolean;
  isUnderClientStructure?: boolean;
}

export interface SerailizedPartsList {
  value: string;
  label: string;
  id: number;
  partTypeId?: number;
  partId?: number;
  name?: string;
  category?: string;
  condition?: string;
  conditionName?: string;
  location?: string;
  serial?: string;
  sw?: string;
  hw?: string;
  fw?: string;
  ft?: string;
}

export interface SearchNodeParts {
  name: string;
  isInClientHierarchy: boolean;
}

export interface PartHistoryMovementListResponse {
  previousPage: number;
  currentPage: number;
  nextPage: number;
  total: number;
  limit: number;
  data: PartHistory[];
}

export interface PartHistory {
  id: number;
  partId: number;
  serial1: string;
  serial2: string;
  serial3: string;
  historyTypeCode: string;
  sourceNodeName: string;
  sourceLocation: string;
  destinationNodeName: string;
  destinationLocation: string;
  partTypeId: number;
  serviceId: number;
  shipmentId: number;
  fromNodeId: string;
  toNodeId: string;
  partConditionCode: string;
  createdAt: string;
  createdBy: number;
  updatedBy: number;
  partTypeNumber: string;
  nodeName: string;
  conditionName: string;
  conditionCode: string;
  softwareVersionId: number;
  softwareVersion: string;
  firmwareVersionId: number;
  firmwareVersion: string;
  hardwareRevisionId: number;
  hardwareVersion: string;
  fleetTagId: number;
  updatedAt: string;
  partTypeName: string;
  partTypeCategoryCode: string;
  partTypeIsActive: boolean;
  serviceTypeCode: string;
  serialised: string;
  serviceTypeName: string;
  serviceStatusName: string;
  customerId1: string;
  customerId2: string;
  customerId3: string;
  createdByRole: string;
  updatedByName: string;
  updatedByRole: string;
  createdByName?: string;
  historyTypeName?: string;
  notes: string;
}
export interface PartHistoryMovementListFilterOptions {
  filterModel: GridFilterModel;
  page: number;
  pageSize: number;
  startDate: string;
  endDate: string;
  partId: string;
  sortModel?: GridSortModel;
}
export interface MerchantDamageTemplateQuery {
  serviceId: number;
  mainLocationNodeId: number;
}

export interface MerchantDamagePartSerialMenuData {
  partId: number;
  partTypeName: string;
  serial: string;
}

export interface MerchantDamagePartSerialQueryData {
  id: number;
  partId: number;
  partTypeName: string;
  serial: string;
}

export interface MerchantDamagePartAdditionalData {
  serviceId: number;
  photoKeys: string;
}

export enum DamagedPartSelectionKeys {
  ContainerId = 'containerId',
  FieldId = 'fieldId',
  SerialData = 'serialData',
  Photos = 'photos'
}

export interface DuplicatePartsDataType {
  id: number;
  allowMerge: boolean;
  partTypeId: number;
  conditionCode: string;
  serial1: string;
  serial2: string;
  serial3: string;
  locationName: string;
  createdAt: string;
  createdByName: string;
}

export interface ExceptionalPartUpdateObject {
  id: number;
  isAccepted: boolean;
  exceptionNotes: string;
  partHistoryNotes: string;
}

export interface BulkUpdatePartType {
  partTypeId?: number;
  partTypeName?: string;
}

export interface PartBulkData {
  id: number;
  partTypeId: number;
  serial1: string;
  serial2: string;
  serial3: string;
}