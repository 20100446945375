import React, { useState, useEffect, useRef, SyntheticEvent, KeyboardEvent } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdvanceLiveSearchBoxProps } from './AdvanceLiveSearchBox.props';
import { SizeProps } from '../../../@types';
import { StyledTextField } from './AdvanceLiveSearchBox.styles';

export const AdvanceLiveSearchBox: React.FC<AdvanceLiveSearchBoxProps> = ({
  title,
  timeOffset,
  value,
  isPaste,
  isPending,
  showProgress,
  overrideOnEnterKey = false,
  clearInputOnEmptyResult,
  onClearValue,
  onChange,
  onApiInvoke,
  renderItem,
  onPaste,
  onKeyDown,
  onTextChange
}) => {
  const [options, setOptions] = useState<Array<any>>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [delayedInput, setDelayedInput] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    if(clearInputOnEmptyResult) {
      setInputValue('');
      onClearValue();
    }
  }, [clearInputOnEmptyResult]);

  useEffect(() => {
    if (delayedInput) {
      if (inputValue && options.length === 1) {
        onChange(options[0]);
        setDelayedInput(false);
        setIsLoading(false);
        setIsOpen(false);

        if(overrideOnEnterKey) {
          onClearValue();
          setInputValue('');
          setOptions([]);
        }
      }
    }
  }, [options]);

  const handleInputChange = async (event: SyntheticEvent, textValue: string) => {
    setInputValue(textValue);
    clearInterval(timerRef.current);

    if (textValue.length) {
      setIsLoading(true);
      timerRef.current = setTimeout(async () => {
        const results = await onApiInvoke(textValue);
        setIsLoading(false);
        setOptions([...results]);
      }, timeOffset);
    }
    else {
      setOptions([]);
      onClearValue();
      setIsLoading(false);
    }
  };

  if (isPaste) {
    return (
      <StyledTextField
        fullWidth
        multiline
        autoFocus
        disabled={isPending}
        size={SizeProps.Small}
        label={title}
        value={value}
        InputProps={{
          endAdornment:
            <InputAdornment position="end" onClick={() => onClearValue()}>
              {showProgress ?
                <CircularProgress size={20} /> :
                <IconButton>
                  <CloseIcon />
                </IconButton>
              }
            </InputAdornment>,
        }}
        onKeyDown={onKeyDown}
        onChange={onTextChange}
      />
    );
  }

  return (
    <Autocomplete
      title={title}
      disabled={isPending}
      open={isOpen}
      renderOption={(props, option, state) => renderItem(props, option, state)}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          fullWidth
          multiline
          disabled={isPending}
          label={title}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
          onPaste={onPaste}
          onKeyDown={async (event: KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Enter') {
              setDelayedInput(true);

              if (overrideOnEnterKey) {
                event.preventDefault();
                clearInterval(timerRef.current);
                const target = event.target as HTMLInputElement;
                const results = await onApiInvoke(target.value, true);
                setIsLoading(false);
                setOptions([...results]);
              }
            }
            onKeyDown && onKeyDown(event);
          }}
        />
      )}
      onChange={(event: React.SyntheticEvent, value: any, reason: string, details: any) => {
        const selectedValue = details.option?.name;

        if (selectedValue) {
          const item = options?.find((op: any) => op.name === selectedValue);
          item && onChange(item);
        }

        setIsLoading(false);
      }}
      defaultValue={value}
      inputValue={inputValue}
      value={value}
      onInputChange={handleInputChange}
      options={options}
      getOptionLabel={(obj: any) => obj?.name || obj || ''}
      filterOptions={(items) => items}
      loading={isLoading}
      size={SizeProps.Small}
      autoHighlight={true}
      onBlur={() => {
        setIsLoading(false);
        setDelayedInput(false);
      }}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    />
  );
};