import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: 'translate(-50%; -50%)';
  max-width: 400;
  width: 80%;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
  padding: 4;
`;