import React from 'react';
import { Stack } from '@mui/material';
import { FormGenerator, SubformTypeNode } from '../../../../../components/templates/FormGenerator';
import { FaultSelectionsFormFragment } from './FaultSelectionsFormFragment';
import { SolutionActionFormFragment } from './SolutionActionFormFragment';
import { PartRequestFormFragment } from './PartRequestFormFragment';
import { GetGeoLocationFormFragment } from './GetGeoLocationFormFragment';
import { ServiceCreationFormFragment } from './ServiceCreationFormFragment';
import { EstimatedTimeOfArrivalFragment } from './EstimatedTimeOfArrivalFragment';
import { ModifyStructureFormFragment } from './ModifyStructureFormFragment';
import { ParameterFormFragment } from './ParameterFormFragment';
import { ParameterNoUIFormFragment } from './ParameterNoUIFormFragment';
import { FormFieldType } from '../../../../../@types';
import { FormInterceptorProps } from './FormInterceptor.props';
import { MerchantDamageTemplateFormFragment } from './MerchantDamageTemplateFormFragment';
import { GetParameterFormFragment } from './GetParameterFormFragment';
import { ServiceSearchFormFragment } from './ServiceSearchFormFragment';

export const FormInterceptor: React.FC<FormInterceptorProps> = ({
  service,
  value,
  onChange,
  partActionData,
  onDamagedPartSelect,
  onAddForm,
  isRepeatable,
  forms
}) => {
  const subformTypeNodes: Array<SubformTypeNode> = [
    {
      service,
      formType: FormFieldType.FaultSelection,
      node: FaultSelectionsFormFragment
    },
    {
      service,
      formType: FormFieldType.SolutionAction,
      node: SolutionActionFormFragment
    },
    {
      service,
      formType: FormFieldType.PartsRequest,
      node: PartRequestFormFragment
    },
    {
      service,
      formType: FormFieldType.ServiceCreation,
      node: ServiceCreationFormFragment
    },
    {
      service,
      formType: FormFieldType.GetGeoLocation,
      node: GetGeoLocationFormFragment
    },
    {
      service,
      formType: FormFieldType.EstimatedTimeOfArrival,
      node: EstimatedTimeOfArrivalFragment
    },
    {
      service,
      formType: FormFieldType.ModifyStructure,
      node: ModifyStructureFormFragment
    },
    {
      service,
      formType: FormFieldType.SetParameter,
      node: ParameterFormFragment
    },
    {
      service,
      formType: FormFieldType.SetParameterNoUI,
      node: ParameterNoUIFormFragment
    },
    {
      service,
      formType: FormFieldType.MerchantDamageTemplate,
      node: MerchantDamageTemplateFormFragment
    },
    {
      service,
      formType: FormFieldType.GetParameter,
      node: GetParameterFormFragment
    },
    {
      service,
      formType: FormFieldType.ServiceSearch,
      node: ServiceSearchFormFragment
    }
  ];
  return (
    <Stack>
      <br/>
      <FormGenerator
        subformTypes={subformTypeNodes}
        value={value}
        onChange={(val) => onChange(val)}
        partActionData={partActionData}
        onDamagedPartSelect={
          (Objectkey: string, objectValue: FormData | number) => 
            onDamagedPartSelect(Objectkey, objectValue)
        }
        service={service}
        onAddForm={(templateId,templateTitle) => onAddForm && onAddForm(templateId,templateTitle)}
        isRepeatable={isRepeatable}
        forms={forms}
      />
    </Stack>
  );
};