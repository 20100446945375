import { AdvancePartSearch, BatchRecieveBulkLiveSearchData, PartTypeBulkLiveSearchDataInsertionType } from '../@types/part-type.type';
import { LinkShipmentSearchData, PartOrPartTypeSearchData, SearchData, StructureMergeSearchData, SubLocationSearch } from '../@types';
import { httpClient, HttpMethod } from './http-client';
import { getPartTypeActionLiveSearchSerialiseType } from '../utils/part-type';
import { SearchNodeParts } from '../@types/part.type';
import { RecieveShipmentSearchNodes } from '../@types/shipment.type';

export const searchNodeOperational = (name: string, hasCode?: boolean) => {
  let url = `/searchnodes?name=${name}&categoryCode=operational`;
  // hasCode property allow AM-LID search when true
  if (hasCode) {
    url += '&hasCode=true';
  }
  return httpClient(url, HttpMethod.Get, null);
};

export const getSubLocationsForCreateServices = (id: number, isNestedCheck?: boolean) => {
  let url = `/subLocationsForCreateServices/${id}`;
  if (isNestedCheck) {
    url += `?isNestedCheck=${isNestedCheck}`;
  }
  return httpClient(url, HttpMethod.Get, null);
};

export const searchNode = (name: string) => {
  return httpClient(`/searchnodes?name=${name}`, HttpMethod.Get, null);
};

export const searchNodeByParentId = (data: any) => {
  let url = `/searchnodes?name=${encodeURIComponent(data?.name)}&categoryCode=operational`;

  if (data?.selectedParentId) {
    url += `&parentNodeId=${parseInt(data?.selectedParentId)}`;
  }

  // hasCode property allow AM-LID search when true
  if (data?.hasCode) {
    url += '&hasCode=true';
  }

  if(data?.includeParentNode) {
    url = `${url}&includeParentNode=${data?.includeParentNode}`;
  }

  if(data?.includeSiblings) {
    url = `${url}&includeSiblings=${data?.includeSiblings}`;
  }

  return httpClient(url, HttpMethod.Get, null);
};

export const searchService = (id: number) => {
  return httpClient(`/searchservices?id=${id}`, HttpMethod.Get, null);
};

export const searchManufacturerNodes = (name: string) => {
  const url = `/searchnodes?name=${name}&categoryCode=operational&nodeTypeCode=supplier-repairer`;
  return httpClient(url, HttpMethod.Get, null);
};

export const searchManufacturerNodesByClientAndContract = (data: any) => {
  const url = `/searchPartTypes?name=${data.name}&nodeIds=${data.applicableNodeIds}&isSerialised=true`;
  return httpClient(url, HttpMethod.Get, null);
};

export const searchNodesParts = (data: any) => {
  const url = `/searchParts?id=${data.id}&name=${data.name}&nodeIdTree=${data.nodeIdTree}&categoryCode=operational&includeSiblings=${data.includeSiblings}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const searchChildPartTypesByClientAndContract = (data: any, isSerialized?: boolean) => {
  const url = `/searchPartTypes?name=${data.name}&nodeIds=${data.applicableNodeIds}&isSerialised=${isSerialized}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const searchPartTypesByClientAndContractForSKU = (data: any, isSerialized?: boolean) => {
  const url = `/searchPartTypes?name=${data.name}&nodeIds=${data.applicableNodeIds}&isSerialised=${isSerialized}&forSKU=true`;
  return httpClient(url, HttpMethod.Get, null);
};

export const searchClientContractPartTypes = (data: any) => {
  const url = `/siteSchema/searchPartTypes?name=${data.name}&nodeIds=${data.applicableNodeIds}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const searchParts = (data: any, searchLimit?: number) => {
  // isRaw property means name not include any hardcodes (s#, AM-LID-##, AM-PID-##, etc..)
  let url = `/searchParts?name=${data.name}&categoryCode=operational&isRaw=true`;
  if (searchLimit) {
    url += `&searchLimit=${searchLimit}`;
  }
  return httpClient(url, HttpMethod.Get, null);
};

export const replenishmentSearchNodes = (data: any) => {
  // hasCode property means whether AM-LID- included or not
  const url = `/searchnodes?name=${data.name}&categoryCode=operational&hasCode=true`;
  return httpClient(url, HttpMethod.Get, null);
};

// search SKUs
export const replenishmentSkuSearch = (data: any) => {
  const url = `/searchSkuConfigs?name=${data.name}&nodeId=${data.nodeId}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const replenishmentPartTypeSearch = (data: any) => {
  const url = `/searchPartTypes?name=${data.name}&nodeId=${data.nodeId}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const searchNodeShipment = (name: string) => {
  return httpClient(`/searchnodes?name=${name}&isPart=true&includeSiblings=true&hasCode=true`, HttpMethod.Get, null);
};

export const advanceSearchPartTypes = (data: PartTypeBulkLiveSearchDataInsertionType) => {
  // support raw search and AM-PTID- search
  const url = `/advanceSearchPartTypes?searchableData=${data.searchableValues}&applicableNodeId=${data.applicableNodeId}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const advanceSearchPartTypesForBulkPartUpdate = (data: PartTypeBulkLiveSearchDataInsertionType) => {
  const url = `/advanceSearchPartTypesForBulkUpdate?searchableData=${data.searchableValues}&isSerialised=true`;
  return httpClient(url, HttpMethod.Get, null);
};

export const advanceBulkSearchPartTypes = (data: PartTypeBulkLiveSearchDataInsertionType) => {
  return httpClient('/advanceBulkSearchPartTypes', HttpMethod.Post, data);
};

export const searchNodeDispatch = (data: SearchData) => {
  let url = `/searchnodes?name=${data.name}&categoryCode=operational&includeSiblings=true&isPart=true`;
  if (data?.hasCode) {
    // hasCode property allow AM-LID search when true
    url += '&hasCode=true';
  }
  return httpClient(url, HttpMethod.Get, null);
};

export const searchPartsBulkUpdate = (data: SearchData) => {
  // enables raw serial search, AM-PID search with visibility rule check
  const url = `/searchParts?name=${data.name}&categoryCode=operational`;
  return httpClient(url, HttpMethod.Get, null);
};

export const searchPartsWithPid = (data: SearchData) => {
  // trigger when part ID search only enabled (work AM-PID- code only)
  const url = `/searchParts?name=${data.name}&categoryCode=operational&isPart=true`;
  return httpClient(url, HttpMethod.Get, null);
};

export const searchPartsOrPartTypes = (data: PartOrPartTypeSearchData) => {
  // name -: [part type name || S# || AM-PTID || AM-PID]
  // serialiseType -: [empty || 'serialised' || 'non-serialised']
  // partTypeId -: [empty || number]
  // clientContractIds -: [clientId, contractId]
  // allowedPartLocationType -: ['node' || 'part']
  // searchType -: ['part-and-part-type' || 'part-type' || 'part']
  const serialiseType = getPartTypeActionLiveSearchSerialiseType(data.serialiseType);
  const url = `/partOrPartType?name=${data.name}&serialiseType=${serialiseType}&partTypeId=${data.partTypeId}&clientContractIds=${encodeURIComponent(JSON.stringify(data.clientContractIds))}&locationId=${data.locationId}&allowedPartLocationType=${data.allowedPartLocationType}&partActionType=${data.partActionType}&searchType=${data.searchType}`;

  return httpClient(url, HttpMethod.Get, null);
};

export const searchReturnPageSource = (name: string) => {
  const url = `/returnShipmentSourceSearch?name=${name}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const searchReturnPageDestination = (name: string) => {
  const url = `/returnShipmentDestinationSearch?name=${name}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const searchNodeInStructureMerge = (data: StructureMergeSearchData) => {
  return httpClient(`/structureMergeSearchNodes?name=${data.name}&nodeTypeCode=${data.nodeTypeCode}&currentNodeId=${data.mergeNodeId}`, HttpMethod.Get, null);
};

export const searchDispatchParts = (data: any) => {
  // isRaw property means name include hardcodes (s#, AM-LID-##, AM-PID-##, etc..)
  let url = `/searchParts?name=${data.name}&categoryCode=operational&isRaw=false`;
  if (data.applicableNodeIds) {
    url += `&applicableNodeIds=${data.applicableNodeIds}`;
  }
  return httpClient(url, HttpMethod.Get, null);
};

export const advanceBulkSearchSerials = (data: PartTypeBulkLiveSearchDataInsertionType) => {
  return httpClient('/advancedBulkSearchSerials', HttpMethod.Post, data);
};

export const getSearchShipment = (data: string) => {
  return httpClient(`/searchShipment?searchQuery=${data}`, HttpMethod.Get, null);
};

export const searchNodesPartsWithoutPartId = (name: string) => {
  const url = `/searchParts?name=${name}&includeSiblingNodes=true&categoryCode=operational`;
  return httpClient(url, HttpMethod.Get, null);
};
export const searchLinkShipments = (data: LinkShipmentSearchData) => {
  const url = `/linkShipmentSearch?id=${data.id}&clientHierarchy=${data.clientHierarchy}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const advancePartSearch = (data: AdvancePartSearch) => {
  // support AM-PTID-, part type name, part type number search to get parts
  const url = `/advancePartSearch?searchableData=${data.searchableValues}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const advancePartSearchByName = (name: string) => {
  const url = `/advanceSearchPartTypes?searchableData=${name}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const searchAllNodesByName = (name: string) => {
  return httpClient(`/searchByNodeName?name=${name}`, HttpMethod.Get, null);
};

// [categoryCode=operational || categoryCode=system and nodeTypeCode=sibling] data will recive within this route
export const searchAllocationNodes = (name: string) => {
  return httpClient(`/searchAllocations?name=${name}`, HttpMethod.Get, null);
};

export const searchNodeParts = (data: SearchNodeParts) => {
  const url = `/searchNodeParts?name=${data.name.trim()}&isInClientHierarchy=${data.isInClientHierarchy}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const getSubLocationsForUpdateServices = (subLocationSearchData: SubLocationSearch) => {
  return httpClient(`/searchSubLocationsForUpdateServices/${subLocationSearchData.id}/name/${subLocationSearchData.name}`, HttpMethod.Get, null);
};

export const searchNodeByNameOrCode = (name: string) => {
  return httpClient(`/searchnodes?name=${name}&hasCode=true`, HttpMethod.Get, null);
};

// Returns nodes which 'Can have parts' and parts. Supports AM-LID, AM-PID, serial1 and node name searches.
export const searchTlcNodesParts = (data: any) => { // Data type changes
  const url = `/searchTlcNodesParts?id=${data.id}&name=${data.name}&currentLocation=${data.currentLocation}&currentLocationType=${data.currentLocationType}&categoryCode=operational`;
  return httpClient(url, HttpMethod.Get, null);
};

// id -: either id can be shipmentId or nodeId
// recieveType -: [individual, bulk]
export const reciveShipmentsSearchNodes = (data: RecieveShipmentSearchNodes) => {
  return httpClient(`/shipment/recieve/searchNodes/${data.id}/recieveType/${data.recieveType}?name=${data.name}&categoryCode=operational,system`, HttpMethod.Get, null);
};

export const searchNodeReceive = (data: SearchData) => {
  let url = `/searchnodes?name=${data.name}&categoryCode=operational&includeSiblings=true`;
  if (data?.hasCode) {
    // hasCode property allow AM-LID search when true
    url += '&hasCode=true';
  }
  return httpClient(url, HttpMethod.Get, null);
};

export const batchRecieveBulkSearchSerials = (batchRecieveBulkLiveSearchData: BatchRecieveBulkLiveSearchData) => {
  return httpClient(`/shipment/manifest/serialized/nodes/${batchRecieveBulkLiveSearchData.nodeId}/bulk/serials?serials=${batchRecieveBulkLiveSearchData.serials}`, HttpMethod.Get, batchRecieveBulkLiveSearchData);
};