import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { LiveSearchBox, LiveSearchListItem } from '../../../../../../components/atoms/LiveSearchBox';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../../../../../components/atoms/Typography';
import { ImagePopup, ImagePopupSlide } from '../../../../../../components/atoms/ImagePopup';
import { DropdownItem, SelectMenu } from '../../../../../../components/atoms/SelectMenu';
import { LocationSearchItem } from '../../../../../../components/molecules/LocationSearchItem';
import { PartRequestFormFragmentProps } from './PartRequestFormFragment.props';
import { 
  useGetInteractionsPartRequestFormPartQuantities, 
  useGetInteractionsPartRequestFormPartTypesChildrenWithParent, 
  useGetInteractionsPartRequestFormPartTypes 
} from '../../../../../../queries/interactions-query';
import { useGetUploadedImages } from '../../../../../../queries/uploadedfiles-query';
import { Folders } from '../../../../../../@types/uploaded-files.type';
import { useGetPartTypesByClientContract } from '../../../../../../queries/part-type-query';
import { PartTypeLiveSearchItem } from '../../../../../../@types';
import { PartConditionOptions } from '../../../../../../@types/part.type';
import { InteractionsPartRequestFormInitialPartType } from '../../../../../../@types/interactions.type';
import { isSerializedPartType, getUniquePartTypesFromLiveSearch } from '../../../../../../utils/form-generator';

export const PartRequestFormFragment: React.FC<PartRequestFormFragmentProps> = ({
  service,
  value,
  onChange,
  isError
}) => {
  const [installedPartTypeOptions, setInstalledPartTypeOptions] = useState<Array<InteractionsPartRequestFormInitialPartType>>([]);
  const [clientContractPartTypes, setClientContractPartTypes] = useState<Array<PartTypeLiveSearchItem>>([]);
  const [partImages, setPartImages] = useState<Array<ImagePopupSlide>>([]);
  const [partTypeOptions, setPartTypeOptions] = useState<Array<DropdownItem>>([]);
  
  const getPartTypesByClientContract = useGetPartTypesByClientContract(service.clientId, service.contractId);
  const getInteractionsPartRequestFormPartTypes = useGetInteractionsPartRequestFormPartTypes(service.id);
  const getInteractionsPartRequestFormPartTypesChildrenWithParentQuery = useGetInteractionsPartRequestFormPartTypesChildrenWithParent(value?.partId || -1);
  const getInteractionsPartRequestFormPartQuantitiesQuery = useGetInteractionsPartRequestFormPartQuantities(value?.partId || -1, service.id, value?.serialised || false);
  const getUploadedImagesQuery = useGetUploadedImages(value?.partId || -1, Folders.PartTypesImages);

  useEffect(() => {
    if (getInteractionsPartRequestFormPartTypes.data) {
      const optionList = getInteractionsPartRequestFormPartTypes.data.filter((partType) => partType.conditionCode === PartConditionOptions.INSTALLED);
      setInstalledPartTypeOptions(optionList);
    }
  }, [getInteractionsPartRequestFormPartTypes.data]);

  useEffect(() => {
    service.clientId && service.contractId && getPartTypesByClientContract.refetch();
  }, [service.clientId, service.contractId]);

  useEffect(() => {
    if (getPartTypesByClientContract.data) {
      const partTypes = getPartTypesByClientContract.data.map((partType) => {
        return {
          ...partType,
          serialised: isSerializedPartType(partType)
        };
      });
      setClientContractPartTypes(partTypes);
    }
  }, [getPartTypesByClientContract.data]);

  useEffect(() => {
    if (value?.partId) {
      getInteractionsPartRequestFormPartTypesChildrenWithParentQuery.refetch();
      getInteractionsPartRequestFormPartQuantitiesQuery.refetch();
      getUploadedImagesQuery.refetch();
    }
  }, [value?.partId]);

  useEffect(() => {
    const partList = getInteractionsPartRequestFormPartTypesChildrenWithParentQuery.data;
    if (partList?.children) {
      const totalList = [partList.parent, ...partList.children];
      if (totalList) {
        const optionList: Array<DropdownItem> = totalList.map((obj) => ({
          label: obj?.name,
          value: obj?.name
        }));
        setPartTypeOptions(optionList);
      }
    }
  }, [getInteractionsPartRequestFormPartTypesChildrenWithParentQuery.data]);

  useEffect(() => {
    const qtyData = getInteractionsPartRequestFormPartQuantitiesQuery.data;
    onChange({
      ...value,
      qty: qtyData?.partsCount || 0
    });
  }, [getInteractionsPartRequestFormPartQuantitiesQuery.data]);

  useEffect(() => {
    if (getUploadedImagesQuery.data) {
      const images: Array<ImagePopupSlide> = getUploadedImagesQuery.data.map((obj) => ({
        url: obj.url
      }));
      setPartImages(images);
    }
  }, [getUploadedImagesQuery.data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} direction="row" justifyContent="space-between">
        <Typography
          variant={TypographyVariantProps.Body2}
          fontWeight={TypographyFontWeightProps.Bold}
        >
        Parts Request
        </Typography>
        {value?.partId && 
          <ImagePopup
            title="Part Images"
            images={partImages}
          />
        }
      </Grid>
      {value?.partId ?
        <Grid item xs={12}>
          <SelectMenu
            id="part-request-form-fragment-part-types-children-parent"
            label="Part Types Children / Parent"
            items={partTypeOptions}
            selectedValue={value?.partName}
            onChange={(val) => onChange({ ...value, partId: val })}
          />
          <Typography
            variant={TypographyVariantProps.Body2}
          >
            Currently available stock qty: {value?.qty || 0}
          </Typography>
        </Grid>
        :
        <Grid item xs={12}>
          <LiveSearchBox
            title="Part Type"
            timeOffset={400}
            value={value?.partName || ''}
            onClearValue={() => ''}
            renderItem={(props: any, option: any) => { 
              return (
                <LiveSearchListItem key={props.id} {...props}>
                  <LocationSearchItem data={option} />
                </LiveSearchListItem>
              );
            }}
            onChange={(obj: any) => {
              onChange({
                ...value,
                partId: obj.id,
                partName: obj.name,
                serialised: obj.serialised == true || obj.serialised == 'Serialised'
              });
            }}
            onApiInvoke={async (name: string) => {
              return await getUniquePartTypesFromLiveSearch(name, clientContractPartTypes, installedPartTypeOptions);
            }}
            defaultSuggetions={installedPartTypeOptions}
            isError={isError && (!value?.partId && !value?.partName)}
          />
        </Grid>
      }
    </Grid>
  );
};