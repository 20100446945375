import { ServiceTypeResponse, StructureLayoutData, StructureTypeResponse, SiteSchemaDropdown, StructureAttribute, StructureMergeLocationNodeData, Warning } from '../@types';
import { TradingHoursData } from '../components/molecules/TradingHours';
import { CapacityData } from '../components/organisms/Capacity';
import { AddressDataType } from '../components/organisms/StructureAddress';
import { ContactDataType, EmptyMainAddress, EmptyMainContact } from '../components/templates/StructureAddressContactLayout';
import { PropertyType as PropertyTypeEnum } from '../configs/enums';
import { ANY_KEYWORD, EMPTY_FIELD_MESSAGE } from '../constants/common';
import { DropdownItem } from '../components/atoms/MultiSelectCheckmarksMenu';
import { isDropDownEmpty, isEmptyString } from './common';

export const getServiceTypesDropdownData = (serviceTypes: Array<ServiceTypeResponse>) => {
  return serviceTypes.map((serviceType) => {
    return {
      value: serviceType?.code,
      label: serviceType?.name,
    };
  });
};

export const getSortedStructureTypes = (structureTypesData: Array<StructureTypeResponse>, preferedNodeTypes: Array<string>) => {
  let data = structureTypesData.filter(item => item.isActive);

  //We need to set prefered types on the top of the list
  if (preferedNodeTypes) {
    const prederedTypes = data.filter(item => preferedNodeTypes.includes(item.code));

    if (prederedTypes) {
      const notPreferedTypes = data.filter(item => !preferedNodeTypes.includes(item.code));

      data = [...prederedTypes, ...notPreferedTypes];
    }
  }

  return data || [];
};

export const getNodeAttributes = (
  structureAddressDataset: Array<AddressDataType>,
  propertyTypes: Array<string>,
  structureContactDataset: Array<ContactDataType>,
  tradingHoursData: Array<TradingHoursData>,
  capacityData: Array<CapacityData>,
  structureLayoutData: StructureLayoutData,
  checkedGlobalReturns: boolean,
  isSiteSchemaEnabled: boolean
) => {
  const attributes: any[] = [];
  if (structureAddressDataset && structureAddressDataset.length) {
    structureAddressDataset.forEach(address => {
      attributes.push({
        ...address,
        addressName: address.name,
        name: 'Address',
        propertyTypeCode: PropertyTypeEnum.Address
      });
    });
  }
  //If address is required by the node type, insert an empty Main record
  else if (propertyTypes.includes(PropertyTypeEnum.Address)) {
    attributes.push({
      ...EmptyMainAddress,
      addressName: EmptyMainAddress.name,
      name: 'Main Address',
      propertyTypeCode: PropertyTypeEnum.Address
    });
  }

  if (structureContactDataset && structureContactDataset.length) {
    structureContactDataset.forEach(contact => {
      attributes.push({
        ...contact,
        name: 'Contact',
        propertyTypeCode: PropertyTypeEnum.Contact
      });
    });
  }
  //If contacts is required by the node type, insert an empty Main record
  else if (propertyTypes.includes(PropertyTypeEnum.Contact)) {
    attributes.push({
      ...EmptyMainContact,
      name: 'Main Contact',
      propertyTypeCode: PropertyTypeEnum.Contact
    });
  }

  if (tradingHoursData && tradingHoursData.length) {
    tradingHoursData.forEach(tradingHours => {
      attributes.push({
        ...tradingHours,
        name: 'Trading Hours',
        propertyTypeCode: PropertyTypeEnum.TradingHours
      });
    });
  }

  if (capacityData && capacityData.length) {
    capacityData.forEach(capacityData => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { itemsData, clientsData, contractsData, ...filteredCapacityData } = capacityData;
      attributes.push({
        ...filteredCapacityData,
        name: 'Capacity',
        propertyTypeCode: PropertyTypeEnum.Allocations
      });
    });
  }

  if (structureLayoutData) {
    attributes.push({
      id: structureLayoutData.refAttribId,
      referenceNo1: structureLayoutData.referenceNo1,
      referenceNo2: structureLayoutData.referenceNo2,
      referenceNo3: structureLayoutData.referenceNo3,
      name: 'Reference Numbers',
      propertyTypeCode: PropertyTypeEnum.ReferenceNumbers
    });
  }

  if (isSiteSchemaEnabled) {
    attributes.push({
      id: structureLayoutData.siteSchemaAttribId,
      siteSchemaId: parseInt(structureLayoutData.siteSchemaId),
      name: 'Site Schema',
      propertyTypeCode: PropertyTypeEnum.SiteSchema
    });
  }

  if (checkedGlobalReturns) {
    attributes.push({
      globalReturns: checkedGlobalReturns,
      name: 'Global Returns',
      propertyTypeCode: PropertyTypeEnum.GlobalReturns
    });
  }

  return attributes;
};

export const genarateURL = (
  id?: string,
  structureLayoutName?: string,
  selectedNodeTypeCode?: string,
  selectedStructureType?: string,
  structureTypes?: Array<StructureTypeResponse>,
  clientId?: string,
  contractId?: string
) => {
  let url = `/structure/create?parentId=${id}&parentName=${structureLayoutName}${selectedNodeTypeCode ? `&nodeTypeCode=${selectedNodeTypeCode}` : ''}`;

  if (clientId) {
    url += `&clientId=${clientId}`;
  }
  if (contractId) {
    url += `&contractId=${contractId}`;
  }

  if (!selectedNodeTypeCode) {
    const parentNodeType = structureTypes?.find(item => item.code === selectedStructureType);

    if (parentNodeType && parentNodeType.childNodeTypes && parentNodeType.childNodeTypes.length > 0) {
      parentNodeType.childNodeTypes.map(item => {
        url += `&preferedNodeType=${item.code}`;
      });
    }
  }

  return url;
};

export const getSiteSchemas = (siteSchemas: Array<SiteSchemaDropdown>) => {
  const siteSchemaList = siteSchemas.map((siteSchema) => {
    return {
      value: siteSchema.id.toString(),
      label: siteSchema.name,
      secondaryLabel: siteSchema.description
    };
  });

  return siteSchemaList;
};

export const generateImportURL= (importSessionID: number) => {
  return `/structure/search?importSessionId=${importSessionID}`;
};

export const getDropdownOptionsWithHandlingAnyKey = (selectedValue: Array<string>, options: Array<DropdownItem>) => {
  return selectedValue?.includes(ANY_KEYWORD) ? options?.filter((item) => item?.value === ANY_KEYWORD) : options;
};

export const configureSelectedDropdownOptions = (selectedValues: string) => {
  return selectedValues.split(',').includes(ANY_KEYWORD) ? [ANY_KEYWORD] : selectedValues.split(',').filter(selectedValue => selectedValue);
};

export const getFleetTagOptions = (fleetTagData: Array<StructureAttribute>) => {
  const fleetTags = fleetTagData.reduce((accumulator: DropdownItem[], currentValue) => {
    if (currentValue.value?.isEnabled) {
      const option: DropdownItem = { label: currentValue.value?.fleetTagName, value: currentValue.id.toString() };
      return [...accumulator, option];
    }
    return accumulator;
  }, []);
  return fleetTags;
};

export const hasErrorInMerge = (location: StructureMergeLocationNodeData, addressesDecision: string, contactsDecision: string, tradingHoursDecision: string) => {
  return (location?.canHaveAddress && isDropDownEmpty(addressesDecision)) || 
  (location?.canHaveContact && isDropDownEmpty(contactsDecision)) || 
  (location?.canHaveTradingHours && isDropDownEmpty(tradingHoursDecision));
};

export const attributesValidation = (value: string, text: string) => {
  return isEmptyString(value) ? text + EMPTY_FIELD_MESSAGE : '';
};

export const getWarningsOnlyForStructures = (warningData: Warning[]) => {
  if (warningData) {
    //For structure locations we should show warnings related to address and contacts since we allow only those attribute to be added
    return warningData.filter(warning => warning.field === PropertyTypeEnum.Address || warning.field === PropertyTypeEnum.Contact || warning.field === PropertyTypeEnum.Allocations);
  }

  return [];
};

export const getWarningsForAddressContacts = (addressForm: any[], contactForm: any[]) => {
  if (addressForm.length > 0) {
    return [{
      field: 'Address',
      message: 'There are unsaved address/addresses exist. Please save them before proceeding',
    }];
  }

  if (contactForm.length > 0) {
    return [{
      field: 'Contact',
      message: 'There are unsaved contact/contacts exist. Please save them before proceeding',
    }];
  }

  return [];
};