import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Edit, Delete, TaskAlt, Unpublished, PictureAsPdfRounded } from '@mui/icons-material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@mui/material';
import { StyledTableWrapper, StyledTypography, ActionColumnWrapper, StyledActionMenu, EditIconWrapper, DeleteIconWrapper, Image, SmallTrackingHistoryLabel, ImageWrapper, ActionColumnMobileWrapper, MarkupWrapper } from './TrackingHistory.styles';
import { GridColDef, GridDensityTypes } from '@mui/x-data-grid-pro';
import { Box } from '@mui/system';
import { TrackingHistoryProps } from './TrackingHistory.props';
import { Accordian } from '../../atoms/Accordian';
import { calculateDateTimeDuration, formatServiceLogDate } from '../../../utils/common';
import { Button, ButtonColorProps, ButtonSizeProps, ButtonVariantProps } from '../../atoms/Button';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { Modal } from '../../atoms/Modal';
import { FileAttachmentURLType, FileMimeTypes } from '../../../@types/uploaded-files.type';
import { LogTypeNames } from '../../../configs/enums';
import { useGetUploadedFilePresignedUrlByKey } from '../../../queries/uploadedfiles-query';
import { getFileAttachmentURLData } from '../../../utils/services';
import { downloadFile } from '../../../utils/file-utils';
import { SERVICE_ATTACHMENT_FILE_URL_DATA } from '../../../constants/service.constant';
import { TaskType } from '../../../@types/task.type';
import { ActionPermissions, CustomPermission, LogType, ScreenSize } from '../../../@types';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { Markup } from 'interweave';
import { MOBILE_DATA_GRID_COLUMN } from '../../../configs/ui-constants';
import { CustomDataGrid } from '../../layouts/CustomDataGrid';
import { MobileDataGridItem } from '../../organisms/MobileDataGridItem';

export const TrackingHistory: React.FC<TrackingHistoryProps> = ({
  historyData,
  onClickEdit,
  onClickDelete,
  onClickApprove,
  onClickDisapprove,
  onClickDownload,
  onClickInteractionEdit,
  isClosedService
}) => {
  const [showButton, setShowButton] = useState<boolean>(false);
  const [hoveredRowId, setHoveredRowId] = useState(0);
  const [idArray, setIdArray] = useState<number[]>([]);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [imageURL, setImageURL] = useState<string>('');
  const [fileAttachmentURL, setFileAttachmentURL] = useState<FileAttachmentURLType>(SERVICE_ATTACHMENT_FILE_URL_DATA);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);

  const isMobileView = useMediaQuery(ScreenSize.MOBILE);
  
  const getUploadedFilePresignedUrlByKey = useGetUploadedFilePresignedUrlByKey(fileAttachmentURL.key,fileAttachmentURL.mimeType, fileAttachmentURL.contentDispositionType );
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const columns: GridColDef[] = [
    {
      field: 'time',
      headerName: 'Time',
      renderCell: (params) => (
        <Grid>
          <Grid><Typography variant={TypographyVariantProps.Body2}>{formatServiceLogDate(params.row.time)}</Typography></Grid>
          <Grid><StyledTypography variant={TypographyVariantProps.Body2}>{calculateDateTimeDuration(params.row.time)}</StyledTypography></Grid>
        </Grid>
      )
    },
    {
      field: 'user',
      headerName: 'User',
    },
    {
      field: 'status',
      headerName: 'Status',
    },
    {
      field: 'notes',
      headerName: 'Notes',
      type: 'string',
      flex: 1,
      renderCell: (params) => {
        return (
          <ActionColumnWrapper>
            <Box 
              onClick={(event) =>{
                if (event.target instanceof HTMLElement) {
                  const htmlString = (params.row.logTypeName === LogTypeNames.Uploads 
                    || params.row.logTypeCode === LogType.InteractionEvent) ? params.row.notes : event.target.outerHTML;
                  handleOpen(htmlString , params.row.logTypeName);
                }
              }}
            >
              <Markup content={params.row.logTypeCode === LogType.InteractionEvent ? isUserHasPermission(ActionPermissions.Service_Edit_Service_Log_View_Interactions, permissions) ? params.row.notes : null : params.row.notes} />
            </Box>
            <StyledActionMenu visible={params.row.id === hoveredRowId ? 'visible' : 'hidden'}>
              <EditIconWrapper>
                {params.row.logTypeCode === `task-${TaskType.QUOTE_VALUE}` && (
                  <Box >
                    <Button variant={ButtonVariantProps.Icon} aria-label="approve" onClick={() => onClickApprove && onClickApprove(params.row.id)}>
                      <TaskAlt />
                    </Button>
                    <Button variant={ButtonVariantProps.Icon} aria-label="disapprove" onClick={() => onClickDisapprove && onClickDisapprove(params.row.id)}>
                      <Unpublished />
                    </Button>
                  </Box>
                )}
                {params.row.logTypeCode === LogType.NOTES && isUserHasPermission(ActionPermissions.Service_Edit_Service_Log_Edit_Notes, permissions) && (
                  <Box >
                    <Button variant={ButtonVariantProps.Icon} aria-label="edit" onClick={() => onClickEdit && onClickEdit(params.row.id)}>
                      <Edit />
                    </Button>
                  </Box>
                )}
                <DeleteIconWrapper>
                  {!idArray.some((id) => id === params.row.id) && isUserHasPermission(ActionPermissions.Service_Edit_Service_Log_Hide_Records, permissions) && 
                    <Button variant={ButtonVariantProps.Icon} aria-label="delete" onClick={() => handleDelete(params.row.id)}>
                      <Delete />
                    </Button>
                  }
                  {idArray.some((id) => id === params.row.id) && showButton && 
                    <Button variant={ButtonVariantProps.Primary} size={ ButtonSizeProps.Small } color={ButtonColorProps.Error} onClick={() => onClickDelete && onClickDelete(params.row.id)}>
                      Confirm Delete
                    </Button>
                  }
                </DeleteIconWrapper>
                {params.row.logTypeCode === LogType.InteractionEvent && params.row.isDownloadableInteraction && (
                  <Box >
                    <Button variant={ButtonVariantProps.Icon} aria-label="download" onClick={() => onClickDownload && onClickDownload(params.row.id)}>
                      <PictureAsPdfRounded />
                    </Button>
                  </Box>
                )}
                {isUserHasPermission(ActionPermissions.Service_Interaction_Edit, permissions) 
                  && !isClosedService
                  &&params.row.logTypeCode === LogType.InteractionEvent 
                  && params.row.isEditableInteraction && (
                  <Box >
                    <Button variant={ButtonVariantProps.Icon} aria-label="edit" onClick={() => onClickInteractionEdit && onClickInteractionEdit(params.row.id)}>
                      <Edit />
                    </Button>
                  </Box>
                )}
              </EditIconWrapper>
            </StyledActionMenu>
          </ActionColumnWrapper >
        );
      }
    },
    {
      field: MOBILE_DATA_GRID_COLUMN,
      headerName: '',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <MobileDataGridItem
            enableSingleColumn={true}
            data={[
              {
                title: 'Time',
                value: 
                  <SmallTrackingHistoryLabel sx={{ color: '#c0bfc0' }}>{formatServiceLogDate(row.time)}</SmallTrackingHistoryLabel>
              },
              {
                title: 'User',
                value: <SmallTrackingHistoryLabel>{row.user}</SmallTrackingHistoryLabel>
              },
              {
                title: 'Status',
                value: <SmallTrackingHistoryLabel fontWeight={500} sx={{ fontSize: 'small' }}>{row.status}</SmallTrackingHistoryLabel>
              },
              {
                title: 'Notes',
                value:
                  <ActionColumnMobileWrapper logType={row.logTypeCode}>
                    <SmallTrackingHistoryLabel>
                      <Box
                        onClick={(event) =>{
                          if (event.target instanceof HTMLElement) {
                            const htmlString = (row.logTypeName === LogTypeNames.Uploads 
                              || row.logTypeCode === LogType.InteractionEvent) ? row.notes : event.target.outerHTML;
                            handleOpen(htmlString , row.logTypeName);
                          }
                        }}
                      >
                        {row.logTypeCode === LogType.NOTES ?
                          <MarkupWrapper>
                            <Markup content={ row.notes } />
                          </MarkupWrapper>
                          :
                          <Markup content={ row.notes } />
                        }
                      </Box>
                    </SmallTrackingHistoryLabel>
                  </ActionColumnMobileWrapper >
              },
            ]}
          />
        );
      },
      sortable: false,
      filterable: false
    }
  ];

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  const handleDelete = (id: number) => {
    setShowButton(true);
    idArray.push(id);
    setIdArray(currentIdArray => [...currentIdArray, id] );
  };

  const handleOpen= async(link: string, logTypeName: string) => {
    if (logTypeName === LogTypeNames.Uploads || logTypeName === LogTypeNames.InteractionEvent) {
      const URL_data = getFileAttachmentURLData(link, logTypeName);
      if (URL_data) {
        const fileAttachmentData = {
          key: URL_data.key,
          mimeType: URL_data.mimeType,
          contentDispositionType: URL_data.contentDispositionType,
          fileName: URL_data.fileName
        };
        setFileAttachmentURL(fileAttachmentData);
      }
    }
  };

  useEffect(() => {
    fileAttachmentURL?.key && getUploadedFilePresignedUrlByKey.refetch();
  }, [fileAttachmentURL?.key]);

  useEffect(() => {
    if (getUploadedFilePresignedUrlByKey.data?.url) {
      if (
        fileAttachmentURL.mimeType === FileMimeTypes.PNG ||
        fileAttachmentURL.mimeType === FileMimeTypes.JPEG ||
        fileAttachmentURL.mimeType === FileMimeTypes.JPG
      ) {
        setImageURL(getUploadedFilePresignedUrlByKey.data.url);
      } else {
        fileAttachmentURL.fileName && downloadFile(getUploadedFilePresignedUrlByKey.data?.url, fileAttachmentURL.fileName);
      }
      setFileAttachmentURL(SERVICE_ATTACHMENT_FILE_URL_DATA);
      getUploadedFilePresignedUrlByKey.remove();
    }

  }, [getUploadedFilePresignedUrlByKey.data]);

  useEffect(() => {
    imageURL && setShowImage(true);
  }, [imageURL]);

  const handleRowOver = (e: any) => {
    setHoveredRowId(Number(e.currentTarget.dataset.id));
  };

  const handleRowLeave = () => {
    setHoveredRowId(0);
  };

  const maxOfCol = (colIndex: number) => {
    const invisibleContainer = document.createElement('div');
    invisibleContainer.setAttribute('style', 'visibility: hidden; z-index: -9999999999; position: absolute; font-size: 14px; top: 0; left: 0;');
    document.body.append(invisibleContainer);

    const widths: any[] = [];

    document.querySelectorAll(`[aria-colindex="${colIndex + 1}"]`).forEach(cell => {

      const invisibleCell = document.createElement('div');
      invisibleCell.innerHTML = cell.innerHTML;
      invisibleCell.setAttribute('style', 'width: 100%; max-width: 100%; min-width: 100%;');
      invisibleContainer.append(invisibleCell);
      widths.push(Math.ceil(invisibleCell.clientWidth + 1));
    });
    
    let max = Math.max(...widths);

    if (max !== 0 && max < 30) { max = 30; }

    invisibleContainer.remove();

    return max;
  };

  const resizeColumns = () => {
    const cols = [...columns]; // columns is my columns state.

    cols.forEach((col, index) => {
      const maxColWidth = maxOfCol(index) + 20;
      col.width = maxColWidth;
    });

    return cols;
  };

  const handleImageClose = () => {
    setShowImage(false);
    setImageURL('');
  };

  // ToDo: This is a temporary fix. WIll remove this after Mui-Datagrid-Pro library update
  useEffect(() => {
    if (historyData.length > 0) {
      setTimeout(() => {
        setIsPageLoaded(true);
      }, 1000);
    }
  }, [historyData]);
  
  return (
    <>
      <Accordian title="History" defaultExpanded={true}>
        <StyledTableWrapper isMobile={isMobileView}>
          <CustomDataGrid
            density={GridDensityTypes.Standard}
            columns={isPageLoaded ? resizeColumns() : columns}
            rows={historyData}
            getRowId={(row) => row?.id}
            getRowHeight={() => 'auto'}
            disableColumnFilter={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            isMobile={isMobileView}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: false
              },
              row: {
                onMouseEnter: handleRowOver,
                onMouseLeave: handleRowLeave,
              }
            }}
            getRowClassName={(params) => `super-app-theme--${params.row.logTypeCode}`}
            initialState={{
              sorting: {
                sortModel: [{ field: 'time', sort: 'asc' }],
              },
            }}
            exportFileName={''}
            enableCSVExport={false}
            autoHeight={true}
          />
        </StyledTableWrapper>
      </Accordian>
      {imageURL &&
        <Modal
          open={showImage}
          onClose={handleImageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ImageWrapper container onMouseDown={handleImageClose}>
            <Image src={imageURL}/>
          </ImageWrapper>
        </Modal>
      }
    </>
  );
};