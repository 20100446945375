export enum TaskType {
  LABOUR_TIME = 'labour-time',
  TRAVEL_TIME = 'travel-time',
  TRAVEL_DISTANCE = 'travel-distance',
  STAGING_TIME = 'staging-time',
  RESOURCE_UTILISED = 'resource-utilised',
  LOADING_TIME = 'loading-time',
  QUOTE_VALUE = 'quote-value'
}

export interface TaskData {
  valueCollection?: number;
  issuedTo?: string;
  contactEmail?: string;
  isApproved?: boolean;
  notes?: string;
  quantity?: number;
  duration?: string;
  adjustValue?: number;
}

export interface TaskCard { 
  id: number;
  taskTypeCode: TaskType;
  serviceId: number;
  taskData?: TaskData;
  notes: string;
  isNew?: boolean;
}