import { styled } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { COLORS } from '../../../configs/colors';

export const GridFlexWrapper = styled('div')`
  display: flex; 
  height: 100%;
`;

export const GridFlexGrowWrapper = styled('div')`
  flex-grow: 1;
`;

export const StyledDataGrid = styled(DataGridPro)`
  .error-styling {
    color: ${COLORS.Red};
  }
  .normal-styling {
    color: ${COLORS.Black};
  }
  .duplicate-part-styling {
    background-color: ${COLORS.LightRed};
    &:hover {
      background-color: ${COLORS.LightRed};
    };
  }
  .MuiDataGrid-cell {
    align-items: flex-start !important;
  }
  .bold-header-styling {
    font-weight: bold;
    color: ${COLORS.Black};
    background-color: ${COLORS.SubtleGrey};
    font-size: 17px;
  }
  .normal-activity-monitor-styling {
    font-size: 16px;
    color: ${COLORS.Black};
  }
  .new-status-styling {
    font-size: 16px;
    background-color: ${COLORS.SubtleRed};
  }
  .parts-required-status-styling {
    font-size: 16px;
    background-color: ${COLORS.SubtleRed};
  }
  .escalated-status-styling {
    font-size: 16px;
    background-color: ${COLORS.SubtleRed};
  }
`;