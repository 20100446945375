import { ActionPermissions, CustomPermission, FormContainerItem, FormFieldItem, FormFieldType, FormObject, ParameterDataFormParameterType, ServiceObject } from '../@types';
import { PendingInteractionFormType } from '../@types/pendingInteraction.type';
import { InteractionRuleTriggerPointType } from '../configs/enums';
import { isUserHasPermission } from '../configs/permissions';
import { ServiceSearchFormData } from '../pages/Services/UpdateService/ServiceActionBarFragment/FormInterceptor/ServiceSearchFormFragment';
import { checkForSelectedRules } from './services';

export const getTriggerType = (triggerTypeCode: string) => {
  switch (triggerTypeCode) {
    case InteractionRuleTriggerPointType.StatusChange: 
      return 'Status Change';
    case InteractionRuleTriggerPointType.SystemEvent: 
      return 'System Event';
    default:
      return '';
  }
};

export const hasFormFieldValueError = (field: FormFieldItem, service: ServiceObject) => {
  switch (field.type) {
    case FormFieldType.PartsRequest: 
      return !field.value?.partId && !field.value?.partName;
    case FormFieldType.ModifyStructure: 
      return service.subLocationNodeId && service.subLocationNodeId > 0 ? false : (!service.subLocationNodeId && !field.value) || (!service.subLocationNodeId && !field.value?.locationName);
    case FormFieldType.SetParameter:
      return field.preSelectedData?.parameterType === ParameterDataFormParameterType.SetTag ? !field.value?.selectedTags?.length && !field.value?.newTags?.length : !field.value;
    case FormFieldType.ServiceCreation:
      return field.value.priorityCode < 1 || field.value.mainLocationNodeId < 1 || checkForSelectedRules(field.value);
    case FormFieldType.GetParameter:
      return !field.value?.parameterValue;
    case FormFieldType.ServiceSearch:
      return field.value.length && !field.value.some((service: ServiceSearchFormData) => service.isSelected);
    case FormFieldType.Checkbox:
    case FormFieldType.GetGeoLocation:
    case FormFieldType.ActionTrigger:
    case FormFieldType.SetParameterNoUI:
      return false;
    default:
      return !field.value;
  }
};

export const getFormIsRepeatableStatus = (selectedform: FormObject, formsList: FormObject[]) => {
  const lastForm = formsList.find((form) => form.id === Math.max(...formsList.filter((form) => form.templateId === selectedform.templateId && form.title === selectedform.title).map((form) => form.id)));
  if (selectedform.isRepeatable && lastForm?.id === selectedform.id) {
    const filteredForms = formsList.filter((form) => form.templateId === selectedform.templateId && form.title === selectedform.title);
    if (selectedform.templateDisplayLimit) {
      if (selectedform.templateDisplayLimit > filteredForms.length + (selectedform.displayCount || 0)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const checkFieldIsError = (permissions: CustomPermission[], form: FormObject, container: FormContainerItem, field: FormFieldItem, service: ServiceObject) => {
  if (container.isDecisionPrompt && container.questionData?.hasValue && !container.questionData?.isYes) {
    return false;
  } else if (container.isDecisionPrompt && !container.questionData?.hasValue) {
    return !isUserHasPermission(ActionPermissions.Configure_Interactions_Bypass_Mandatory, permissions) && (field.isRequired || form.isMandatory);
  } else if (field.type === FormFieldType.Label) {
    return false;
  } else if (field.type === FormFieldType.ServiceCreation) {
    return hasFormFieldValueError(field, service);
  } else {
    return !isUserHasPermission(ActionPermissions.Configure_Interactions_Bypass_Mandatory, permissions) && (field.isRequired || form.isMandatory) && hasFormFieldValueError(field, service);
  }
};

export const checkIsByPassed = (permissions: CustomPermission[], form: FormObject, container: FormContainerItem, field: FormFieldItem, service: ServiceObject) => {
  if (container.isDecisionPrompt && container.questionData?.hasValue && !container.questionData?.isYes) {
    return false;
  } else if (isUserHasPermission(ActionPermissions.Configure_Interactions_Bypass_Mandatory, permissions) && form.isMandatory && container.isDecisionPrompt && !container.questionData?.hasValue) {
    return true;
  } else if (field.type === FormFieldType.Label || field.type === FormFieldType.ServiceCreation) {
    return false;
  } else {
    return isUserHasPermission(ActionPermissions.Configure_Interactions_Bypass_Mandatory, permissions) && (field.isRequired || form.isMandatory) && hasFormFieldValueError(field, service);
  }
};

export const getSortedPendingInteractions = (formsWithDisplayOrder: PendingInteractionFormType[], formsWithoutDisplayOrder: PendingInteractionFormType[]) => {
  return formsWithDisplayOrder.sort((form1, form2) => (form1.displayOrder as number) - (form2.displayOrder as number)).concat(formsWithoutDisplayOrder);
};