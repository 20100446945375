import React, { useEffect, useState } from 'react';
import { Stack, Table, TableContainer, TableHead, TableRow } from '@mui/material';
import { ActionPermissions, CustomPermission, SizeProps } from '../../../@types';
import { SerializedShipmentManifestData, ShipmentTypeCodes } from '../../../@types/shipment.type';
import { Checkbox, CheckboxColorProps } from '../../atoms/Checkbox';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../../molecules/LocationSearchItem';
import { SerializedStyledLocationTableCell, SerializedStyledPartTypeTableCell, SerializedStyledTableCell } from './SerializedShipmentManifestItem.styles';
import { SerializedShipmentManifestItemProps } from '.';
import { Link } from '../../atoms/Link';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { Typography } from '../../atoms/Typography';
import { COLORS } from '../../../configs/colors';

export const SerializedShipmentManifestItem: React.FC<SerializedShipmentManifestItemProps> = ({
  serializedShipmentManifestItemData,
  onChange,
  onApiInvoke,
  isEditMode,
  isCheckbox
}) => {
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [permissions, setPermissions] = useState<CustomPermission[]>();

  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);
  
  const onClickAll = () => {
    const updatedData: Array<SerializedShipmentManifestData> = serializedShipmentManifestItemData.map((item) => ({ ...item, selected: !selectAllCheckbox }));
    onChange(updatedData);
    setSelectAllCheckbox(!selectAllCheckbox);
  };

  return (
    <TableContainer>
      <Table size={SizeProps.Small} sx={{ width: '100%', overflow: 'auto' }} >
        <TableHead>
          {isCheckbox &&
            <SerializedStyledTableCell>
              <Checkbox
                color={CheckboxColorProps.Primary}
                checked={selectAllCheckbox}
                onChange={onClickAll}
              />
            </SerializedStyledTableCell>
          }
          <SerializedStyledTableCell></SerializedStyledTableCell>
          <SerializedStyledTableCell>Serial1</SerializedStyledTableCell>
          <SerializedStyledTableCell>Shipment ID</SerializedStyledTableCell>
          <SerializedStyledTableCell>Part ID</SerializedStyledTableCell>
          <SerializedStyledTableCell>Condition</SerializedStyledTableCell>
          <SerializedStyledTableCell>HW</SerializedStyledTableCell>
          <SerializedStyledTableCell>FW</SerializedStyledTableCell>
          <SerializedStyledTableCell>SW</SerializedStyledTableCell>
          <SerializedStyledTableCell>Fleet Tag</SerializedStyledTableCell>
          {isUserHasPermission(ActionPermissions.Shipment_Uses_Put_away, permissions) &&
            <SerializedStyledTableCell>Put Away Destination</SerializedStyledTableCell>
          }
          <SerializedStyledTableCell></SerializedStyledTableCell>
        </TableHead>
        {serializedShipmentManifestItemData.map((item, index: number) => 
          <TableRow key={index} sx={{ backgroundColor: item.isReturn ? COLORS.LightOrange : COLORS.White, overflow: 'auto' }}>
            {isCheckbox &&
              <SerializedStyledTableCell>
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={item.selected}
                  onChange={(event) => {
                    const updatedData = [...serializedShipmentManifestItemData];
                    updatedData[index].selected = event.target.checked;
                    onChange(updatedData);
                  }}
                />
              </SerializedStyledTableCell>
            }
            <SerializedStyledPartTypeTableCell>
              <Stack alignItems="flex-start">
                <Typography>Part Type Id: <b>{item.partTypeId}</b></Typography>
                <Typography>Part Type Name: <b>{item.partTypeName}</b></Typography>
                <Typography>Part No: <b>{item.partTypeNumber}</b></Typography>
              </Stack>
            </SerializedStyledPartTypeTableCell>
            <SerializedStyledTableCell>
              {item?.source !== ShipmentTypeCodes.AddStock && item.serial1 ? (
                <Link href={`/parts/${item.partId}`}>{item.serial1}</Link>
              ) : (
                item.serial1
              )}
            </SerializedStyledTableCell>
            <SerializedStyledTableCell>{item.shipmentId}</SerializedStyledTableCell>
            {item?.source !== ShipmentTypeCodes.AddStock ? 
              <SerializedStyledTableCell>{item.partId}</SerializedStyledTableCell>
              :
              <SerializedStyledTableCell></SerializedStyledTableCell>
            }
            <SerializedStyledTableCell>{item.conditionName}</SerializedStyledTableCell>
            <SerializedStyledTableCell>{item.hardwareVersion}</SerializedStyledTableCell>
            <SerializedStyledTableCell>{item.firmwareVersion}</SerializedStyledTableCell>
            <SerializedStyledTableCell>{item.softwareVersion}</SerializedStyledTableCell>
            <SerializedStyledTableCell>{item.fleetTagName}</SerializedStyledTableCell>
            {isUserHasPermission(ActionPermissions.Shipment_Uses_Put_away, permissions) &&
              <SerializedStyledLocationTableCell>
                {item.isReturn ? 
                  <Typography>{item.putAwayLocationName}</Typography> 
                  :
                  <LiveSearchBox
                    title="Select a Location"
                    timeOffset={400}
                    value={item.putAwayLocationName}
                    onClearValue={() => ''}
                    disabled={!isEditMode}
                    defaultSuggetions={item.serialisedSuggestions}
                    onChange={(value) => {
                      const updatedData = [...serializedShipmentManifestItemData];
                      updatedData[index].putAwayLocationName = value.name;
                      updatedData[index].putAwayLocationId = value.id;
                      onChange(updatedData);
                    }}
                    renderItem={(props: any, option: any) => {
                      return (
                        <LiveSearchListItem {...props}>
                          <LocationSearchItem data={option} />
                        </LiveSearchListItem>
                      );
                    }}
                    onApiInvoke={onApiInvoke}
                  />
                }
              </SerializedStyledLocationTableCell>
            }
          </TableRow>
        )}
      </Table>
    </TableContainer>
  );
};