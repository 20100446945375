import React, { useEffect, useRef, KeyboardEvent } from 'react';
import { Stack, Table, TableHead, TableRow, Typography, TextField, IconButton } from '@mui/material';
import { CheckCircleOutline as CheckCircleOutlineIcon, Delete } from '@mui/icons-material';
import { TypographyVariantProps } from '../../atoms/Typography';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { LocationSearchItem } from '../../molecules/LocationSearchItem';
import { TLCManifestRowItemProps } from './TLCManifestRowItem.props';
import { StyledStackContainer, StyledStatusStack, SerializedStyledTableCell, StyledTableCell, StyledSubHeading, StyledTypography, StyledDeleteContainer } from './TLCManifestRowItem.styles';
import { SizeProps, ColorProps } from '../../../@types';
import { IconButtonColor, TLCTemplateActionErrorLabel, TLCTemplateActionStatusLabel, TLCTemplateBehavior, TLCTemplateNotePrompt } from '../../../configs/enums';
import { useSearchTlcNodesParts } from '../../../queries/live-search-query';
import { COLORS } from '../../../configs/colors';
import { getPartConditionLabelsfromValues } from '../../../utils/part';

export const TLCManifestRowItem: React.FC<TLCManifestRowItemProps> = ({
  value,
  manifestData,
  conditionsList,
  templateData,
  batchExecutionComplete,
  onChangeLocation,
  onChangeNote,
  onDelete
}) => {
  const searchTlcNodesParts = useSearchTlcNodesParts();
  const inputRef: any = useRef(null);

  useEffect(() => {
    templateData.data.templateAction.locationPrompt && manifestData[0].id === value.id && !value.errors && inputRef.current?.focus();
  }, [value.id]);
  
  return (
    <Stack direction="row">
      <StyledStackContainer flex={1} error={value.errors}>
        {!value.errors &&
        <StyledStatusStack>
          <StyledTypography color={COLORS.Blue} variant={TypographyVariantProps.Caption}>{value.validation && !value.execution && TLCTemplateActionStatusLabel.Validated}</StyledTypography>
          <StyledTypography color={COLORS.LightGreen} variant={TypographyVariantProps.Caption}>{value.execution && TLCTemplateActionStatusLabel.Executed}</StyledTypography>
          {value.execution && <CheckCircleOutlineIcon color={ColorProps.Success} />}
        </StyledStatusStack>
        }
        <Stack direction="row">
          <TableRow>
            <StyledTableCell width="auto">
              <Stack direction="column">
                <StyledSubHeading>Part Type Id: <StyledTypography variant={TypographyVariantProps.Caption}>{value.partTypeId}</StyledTypography></StyledSubHeading>
                <StyledSubHeading>Part Type Name: <StyledTypography variant={TypographyVariantProps.Caption}>{value.partTypeName}</StyledTypography></StyledSubHeading>
                <StyledSubHeading>Part No: <StyledTypography variant={TypographyVariantProps.Caption}>{value.partTypeNo}</StyledTypography></StyledSubHeading>
              </Stack>
            </StyledTableCell>
            <StyledTableCell width="auto">
              <Table size={SizeProps.Small} padding="none">
                <TableHead>
                  <SerializedStyledTableCell><StyledSubHeading>Scan ID</StyledSubHeading></SerializedStyledTableCell>
                  <SerializedStyledTableCell><StyledSubHeading>Serial</StyledSubHeading></SerializedStyledTableCell>
                  {!value.errors &&
                  <>
                    <SerializedStyledTableCell><StyledSubHeading>Condition</StyledSubHeading></SerializedStyledTableCell>
                    <SerializedStyledTableCell><StyledSubHeading>Location</StyledSubHeading></SerializedStyledTableCell>
                    <SerializedStyledTableCell><StyledSubHeading>SW</StyledSubHeading></SerializedStyledTableCell>
                    <SerializedStyledTableCell><StyledSubHeading>HW</StyledSubHeading></SerializedStyledTableCell>
                    <SerializedStyledTableCell><StyledSubHeading>FW</StyledSubHeading></SerializedStyledTableCell>
                    <SerializedStyledTableCell><StyledSubHeading>Fleet Tag</StyledSubHeading></SerializedStyledTableCell>
                  </>
                  }
                </TableHead>
                <TableRow>
                  <SerializedStyledTableCell><StyledTypography>{value.id}</StyledTypography></SerializedStyledTableCell>
                  <SerializedStyledTableCell><StyledTypography>{value.serial}</StyledTypography></SerializedStyledTableCell>
                  {!value.errors &&
                  <>
                    <SerializedStyledTableCell><StyledTypography>{getPartConditionLabelsfromValues(value.condition, conditionsList)}</StyledTypography></SerializedStyledTableCell>
                    <SerializedStyledTableCell><StyledTypography>{value.location?.name}</StyledTypography></SerializedStyledTableCell>
                    <SerializedStyledTableCell><StyledTypography>{value.softwareVersion}</StyledTypography></SerializedStyledTableCell>
                    <SerializedStyledTableCell><StyledTypography>{value.hardwareRevision}</StyledTypography></SerializedStyledTableCell>
                    <SerializedStyledTableCell><StyledTypography>{value.firmwareVersion}</StyledTypography></SerializedStyledTableCell>
                    <SerializedStyledTableCell><StyledTypography>{value.fleetTag}</StyledTypography></SerializedStyledTableCell>
                  </>
                  }
                </TableRow>
              </Table>
            </StyledTableCell>
          </TableRow>
          {!value.errors && templateData.behavior.toLowerCase() === TLCTemplateBehavior.Batch &&
            templateData.data.templateAction.locationPrompt &&
          <Stack flex={1} justifyContent="center" marginRight="20px">
            <LiveSearchBox
              disabled={value.execution}
              inputRef={inputRef}
              title="Location search"
              timeOffset={400}
              value={value.newLocation?.name || ''}
              onClearValue={() => onChangeLocation && onChangeLocation('', value.id)}
              onChange={(locationData) => onChangeLocation && onChangeLocation(locationData, value.id)}
              onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
                if (event.key === 'Enter') {
                  onChangeLocation && onChangeLocation(event.currentTarget, value.id);
                }
              } }
              onApiInvoke={async (name) => {
                return await searchTlcNodesParts.mutateAsync({
                  id: value.partId,
                  name,
                  currentLocation: value.location.id,
                  currentLocationType: value?.partCurrentData?.locationTypeCode
                });
              } }
              renderItem={(props: any, option: any) => {
                return (
                  <LiveSearchListItem {...props}>
                    <LocationSearchItem data={option} />
                  </LiveSearchListItem>
                );
              } } />
          </Stack>
          }
          {!value.errors && templateData.behavior.toLowerCase() === TLCTemplateBehavior.Batch &&
          templateData.data.templateAction.notePrompt.prompt &&
          templateData.data.templateAction.notePrompt.behavior.toLowerCase() === TLCTemplateNotePrompt.Individual &&
          <Stack flex={1} justifyContent="center">
            <TextField
              disabled={value.execution}
              placeholder="Provide a note"
              fullWidth
              multiline
              size={SizeProps.Medium}
              value={value.note}
              onChange={(event) => onChangeNote && onChangeNote(event.target.value, value.id)} />
          </Stack>
          }
          {value.errors ?
            <Stack flex={1} justifyContent="center">
              <Table size={SizeProps.Small} padding="none">
                <TableHead>
                  <SerializedStyledTableCell><Typography fontWeight={600} fontSize={20} variant={TypographyVariantProps.Caption}>{value.validation ? TLCTemplateActionErrorLabel.TemplateAction : TLCTemplateActionErrorLabel.Restriction}Errors</Typography></SerializedStyledTableCell>
                </TableHead>
                <TableRow>
                  <SerializedStyledTableCell><Typography fontSize={18} variant={TypographyVariantProps.Caption}>{value.errors}</Typography></SerializedStyledTableCell>
                </TableRow>
              </Table>
            </Stack>
            :
            null
          }
        </Stack>
      </StyledStackContainer>
      {templateData.behavior.toLowerCase() === TLCTemplateBehavior.Batch ?
        <StyledDeleteContainer>
          <IconButton disabled={batchExecutionComplete} color={IconButtonColor.Error} onClick={() => onDelete && onDelete(value.id)}>
            <Delete />
          </IconButton>
        </StyledDeleteContainer>
        :
        null
      }
    </Stack>
  );
};