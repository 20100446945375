import { DateTypeNames } from '../@types/attendanceReport.type';

export const AUTOCOMPLET_DROPDOWN_ITEMS = {
  label: '',
  value: ''
};

export const MONTHS_OF_YEAR = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const DAYS_OF_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const RAW_DATE_RANGES = [
  { label: 'Now', value: 'now' },
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'first day of this week', value: DateTypeNames.First_Day_Of_This_Week },
  { label: 'first day of this month', value: DateTypeNames.First_Day_Of_This_Month },
  { label: 'first day of this year', value: DateTypeNames.First_Day_Of_This_Year },
  { label: 'last day of this week', value: DateTypeNames.Last_Day_Of_This_Week },
  { label: 'last day of this month', value: DateTypeNames.Last_Day_Of_This_Month },
  { label: 'last day of this year', value: DateTypeNames.Last_Day_Of_This_Year },
  { label: 'first day of last week', value: DateTypeNames.First_Day_Of_Last_Week },
  { label: 'first day of last month', value: DateTypeNames.First_Day_Of_Last_Month },
  { label: 'first day of last year', value: DateTypeNames.First_Day_Of_Last_Year },
  { label: 'last day of last week', value: DateTypeNames.Last_Day_Of_Last_Week },
  { label: 'last day of last month', value: DateTypeNames.Last_Day_Of_Last_Month },
  { label: 'last day of last year', value: DateTypeNames.Last_Day_Of_Last_Year }
];