import { DropdownItem } from '../../atoms/SelectMenu';

export enum SLAType {
  WholeDayTimerSameday = 'whole-day-timer-same-day',
  WholeDayTimerNextday = 'whole-day-timer-next-day',
  WholeDayTimerSamedayWithCutoffTime = 'whole-day-timer-same-day-with-cutoff-time',
  WholeDayTimerNextdayWithCutoffTime = 'whole-day-timer-next-day-with-cutoff-time',
  DurationTimer = 'duration',
  ScheduledDateTimerScheduledEventEnd = 'scheduled-date-timer-scheduled-event-end',
  ScheduledDateTimerScheduledDateOnly = 'scheduled-date-timer-scheduled-date-only'
}

export interface slaTypeDataType {
  code: string;
  name: string;
  timerTypeCode: string;
  timerTypeName: string;
}

export interface SLATypeSelectorProps {
  open: boolean;
  slaTypeData: DropdownItem[];
  selectedSlaType: string;
  selectedValue?: string;
  handleSlaTypeSelection: (slaTypeHierarchy: string) => void;
  onChange?: (selectedSlaType: string, value: string, slaTypeHierarchy: string) => void;
  handleClose: () => void;
}