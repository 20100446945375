import React, { useEffect, useState } from 'react';
import { Alert, CardActions, Grid, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { ButtonColorProps, ButtonVariantProps } from '../../../../../components/atoms/Button';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../../../../components/atoms/Typography';
import { TextField, TextFieldTypeProps } from '../../../../../components/atoms/TextField';
import { ImagePopup, ImagePopupSlide } from '../../../../../components/atoms/ImagePopup';
import { PartTypeActionSearch } from '../../../../../components/molecules/PartTypeActionSearch';
import { PartTypeActionSHFData } from '../../../../../components/molecules/PartTypeActionSHFData';
import { PartTypeActionNote } from '../../../../../components/molecules/PartTypeActionNote';
import { PartTypeLocationItemData } from '../../../../../components/molecules/PartTypeLocationItem';
import { PartLocationSelectPopup } from '../../../../../components/organisms/PartLocationSelectPopup';
import { useGetUploadedImages } from '../../../../../queries/uploadedfiles-query';
import { ActionPermissions, CustomPermission, PartTypeActionLiveSearchItem, PartTypeActionLiveSearchItemCategoryCode } from '../../../../../@types';
import { Folders } from '../../../../../@types/uploaded-files.type';
import { isEmptyString } from '../../../../../utils/common';
import { PartActionDoaCardProps } from './PartActionDoaCard.props';
import { 
  DefaultCard, 
  StyledCardContent,
  CardTitle,
  CloseWrapper,
  SHFDataWrapper,
  StyledButton,
  SelectButton
} from './PartActionDoaCard.styles';
import { useGetPartLocationDataByServiceId } from '../../../../../queries/part-query';
import { PartActionType, PartActionValidateType } from '../../../../../@types/partAction.type';
import { partActionConditionValidation } from '../../../../../utils/part-action';
import { PartConditionOptions } from '../../../../../@types/part.type';
import { LOCATION_CANNOT_EMPTY } from '../../../../../constants/partAction';
import { useSubLocationsForCreateServices } from '../../../../../queries/live-search-query';
import { useGetUserSystemPermissions } from '../../../../../queries/user-query';
import { isUserHasPermission } from '../../../../../configs/permissions';
import { COLORS } from '../../../../../configs/colors';

export const PartActionDoaCard: React.FC<PartActionDoaCardProps> = ({
  service,
  value,
  helperText,
  onChange,
  onSave,
  onExec,
  onClose,
  setHelperText
}) => {
  const [isHavingPermission, setIsHavingPermission] = useState(true);
  const [isSearchMode, setIsSearchMode] = useState(true);
  const [isOutSerialized, setIsOutSerialized] = useState(false);
  const [showOutSerialSearch, setShowOutSerialSearch] = useState(false);
  const [isSelectOutLocationPopup, setIsSelectOutLocationPopup] = useState(false);
  const [outPartImages, setOutPartImages] = useState<Array<ImagePopupSlide>>([]);
  const [siteHierarchyWarning, setSiteHierarchyWarning] = useState<string>('');
  const [underClientStructureWarning, setUnderClientStructureWarning] = useState<string>('');
  const [permissions, setPermissions] = useState<CustomPermission[]>();

  const outValueUploadedImagesQuery = useGetUploadedImages(value?.outValue?.partTypeId || 0, Folders.PartTypesImages);
  const outPartLocationDataByServiceIdQuery = useGetPartLocationDataByServiceId(value?.outValue?.partTypeId || 0, service.id);
  const subLocationsForCreateServicesQuery = useSubLocationsForCreateServices();
  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    setIsHavingPermission(isUserHasPermission(ActionPermissions.Service_Edit_Part_Actions_Exceptional_Creation, permissions));
  }, [permissions]);

  useEffect(() => {
    const outValidations = async () => {
      if (value?.outValue?.isInSiteHierarchy === false && value?.outValue?.serial) {
        const subLocationsResult = await subLocationsForCreateServicesQuery.mutateAsync(value.outValue?.locationId);
        const main = subLocationsResult?.find(subLocation => subLocation.isMain);
    
        const partParentNode = main ? `${main.name} /` : '';
        const serviceBaseNode = service.subLocation ? `/ ${service.subLocation}` : '';

        setSiteHierarchyWarning(`Warning: ${value?.outValue?.serial} is not at ${service.mainLocation} ${serviceBaseNode}, but at ${partParentNode} ${value.outValue?.location}. This is an unexpected part location; please confirm the correct part has been selected prior to proceeding. installed in ${value?.outValue?.name}: ${value?.outValue?.serial}`);
      } else {
        setSiteHierarchyWarning('');
      } 
      
      if (value?.outValue?.isUnderClientStructure === false && value?.outValue?.serial) {
        const baseOrParentnode = service.subLocation ? service.subLocation : service.mainLocation;
        setUnderClientStructureWarning(`Warning: ${value?.outValue?.serial} is not at ${baseOrParentnode}, but at ${value.outValue?.location}.  Please confirm the correct part has been selected prior to proceeding.`);
      } else {
        setUnderClientStructureWarning('');
      }
    };

    outValidations();
  }, [value?.outValue?.isInSiteHierarchy, value?.outValue?.isUnderClientStructure, value?.outValue?.serial]);

  useEffect(() => {
    value?.outValue?.name && setIsSearchMode(isEmptyString(value?.outValue?.name));
    value?.outValue?.category && setIsOutSerialized(value?.outValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent || value?.outValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice);
    value?.outValue?.partTypeId && outValueUploadedImagesQuery.refetch();
  }, [value?.outValue?.name, value?.outValue?.category, value?.outValue?.partTypeId]);

  useEffect(() => {
    if (value?.outValue?.name) {
      setShowOutSerialSearch(value?.outValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent || value?.outValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice);
    }
  }, [value?.outValue?.name]);
 
  useEffect(() => {
    if (outValueUploadedImagesQuery.data) {
      const outImages: Array<ImagePopupSlide> = outValueUploadedImagesQuery.data && outValueUploadedImagesQuery.data.map((obj) => ({
        url: obj.url
      }));
      setOutPartImages(outImages);
    }
  }, [outValueUploadedImagesQuery.data]);

  const handleOutPartTypeActionSearch = async (liveSearchSelectedItem: PartTypeActionLiveSearchItem) => { 
    setHelperText({
      ...helperText,
      out: { 
        ...helperText.out,
        partTypeHelperText: '',
        partSerialHelperText: ''
      },
    });

    if (liveSearchSelectedItem.serial1) {
      onChange({
        value: value.value,
        outValue: {
          ...value.outValue,
          id: value.outValue?.id || 0,
          partTypeId: liveSearchSelectedItem.partTypeId,
          partId: liveSearchSelectedItem.partId,
          name: liveSearchSelectedItem.partTypeName,
          category: liveSearchSelectedItem.categoryCode,
          location: liveSearchSelectedItem.locationName,
          locationId: liveSearchSelectedItem.locationId,
          condition: liveSearchSelectedItem.conditionCode,
          conditionName: liveSearchSelectedItem?.partCondition?.name,
          serial: liveSearchSelectedItem.serial1,
          sw: liveSearchSelectedItem.softwareVersion,
          hw: liveSearchSelectedItem.hardwareVersion,
          fw: liveSearchSelectedItem.firmwareVersion,
          ft: liveSearchSelectedItem.fleetTagName,
          isInSiteHierarchy: liveSearchSelectedItem.isInSiteHierarchy,
          isUnderClientStructure: liveSearchSelectedItem.isUnderClientStructure
        },
        inValue: value.inValue
      });
    } else {
      onChange({
        value: value.value,
        outValue: {
          ...value.outValue,
          id: value.outValue?.id || 0,
          partTypeId: liveSearchSelectedItem.partTypeId,
          ns_partId: liveSearchSelectedItem.partId,
          name: liveSearchSelectedItem.partTypeName,
          category: liveSearchSelectedItem.categoryCode,
          location: liveSearchSelectedItem.locationName,
          condition: liveSearchSelectedItem.conditionCode,
          conditionName: liveSearchSelectedItem?.partCondition?.name,
          serial: '',
          sw: liveSearchSelectedItem.softwareVersion,
          hw: liveSearchSelectedItem.hardwareVersion,
          fw: liveSearchSelectedItem.firmwareVersion,
          ft: liveSearchSelectedItem.fleetTagName
        },
        inValue: value.inValue
      });
    }
  };

  const handleOutPartTypeSerialSearch = (val: PartTypeActionLiveSearchItem) => {
    setHelperText({
      ...helperText,
      out: { 
        ...helperText.out,
        partTypeHelperText: '',
        partSerialHelperText: ''
      },
    });

    const updatedValue = {
      value: value.value,
      outValue: {
        ...value.outValue,
        id: value.outValue?.id || 0,
        ns_partId: NaN,
        partId: val.partId,
        location: val.locationName,
        locationId: val.locationId,
        serial: val.serial1,
        condition: val.conditionCode,
        conditionName: val?.partCondition?.name,
        sw: val.softwareVersion,
        hw: val.hardwareVersion,
        fw: val.firmwareVersion,
        ft: val.fleetTagName,
        isInSiteHierarchy: val.isInSiteHierarchy,
        isUnderClientStructure: val.isUnderClientStructure
      },
      inValue: value.inValue
    };
    onChange(updatedValue);
  };

  const handleOutPartTypeLocationSelect = (val: PartTypeLocationItemData) => {
    setHelperText({
      ...helperText,
      out: { 
        ...helperText.out,
        locationHelperText: ''
      },
    });

    const updatedValue = {
      value: value.value,
      outValue: {
        ...value.outValue,
        id: value.outValue?.id || 0,
        partId: NaN,
        serial: '',
        ns_partId: val.ns_partId,
        location: val.locationName,
        condition: val.conditionCode,
        conditionName: val?.partCondition?.name,
        ft: val.fleetTagName
      },
      inValue: value.inValue
    };

    onChange(updatedValue);
    setIsSelectOutLocationPopup(false);
  };
  
  return (
    <DefaultCard>
      <StyledCardContent>
        <CardTitle>DOA</CardTitle>
        <CloseWrapper onClick={onClose}>
          <CloseRounded htmlColor={COLORS.White} />
        </CloseWrapper>
        <Stack direction="column" spacing={1.5} width="100%">
          <Stack direction="row" justifyContent="space-between" spacing={1} marginTop={1} width="100%">
            <Stack direction="column" spacing={2} width="100%">
              <PartTypeActionSearch
                title={showOutSerialSearch ? 'Part Type Search' : 'Part/Part Type Input'}
                serviceData={service}
                value={value?.outValue?.name || ''}
                partActionType={PartActionType.DOA}
                error={!!helperText.out.partTypeHelperText}
                helperText={helperText.out.partTypeHelperText}
                onChange={handleOutPartTypeActionSearch}
                searchType={showOutSerialSearch ? 'part-type' : 'part-and-part-type'}
              />
              {showOutSerialSearch &&
              <PartTypeActionSearch
                title="Part Serial Search"
                serialiseType={value?.outValue?.category}
                partTypeId={`${value?.outValue?.partTypeId}`}
                value={value?.outValue?.serial || ''}
                partActionType={PartActionType.DOA}
                error={!!helperText.out.partSerialHelperText}
                helperText={helperText.out.partSerialHelperText}
                onChange={handleOutPartTypeSerialSearch}
                searchType="part"
              />
              }
            </Stack>
            {isSearchMode || <ImagePopup title="Photos" images={outPartImages} />}
          </Stack>
          {isSearchMode || 
            <>
              {isOutSerialized ||
                <>
                  <SelectButton 
                    variant={ButtonVariantProps.Secondary} 
                    color={ButtonColorProps.Success}
                    onClick={() => setIsSelectOutLocationPopup(true)}
                  >
                    Select Location
                  </SelectButton>
                  {!!helperText.out.locationHelperText && 
                    <Grid item xs={12}>
                      <Alert severity="error">{LOCATION_CANNOT_EMPTY}</Alert>
                    </Grid>
                  }
                </>
              }
              {isOutSerialized ?
                <SHFDataWrapper>
                  <PartTypeActionSHFData sw={value?.outValue?.sw || '_'} hw={value?.outValue?.hw || '_'} fw={value?.outValue?.fw || '_'} />
                </SHFDataWrapper>
                :
                <TextField 
                  label="Qty"
                  type={TextFieldTypeProps.Number}
                  value={value?.outValue?.qty}
                  inputProps={{ type: 'number', min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={(val) =>{
                    onChange({
                      value: value.value,
                      outValue: {
                        ...value.outValue,
                        id: value.outValue?.id || 0,
                        qty: Number(val)
                      },
                      inValue: value.inValue
                    });}}
                />
              }
              {value?.outValue?.condition && 
                  <Stack direction="row" spacing={0.5}>
                    <Typography 
                      variant={TypographyVariantProps.Body2} 
                      fontWeight={TypographyFontWeightProps.Bold}
                    >
                      COND:
                    </Typography>
                    <Typography variant={TypographyVariantProps.Body2}>{value?.outValue?.conditionName || value?.outValue?.condition}</Typography>
                  </Stack>
              }
              {value?.outValue?.location &&
                  <Stack direction="row" spacing={0.5}>
                    <Typography 
                      variant={TypographyVariantProps.Body2} 
                      fontWeight={TypographyFontWeightProps.Bold}
                    >
                      LOC:
                    </Typography>
                    <Typography variant={TypographyVariantProps.Body2}>{value?.outValue?.location}</Typography>
                  </Stack>
              }
              {value?.outValue?.ft &&
                  <Stack direction="row" spacing={0.5}>
                    <Typography 
                      variant={TypographyVariantProps.Body2} 
                      fontWeight={TypographyFontWeightProps.Bold}
                    >
                      FT:
                    </Typography>
                    <Typography variant={TypographyVariantProps.Body2}>{value?.outValue?.ft}</Typography>
                  </Stack>
              }
            </>
          }
          {isSearchMode || 
            <>
              <Grid item xs={12}>
                <PartTypeActionNote
                  value={value?.outValue?.notes || ''}
                  onChange={(note) => onChange({
                    value: value.value,
                    outValue: {
                      ...value.outValue,
                      id: value.outValue?.id || 0,
                      notes: note
                    },
                    inValue: value.inValue
                  })}
                />
              </Grid>
              {value?.outValue?.condition && value?.outValue?.serial && partActionConditionValidation(value.outValue.condition.toLowerCase() === PartConditionOptions.INSTALLED ? PartActionValidateType.DOAOUT : PartActionValidateType.DOAIN, value.outValue.condition, value.outValue.serial) &&
                <Grid item xs={12}>
                  <Alert severity="warning">{partActionConditionValidation(value.outValue.condition === PartConditionOptions.INSTALLED ? PartActionValidateType.DOAOUT : PartActionValidateType.DOAIN, value.outValue.condition, value.outValue.serial)}</Alert>
                </Grid>
              }
              {siteHierarchyWarning &&
                <Grid item xs={12}>
                  <Alert severity="warning">{siteHierarchyWarning}</Alert>
                </Grid>
              }
              {underClientStructureWarning &&
                <Grid item xs={12}>
                  <Alert severity="warning">{underClientStructureWarning}</Alert>
                </Grid>
              }
            </>
          }
        </Stack>
      </StyledCardContent>
      {isHavingPermission && (isSearchMode ||
        <CardActions>
          <Stack direction="row-reverse" spacing={1} width="100%">
            <StyledButton 
              variant={ButtonVariantProps.Primary} 
              color={ButtonColorProps.Error}
              onClick={onExec}
            >
                Exec
            </StyledButton>
            <StyledButton 
              variant={ButtonVariantProps.Primary} 
              color={ButtonColorProps.Success}
              onClick={onSave}
            >
              {value.outValue?.actionId ? 'Update' : 'Save'}
            </StyledButton>
          </Stack>
        </CardActions>
      )}
      <PartLocationSelectPopup
        open={isSelectOutLocationPopup}
        onSelect={handleOutPartTypeLocationSelect}
        options={outPartLocationDataByServiceIdQuery.data || []}
        onClose={() => setIsSelectOutLocationPopup(false)}
      />
    </DefaultCard>
  );
};