import { TaskCard } from '../@types/task.type';
import { HttpMethod, httpClient } from './http-client';

export const getTaskByServiceId = (serviceId: number) => {
  return httpClient(`/tasksByServiceId/${serviceId}`, HttpMethod.Get, null);
};

export const createAndUpdateTask = (taskCardObject: TaskCard) => {
  return httpClient('/task', HttpMethod.Post, taskCardObject);
};

export const createTaskServiceLogByApprove = (serviceLogId: number,isApprove: boolean) => {
  return httpClient(`/tasks/serviceLogs/${serviceLogId}/approve`, HttpMethod.Post, isApprove);
};

export const deleteTaskById = (id: number) => {
  return httpClient(`/task/${id}`, HttpMethod.Delete, null);
};