import { ServiceListColumnItemTitle } from '../@types';
import { TAGS } from './tag.constant';

export const DefaultClientSelectorUndefinedText = 'undefined/undefined/undefined';

export const SAMPLE_SERVICE_SUMMARY = {
  id: 0,
  client: '',
  contract: '',
  loggedAt: '',
  loggedBy: '',
  subLocation: '',
  subLocationId: 0,
  mainLocationId: 0,
  brand: '',
  priorityId: '',
  serviceType: '',
  actionType: '',
  processType: '',
  clientSLA: '',
  opsSLA: '',
  scheduled: '',
  followUp: '',
  referenceNo1: '',
  referenceNo2: '',
  referenceNo3: '',
  selectedServiceCreationTypeValue: [],
  siteSchema: '',
};

export const SAMPLE_SERVICE_STATUS = {
  code: '',
  name: ''
};

export const SAMPLE_SERVICE_TYPE = {
  code: '',
  name: '',
  isCheckInAvailable: true,
  isActive: true
};

export const SAMPLE_ACTION_TYPE = {
  code: '',
  name: '',
  isActive: true
};

export const SAMPLE_PROCESS_TYPE = {
  code: '',
  name: '',
  isActive: true
};

export const VALUE = {
  value: ''
};

export const ATTRIBUTES = {
  code: '',
  name: '',
  value: VALUE,
};

export const PRIORITY = {
  shortName: '',
  color: '',
};

export const SAMPLE_SERVICE = {
  id: 0,
  serviceStatus: SAMPLE_SERVICE_STATUS,
  serviceTypeCode: '-1',
  serviceStatusCode: '',
  serviceType: [SAMPLE_SERVICE_TYPE],
  actionTypeCode: '-1',
  actionType: [SAMPLE_ACTION_TYPE],
  processTypeCode:'-1',
  processType: [SAMPLE_PROCESS_TYPE],
  priorityCode: '-1',
  location: '',
  tags: [TAGS],
  referenceNo1: '',
  referenceNo2: '',
  referenceNo3: '',
  userDepotAllocation: '',
  scheduledAt: '',
  notes: '',
  nodeId: 0,
  reportedSerialNumber: '',
  reportedSerialNumberPartId: NaN,
  reportedModel: '-1',
  reportedModelId: 0,
  reportedFaultAndDescription: '-1',
  reportedFaultAndDescriptionId: 0,
  elementForDisplaying: [],
  attributes: [ATTRIBUTES],
  client: '',
  contract: '',
  clientCode: '',
  contractCode: '',
  brand: '',
  createdAt: '',
  mainLocation: '',
  subLocation: '',
  loggedBy: '',
  followUpAt: '',
  priorityId: '',
  priority: PRIORITY,
  parentId: '',
  clientId: 0,
  contractId: 0,
  brandId: 0,
  travelStartTime: '',
  selectedServiceCreationTypeValue: [],
  isHelpdeskUser: false,
  siteSchema: '',
  contractName: ''
};

export const SAMPLE_SERVICE_COMMON_TYPE = {
  serviceTypes: [SAMPLE_SERVICE_TYPE],
  actionTypes: [SAMPLE_ACTION_TYPE],
  processTypes: [SAMPLE_PROCESS_TYPE]
};

export const SAMPLE_NODE_ATTRIBUTE_VALUE_TYPE = {
  shortName: '',
  description: '',
  color: '',
  isActive: true
};

export const SAMPLE_PRIORITY_TYPE = {
  id: 0,
  propertyTypeCode: '',
  value: SAMPLE_NODE_ATTRIBUTE_VALUE_TYPE
};

export const SAMPLE_BULK_SERVICE_UPDATE_TYPE = {
  'Service_Id': '',
  'Linked_Field': ''
};

export const SAMPLE_REPORTED_INFORMATION = {
  id: 0,
  loggedBy: '',
  createdAt: '',
  referenceNo1: '',
  referenceNo2: '',
  referenceNo3: '',
  reportedSerialNumber: '',
  reportedModel: '',
  reportedFaultAndDescription: ''
};

export const INIT_SUMMARY_EDIT_PERMISSIONS = {
  isClient: false,
  isContract: false,
  isLoggedAt: false,
  isLoggedBy: false,
  isSubLocation: false,
  isBrand: false,
  isPriority: false,
  isServiceType: false,
  isActionType: false,
  isProcessType: false,
  isClientSLA: false,
  isOpsSLA: false,
  isScheduled: false,
  isFollowUp: false,
  isReferenceNo1: false,
  isReferenceNo2: false,
  isReferenceNo3: false,
  isReportedSerial: false,
  isReportedFault: false,
  isReportedModel: false
};

export const INIT_REPORTED_INFORMATION_EDIT_PERMISSIONS = {
  isReferenceNo1: false,
  isReferenceNo2: false,
  isReferenceNo3: false,
  isReportedSerial: false,
  isReportedFault: false,
  isReportedModel: false
};

export const LINKED_PARENT_CHILD_SERVICE_DATA = {
  id: 0,
  serviceTypeCode: ''
};

export const SAMPLE_ALLOCATED_USER = {
  id: 0,
  serviceId: 0,
  serviceActionProcessTypes: '',
  nodeName: '',
  serviceStatusName: '',
  isUserCheckedIn: false,
};

export const SAMPLE_SERVICE_LOG = {
  id: null,
  notes: ''
};

export const BILLABLE_TYPES = [
  { label: 'None', value: '' },
  { label: 'Annuity', value: 'annuity' },
  { label: 'Billable', value: 'billable' },
  { label: 'Duplicate', value: 'duplicate' },
  { label: 'Not Billable', value: 'notbillable' }
];

export const linkedFieldItems = [
  { value: 'actionType', label: 'Action Type' },
  { value: 'processType', label: 'Process Type' },
  { value: 'referenceNo1', label: 'Reference No 1' },
  { value: 'referenceNo2', label: 'Reference No 2' },
  { value: 'referenceNo3', label: 'Reference No 3' },
  { value: 'userDepotAllocation', label: 'User/Depot Allocation' },
  { value: 'secondaryUserDepotAllocation', label: 'Secondary User/Depot Allocation' },
  { value: 'scheduledDateTime', label: 'Scheduled Date/Time' },
  { value: 'followUpDateTime', label: 'Follow up Date/Time' },
  { value: 'notes', label: 'Notes' },
  { value: 'status', label: 'Status' },
  { value: 'parentServiceID', label: 'Parent Service Id' }
];

export const SERVICE_IMPORT_TOGGLE_BUTTONS = [
  {
    id: 1,
    toggleName: 'Show Error records'
  },
  {
    id: 2,
    toggleName: 'Show Createable records'
  }
];

export const SERVICE_IMPORT_TOGGLE_BUTTON_VALUES = [
  {
    id: 1,
    value: true
  },
  {
    id: 2,
    value: true
  },
];

export const SERVICE_IMPORT_DATA = {
  fileName: '',
  fileSize: 0,
  fileId: 0,
  sessionId: 0,
  preprocessingStatus: '',
  processingStatus: '',
  preprocessingErrorMessage: '',
  processingErrorMessage: ''
};

export const SERVICE_ATTACHMENT_FILE_URL_DATA = {
  key: '',
  mimeType: '',
  contentDispositionType: '',
  fileName: ''
};

// Regular expression to match "hh:mm" format
export const REGEX_FOR_HHMM = /^([0-9]|[0-9][0-9]):([0-5][0-9]|[0-9][0-9])$/;
export const INVALID_TIME_FORMAT_HELPER_TEXT = 'Invalid time format (hh:mm)';

export const columnSelectionItems = [
  {
    title: ServiceListColumnItemTitle.Service,
    value: [
      {
        field: 'serviceId',
        title: 'Service ID',
        value: true
      },
      {
        field: 'serviceTypeName',
        title: 'Service Type Name',
        value: true
      },
      {
        field: 'serviceTypeCode',
        title: 'Service Type Code',
        value: false
      },
      {
        field: 'actionTypeName',
        title: 'Action Type',
        value: true
      },
      {
        field: 'processTypeName',
        title: 'Process Type',
        value: true
      },
      {
        field: 'serviceParams',
        title: 'Service Params',
        value: false
      },
      {
        field: 'clientHierarchy',
        title: 'Client',
        value: true
      },
      {
        field: 'clientName',
        title: 'Client Name',
        value: false
      },
      {
        field: 'clientCode',
        title: 'Client Code',
        value: false
      },
      {
        field: 'contractName',
        title: 'Contract',
        value: false
      },
      {
        field: 'contractCode',
        title: 'Contract Code',
        value: false
      },
      {
        field: 'brandName',
        title: 'Brand',
        value: false
      },
      {
        field: 'priorityCode',
        title: 'Priority',
        value: true
      },
      {
        field: 'priorityDescription',
        title: 'Priority Description',
        value: false
      },
      {
        field: 'reportedSerial',
        title: 'Reported Serial',
        value: false
      },
      {
        field: 'reportedModel',
        title: 'Reported Model',
        value: false
      },
      {
        field: 'reportedModelSerial',
        title: 'Reported Model/Serial',
        value: false
      },
      {
        field: 'reportedFault',
        title: 'Reported Fault',
        value: false
      },
      {
        field: 'reportedFaultDescription',
        title: 'Reported Fault Description',
        value: false
      },
      {
        field: 'reportedFaultDescriptionCombine',
        title: 'Reported Fault/Description',
        value: false
      },
      {
        field: 'creationNotes',
        title: 'Creation Notes',
        value: false
      },
      {
        field: 'createdAt',
        title: 'Created At',
        value: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        value: false
      },
      {
        field: 'reference1',
        title: 'Reference #1',
        value: true
      },
      {
        field: 'reference2',
        title: 'Reference #2',
        value: false
      },
      {
        field: 'reference3',
        title: 'Reference #3',
        value: false
      },
      {
        field: 'tags',
        title: 'Tags',
        value: false
      }
    ]
  },
  {
    title: ServiceListColumnItemTitle.StatuAndAllocation,
    value: [
      {
        field: 'serviceStatusName',
        title: 'Status Name',
        value: true
      },
      {
        field: 'primaryAllocation',
        title: 'Primary Allocation',
        value: true
      },
      {
        field: 'primaryAllocationNode',
        title: 'Primary Allocation Node Name',
        value: false
      },
      {
        field: 'secondaryAllocation',
        title: 'Secondary Allocation',
        value: false
      },
      {
        field: 'secondaryAllocationNode',
        title: 'Secondary Allocation Node Name',
        value: false
      },
      {
        field: 'scheduledDate',
        title: 'Scheduled Date',
        value: false
      },
      {
        field: 'followUpDate',
        title: 'Follow Up Date',
        value: false
      },
      {
        field: 'updatedAt',
        title: 'Last Updated At',
        value: false
      },
      {
        field: 'updatedBy',
        title: 'Last Updated By',
        value: false
      },
      {
        field: 'lastUpdatedNote',
        title: 'Last Updated Notes',
        value: false
      },
      {
        field: 'specialScheduled',
        title: 'Special Scheduled',
        value: false
      }
    ]
  },
  {
    title: ServiceListColumnItemTitle.Structure,
    value: [
      {
        field: 'locationName',
        title: 'Location Name',
        value: true
      },
      {
        field: 'structureNodeName',
        title: 'Structure Node Name',
        value: true
      },
      {
        field: 'structureNodeId',
        title: 'Structure Node ID',
        value: false
      },
      {
        field: 'structureNodeNotes',
        title: 'Structure Node Notes',
        value: false
      },
      {
        field: 'subLocationName',
        title: 'Sublocation Name',
        value: true
      },
      {
        field: 'subLocationId',
        title: 'Sublocation ID',
        value: false
      },
      {
        field: 'serviceAddress',
        title: 'Service Address',
        value: false
      },
      {
        field: 'mainAddress',
        title: 'Main Address',
        value: false
      },
      {
        field: 'logisticsAddress',
        title: 'Logistics Address',
        value: false
      },
      {
        field: 'regionType',
        title: 'Region Type',
        value: false
      },
      {
        field: 'serviceContact',
        title: 'Service Contact',
        value: false
      },
      {
        field: 'mainContact',
        title: 'Main Contact',
        value: true
      },
      {
        field: 'nodeReferences',
        title: 'Node Reference Numbers',
        value: false
      },
      {
        field: 'nodeSiteSchema',
        title: 'Node Site Schema',
        value: false
      },
      {
        field: 'tradingHours',
        title: 'Trading Hours',
        value: false
      }
    ]
  },
  {
    title: ServiceListColumnItemTitle.ReportedPart,
    value: [
      {
        field: 'partTypeId',
        title: 'Part Type ID',
        value: false
      },
      {
        field: 'partTypeName',
        title: 'Part Type Name',
        value: false
      },
      {
        field: 'partNo',
        title: 'Part No.',
        value: false
      },
      {
        field: 'partSerial1',
        title: 'Serial 1',
        value: false
      },
      {
        field: 'partSerial2',
        title: 'Serial 2',
        value: false
      },
      {
        field: 'partSerial3',
        title: 'Serial 3',
        value: false
      },
      {
        field: 'softwareVersion',
        title: 'Software Ver',
        value: false
      },
      {
        field: 'firmwareVersion',
        title: 'Firmware Ver',
        value: false
      },
      {
        field: 'hardwareRevision',
        title: 'Hardware Rev',
        value: false
      },
      {
        field: 'fleetTag',
        title: 'Fleet Tag',
        value: false
      }
    ]
  },
  {
    title: ServiceListColumnItemTitle.LinkedRecords,
    value: [
      {
        field: 'linkedServices',
        title: 'Linked Service #',
        value: false
      },
      {
        field: 'linkedShipments',
        title: 'Linked Shipments',
        value: false
      },
      {
        field: 'linkedShipmentConnotes',
        title: 'Linked Shipments Connotes',
        value: false
      }
    ]
  },
  {
    title: ServiceListColumnItemTitle.Progress,
    value: [
      {
        field: 'daysOpen',
        title: 'Days Open',
        value: false
      },
      {
        field: 'closedAt',
        title: 'Closed At',
        value: false
      },
      {
        field: 'attendanceCount',
        title: '# of Attendances',
        value: false
      },
      {
        field: 'reopensCount',
        title: '# of Re-opens',
        value: false
      },
      {
        field: 'uniqueTechniciansCount',
        title: '# of Unique Technicians',
        value: false
      },
      {
        field: 'observedFault',
        title: 'Observed Fault',
        value: false
      },
      {
        field: 'observedFaultDescription',
        title: 'Observed Fault Description',
        value: false
      },
      {
        field: 'solutionAction',
        title: 'Solution Action',
        value: false
      },
      {
        field: 'solutionActionPartType',
        title: 'Solution Action Part Type Name',
        value: false
      },
      {
        field: 'partActions',
        title: 'Part Actions',
        value: false
      },
      {
        field: 'attachments',
        title: 'Attachments',
        value: false
      }
    ]
  },
  {
    title: ServiceListColumnItemTitle.SLA,
    value: [
      {
        field: 'clientSLASummary',
        title: 'Client SLA Summary',
        value: false
      },
      {
        field: 'opsSLASummary',
        title: 'Ops SLA Summary',
        value: false
      }
    ]
  },
  {
    title: ServiceListColumnItemTitle.Interactions,
    value: [
      {
        field: 'surveyColumns',
        title: 'Survey columns',
        value: false
      },
      {
        field: 'partsPending',
        title: 'Parts Pending',
        value: false
      }
    ]
  },
  {
    title: ServiceListColumnItemTitle.BillingAndAdmin,
    value: [
      {
        field: 'reviewed',
        title: 'Reviewed',
        value: false
      },
      {
        field: 'billableType',
        title: 'Billable Type',
        value: false
      },
      {
        field: 'clientInvoiced',
        title: 'Client Invoiced',
        value: false
      },
      {
        field: 'billingNotes',
        title: 'Billing Notes',
        value: false
      },
      {
        field: 'internalInvoices',
        title: 'Internal Invoices',
        value: false
      },
      {
        field: 'externalInvoices',
        title: 'External Invoices',
        value: false
      }
    ]
  },
  {
    title: ServiceListColumnItemTitle.Misc,
    value: [
      {
        field: 'allNotes',
        title: 'All Notes',
        value: false
      },
      {
        field: 'importSessionId',
        title: 'Import Session ID',
        value: false
      }
    ]
  }
];

export const SERVICE_DISPATCH_SHIPMENT_DATA = {
  openFragment: false,
  serviceId: 0,
  mainLocation: '',
  mainLocationNodeId: 0,
  exited: true,
  isFromServiceEdit: true
};

export const SERVICE_BILLING_DATA = {
  billingTypeCode: '',
  isReviewed: false,
  isClientInvoiced: false,
  billingNotes: '',
  privateNotes: '',
  invoices: []
};

export const SERVICE_EDIT_CAROUSEL_CONFIG = {
  arrows: true,
  speed: 500,
  slidesToShow: 1,
  centerMode: true,
  focusOnSelect: true
};

export const BULK_SERVICE_EDIT = {
  actionType: '-1',
  processType: '-1',
  referenceNo1: '',
  referenceNo2: '',
  referenceNo3: '',
  userDepotAllocation: '',
  secondaryUserDepotAllocation: '',
  userDepotAllocationName: '',
  secondaryUserDepotAllocationName: '',
  scheduledDateTime: '',
  followUpDateTime: '',
  notes: '',
  status: '-1',
  parentServiceId: '',
  linkedField: '-1'
};

export const BULK_SERVICE_EDIT_CHECK = {
  isCheckActionType: false,
  isCheckProcessType: false,
  isCheckReferenceNo1: false,
  isCheckReferenceNo2: false,
  isCheckReferenceNo3: false,
  isCheckUserDepotAllocation: false,
  isCheckSecondaryUserDepotAllocation: false,
  isCheckScheduledDateTime: false,
  isCheckFollowUpDateTime: false,
  isCheckNotes: false,
  isCheckStatus: false,
  isCheckParentServiceId: false,
  isCheckLinkedField: false
};

export const BULK_SERVICE_EDIT_CHECK_EMPTY = {
  isEmptyActionType: false,
  isEmptyProcessType: false,
  isEmptyUserDepotAllocation: false,
  isEmptySecondaryUserDepotAllocation: false,
  isEmptyScheduledDateTime: false,
  isEmptyFollowUpDateTime: false,
  isEmptyNotes: false,
  isEmptyStatus: false,
  isEmptyLinkedField: false
};