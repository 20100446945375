import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  ServiceSummary,
  ServiceSummaryData,
  ServiceSummaryEditPermissions
} from '../../../../components/templates/ServiceSummary';
import { ServiceSummaryFragmentProps } from './ServiceSummaryFragment.props';
import { INIT_SUMMARY_EDIT_PERMISSIONS } from '../../../../constants/service.constant';
import { useGetUserSystemPermissions } from '../../../../queries/user-query';
import { isUserHasPermission } from '../../../../configs/permissions';
import { ActionPermissions, OutcomeTypeCode, PriorityType, ServiceSummaryObject } from '../../../../@types';
import { formatDate, getDateFromTimestamp, getTimestamp } from '../../../../utils/common';
import { useGetServiceActionProcessTypes, useUpdateService } from '../../../../queries/service-query';
import { NodeAttributeType, ServiceCreationRuleTypes } from '../../../../configs/enums';
import { useGetFilteredNodeAttributesByCustomRange } from '../../../../queries/structure-query';
import { Snackbar } from '../../../../components/atoms/Snackbar';
import { Alert } from '@mui/material';
import { SAMPLE_SERVICE_SUMMARY } from '../../../../constants/service.constant';

export const ServiceSummaryFragment: React.FC<ServiceSummaryFragmentProps> = ({
  data
}) => {
  const activeOnly = true;
  const [id, setId] = useState(0);
  const [priority, setPriority] = useState<PriorityType[]>([]);
  const [parentId, setParentId] = useState('');
  const [nodeAttributeTypes] = useState<NodeAttributeType[]>([
    NodeAttributeType.PriorityAndServices,
    NodeAttributeType.Models,
    NodeAttributeType.FaultCategory,
    NodeAttributeType.FaultCode
  ]);
  const [serviceTypeCode, setServiceTypeCode] = useState('');
  const [requiredFields, setRequiredFields] = useState<any>(Array);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [summaryEditPermissions, setSummaryEditPermissions] = useState<ServiceSummaryEditPermissions>(INIT_SUMMARY_EDIT_PERMISSIONS);
  const [serviceSummary, setServiceSummary] = useState<ServiceSummaryData>(SAMPLE_SERVICE_SUMMARY);

  useEffect(() => {
    if (data) {
      setServiceSummary({
        ...data,
        loggedAt: getDateFromTimestamp(data.createdAt),
        mainLocationId: data?.mainLocationNodeId,
        serviceType: data.serviceTypeCode,
        actionType: data.actionTypeCode,
        processType: data.processTypeCode,
        clientSLA: getDateFromTimestamp(data.clientSLA?.timeoutAt || ''),
        opsSLA: getDateFromTimestamp(data.opsSLA?.timeoutAt || ''),
        scheduled: formatDate(data?.scheduledAt, ''),
        followUp: getDateFromTimestamp(data?.followUpAt || ''),
        isClientSLABreached: data.clientSLA?.slaOutcome?.outcomeTypeCode === OutcomeTypeCode.Breached ? true : false,
        isOpsSLABreached: data.opsSLA?.slaOutcome?.outcomeTypeCode === OutcomeTypeCode.Breached ? true : false
      });
      setParentId(data.parentId);
      setServiceTypeCode(data.serviceTypeCode);
      setId(data.id);
    }
  }, [data]);

  const updateServiceQuery = useUpdateService(id);
  const getUserSystemPermissionsQuery = useGetUserSystemPermissions();
  const getServiceActionProcessTypesQuery = useGetServiceActionProcessTypes(activeOnly);
  const getNodeAttributesByCustomRange = useGetFilteredNodeAttributesByCustomRange(parentId, nodeAttributeTypes, serviceTypeCode);

  useEffect(() => {
    if (parentId && nodeAttributeTypes && serviceTypeCode) {
      getNodeAttributesByCustomRange.refetch();
    }
  }, [parentId, nodeAttributeTypes, serviceTypeCode]);

  useEffect(() => {
    if (getNodeAttributesByCustomRange.data) {
      setPriority(getNodeAttributesByCustomRange.data.filter((nodeAttribute: any) => nodeAttribute.propertyTypeCode === NodeAttributeType.PriorityAndServices));
    }
  }, [getNodeAttributesByCustomRange.data]);

  useEffect(() => {
    if (serviceSummary.selectedServiceCreationTypeValue) {
      const isReference1Required = serviceSummary.selectedServiceCreationTypeValue.some((attribute: any) => attribute === ServiceCreationRuleTypes.Reference1);
      const isNoteRequired = serviceSummary.selectedServiceCreationTypeValue.some((attribute: any) => attribute === ServiceCreationRuleTypes.Notes);

      setRequiredFields({
        ...requiredFields,
        isReference1Required: isReference1Required,
        reference1RequiredMessage: isReference1Required ? 'Reference 1' : '',
        isNoteRequired: isNoteRequired,
        noteRequiredMessage: isNoteRequired ? 'Note' : '',
      });
    }
  }, [data]);

  useEffect(() => {
    const permissions = getUserSystemPermissionsQuery.data;
    if (permissions) {
      const isGeneralPermission = isUserHasPermission(ActionPermissions.Service_Edit_Service_Parameters_General, permissions);
      const isProcessActionPermission = isUserHasPermission(ActionPermissions.Service_Edit_Service_Parameters_Process_Action, permissions);
      const isAdvancedPermission = isUserHasPermission(ActionPermissions.Service_Edit_Service_Parameters_Advanced, permissions);

      const hasClientSLAViewPermission = isUserHasPermission(ActionPermissions.Service_SLA_Visibility_Client, permissions);
      const hasOpsSLAViewPermission = isUserHasPermission(ActionPermissions.Service_SLA_Visibility_OPS_Field, permissions) || isUserHasPermission(ActionPermissions.Service_SLA_Visibility_OPS_Helpdesk, permissions) || isUserHasPermission(ActionPermissions.Service_SLA_Visibility_OPS_Logistics, permissions);

      setSummaryEditPermissions({
        ...summaryEditPermissions,
        isScheduled: isGeneralPermission,
        isFollowUp: isGeneralPermission,
        isReferenceNo1: isGeneralPermission,
        isSubLocation: isGeneralPermission,
        isProcessType: isProcessActionPermission,
        isActionType: isProcessActionPermission,
        isPriority: isAdvancedPermission,
        isServiceType: isAdvancedPermission,
        isClientSLA: hasClientSLAViewPermission,
        isOpsSLA: hasOpsSLAViewPermission,
      });
    }
  }, [getUserSystemPermissionsQuery.data, data]);

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleServiceSummaryChange = async (val: ServiceSummaryData) => {
    if (requiredFields.isReference1Required && !val.referenceNo1) {
      setOpenSnackbar(true);
      setSnackbarMessage(requiredFields.reference1RequiredMessage);
      return;
    }

    let updateServiceSummery: ServiceSummaryObject = {
      id: data.id,
      serviceTypeCode: val.serviceType,
      serviceStatusCode: data.serviceStatus.code,
      actionTypeCode: val.actionType,
      processTypeCode: val.processType,
      nodeId: data.nodeId,
      priorityCode: val.priorityId?.toString(),
      referenceNo1: val.referenceNo1,
      subLocationNodeId: val.subLocationId || data.subLocationNodeId
    };

    if (val.scheduled && val.scheduled !== '-') {
      updateServiceSummery = { ...updateServiceSummery, scheduledAt: formatDate(val.scheduled) };
    }

    if (val.followUp && val.followUp !== '-') {
      updateServiceSummery = { ...updateServiceSummery, followUpAt: getTimestamp(val.followUp) };
    }

    await updateServiceQuery.mutateAsync(updateServiceSummery);

    setServiceTypeCode(val.serviceType || '');
    setServiceSummary(val);
  };

  return (
    <>
      <ServiceSummary
        permissions={summaryEditPermissions}
        options={{
          serviceOptions: getServiceActionProcessTypesQuery.data?.serviceTypes,
          actionTypeOptions: getServiceActionProcessTypesQuery.data?.actionTypes,
          processTypeOptions: getServiceActionProcessTypesQuery.data?.processTypes,
          priorityAndServicesOptions: priority
        }}
        value={serviceSummary}
        onChange={handleServiceSummaryChange}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert severity="error" onClose={handleClose}>{snackbarMessage + ' cannot be empty'}</Alert>
      </Snackbar>
    </>
  );
};