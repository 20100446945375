import React, { useCallback, useEffect, useState } from 'react';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { GridFeatureModeConstant, GridFilterModel, GridRenderCellParams, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { Edit } from '@mui/icons-material';
import { StyledBox } from './ReplenishmentConfigList.styles';
import { useGetReplenishmentConfigs } from '../../../queries/replenishmentConfig-query';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { Modal } from '../../../components/atoms/Modal';
import { AddEditReplenishmentConfigFragment } from '../AddEditReplenishmentConfigFragment';
import { ReplenishmentConfigListResponse } from '../../../@types/replenishmentConfig.type';
import { CustomDataGrid } from '../../../components/layouts/CustomDataGrid';
import { ColumnSelectionItem } from '../../../components/templates/ColumnSelection';
import { GridContainer } from '../../../styled/common.styles';
import { CloseIcon } from '../../../components/atoms/CloseIcon';

export const ReplenishmentConfigList: React.FC = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [replenishmentConfigId, setReplenishmentConfigId] = useState<number>(0);
  const [replenishmentConfigs, setReplenishmentConfigs] = useState<ReplenishmentConfigListResponse>();
  const [open, setOpen] = useState(false);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [{ columnField: 'isActive', operatorValue: 'is', value: true }] });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const getAllReplenishmentConfigs = useGetReplenishmentConfigs({
    page,
    pageSize,
    filterModel,
    sortModel
  });

  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'ID',
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value.toString()
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.name
    },
    {
      field: 'clientContract',
      headerName: 'Client/Contract',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.clientContract
    },
    {
      field: 'locationName',
      headerName: 'Location',
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.locationName
    },
    {
      field: 'partTypeOrSkuName', 
      headerName: 'Part Type/Sku', 
      width: 200,
      valueGetter: (params: GridValueGetterParams) => params.row.partTypeOrSkuName
    },
    {
      field: 'min', 
      headerName: 'Min',
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value?.toString(),
      valueGetter: (params: GridValueGetterParams) => params.row.min
    },
    {
      field: 'max', 
      headerName: 'Max',
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value?.toString(),
      valueGetter: (params: GridValueGetterParams) => params.row.max
    },
    {
      field: 'includeChildNode', 
      headerName: 'Include Child Nodes',
      width: 200,
      type: 'singleSelect',
      valueOptions: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' }
      ],
      valueGetter: (params: GridValueGetterParams) => params.row.includeChildNode ? 'Yes' : 'No'
    },
    {
      field: 'isActive', 
      headerName: 'Active',
      width: 150,
      type: 'singleSelect',
      valueOptions: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' }
      ],
      valueGetter: (params: GridValueGetterParams) => params.row.isActive ? 'Yes' : 'No'
    },
    {
      field: 'edit',
      filterable: false,
      sortable: false,
      headerName: '', 
      renderCell: (params: GridRenderCellParams) => (
        <Button 
          variant={ButtonVariantProps.Icon} 
          onClick={() => {
            setReplenishmentConfigId(params.row.id);
            setOpen(true);
          }}>
          <Edit />
        </Button>)
    },
  ]);

  useEffect(() => {
    getAllReplenishmentConfigs.data && setReplenishmentConfigs(getAllReplenishmentConfigs.data);
  }, [getAllReplenishmentConfigs.data]);

  useEffect(() => {
    if (page || pageSize) {
      getAllReplenishmentConfigs.refetch();  
    }
  }, [page, pageSize, filterModel, sortModel]);

  const columnSelectionItems: Array<ColumnSelectionItem> = [
    {
      title: 'Replenishment Config',
      value: [
        {
          field: 'id',
          title: 'ID',
          value: true
        },
        {
          field: 'name',
          title: 'Name',
          value: true
        },
        {
          field: 'clientContract',
          title: 'Client/Contract',
          value: true
        },
        {
          field: 'locationName',
          title: 'Location',
          value: true
        },
        {
          field: 'partTypeOrSkuName', 
          title: 'Part Type/Sku', 
          value: true
        },
        {
          field: 'min', 
          title: 'Min', 
          value: true
        },
        {
          field: 'max', 
          title: 'Max',   
          value: true
        },
        {
          field: 'includeChildNode', 
          title: 'Include Child Nodes',
          value: true
        },
        {
          field: 'isActive', 
          title: 'Active', 
          value: true
        }
      ]
    }
  ];

  const handleClose = (event?: any, reason?: string) => {
    if (reason !== 'backdropClick') setOpen(false);
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setFilterModel({ ...filterModel });
  }, []);
  
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  return (
    <GridContainer>
      <CustomDataGrid
        columns={columns}
        rows={replenishmentConfigs?.data || []}
        rowCount={replenishmentConfigs?.total}
        columnSelectionItems={columnSelectionItems}
        filterMode={GridFeatureModeConstant.server}
        enableCSVExport={false}
        exportFileName={''}
        filterModel={filterModel}
        onFilterModelChange={(filterModel: GridFilterModel) => {
          onFilterChange(filterModel);
        }}
        onSortModelChange={handleSortModelChange}
        loading={getAllReplenishmentConfigs.isLoading}
        rowsPerPageOptions={[25, 50, 100]}
        pagination={true}
        page={page}
        pageSize={pageSize}
        paginationMode={GridFeatureModeConstant.server}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        initialState={{
          columns: {
            columnVisibilityModel: {
              parentNodeId: false,
              referenceNumbers: false,
              status: false,
            },
          },
        }}
        componentsProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true }
          }
        }}
        getRowHeight={() => 'auto'}
        setColumns={setColumns}
      />
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledBox>
          <CloseIcon handleClose={handleClose} />
          <AddEditReplenishmentConfigFragment 
            isOpen={open}
            isEdit={true}
            replenishmentConfigId={replenishmentConfigId}
            onClose={handleClose}
          />
        </StyledBox>
      </Modal>
    </GridContainer>
  );
};