import React, { useEffect, useState } from 'react';
import { CardActions, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { ButtonColorProps, ButtonVariantProps } from '../../../../../components/atoms/Button';
import { TaskTimeCardProps } from './TaskTimeCard.props';
import {
  DefaultCard,
  StyledCardContent,
  CardTitle,
  CloseWrapper,
  StyledButton
} from './TaskTimeCard.styles';
import { ServiceTaskTime, ServiceTaskTimeHelperTextObject, ServiceTaskTimeObject } from '../../../../../components/molecules/ServiceTaskTime';
import { INVALID_TIME_FORMAT_HELPER_TEXT, REGEX_FOR_HHMM } from '../../../../../constants/service.constant';
import { convertHyphenTextToReadText } from '../../../../../utils/common';
import { TaskType } from '../../../../../@types/task.type';
import { SAMPLE_TASK_TIME_CARD_HELPERTEXT } from '../../../../../constants/task';
import { COLORS } from '../../../../../configs/colors';

export const TaskTimeCard: React.FC<TaskTimeCardProps> = ({
  data,
  onChange,
  onSave,
  onExec,
  onClose
}) => {
  const [serviceTaskTimeData, setServiceTaskTimeData] = useState<ServiceTaskTimeObject>({
    duration: data.taskData?.duration || '',
    notes: data.taskData?.notes || '',
    adjustValue: data.taskTypeCode === TaskType.LOADING_TIME ? 1 : 15
  });

  const [helperTextData, setHelperTextData] = useState<ServiceTaskTimeHelperTextObject>(SAMPLE_TASK_TIME_CARD_HELPERTEXT);

  useEffect(() => {
    const regex = REGEX_FOR_HHMM;
    if (!regex.test(serviceTaskTimeData.duration)) setHelperTextData({ ...helperTextData, durationHelperText: INVALID_TIME_FORMAT_HELPER_TEXT });
    else {
      setHelperTextData({ ...helperTextData, durationHelperText: '' });
      onChange({
        ...data, taskData: {
          duration: serviceTaskTimeData.duration,
          notes: serviceTaskTimeData.notes
        }
      });
    }
  }, [serviceTaskTimeData]);

  return (
    <DefaultCard>
      <StyledCardContent>
        <CardTitle>{convertHyphenTextToReadText(data.taskTypeCode)}</CardTitle>
        <CloseWrapper onClick={onClose}>
          <CloseRounded htmlColor={COLORS.White} />
        </CloseWrapper>
        <Stack direction="column" spacing={1.5} width="100%">
          <Stack direction="row" justifyContent="space-between" spacing={1} width="100%">
            <Stack direction="column" spacing={2} marginTop={1} width="100%">
              <ServiceTaskTime
                serviceTaskTimeData={{
                  duration: data.taskData?.duration || '',
                  notes: data.taskData?.notes || '',
                  adjustValue: data.taskTypeCode === TaskType.LOADING_TIME ? 1 : 15
                }}
                helperTextData={helperTextData}
                onChange={(serviceTaskTimeObject: ServiceTaskTimeObject) => setServiceTaskTimeData(serviceTaskTimeObject)}
              />
            </Stack>
          </Stack>
        </Stack>
      </StyledCardContent>
      <CardActions>
        <Stack direction="row-reverse" spacing={1} width="100%">
          <StyledButton
            variant={ButtonVariantProps.Primary}
            color={ButtonColorProps.Error}
            onClick={onExec}
          >
            Exec
          </StyledButton>
          <StyledButton
            variant={ButtonVariantProps.Primary}
            color={ButtonColorProps.Success}
            onClick={onSave}
          >
            {data.isNew ? 'Save' : 'Update'}
          </StyledButton>
        </Stack>
      </CardActions>
    </DefaultCard>
  );
};