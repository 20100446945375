import { Grid, styled } from '@mui/material';
import { TextField } from '../../atoms/TextField';

export const StyledGrid = styled(Grid)`
  background-color: whitesmoke;
  padding-bottom: 10px;
  margin-bottom: 5px;
`;

export const StyledTextField = styled(TextField)`
  width: 400px;
  min-width: 400px;
`;