import { useQuery, UseQueryResult, useQueryClient, UseMutationResult, useMutation } from '@tanstack/react-query';
import { TaskTypeKey } from '../configs/task';
import { createAndUpdateTask, createTaskServiceLogByApprove, deleteTaskById, getTaskByServiceId } from '../api/task';
import { TaskCard } from '../@types/task.type';
import { ServiceLogAllKey } from '../configs/services';

export const useGetTaskByServiceId = (serviceId: number): UseQueryResult<TaskCard[]> => {
  return useQuery([TaskTypeKey, serviceId], () => getTaskByServiceId(serviceId), { enabled: false });
};

export const useCreateAndUpdateTask = (serviceId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createAndUpdateTask(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([TaskTypeKey, serviceId]);
      await queryClient.invalidateQueries([ServiceLogAllKey, serviceId]);
    },
  });
};

export const useCreateTaskServiceLogByApprove = (serviceId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await createTaskServiceLogByApprove(data.id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceLogAllKey, serviceId]);
    },
  });
};

export const useDeleteTaskById = (serviceId: number): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (data: any) => {
    return await deleteTaskById(data.id);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([TaskTypeKey, serviceId]);
    },
  });
};