import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { LiveSearchBox, LiveSearchListItem } from '../../atoms/LiveSearchBox';
import { PartTypeActionSearchItem } from '../PartTypeActionSearchItem';
import { PartTypeActionSearchProps } from './PartTypeActionSearch.props';
import { LiveSearchBoxWrapper } from './PartTypeActionSearch.styles';
import { useSearchPartsOrPartTypes } from '../../../queries/live-search-query';
import { ActionPermissions, CustomPermission, PartTypeActionLiveSearchItem, PartTypeActionLiveSearchItemCategoryCode } from '../../../@types';
import { Action, PartActionType } from '../../../@types/partAction.type';
import { PartLocationType } from '../../../@types/part.type';
import { isUserHasPermission } from '../../../configs/permissions';
import { useGetUserSystemPermissions } from '../../../queries/user-query';

export const PartTypeActionSearch: React.FC<PartTypeActionSearchProps> = ({
  title,
  serialiseType = '',
  serviceData,
  partTypeId = '',
  value,
  locationId,
  partActionType,
  error,
  helperText,
  action,
  searchType,
  onChange
}) => {
  const [permissions, setPermissions] = useState<CustomPermission[]>();

  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const searchPartsOrPartTypesQuery = useSearchPartsOrPartTypes();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  return (
    <Stack direction="row" alignItems="center" spacing={1} width="100%">
      <LiveSearchBoxWrapper>
        <LiveSearchBox
          title={title}
          timeOffset={400} 
          value={value}
          isError={error}
          helperText={helperText}
          onClearValue={() => ''}
          feature="part-action"
          onChange={(val) => onChange(val)}
          renderItem={(props: any, option: PartTypeActionLiveSearchItem) => { 
            if (option.isPartCreation && isUserHasPermission(ActionPermissions.Service_Edit_Part_Actions_Exceptional_Creation, permissions)){
              return (
                <LiveSearchListItem {...props}>
                  <PartTypeActionSearchItem
                    name={option.name}
                    serial={''}
                    partTypeId={0}
                    partId={''}
                    nsPartId={''}
                    category={''}
                    isCreateAction={true}
                  />
                </LiveSearchListItem>
              );
            }

            return (
              <LiveSearchListItem {...props}>
                <PartTypeActionSearchItem 
                  name={option.partTypeName}
                  serial={option.serial1}
                  partTypeId={option.partTypeId}
                  partId={option?.partId?.toString() || ''}
                  nsPartId={option?.ns_partId?.toString() || ''}
                  category={option.categoryCode}
                />
              </LiveSearchListItem>
            );
          }}
          onApiInvoke={async (name) => { 
            let allowedPartLocationType = '';
            const clientContractIds: Array<number> = [serviceData?.clientId || 0, serviceData?.contractId || 0];
            if (partActionType === PartActionType.REPLACE || partActionType === PartActionType.REMOVE || partActionType === PartActionType.DOA) {
              serialiseType = PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent;
            }

            if (partActionType === PartActionType.INSTALL || action === Action.IN) {
              allowedPartLocationType = PartLocationType.Node;
            }

            const inputValue = name.replace('#', '%23');
            const results = await searchPartsOrPartTypesQuery.mutateAsync({
              name: inputValue,
              serialiseType,
              partTypeId,
              clientContractIds: clientContractIds,
              locationId: locationId ? locationId : '',
              allowedPartLocationType,
              partActionType,
              searchType
            });

            let options: PartTypeActionLiveSearchItem[] = [];

            if (isUserHasPermission(ActionPermissions.Service_Edit_Part_Actions_Exceptional_Creation, permissions) && results.length == 0) {
              options.push({
                name: inputValue,
                partTypeId: 0, 
                partTypeName: '', 
                categoryCode: PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice, 
                conditionCode: '',
                serial1: '', 
                softwareVersion: '', 
                hardwareVersion: '', 
                firmwareVersion: '',
                fleetTagName: '', 
                locationName: '', 
                locationId: 0, 
                serviceId: 0,
                isPartCreation: true
              });
            }

            if (searchType === 'part') {
              options = results?.map((result) => ({
                ...result,
                serial1: result.serial1,
                name: result.partTypeName
              })) || [];
  
              return options;
            } else if (searchType === 'part-type') {
              options = results?.map((result) => ({
                ...result,
                name: result.partTypeName
              })) || [];
            } else {
              results?.map((result) => {
                if (result.serial1) {
                  options.push({ ...result, serial1: result.serial1, name: '' });
                } else {
                  options.push({ ...result, name: result.partTypeName });
                }
              });
            }

            return options;
          }}
        />
      </LiveSearchBoxWrapper>
    </Stack>
  );
};