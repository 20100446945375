import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { HorizontalAlignProps, InlineGroup } from '../../../components/atoms/InlineGroup';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { InteractionsRulesList } from './RulesList';
import { CreateEditInteractionRuleFragment } from './CreateEditInteractionRuleFragment';
import { Wrapper } from '../../../styled/common.styles';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { isUserHasPermission } from '../../../configs/permissions';
import { ActionPermissions, CustomPermission } from '../../../@types';
import { useGetUserSystemPermissions } from '../../../queries/user-query';

export const InteractionsRules: React.FC = () => {
  const [permissions, setPermissions] = useState<CustomPermission[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  const addNewRule = () => {
    setIsOpen(!isOpen);
  };

  TabHandler(`Interaction Rules | ${PLATFORM_NAME}`);

  return(
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            Interaction Rules
          </Typography>
          {isUserHasPermission(ActionPermissions.Configure_Interactions_Edit_Rules, permissions) &&
            <Button variant={ButtonVariantProps.Primary} startIcon={<AddIcon />} onClick={() => addNewRule()}>
              Create Rule
            </Button>
          }
          {isOpen && <CreateEditInteractionRuleFragment
            openModal={isOpen}
            handleClose={() => {
              setIsOpen(false);
            }}
          />}
        </InlineGroup>
        {permissions.length && <InteractionsRulesList canEdit={isUserHasPermission(ActionPermissions.Configure_Interactions_Edit_Rules, permissions)}/>}
      </Wrapper>
    </PageContainer>
  );
};