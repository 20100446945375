import { DropdownItem } from '../components/atoms/SelectMenu';
import { RegionType } from './regionType.type';
import { ActionType, ProcessType, ServiceType } from './service.type';

export interface SLARuleType extends SLAConfigData {
  onStatusChangeStatuses?: string[];
  targetTypeOnStatusChangeStatuses?: string[];
  selectedParenHierarchy?: string;
  selectedSlaTypeHierarchy?: string;
}

export interface SLAScope {
  code: string;
  name: string;
}

export interface SLATimerType {
  code: string;
  name: string;
}

export interface SLAType {
  code: string;
  name: string;
  timerTypeCode: string;
  timerTypeName: string;
}

export interface SLATargetType {
  code: string;
  name: string;
}

export interface SLAConfigMetaDataResponse {
  serviceTypes: ServiceType[];
  actionTypes: ActionType[];
  processTypes: ProcessType[];
  regionTypes: RegionType[];
  slaScopes: SLAScope[];
  slaTimerTypes: SLATimerType[];
  slaTargets: SLATargetType[];
}

export interface SLAConfigMetaData {
  serviceTypes: ServiceType[];
  actionTypes: ActionType[];
  processTypes: ProcessType[];
  regionTypeList: DropdownItem[];
  slaScopeList: DropdownItem[];
  slaTypeList: DropdownItem[];
  slaTargetTypeList: DropdownItem[];
}

interface SLATimer {
  type: string;
  days?: number;
  hours?: number;
  minutes?: number;
  cutoffTime? : string;
}

export interface SLAConfigData {
  id: number;
  name: string;
  notes: string;
  ph: boolean;
  isActive: boolean;
  serviceType: string[];
  actionType: string[];
  processType: string[];
  priority: string[];
  region: string[];
  scope: string;
  scopeName: string;
  targetType: string;
  serviceStatuses: ({ code: string; name: string }[])[];
  slaType: string;
  timer: SLATimer;
  nodeHierarchy: string;
  client: string[];
  contract: string[];
  brand?: string[];
  serviceTypeNames: string;
  processTypeNames: string;
  actionTypeNames: string;
  priorityNames: string;
  targetText: string;
  regionNames: string;
  slaTypeText: string;
  duration: string;
}

export interface OutcomeReason {
  id: number;
  name: string;
  outcomeTypeCode: string;
  reasonCategoryCode: string;
  isActive: boolean;
}

export interface OutcomeReview {
  id: number;
  outcomeTypeCode: string;
  outcomeReasonId: number;
  description: string;
  notes: string;
}

export interface SLARuleListObject {
  isName?: boolean;
  isSLAScope?: boolean;
  isClientContractBrand?: boolean;
  isServiceType?: boolean;
  isActionType?: boolean;
  isProcessType?: boolean;
  isPriority?: boolean;
  isRegion?: boolean;
  isTargetType?: boolean;
  isTargetTypeOnStatusChangeStatuses?: boolean;
  isSLATypeSelection?: boolean;
}

export enum OutcomeTypeCode {
  Reviewed = 'reviewed',
  Mitigated = 'mitigated',
  Breached = 'breached',
  Met = 'met'
}

export enum SLASnackbarText {
  Review = 'Review',
  Mitigation = 'Mitigation',
}

export interface SLADetailsHelperText {
  reviewNoteHelperText?: string;
  mitigationNoteHelperText?: string;
}