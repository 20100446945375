import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { TypographyVariantProps } from '../../atoms/Typography';
import { MultiClientSelectorProps } from './MultiClientSelector.props';
import { ContentWrapperBox, HeadingWrapper } from './MultiClientSelector.styles';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { MultiSelectCheckmarksMenu } from '../../atoms/MultiSelectCheckmarksMenu';
import { NodeType } from '../../../@types';
import { Modal } from '../../atoms/Modal';
import { CloseIcon } from '../../atoms/CloseIcon';

export const MultiClientSelector: React.FC<MultiClientSelectorProps> = ({
  open,
  onClose,
  clients,
  contracts,
  brands,
  handleParentSelection,
  onChangeNodeType,
  onChange,
  selectedMultiClients,
  selectedMultiContracts,
  selectedMultiBrands,
  hasBrandSelection
}) => {
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [selectedContracts, setSelectedContracts] = useState<string[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);

  useEffect(() => {
    selectedMultiClients && setSelectedClients(selectedMultiClients);
    selectedMultiContracts && setSelectedContracts(selectedMultiContracts);
    selectedMultiBrands && setSelectedBrands(selectedMultiBrands);
  }, [selectedMultiClients, selectedMultiContracts, selectedMultiBrands]);

  useEffect(() => {
    onChange && onChange(selectedClients, selectedContracts, selectedBrands, getClientHierarchy());
  }, [selectedClients, selectedContracts, selectedBrands]);

  const onSelectParent = () => {
    handleParentSelection(selectedClients, selectedContracts, getClientHierarchy(), selectedBrands);
    onChange && onChange(selectedClients, selectedContracts, selectedBrands);
    onClose();
  };

  const getClientHierarchy = () => {
    const clientString = clients.reduce((accumulator: string[], currentValue: any) => {
      if (selectedClients.includes(currentValue.value)) {
        return [...accumulator, currentValue.label];
      }
      return accumulator;
    }, []).join(', ');
    const contractString = contracts.reduce((accumulator: string[], currentValue: any) => {
      if (selectedContracts.includes(currentValue.value)) {
        return [...accumulator, currentValue.label];
      }
      return accumulator;
    }, []).join(', ');
    const brandString = brands?.reduce((accumulator: string[], currentValue: any) => {
      if (selectedBrands.includes(currentValue.value)) {
        return [...accumulator, currentValue.label];
      }
      return accumulator;
    }, []).join(', ');

    if (hasBrandSelection)
      return `${clientString || undefined} -> ${contractString || undefined} -> ${brandString || undefined}`;
    else
      return `${clientString || undefined} -> ${contractString || undefined}`;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ContentWrapperBox>
        <CloseIcon handleClose={onClose} />
        <HeadingWrapper variant={TypographyVariantProps.H6}>
          Client/Contract{hasBrandSelection && '/Brand'} Selection
        </HeadingWrapper>
        <Grid container width="100%" gap={2}>
          <Grid item xs>
            <MultiSelectCheckmarksMenu
              label="Select Clients"
              id="page-size-menu"
              items={selectedClients.includes('any') ? clients.filter((item) => item.value === 'any') : clients}
              selectedValue={selectedClients}
              onChange={(value) => {
                const values = value.split(',');
                setSelectedClients(value === '' ? [] : values);
                if (values.includes('any')) {
                  setSelectedClients(['any']);
                  setSelectedContracts(['any']);
                  setSelectedBrands(['any']);
                }
                if (values.length > 1){
                  setSelectedContracts(['any']);
                  setSelectedBrands(['any']);
                }
                if (value === ''){
                  setSelectedContracts([]);
                  setSelectedBrands([]);
                }
                onChangeNodeType && onChangeNodeType(NodeType.Contract, value);
              }}
            />
          </Grid>
          <Grid item xs>
            <MultiSelectCheckmarksMenu
              label="Select Contracts"
              id="page-size-menu"
              items={selectedContracts.includes('any') ? contracts.filter((item) => item.value === 'any') : contracts}
              disabled={selectedClients.length === 0 || selectedClients.length > 1 || selectedClients.includes('any')}
              selectedValue={selectedContracts}
              onChange={(value) => {
                const values = value.split(',');
                setSelectedContracts(value === '' ? [] : values);
                if (values.includes('any')) {
                  setSelectedContracts(['any']);
                  setSelectedBrands(['any']);
                }
                if (values.length > 1) setSelectedBrands(['any']);
                if (value === '') setSelectedBrands([]);
                onChangeNodeType && onChangeNodeType(NodeType.Brand, value);
              }}
            />
          </Grid>
          {hasBrandSelection && brands &&
            <Grid item xs>
              <MultiSelectCheckmarksMenu
                label="Select Brands"
                id="page-size-menu"
                items={selectedBrands.includes('any') ? brands.filter((item) => item.value === 'any') : brands}
                disabled={selectedContracts.length === 0 || selectedContracts.length > 1 || selectedContracts.includes('any')}
                selectedValue={selectedBrands}
                onChange={(value) => {
                  const values = value.split(',');
                  setSelectedBrands(value === '' ? [] : values);
                  if (values.includes('any')) {
                    setSelectedBrands(['any']);
                  }
                }}
              />
            </Grid>
          }
        </Grid>
        <Grid container mt={2} justifyContent="flex-end" gap={2}>
          <Grid>
            <Button variant={ButtonVariantProps.Secondary} onClick={onClose}>Cancel</Button>
          </Grid>
          <Grid>
            <Button variant={ButtonVariantProps.Primary} onClick={onSelectParent}>Select</Button>
          </Grid>
        </Grid>
      </ContentWrapperBox>
    </Modal>
  );
};
