import React, { useEffect, useState } from 'react';
import { Alert, CardActions, Grid, Stack } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { ButtonColorProps, ButtonVariantProps } from '../../../../../components/atoms/Button';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../../../../components/atoms/Typography';
import { TextField, TextFieldTypeProps } from '../../../../../components/atoms/TextField';
import { ImagePopup, ImagePopupSlide } from '../../../../../components/atoms/ImagePopup';
import { PartTypeActionSearch } from '../../../../../components/molecules/PartTypeActionSearch';
import { PartTypeActionSHFData } from '../../../../../components/molecules/PartTypeActionSHFData';
import { PartTypeActionNote } from '../../../../../components/molecules/PartTypeActionNote';
import { PartTypeLocationItemData } from '../../../../../components/molecules/PartTypeLocationItem';
import { PartLocationSelectPopup } from '../../../../../components/organisms/PartLocationSelectPopup';
import { useGetUploadedImages } from '../../../../../queries/uploadedfiles-query';
import { ActionPermissions, CustomPermission, PartTypeActionLiveSearchItem, PartTypeActionLiveSearchItemCategoryCode } from '../../../../../@types';
import { Folders } from '../../../../../@types/uploaded-files.type';
import { isEmptyString } from '../../../../../utils/common';
import { PartActionReplaceCardProps } from './PartActionReplaceCard.props';
import { 
  DefaultCard, 
  StyledCardContent,
  CardTitle,
  CloseWrapper,
  SHFDataWrapper,
  StyledButton,
  SelectButton
} from './PartActionReplaceCard.styles';
import { useGetPartLocationDataByServiceId } from '../../../../../queries/part-query';
import { isUserHasPermission } from '../../../../../configs/permissions';
import { useGetUserSystemPermissions } from '../../../../../queries/user-query';
import { Action, PartActionType, PartActionValidateType } from '../../../../../@types/partAction.type';
import { partActionConditionValidation, partActionInValidations } from '../../../../../utils/part-action';
import { useSubLocationsForCreateServices } from '../../../../../queries/live-search-query';
import { COLORS } from '../../../../../configs/colors';

export const PartActionReplaceCard: React.FC<PartActionReplaceCardProps> = ({
  service,
  value,
  helperText,
  onChange,
  onSave,
  onExec,
  onClose,
  setHelperText
}) => {
  const [isSearchMode, setIsSearchMode] = useState(true);
  const [isOutSearchMode, setIsOutSearchMode] = useState(true);
  const [isInSearchMode, setIsInSearchMode] = useState(true);
  const [isInSerialized, setIsInSerialized] = useState(false);
  const [isOutSerialized, setIsOutSerialized] = useState(false);
  const [showOutSerialSearch, setShowOutSerialSearch] = useState(false);
  const [showPartType, setShowPartType] = useState(true);
  const [showInSerialSearch, setShowInSerialSearch] = useState(false);
  const [isSelectInLocationPopup, setIsSelectInLocationPopup] = useState(false);
  const [outPartImages, setOutPartImages] = useState<Array<ImagePopupSlide>>([]);
  const [inPartImages, setInPartImages] = useState<Array<ImagePopupSlide>>([]);
  const [isHavingPermission, setIsHavingPermission] = useState(true);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [siteHierarchyWarning, setSiteHierarchyWarning] = useState<string>('');
  const [underClientStructureWarning, setUnderClientStructureWarning] = useState<string>('');

  const outValueUploadedImagesQuery = useGetUploadedImages(value?.outValue?.partTypeId || 0, Folders.PartTypesImages);
  const inValueUploadedImagesQuery = useGetUploadedImages(value?.inValue?.partTypeId || 0, Folders.PartTypesImages);
  const inPartLocationDataByServiceIdQuery = useGetPartLocationDataByServiceId(value?.inValue?.partTypeId || 0, service.id);
  const subLocationsForCreateServicesQuery = useSubLocationsForCreateServices();

  const getUserPermissionsQuery = useGetUserSystemPermissions();

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    const outValidations = async () => {
      if (value?.outValue?.isInSiteHierarchy === false && value?.outValue?.serial) {
        const subLocationsResult = await subLocationsForCreateServicesQuery.mutateAsync(value.outValue?.locationId);
        const main = subLocationsResult?.find(subLocation => subLocation.isMain);
    
        const partParentNode = main ? `${main.name} /` : '';
        const serviceBaseNode = service.subLocation ? `/ ${service.subLocation}` : '';

        setSiteHierarchyWarning(`Warning: ${value?.outValue?.serial} is not at ${service.mainLocation} ${serviceBaseNode}, but at ${partParentNode} ${value.outValue?.location}. This is an unexpected part location; please confirm the correct part has been selected prior to proceeding. installed in ${value?.outValue?.name}: ${value?.outValue?.serial}`);
      } else {
        setSiteHierarchyWarning('');
      } 
      
      if (value?.outValue?.isUnderClientStructure === false && value?.outValue?.serial) {
        const baseOrParentnode = service.subLocation ? service.subLocation : service.mainLocation;
        setUnderClientStructureWarning(`Warning: ${value?.outValue?.serial} is not at ${baseOrParentnode}, but at ${value.outValue?.location}.  Please confirm the correct part has been selected prior to proceeding.`);
      } else {
        setUnderClientStructureWarning('');
      }
    };

    outValidations();
  }, [value?.outValue?.isInSiteHierarchy, value?.outValue?.isUnderClientStructure, value?.outValue?.serial]);

  useEffect(() => {
    setIsHavingPermission(isUserHasPermission(ActionPermissions.Service_Edit_Part_Actions_Exceptional_Creation, permissions));
  }, [permissions]);
  
  useEffect(() => {
    if (value?.inValue?.name === value?.outValue?.name && value?.outValue?.name !== '') {
      setShowPartType(false);
    }
    
    value?.inValue?.name && value?.outValue?.name && setIsSearchMode(isEmptyString(value?.inValue?.name) && isEmptyString(value?.outValue?.name));
    value?.outValue?.name && setIsOutSearchMode(isEmptyString(value?.outValue?.name));
    value?.inValue?.name && setIsInSearchMode(isEmptyString(value?.inValue?.name));
    
    setIsInSerialized(value?.inValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent || value?.inValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice);
    setIsOutSerialized(value?.outValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent || value?.outValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice);

    value?.inValue?.partTypeId && inValueUploadedImagesQuery.refetch();
    value?.outValue?.partTypeId && outValueUploadedImagesQuery.refetch();
  }, [value?.inValue?.name , value?.inValue?.category, value?.outValue?.name, value?.outValue?.category, value?.inValue?.partTypeId, value?.outValue?.partTypeId]);

  useEffect(() => {
    if (value?.outValue?.name != '' || value?.inValue?.name != '') {
      setShowOutSerialSearch(value?.outValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent || value?.outValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice);
      setShowInSerialSearch(value?.inValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseComponent || value?.inValue?.category === PartTypeActionLiveSearchItemCategoryCode.SerialiseDevice);  
    }
  }, [value?.outValue?.name, value?.inValue?.name]);
 
  useEffect(() => {
    if (outValueUploadedImagesQuery.data) {
      const outImages: Array<ImagePopupSlide> = outValueUploadedImagesQuery.data && outValueUploadedImagesQuery.data.map((obj) => ({
        url: obj.url
      }));
      setOutPartImages(outImages);
    }
    if (inValueUploadedImagesQuery.data) {
      const inImages: Array<ImagePopupSlide> = inValueUploadedImagesQuery.data && inValueUploadedImagesQuery.data.map((obj) => ({
        url: obj.url
      }));
      setInPartImages(inImages);
    }

  }, [outValueUploadedImagesQuery.data, inValueUploadedImagesQuery.data]);

  const handleOutPartTypeActionSearch = async (liveSearchSelectedItem: PartTypeActionLiveSearchItem) => {
    if (liveSearchSelectedItem.isPartCreation) {
      onChange({
        newPartSerial: liveSearchSelectedItem.name,
        isPartCreation: true,
        action: Action.OUT,
        partActionType: PartActionType.REPLACE,
        value: value.value,
        outValue: value.outValue,
        inValue: value.inValue
      });
    } else {
      setHelperText({
        ...helperText,
        out: {
          ...helperText.out,
          partTypeHelperText: '',
          partSerialHelperText: ''
        },
      });
      if (liveSearchSelectedItem.serial1) {
        onChange({
          value: value.value,
          outValue: {
            ...value.outValue,
            id: value.outValue?.id || 0,
            partTypeId: liveSearchSelectedItem.partTypeId,
            partId: liveSearchSelectedItem.partId,
            name: liveSearchSelectedItem.partTypeName,
            category: liveSearchSelectedItem.categoryCode,
            location: liveSearchSelectedItem.locationName,
            locationId: liveSearchSelectedItem.locationId,
            condition: liveSearchSelectedItem.conditionCode,
            conditionName: liveSearchSelectedItem?.partCondition?.name,
            serial: liveSearchSelectedItem.serial1,
            sw: liveSearchSelectedItem.softwareVersion,
            hw: liveSearchSelectedItem.hardwareVersion,
            fw: liveSearchSelectedItem.firmwareVersion,
            ft: liveSearchSelectedItem.fleetTagName,
            isInSiteHierarchy: liveSearchSelectedItem.isInSiteHierarchy,
            isUnderClientStructure: liveSearchSelectedItem.isUnderClientStructure
          },
          inValue: value.inValue
        });
      } else {
        onChange({
          value: value.value,
          outValue: {
            ...value.outValue,
            id: value.outValue?.id || 0,
            partTypeId: liveSearchSelectedItem.partTypeId,
            ns_partId: liveSearchSelectedItem.partId,
            name: liveSearchSelectedItem.partTypeName,
            category: liveSearchSelectedItem.categoryCode,
            location: liveSearchSelectedItem.locationName,
            condition: liveSearchSelectedItem.conditionCode,
            conditionName: liveSearchSelectedItem?.partCondition?.name,
            serial: '',
            sw: liveSearchSelectedItem.softwareVersion,
            hw: liveSearchSelectedItem.hardwareVersion,
            fw: liveSearchSelectedItem.firmwareVersion,
            ft: liveSearchSelectedItem.fleetTagName
          },
          inValue: value.inValue
        });
      }
    }
  };

  const handleInPartTypeActionSearch = async (liveSearchSelectedItem: PartTypeActionLiveSearchItem) => {
    if (liveSearchSelectedItem.isPartCreation) {
      onChange({
        newPartSerial: liveSearchSelectedItem.name,
        isPartCreation: true,
        action: Action.IN,
        partActionType: PartActionType.REPLACE,
        value: value.value,
        outValue: value.outValue,
        inValue: value.inValue
      });
    } else {
      setHelperText({
        ...helperText,
        in: {
          ...helperText.in,
          partTypeHelperText: '',
          partSerialHelperText: ''
        },
      });

      if (liveSearchSelectedItem.serial1) {
        onChange({
          value: value.value,
          inValue: {
            ...value.inValue,
            id: value.inValue?.id || 0,
            partTypeId: liveSearchSelectedItem.partTypeId,
            partId: liveSearchSelectedItem.partId,
            name: liveSearchSelectedItem.partTypeName,
            category: liveSearchSelectedItem.categoryCode,
            location: liveSearchSelectedItem.locationName,
            condition: liveSearchSelectedItem.conditionCode,
            conditionName: liveSearchSelectedItem?.partCondition?.name,
            serial: liveSearchSelectedItem.serial1,
            sw: liveSearchSelectedItem.softwareVersion,
            hw: liveSearchSelectedItem.hardwareVersion,
            fw: liveSearchSelectedItem.firmwareVersion,
            ft: liveSearchSelectedItem.fleetTagName,
            serviceId: liveSearchSelectedItem.serviceId,
            isInSiblingHierarchy: liveSearchSelectedItem.isInSiblingHierarchy
          },
          outValue: value.outValue
        });
      } else {
        onChange({
          value: value.value,
          inValue: {
            ...value.inValue,
            id: value.inValue?.id || 0,
            partTypeId: liveSearchSelectedItem.partTypeId,
            ns_partId: liveSearchSelectedItem.partId,
            name: liveSearchSelectedItem.partTypeName,
            category: liveSearchSelectedItem.categoryCode,
            condition: liveSearchSelectedItem.conditionCode,
            conditionName: liveSearchSelectedItem?.partCondition?.name,
            location: liveSearchSelectedItem.locationName,
            serial: '',
            sw: liveSearchSelectedItem.softwareVersion,
            hw: liveSearchSelectedItem.hardwareVersion,
            fw: liveSearchSelectedItem.firmwareVersion,
            ft: liveSearchSelectedItem.fleetTagName
          },
          outValue: value.outValue
        });
      }
    }
  };


  const handleOutPartTypeSerialSearch = (val: PartTypeActionLiveSearchItem) => {
    if (val.isPartCreation) {
      onChange({
        newPartSerial: val.name,
        isPartCreation: true,
        action: Action.OUT,
        partActionType: PartActionType.REPLACE,
        value: value.value,
        outValue: value.outValue,
        inValue: value.inValue
      });
    } else {
      setHelperText({
        ...helperText,
        out: {
          ...helperText.out,
          partTypeHelperText: '',
          partSerialHelperText: ''
        },
      });

      const updatedValue = {
        value: value.value,
        outValue: {
          ...value.outValue,
          id: value.outValue?.id || 0,
          ns_partId: NaN,
          partId: val.partId,
          location: val.locationName,
          locationId: val.locationId,
          serial: val.serial1,
          condition: val.conditionCode,
          conditionName: val?.partCondition?.name,
          sw: val.softwareVersion,
          hw: val.hardwareVersion,
          fw: val.firmwareVersion,
          ft: val.fleetTagName,
          isInSiteHierarchy: val.isInSiteHierarchy,
          isUnderClientStructure: val.isUnderClientStructure
        },
        inValue: value.inValue
      };

      onChange(updatedValue);
    }
  };

  const handleInPartTypeSerialSearch = (val: PartTypeActionLiveSearchItem) => {
    if (val.isPartCreation) {
      onChange({
        newPartSerial: val.name,
        isPartCreation: true,
        action: Action.OUT,
        partActionType: PartActionType.REPLACE,
        value: value.value,
        outValue: value.outValue,
        inValue: value.inValue
      });
    } else {
      setHelperText({
        ...helperText,
        in: {
          ...helperText.in,
          partTypeHelperText: '',
          partSerialHelperText: ''
        },
      });
      const updatedValue = {
        value: value.value,
        inValue: {
          ...value.inValue,
          id: value.inValue?.id || 0,
          ns_partId: NaN,
          partId: val.partId,
          location: val.locationName,
          serial: val.serial1,
          condition: val.conditionCode,
          conditionName: val?.partCondition?.name,
          sw: val.softwareVersion,
          hw: val.hardwareVersion,
          fw: val.firmwareVersion,
          ft: val.fleetTagName,
          serviceId: val.serviceId,
          isInSiblingHierarchy: val.isInSiblingHierarchy
        },
        outValue: value.outValue
      };

      onChange(updatedValue);
    }
  };

  const handleInPartTypeLocationSelect = (val: PartTypeLocationItemData) => {
    setHelperText({
      ...helperText,
      in: { 
        ...helperText.in,
        locationHelperText: ''
      },
    });
    const updatedValue = {
      value: value.value,
      inValue: {
        ...value.inValue,
        id: value.inValue?.id || 0,
        partId: NaN,
        serial: '',
        ns_partId: val.ns_partId,
        location: val.locationName,
        condition: val.conditionCode,
        conditionName: val?.partCondition?.name,
        ft: val.fleetTagName
      },
      outValue: value.outValue
    };

    onChange(updatedValue);
    setIsSelectInLocationPopup(false);
  };

  return (
    <DefaultCard>
      <StyledCardContent>
        <CardTitle>Replace</CardTitle>
        <CloseWrapper onClick={onClose}>
          <CloseRounded htmlColor={COLORS.White} />
        </CloseWrapper>
        <Stack direction="column" spacing={1.5} width="100%">
          <Stack direction="row" justifyContent="space-between" spacing={1} width="100%" marginTop={1}>
            <Stack width="20%">
              <Typography
                variant={TypographyVariantProps.Body2} 
                fontWeight={TypographyFontWeightProps.Bold}
              >
                Out
              </Typography>
            </Stack>
            <Stack direction="column" spacing={2} width="100%">
              <PartTypeActionSearch
                title={showOutSerialSearch ? 'Part Type Search' : 'Part/Part Type Input'}
                serviceData={service}
                value={value?.outValue?.name || ''} 
                partActionType={PartActionType.REPLACE}
                action={Action.OUT}
                error={!!helperText.out.partTypeHelperText}
                helperText={helperText.out.partTypeHelperText}
                locationId={service.subLocationNodeId || service.mainLocationNodeId}
                onChange={handleOutPartTypeActionSearch}
                searchType={showOutSerialSearch ? 'part-type' : 'part-and-part-type'}
              />
              {showOutSerialSearch &&
              <PartTypeActionSearch
                title="Part Serial Search"
                serialiseType={value?.outValue?.category}
                partTypeId={`${value?.outValue?.partTypeId}`}
                serviceData={service}
                value={value?.outValue?.serial || ''}
                partActionType={PartActionType.REPLACE}
                action={Action.OUT}
                error={!!helperText.out.partSerialHelperText}
                helperText={helperText.out.partSerialHelperText}
                locationId={service.subLocationNodeId || service.mainLocationNodeId}
                onChange={handleOutPartTypeSerialSearch}
                searchType="part"
              />
              }
            </Stack>
            {isOutSearchMode || <ImagePopup title="Photos" images={outPartImages} />}
          </Stack>
          {isOutSearchMode || 
            <>
              {isOutSerialized &&
                <SHFDataWrapper>
                  <PartTypeActionSHFData sw={value?.outValue?.sw || '_'} hw={value?.outValue?.hw || '_'} fw={value?.outValue?.fw || '_'} />
                </SHFDataWrapper>
              }
              {value?.outValue?.condition && 
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    COND:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value?.outValue?.conditionName || value?.outValue?.condition}</Typography>
                </Stack>
              }
              {value?.outValue?.location &&
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    LOC:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value?.outValue?.location}</Typography>
                </Stack>
              }
              {value?.outValue?.ft &&
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    FT:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value?.outValue?.ft}</Typography>
                </Stack>
              }
              {value?.outValue?.condition && value?.outValue?.serial && partActionConditionValidation(PartActionValidateType.REPLACEOUT, value.outValue.condition, value.outValue.serial) &&
                <Grid item xs={12}>
                  <Alert severity="warning">{partActionConditionValidation(PartActionValidateType.REPLACEOUT, value.outValue.condition, value.outValue.serial)}</Alert>
                </Grid>
              }
              {siteHierarchyWarning &&
                <Grid item xs={12}>
                  <Alert severity="warning">{siteHierarchyWarning}</Alert>
                </Grid>
              }
              {underClientStructureWarning &&
                <Grid item xs={12}>
                  <Alert severity="warning">{underClientStructureWarning}</Alert>
                </Grid>
              }
            </>
          }
          <Stack direction="row" justifyContent="space-between" spacing={1} marginTop={1} width="100%">
            <Stack width="20%">
              <Typography 
                variant={TypographyVariantProps.Body2} 
                fontWeight={TypographyFontWeightProps.Bold}
              >
                In
              </Typography>
            </Stack>
            <Stack direction="column" spacing={2} width="100%">
              {showPartType && 
              <PartTypeActionSearch
                title={showInSerialSearch ? 'Part Type Search' : 'Part/Part Type Input'}
                serviceData={service}
                value={value?.inValue?.name || ''} 
                partActionType={PartActionType.REPLACE}
                error={!!helperText.in.partTypeHelperText}
                helperText={helperText.in.partTypeHelperText}
                onChange={handleInPartTypeActionSearch}
                searchType={showInSerialSearch ? 'part-type' : 'part-and-part-type'}
                action={Action.IN}
              />
              }
              {showInSerialSearch &&
              <PartTypeActionSearch
                title="Part Serial Search"
                serialiseType={value?.inValue?.category}
                partTypeId={`${value?.inValue?.partTypeId}`}
                serviceData={service}
                value={value?.inValue?.serial || ''}
                partActionType={PartActionType.REPLACE}
                error={!!helperText.in.partSerialHelperText}
                helperText={helperText.in.partSerialHelperText}
                onChange={handleInPartTypeSerialSearch}
                searchType="part"
                action={Action.IN}
              />
              }
            </Stack>
            {showPartType && (isInSearchMode || <ImagePopup title="Photos" images={inPartImages} />)}
          </Stack>
          {isInSearchMode || 
            <>
              {isOutSerialized ||
                <SelectButton 
                  variant={ButtonVariantProps.Secondary} 
                  color={ButtonColorProps.Success}
                  onClick={() => setIsSelectInLocationPopup(true)}
                >
                  Select Location
                </SelectButton>
              }
              {isInSerialized ?
                <SHFDataWrapper>
                  <PartTypeActionSHFData sw={value?.inValue?.sw || '_'} hw={value?.inValue?.hw || '_'} fw={value?.inValue?.fw || '_'} />
                </SHFDataWrapper>
                :
                <TextField 
                  label="Qty"
                  type={TextFieldTypeProps.Number}
                  value={value?.inValue?.qty}
                  inputProps={{ type: 'number', min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
                  onChange={(val) =>{
                    onChange({
                      value: value.value,
                      inValue: {
                        ...value.inValue,
                        id: value.inValue?.id || 0,
                        qty: Number(val)
                      },
                      outValue: {
                        ...value.outValue,
                        id: value.inValue?.id || 0,
                        qty: Number(val)
                      },
                    });
                  }}
                />
              }
              {value?.inValue?.condition && 
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    COND:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value?.inValue?.conditionName || value?.inValue?.condition}</Typography>
                </Stack>
              }
              {value?.inValue?.location &&
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    LOC:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value?.inValue?.location}</Typography>
                </Stack>
              }
              {value?.inValue?.ft &&
                <Stack direction="row" spacing={0.5}>
                  <Typography 
                    variant={TypographyVariantProps.Body2} 
                    fontWeight={TypographyFontWeightProps.Bold}
                  >
                    FT:
                  </Typography>
                  <Typography variant={TypographyVariantProps.Body2}>{value?.inValue?.ft}</Typography>
                </Stack>
              }
              {value.warning &&
                <Grid item xs={12}>
                  <Alert severity="warning">{value.warning}</Alert>
                </Grid>
              }
              {value?.inValue?.condition && value?.inValue?.serial && partActionConditionValidation(PartActionValidateType.REPLACEIN, value.inValue.condition, value.inValue.serial) &&
                <Grid item xs={12}>
                  <Alert severity="warning">{partActionConditionValidation(PartActionValidateType.REPLACEIN, value.inValue.condition, value.inValue.serial)}</Alert>
                </Grid>
              }
              {value?.inValue?.serviceId && service.id !== value?.inValue?.serviceId &&
                <Grid item xs={12}>
                  <Alert severity="warning">Warning: This part is marked as Reseved for Service ID {value.inValue.serviceId}.</Alert>
                </Grid>
              }
              {value?.inValue?.isInSiblingHierarchy === false && value?.outValue?.serial && partActionInValidations(value?.inValue?.condition || '', value?.inValue?.serial || '', value?.inValue?.location || '') &&
                <Grid item xs={12}>
                  <Alert severity="warning">{partActionInValidations(value?.inValue?.condition || '', value?.inValue?.serial || '', value?.inValue?.location || '')}</Alert>
                </Grid>
              }
              <Grid item xs={12}>
                <PartTypeActionNote
                  value={value?.outValue?.notes || ''}
                  error={!!helperText.out.noteHelperText}
                  helperText={helperText.out.noteHelperText}
                  onChange={(note) => {
                    onChange({
                      value: value.value,
                      outValue: {
                        ...value.outValue,
                        id: value.outValue?.id || 0,
                        notes: note
                      },
                      inValue: value.inValue
                    });

                    setHelperText({
                      ...helperText,
                      out: { 
                        ...helperText.out,
                        noteHelperText: ''
                      },
                    });
                  }}
                />
              </Grid>
            </>
          }
        </Stack>
      </StyledCardContent>
      {isHavingPermission && (isSearchMode ||
        <CardActions>
          <Stack direction="row-reverse" spacing={1} width="100%">
            <StyledButton 
              variant={ButtonVariantProps.Primary} 
              color={ButtonColorProps.Error}
              onClick={onExec}
            >
              Exec
            </StyledButton>
            <StyledButton 
              variant={ButtonVariantProps.Primary} 
              color={ButtonColorProps.Success}
              onClick={onSave}
            >
              {value.inValue?.actionId ? 'Update' : 'Save'}
            </StyledButton>
          </Stack>
        </CardActions>
      )}
      <PartLocationSelectPopup
        open={isSelectInLocationPopup}
        options={inPartLocationDataByServiceIdQuery.data || []}
        onSelect={handleInPartTypeLocationSelect}
        onClose={() => setIsSelectInLocationPopup(false)}
      />
    </DefaultCard>
  );
};