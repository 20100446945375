import { ReactNode } from 'react';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { GridCellParams, GridColumnOrderChangeParams, GridExperimentalProFeatures, GridRowHeightParams, GridRowHeightReturnValue, GridRowParams, GridRowTreeNodeConfig } from '@mui/x-data-grid-pro';
import {
  GridDensity,
  GridRowIdGetter,
  GridSlotsComponentsProps,
  GridFeatureMode,
  GridFilterModel,
  GridCallbackDetails,
  GridInitialState,
  GridSortModel,
  GridRowClassNameParams,
  GridSelectionModel
} from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

export enum GridAllignmentTypes {
  Center = 'center',
  Left = 'left',
  Right = 'right'
}

export interface DataGridProps {
  columns: Array<GridColDef>;
  rows: Array<any>;
  density?: GridDensity;
  getRowId?: GridRowIdGetter;
  componentsProps?: GridSlotsComponentsProps;
  columnVisibilityModel?: GridColumnVisibilityModel;
  disableColumnFilter?: boolean;
  disableColumnMenu?: boolean;
  disableColumnSelector?: boolean;
  disableDensitySelector?: boolean;
  checkboxSelection?: boolean;
  getDetailPanelHeight?: (params: GridRowParams<any>) => number | 'auto';
  getDetailPanelContent?: (params: GridRowParams<any>) => ReactNode;
  filterMode?: GridFeatureMode;
  onFilterModelChange?: (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => void;
  loading?: boolean;
  rowCount?: number;
  rowsPerPageOptions?: Array<number>;
  pagination?: boolean;
  page?: number;
  pageSize?: number;
  paginationMode?: GridFeatureMode;
  initialState?: GridInitialState;
  customToolbar?: () => JSX.Element;
  onPageChange?: (newPage: number) => void;
  onPageSizeChange?: (newPageSize: number) => void;
  filterModel?: GridFilterModel;
  sortingMode?: GridFeatureMode;
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails<any>) => void;
  getRowClassName?: (params: GridRowClassNameParams) => string;
  selectionModel?: GridSelectionModel;
  onSelectionModelChange?: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void;
  hideFooter?: boolean;
  autoHeight?: boolean;
  treeData?: boolean;
  getTreeDataPath?:(row: any) => Array<string>;
  defaultGroupingExpansionDepth?: number;
  groupingColDef?: any;
  getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue;
  isRowSelectable?: (params: GridRowParams) => boolean;
  onColumnOrderChange?: (params: GridColumnOrderChangeParams) => void;
  setColumns?: (params: GridColDef[]) => void;
  customFooter?: () => JSX.Element;
  onCellClick?: (params: GridCellParams) => void;
  onCellEditStop?: (params: GridCellParams) => void;
  apiRef?: React.MutableRefObject<GridApiPro>;
  experimentalFeatures?: Partial<GridExperimentalProFeatures>;
  getCellClassName?: (params: GridCellParams) => string;
  showCellRightBorder?: boolean;
  headerHeight?: number;
  onCellDoubleClick?: (params: GridCellParams) => void;
  isGroupExpandedByDefault?: (node: GridRowTreeNodeConfig) => boolean;
  serviceColumns?: Array<GridColDef>;
  disableSelectionOnClick?: boolean;
}