import React, { useCallback, useEffect, useState } from 'react';
import { GridFeatureModeConstant, GridFilterModel, GridRenderCellParams, GridSelectionModel, GridSortModel, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { GridColDef } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Edit } from '@mui/icons-material';
import { ColumnSelectionItem } from '../../../../components/templates/ColumnSelection';
import { CustomDataGrid } from '../../../../components/layouts/CustomDataGrid';
import { ColorProps, ListType } from '../../../../@types';
import { useGetAllInteractionsRulesConfigs } from '../../../../queries/interactions-rules-config-query';
import { InteractionsRulesQueryResponse } from '../../../../@types/interactionsRules.type';
import { CreateEditInteractionRuleFragment } from '../CreateEditInteractionRuleFragment';
import { InteractionRuleObject } from '../../../../@types/interaction.type';
import { GridContainer } from '../../../../styled/common.styles';
import { ISACTIVE_FILTER_DROPDOWN_ITEM_ARRAY } from '../../../../constants/common';
import { Chip } from '../../../../components/atoms/Chip';
import { InteractionsRulesListProps } from './InteractionsRulesList.props';

export const InteractionsRulesList: React.FC<InteractionsRulesListProps> = ({ canEdit }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [interactionRuleList, setInteractionRuleList] = useState<InteractionsRulesQueryResponse>();
  const [editConfigData, setEditConfigData] = useState<InteractionRuleObject>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const interactionsRulesConfigQuery = useGetAllInteractionsRulesConfigs({ page, pageSize, filterModel, sortModel });

  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value.toString()
    },
    {
      field: 'name',
      headerName: 'Rule Name',
      type: 'string',
      width: 150,
    },
    {
      field: 'serviceTypes',
      headerName: 'Service Type',
      type: 'string',
      width: 150,
    },
    {
      field: 'actionTypes',
      headerName: 'Action Type',
      type: 'string',
      width: 150
    },
    {
      field: 'processTypes',
      headerName: 'Process Type',
      type: 'string',
      width: 150
    },
    {
      field: 'nodeHierarchy',
      headerName: 'Client/Contract/Brand',
      type: 'string',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.criteriaJSON.nodeHierarchy
    },
    {
      field: 'priorities',
      headerName: 'Priority',
      type: 'string',
      width: 150,
    },
    {
      field: 'regions',
      headerName: 'Region',
      type: 'string',
      width: 150,
      valueGetter: (params: GridValueGetterParams) => params.row?.regions && params.row.regions
    },
    {
      field: 'models',
      headerName: 'Model',
      type: 'string',
      width: 150,
    },
    {
      field: 'triggerPoint',
      headerName: 'Trigger Point',
      type: 'singleSelect',
      width: 200,
      valueOptions: [
        { value: 'Status Change', label: 'Status Change' },
        { value: 'System Event', label: 'System Event' }
      ],
      valueGetter: (params: GridValueGetterParams) => params.row.triggerPoint
    },
    {
      field: 'templateName',
      headerName: 'Template',
      type: 'string',
      width: 200,
    },
    {
      field: 'notes',
      headerName: 'Rule Notes',
      type: 'string',
      width: 200,
    },
    {
      field: 'isActive',
      headerName: 'Is Active',
      width: 100,
      type: 'singleSelect',
      valueOptions: ISACTIVE_FILTER_DROPDOWN_ITEM_ARRAY,
      renderCell: (params: GridRenderCellParams) => params.row.isActive ? <Chip label="YES" color={ColorProps.Success} /> : <Chip label="NO" color={ColorProps.Error} />,
      valueGetter: (params: GridValueGetterParams) => params.row.isActive
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      filterable: false,
      width: 75,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        canEdit &&
        <Grid>
          <IconButton aria-label="edit" onClick={() => {
            setEditConfigData(params.row.criteriaJSON);
            setIsOpen(true);
          }}>
            <Edit />
          </IconButton>
        </Grid>
      )
    }
  ]);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setFilterModel({ ...filterModel });
  }, []);
  
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleSelectionModelChange = useCallback((selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  }, []);

  useEffect(() => {
    interactionsRulesConfigQuery.refetch();
  }, [page, pageSize, filterModel, sortModel]);

  useEffect(() => {
    interactionsRulesConfigQuery.data && setInteractionRuleList(interactionsRulesConfigQuery.data || []);
  }, [interactionsRulesConfigQuery.data]);

  const columnSelectionItems: Array<ColumnSelectionItem> = [
    {
      title: 'Rules',
      value: [
        {
          title: 'ID',
          field: 'id',
          value: true
        },
        {
          title: 'Rule Name',
          field: 'name',
          value: true
        },
        {
          title: 'Service Type',
          field: 'serviceTypes',
          value: true
        },
        {
          title: 'Action Type',
          field: 'actionTypes',
          value: true
        },
        {
          title: 'Process Type',
          field: 'processTypes',
          value: true
        },
        {
          title: 'Client/Contract/Brand',
          field: 'nodeHierarchy',
          value: true
        },
        {
          title: 'Priority',
          field: 'priorities',
          value: true
        },
        {
          title: 'Region',
          field: 'regions',
          value: true
        },
        {
          title: 'Model',
          field: 'models',
          value: true
        },
        {
          title: 'Trigger Point',
          field: 'triggerPoint',
          value: true
        },
        {
          title: 'Template',
          field: 'templateName',
          value: true
        },
        {
          title: 'Rule Notes',
          field: 'notes',
          value: true
        },
        {
          title: 'Is Active',
          field: 'isActive',
          value: true
        }
      ]
    },
  ];

  return (
    <Grid container>
      <GridContainer>
        <CustomDataGrid
          columns={columns}
          rows={interactionRuleList?.data || []}
          rowCount={interactionRuleList?.total}
          exportFileName={ListType.InteractionRules}
          enableCSVExport={true}
          columnSelectionItems={columnSelectionItems}
          filterMode={GridFeatureModeConstant.server}
          filterModel={filterModel}
          onFilterModelChange={(filterModel: GridFilterModel) => {
            onFilterChange(filterModel);
          }}
          loading={interactionsRulesConfigQuery.isLoading}
          rowsPerPageOptions={[25, 50, 100]}
          pagination={true}
          page={page}
          pageSize={pageSize}
          paginationMode={GridFeatureModeConstant.server}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          sortModel={sortModel}
          sortingMode={GridFeatureModeConstant.server}
          onSortModelChange={handleSortModelChange}
          selectionModel={selectionModel}
          onSelectionModelChange={handleSelectionModelChange}
          componentsProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true }
            }
          }}
          getRowHeight={() => 'auto'}
          setColumns={setColumns}
        />
      </GridContainer>
      <CreateEditInteractionRuleFragment 
        openModal={isOpen}
        handleClose={() => {
          setIsOpen(false);
        }}
        data={editConfigData} />

    </Grid>
  );
};