import React, { useEffect, useState } from 'react';
import { PageContainer } from '../../../../components/atoms/PageContainer';
import { Accordian } from '../../../../components/atoms/Accordian';
import { Grid, Table } from '@mui/material';
import { Typography } from '../../../../components/atoms/Typography';
import { StyledBox, StyledTableCell } from './AddStockDetailsFragment.styles';
import { LiveSearchBox, LiveSearchListItem } from '../../../../components/atoms/LiveSearchBox';
import { LocationNodeData, NodeType } from '../../../../@types';
import { LocationSearchItem } from '../../../../components/molecules/LocationSearchItem';
import { useSearchNodeShipment } from '../../../../queries/live-search-query';
import { AddStockDetailsFragmentProps } from './AddStockDetailsFragment.props';
import { DropdownItem, SelectMenu } from '../../../../components/atoms/SelectMenu';
import { SelectionButton } from '../../../../components/molecules/SelectionButton';
import { ContentDispositionType, StructureTypeCategory } from '../../../../configs/enums';
import { useGetNodeChildrenByNodeType, useGetNodeSubTree, useGetStructuresByTypeCategoryCodeAndTypeCode } from '../../../../queries/structure-query';
import { ContractMultiSelector } from '../../../../components/molecules/ContractMultiSelector';
import { getClientHierarchy, leafNodeCreater } from '../../../../utils/clientContractHierarchy';
import { TextField } from '../../../../components/atoms/TextField';
import { DateTimePicker } from '../../../../components/atoms/DateTimePicker';
import { UploadedFile, Folders, FileUploadActionType, FileTypes, UploadedFileActionData } from '../../../../@types/uploaded-files.type';
import { deleteUploadedFile, uploadFiles } from '../../../../api/uploadedFiles';
import { Attachments } from '../../../../components/organisms/Attachments';
import { MaxFileSize } from '../../../../configs/uploaded-files';
import { useGetUploadedFilePresignedUrlById, useGetUploadedFiles } from '../../../../queries/uploadedfiles-query';
import { AddStockDetailFields, ShipmentStatusCodes, ShipmentTypeCodes } from '../../../../@types/shipment.type';
import { nameValidation } from '../../../../utils/common';
import { useGetAllShipmentStatus } from '../../../../queries/shipment-query';
import { getShipmentStatusList } from '../../../../utils/shipment';
import { PrintLabel } from '../../../../components/molecules/PintLabel';
import { PrintLabelEntityTypes } from '../../../../@types/print-label.type';
import { Modal } from '../../../../components/atoms/Modal';
import { ADD_STOCK_ATTACHMENTS_MIME_TYPES } from '../../../../configs/envs';
import { StyledImage } from '../../../../components/organisms/MultipleImageUpload/MultipleImageUpload.styles';
import { SearchCode } from '../../../../@types/part.type';

export const AddStockDetailsFragment: React.FC<AddStockDetailsFragmentProps> = ({
  isNew,
  addStockDetail,
  helperText,
  setHelperText,
  setAddStockDetail
}) => {
  const anyItem: DropdownItem[] = [{ value: 'any', label: 'Any' }];
  const [selectedParentId, setSelectedParentId] = useState('');
  const [openParentSelector, setOpenParentSelector] = useState(false);
  const [queryNodeType, setQueryNodeType] = useState(NodeType.Client);
  const [clients, setClients] = useState<DropdownItem[]>([]);
  const [contracts, setContracts] = useState<DropdownItem[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [selectedFileActionData, setSelectedFileActionData] = useState<UploadedFileActionData>({ id: 0, contentDispositionType: '', mimeType: '' });
  const [shipmentStatus, setShipmentStatus] = useState<DropdownItem[]>([]);
  const [nodeIdToCheckClient, setNodeIdToCheckClient] = useState<number>(0);
  const [fullImage, setFullImage] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (addStockDetail) {
      setSelectedParentId(addStockDetail.client);
    }
  }, [addStockDetail]);

  const searchNodeShipmentQuery = useSearchNodeShipment();
  const getAllShipmentStatus = useGetAllShipmentStatus();
  const getStructuresByTypeCategoryCodeAndTypeCode = useGetStructuresByTypeCategoryCodeAndTypeCode(StructureTypeCategory.System, NodeType.Client);
  const getNodeChildrenByNodeType = useGetNodeChildrenByNodeType(selectedParentId, queryNodeType);
  const uploadedFilesQuery = useGetUploadedFiles(addStockDetail?.id, Folders.ShipmentAddStockAttachments, true);
  const uploadedFileDataByKey = useGetUploadedFilePresignedUrlById(selectedFileActionData.id, selectedFileActionData.contentDispositionType);
  const getNodeSubTreeQuery = useGetNodeSubTree(nodeIdToCheckClient.toString());

  useEffect(() => {
    if (getAllShipmentStatus.data) {
      setShipmentStatus(getShipmentStatusList(getAllShipmentStatus.data));
    }
  }, [getAllShipmentStatus.data]);

  useEffect(() => {
    if (selectedFileActionData.id) {
      uploadedFileDataByKey.refetch();
    }
  }, [selectedFileActionData.id, selectedFileActionData.contentDispositionType]);

  useEffect(() => {
    if (uploadedFileDataByKey.data && selectedFileActionData.mimeType.split('/').includes('image'), open) {
      if (uploadedFileDataByKey.data?.url) {
        setFullImage(uploadedFileDataByKey.data?.url);
        setOpen(true);
      }
    }

    if (uploadedFileDataByKey.data && !selectedFileActionData.mimeType.split('/').includes('image')) {
      const link = document.createElement('a');
      link.href = uploadedFileDataByKey.data?.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setSelectedFileActionData({ id: 0, contentDispositionType: '', mimeType: '' });
    }

  }, [uploadedFileDataByKey.data, selectedFileActionData.mimeType, open]);

  useEffect(() => {
    getStructuresByTypeCategoryCodeAndTypeCode.refetch();
    setQueryNodeType(NodeType.Contract);
    if (selectedParentId !== '') {
      getNodeChildrenByNodeType.refetch();
    }
  }, []);

  useEffect(() => {
    if (getStructuresByTypeCategoryCodeAndTypeCode.data) {
      setClients(getStructuresByTypeCategoryCodeAndTypeCode.data.map(item => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive })));
    }
  }, [getStructuresByTypeCategoryCodeAndTypeCode.data]);

  useEffect(() => {
    if (getNodeChildrenByNodeType.data) {
      if (queryNodeType === NodeType.Contract) {
        const contractMenuList = getNodeChildrenByNodeType.data.map(item => ({
          value: item.id.toString(),
          label: item.name,
          disabled: !item.isActive
        }));
        const contractMenuListWithAny = anyItem.concat(contractMenuList);
        setContracts(contractMenuListWithAny);
      }
    }
  }, [getNodeChildrenByNodeType.data]);

  useEffect(() => {
    if (selectedParentId && queryNodeType) {
      getNodeChildrenByNodeType.refetch();
    }
  }, [selectedParentId, queryNodeType]);

  // hierarchy displaying when edit page.
  useEffect(() => {
    if (!isNew) {
      setAddStockDetail && setAddStockDetail({
        ...addStockDetail,
        clientHierarchyName: getClientHierarchy(clients || [], contracts || [], addStockDetail.client || '', addStockDetail.contract || [])
      });
    }
  }, [isNew, clients, contracts]);

  useEffect(() => {
    uploadedFilesQuery.data && setUploadedFiles(uploadedFilesQuery.data);
  }, [uploadedFilesQuery.data]);

  useEffect(() => {
    if (!addStockDetail.client && addStockDetail.destinationNodeId) {
      setNodeIdToCheckClient(addStockDetail.destinationNodeId);
    }
  }, [addStockDetail.destinationNodeId]);

  useEffect(() => {
    if (!addStockDetail.client && addStockDetail.sourceNodeId) {
      setNodeIdToCheckClient(addStockDetail.sourceNodeId);
    }
  }, [addStockDetail.sourceNodeId]);

  useEffect(() => {
    getNodeSubTreeQuery.refetch();
  }, [nodeIdToCheckClient]);

  useEffect(() => {
    if (getNodeSubTreeQuery.data) {
      const clientNode = getNodeSubTreeQuery.data.find(node => {
        return node.nodeTypeCode === NodeType.Client;
      });

      if (clientNode) {
        setAddStockDetail && setAddStockDetail({
          ...addStockDetail,
          client: clientNode.id.toString(),
          clientHierarchy: `[${clientNode.id}]`,
          contract: ['any'],
          clientHierarchyName: `${clientNode.name} -> Any`
        });
      }
    }
  }, [getNodeSubTreeQuery.data]);

  const onClickUploadedFiles = async (id: number, actionType: FileUploadActionType, mimeType = '') => {
    if (mimeType.split('/').includes('image')) {
      setOpen(true);
    }

    if (actionType === FileUploadActionType.Archive) {
      await deleteUploadedFile(id);
      const refetchUploadedFilesQuery = await uploadedFilesQuery.refetch();
      refetchUploadedFilesQuery.data && setUploadedFiles(refetchUploadedFilesQuery.data);
      return;
    }

    setSelectedFileActionData(
      {
        id: id,
        contentDispositionType: actionType === FileUploadActionType.Download || mimeType.startsWith(FileTypes.MSExcel) || mimeType.startsWith(FileTypes.OpenXml) ? ContentDispositionType.Attachment : ContentDispositionType.Inline,
        mimeType
      });
  };

  const onChangeNodeType = (nodeType: NodeType, parentId: string) => {
    setSelectedParentId(parentId);
    setQueryNodeType(nodeType);
  };

  const setFileDetails = async (fileList: FileList) => {
    if (fileList.length > 0) {
      const formData = new FormData();
      const files = [...fileList];
      files.forEach((item) => formData.append('fileList', item));
      await uploadFiles(addStockDetail.id, formData, Folders.ShipmentAddStockAttachments);
      const refetchUploadedFilesQuery = await uploadedFilesQuery.refetch();
      refetchUploadedFilesQuery.data && setUploadedFiles(refetchUploadedFilesQuery.data);
    }
  };

  const handleImageClose = () => {
    setOpen(false);
    setFullImage('');
  };

  return (
    <PageContainer>
      <Accordian title={
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography>Detail</Typography>
          </Grid>
        </Grid>
      } defaultExpanded={true}>
        <Table>
          <StyledTableCell width="40%">
            <StyledBox>
              <LiveSearchBox
                disabled={!isNew}
                isRequired={true}
                helperText={helperText.sourceHelperText}
                title="Source"
                timeOffset={400}
                value={addStockDetail.source}
                onClearValue={() => { setAddStockDetail && setAddStockDetail({ ...addStockDetail, source: '', sourceNodeId: NaN }); }}
                renderItem={(props: any, option: any) => {
                  return (
                    <LiveSearchListItem {...props}>
                      <LocationSearchItem data={option} />
                    </LiveSearchListItem>
                  );
                }}
                onChange={(obj: LocationNodeData) => {
                  setAddStockDetail && setAddStockDetail({
                    ...addStockDetail,
                    source: obj.name.includes(SearchCode.AM_LID) ? obj.code : obj.name,
                    sourceNodeId: obj.id
                  });
                  setHelperText && setHelperText({ ...helperText, sourceHelperText: nameValidation(obj.code, AddStockDetailFields.SourceField) });
                }}
                onApiInvoke={async (name: string) => {
                  return await searchNodeShipmentQuery.mutateAsync(name);
                }}
              />
            </StyledBox>
            <StyledBox mt={2}>
              <LiveSearchBox
                disabled={!isNew}
                isRequired={true}
                helperText={helperText.destinationHelperText}
                title="Destination"
                timeOffset={400}
                value={addStockDetail.destination}
                onClearValue={() => { setAddStockDetail && setAddStockDetail({ ...addStockDetail, destination: '', destinationNodeId: NaN }); }}
                renderItem={(props: any, option: any) => {
                  return (
                    <LiveSearchListItem {...props}>
                      <LocationSearchItem data={option} />
                    </LiveSearchListItem>
                  );
                }}
                onChange={(obj: LocationNodeData) => {
                  setAddStockDetail && setAddStockDetail({
                    ...addStockDetail,
                    destination: obj.name.includes(SearchCode.AM_LID) ? obj.code : obj.name,
                    destinationNodeId: obj.id
                  });
                  setHelperText && setHelperText({ ...helperText, destinationHelperText: nameValidation(obj.code, AddStockDetailFields.DestinationField) });
                }}
                onApiInvoke={async (name: string) => {
                  return await searchNodeShipmentQuery.mutateAsync(name);
                }}
              />
            </StyledBox>
            <StyledBox mt={2}>
              <SelectMenu
                disabled={addStockDetail.shipmentTypeCode === ShipmentTypeCodes.AddStock}
                id="shipment-status"
                label="Status"
                selectedValue={addStockDetail.shipmentStatusCode}
                onChange={(status: string) => setAddStockDetail && setAddStockDetail({ ...addStockDetail, shipmentStatusCode: status === '0' ? 'pending' : status })}
                items={shipmentStatus}
              />
            </StyledBox>
            <StyledBox mt={2}>
              <SelectionButton
                key={addStockDetail.clientHierarchyName}
                error={!!helperText.clientContractHelperText}
                label="Client/Contract Selection*"
                value={addStockDetail.clientHierarchyName}
                onClick={() => {
                  isNew && setOpenParentSelector(true);
                  getStructuresByTypeCategoryCodeAndTypeCode.refetch();
                  onChangeNodeType(NodeType.Contract, addStockDetail.client);
                }}
                disabled={!isNew}
              />
            </StyledBox>
            <StyledBox mt={2}>
              <TextField
                disabled={!isNew}
                fullWidth
                label="Customer ID"
                value={addStockDetail.customerId}
                onChange={(customerId: string) => setAddStockDetail && setAddStockDetail({ ...addStockDetail, customerId })}
              />
            </StyledBox>
            <StyledBox mt={2}>
              <TextField
                disabled={!isNew && (addStockDetail.shipmentStatusCode === ShipmentStatusCodes.Complete || addStockDetail.shipmentStatusCode === ShipmentStatusCodes.Cancelled)}
                fullWidth
                label="Shipper Connote"
                value={addStockDetail.connoteNo}
                onChange={(connoteNo: string) => setAddStockDetail && setAddStockDetail({ ...addStockDetail, connoteNo })}
              />
            </StyledBox>
            <StyledBox mt={2}>
              <DateTimePicker
                disabled={!isNew}
                label="Expected Arrival Date"
                value={addStockDetail.expectedArrivalDate}
                onChange={(value) => {
                  setAddStockDetail && setAddStockDetail({ ...addStockDetail, expectedArrivalDate: value });
                }}
              />
            </StyledBox>
            <StyledBox mt={2}>
              <TextField
                fullWidth
                disabled={!isNew}
                label="Shipping Cost"
                value={addStockDetail?.shippingCost}
                inputProps={{ type: 'number', min: 0, inputMode: 'numeric', pattern: '[0-9]*' }}
                onChange={(val) => {
                  const numValue = parseFloat(val);

                  if (typeof (numValue) === 'number' && setAddStockDetail) {
                    setAddStockDetail({
                      ...addStockDetail,
                      shippingCost: numValue
                    });
                  }
                }}
              />
            </StyledBox>
            {!isNew &&
              <StyledBox mt={2}>
                <PrintLabel entityId={addStockDetail.id} entityType={PrintLabelEntityTypes.Shipment} />
              </StyledBox>}
          </StyledTableCell>
          <StyledTableCell width="5%"></StyledTableCell>
          <StyledTableCell width="40%">
            <StyledBox mt={2}>
              <TextField
                fullWidth
                disabled={!isNew && (addStockDetail.shipmentStatusCode === ShipmentStatusCodes.Complete || addStockDetail.shipmentStatusCode === ShipmentStatusCodes.Cancelled)}
                label="Note"
                value={addStockDetail.note}
                onChange={(note: string) => setAddStockDetail && setAddStockDetail({ ...addStockDetail, note })}
                multiline
                rows={5}
              />
            </StyledBox>
            <StyledBox mt={2}>
              <TextField
                fullWidth
                disabled={!isNew}
                label="Reference 1"
                value={addStockDetail.referenceNumber1}
                onChange={(referenceNumber1: string) => setAddStockDetail && setAddStockDetail({ ...addStockDetail, referenceNumber1 })}
              />
            </StyledBox>
            <StyledBox mt={2}>
              <TextField
                fullWidth
                disabled={!isNew}
                label="Reference 2"
                value={addStockDetail.referenceNumber2}
                onChange={(referenceNumber2: string) => setAddStockDetail && setAddStockDetail({ ...addStockDetail, referenceNumber2 })}
              />
            </StyledBox>
            <StyledBox mt={2}>
              <TextField
                fullWidth
                disabled={!isNew}
                label="Reference 3"
                value={addStockDetail.referenceNumber3}
                onChange={(referenceNumber3: string) => setAddStockDetail && setAddStockDetail({ ...addStockDetail, referenceNumber3 })}
              />
            </StyledBox>
            {!isNew && <StyledBox mt={2}>
              <Attachments
                rows={uploadedFiles}
                onClick={onClickUploadedFiles}
                fileTypes={ADD_STOCK_ATTACHMENTS_MIME_TYPES.split(',')}
                maxFileSize={MaxFileSize}
                setFileDetails={setFileDetails}
              />
            </StyledBox>}
          </StyledTableCell>
        </Table>
        <ContractMultiSelector
          open={openParentSelector}
          client={addStockDetail.client}
          contract={addStockDetail.contract}
          clients={clients}
          contracts={contracts}
          onChange={(clientVal: string, contractVal: Array<string>) => {
            setSelectedParentId(clientVal);
            onChangeNodeType(NodeType.Contract, clientVal);

            setAddStockDetail && setAddStockDetail({
              ...addStockDetail,
              client: clientVal,
              contract: contractVal,
              clientHierarchyName: ''
            });
          }}
          onSelect={() => {
            if (addStockDetail.client && addStockDetail.contract.length > 0) {
              setAddStockDetail && setAddStockDetail({
                ...addStockDetail,
                clientHierarchy: JSON.stringify(leafNodeCreater(addStockDetail.client, addStockDetail.contract)),
                clientHierarchyName: getClientHierarchy(clients, contracts, addStockDetail.client, addStockDetail.contract)
              });
            }
            setOpenParentSelector(false);
            const hierarchyName = getClientHierarchy(clients, contracts, addStockDetail.client, addStockDetail.contract);
            setHelperText && setHelperText({ ...helperText, clientContractHelperText: nameValidation(hierarchyName, AddStockDetailFields.ClientContractField) });
          }}
          onClose={() => {
            setSelectedParentId('');
            setOpenParentSelector(false);
          }}
        />
      </Accordian>
      <Modal
        open={open}
        onClose={handleImageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid onMouseDown={handleImageClose}>
          {fullImage && <StyledImage src={fullImage}/>}
        </Grid>
      </Modal>
    </PageContainer>
  );
};