import { ImagePopupSlide } from '../components/atoms/ImagePopup';
import { PartData } from './part.type';

export enum PartActionType {
  INSTALL = 'install',
  REPLACE = 'replace',
  REMOVE = 'remove',
  DOA = 'doa',
  CLEAN = 'clean',
  TEST = 'test'
}

export enum PartActionValidateType {
  INSTALL = 'install',
  REPLACE = 'replace',
  REMOVE = 'remove',
  DOA = 'doa',
  CLEAN = 'clean',
  TEST = 'test',
  REPLACEIN = 'replace-in',
  REPLACEOUT = 'replace-out',
  DOAIN = 'doa-in',
  DOAOUT = 'doa-out',
}

export enum Action {
  IN = 'in',
  OUT = 'out'
}


export interface PartActionCard {
  id: number;
  actionId?: number;
  partTypeId?: number;
  partId?: number;
  ns_partId?: number;
  name?: string;
  category?: string;
  sw?: string;
  hw?: string;
  fw?: string;
  ft?: string;
  serial?: string;
  qty?: number;
  condition?: string;
  conditionName?: string;
  location?: string;
  locationId?: number;
  notes?: string;
  type?: string;
  images?: Array<ImagePopupSlide>;
  serviceId?: number;
  isInSiblingHierarchy?: boolean;
  isInSiteHierarchy?: boolean;
  isUnderClientStructure?: boolean;
}

export interface CustomisePartActionCard { 
  value: PartActionCard;
  outValue?: PartActionCard; 
  inValue?: PartActionCard;
  warning?: string;
}

export interface CreatePartActionResponse { 
  actionTypeCode: string;
  serviceId: number;
  installedOrMaintainedPartData: JSON;
  removedPartData: JSON;
  notes: string;
  execute: boolean;
}

export interface PartActionResponse { 
  id: number;
  actionTypeCode: string;
  serviceId: number;
  installedOrMaintainedPartData: PartData;
  removedPartData: PartData;
  notes: string;
}

export interface AddPartActionResponse { 
  id: number;
  actionTypeCode: string;
  serviceId: number;
  installedOrMaintainedPartData: PartData;
  removedPartData: PartData;
  notes: string;
  createdAt: string;
  createdBy: number;
  updatedAt: string;
  updatedby: number;
}

export interface PartActionWarningInOutDependancies { 
  serial1?: string;
  partTypeName?: string;
}

export interface PartActionWarningDependancies { 
  in: PartActionWarningInOutDependancies;
  out: PartActionWarningInOutDependancies;
}

export interface PartActionInOutHelperText {
  partTypeHelperText: string;
  partSerialHelperText: string;
  locationHelperText: string;
  noteHelperText: string;
}

export interface PartActionCardHelperText {
  in: PartActionInOutHelperText;
  out: PartActionInOutHelperText;
}

export interface PartCreationOptionData { 
  newPartSerial: string;
  isPartCreation: boolean;
  action: Action;
  partActionType: PartActionType;
  value: PartActionCard;
  outValue?: PartActionCard; 
  inValue?: PartActionCard;
}

export interface PartActionTypes {
  code: string;
  name: string;
  type: string;
  isActive: boolean;
}