import React, { useState, useEffect } from 'react';
import { ServiceHistoryFragmentProps } from './ServiceHistoryFragment.props';
import { TrackingHistory } from '../../../../components/molecules/TrackingHistory';
import { useGetServiceLogsById, useHideServiceLog, useUpdateServiceLogsById } from '../../../../queries/service-log-query';
import { LogType, ServiceLog } from '../../../../@types/service.type';
import { RichTextEditor } from '../../../../components/atoms/RichTextEditor';
import { isUserHasPermission } from '../../../../configs/permissions';
import { ActionPermissions } from '../../../../@types/permission.type';
import { SAMPLE_SERVICE_LOG } from '../../../../constants/service.constant';
import { useCreateTaskServiceLogByApprove } from '../../../../queries/task-query';
import { PopupDialog } from '../../../../components/molecules/PopupDialog';
import { useDownloadNmiCertificate, useEditInteraction } from '../../../../queries/interactions-query';
import { downloadFile } from '../../../../utils/file-utils';
import { Stack } from '@mui/material';
import { FormGenerator } from '../../../../components/templates/FormGenerator';
import { FormObject } from '../../../../@types';
import { formatDateTime } from '../../../../utils/date-time-utils';

export const ServiceHistoryFragment: React.FC<ServiceHistoryFragmentProps> = ({
  serviceLogData,
  permissions,
  serviceId,
  service
}) => {
  const [selectedServiceLogId, setSelectedServiceLogId] = useState<number | null>();
  const [modalData, setModalData] = useState<ServiceLog>(SAMPLE_SERVICE_LOG);
  const [open, setOpen] = useState(false);
  const [interactionEditOpen, setInteractionEditOpen] = useState(false);
  const [interactionForm, setInteractionForm] = useState<FormObject>();

  const hideServiceLogQuery = useHideServiceLog();
  const getServiceLogsByIdQuery = useGetServiceLogsById(selectedServiceLogId || 0);
  const updateServiceLogsByIdQuery = useUpdateServiceLogsById();
  const createTaskServiceLogByApproveQuery = useCreateTaskServiceLogByApprove(serviceId);
  const downloadNmiCertificateQuery = useDownloadNmiCertificate();
  const editInteractionQuery = useEditInteraction();

  useEffect(() => {
    selectedServiceLogId && getServiceLogsByIdQuery.refetch();
  }, [selectedServiceLogId]);

  useEffect(() => {
    if(getServiceLogsByIdQuery.data){
      if (getServiceLogsByIdQuery.data.logTypeCode === LogType.InteractionEvent) {
        setInteractionForm(getServiceLogsByIdQuery.data.data.interactionForms);
      }else{
        setModalData(getServiceLogsByIdQuery.data);
      }
    }
  }, [getServiceLogsByIdQuery.data]);

  useEffect(() => {
    modalData.id && setOpen(true);
  }, [modalData]);

  const onClickDelete = async (serviceId: number) => {
    const data = {
      id: serviceId,
      isHidden: true
    };
    await hideServiceLogQuery.mutateAsync(data);
  };

  const onClickApprove = async (serviceId: number) => {
    const data = {
      id: serviceId,
      isApprove: true
    };
    await createTaskServiceLogByApproveQuery.mutateAsync(data);
  };

  const onClickDisapprove = async (serviceId: number) => {
    const data = {
      id: serviceId,
      isApprove: false
    };
    await createTaskServiceLogByApproveQuery.mutateAsync(data);
  };

  const onClickEdit = async (serviceId: number) => {
    if (isUserHasPermission(ActionPermissions.Service_Edit_Service_Log_Edit_Notes, permissions)) {
      setSelectedServiceLogId(serviceId);
    }
  };

  const onSave = async () => {
    if (modalData?.notes) {
      await updateServiceLogsByIdQuery.mutateAsync(modalData);
      setOpen(false);
      setModalData(SAMPLE_SERVICE_LOG);
      setSelectedServiceLogId(null);
      getServiceLogsByIdQuery.remove();
    }
  };

  const onClose = (event?: any, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      setModalData(SAMPLE_SERVICE_LOG);
      setSelectedServiceLogId(null);
      getServiceLogsByIdQuery.remove();
    }
  };

  const onClickDownload = async (serviceLogId: number) => {
    const response = await downloadNmiCertificateQuery.mutateAsync({ serviceId, serviceLogId });
    if (response && response.url) {
      const fileName = `Verification Certificate-${serviceLogId}-${formatDateTime(new Date(), 'YYYYMMDDHHMM')}.pdf`;
      downloadFile(response.url, fileName);
    }
  };

  const onClickInteractionEdit = async (serviceLogId: number) => {
    setSelectedServiceLogId(serviceLogId);
    setInteractionEditOpen(true);
  };

  const onChangedValue = async (editedForms: any) => {
    setInteractionForm(editedForms);
  };

  const onSaveInteraction = async () => {
    await editInteractionQuery.mutateAsync({ interactionForm, serviceId, serviceLogId: selectedServiceLogId });
    setInteractionEditOpen(false);
  };

  const onCloseEditInteraction = (event?: any, reason?: string) => {
    (reason !== 'backdropClick') && setInteractionEditOpen(false);
  };

  return (
    <>
      <TrackingHistory
        historyData={serviceLogData}
        onClickDelete={onClickDelete}
        onClickEdit={onClickEdit}
        onClickApprove={onClickApprove}
        onClickDisapprove={onClickDisapprove}
        onClickDownload={onClickDownload}
        onClickInteractionEdit={onClickInteractionEdit}
        isClosedService={service.serviceStatus.isClosed}
      />
      <PopupDialog
        title="Edit Note"
        isOpen={open}
        width="85%"
        maxWidth="800px"
        onClose={onClose}
        onSave={onSave}
      >
        <RichTextEditor
          value={modalData?.notes || ''}
          onValueChange={(value: string) => {
            modalData && setModalData({
              ...modalData,
              id: selectedServiceLogId || 0,
              notes: value
            });
          }}
        />
      </PopupDialog>
      <PopupDialog
        title="Edit Interaction"
        isOpen={interactionEditOpen}
        width="85%"
        maxWidth="800px"
        onClose={onCloseEditInteraction}
        onSave={onSaveInteraction}
      >
        <Stack>
          <br />
          {interactionForm && <FormGenerator
            subformTypes={[]}
            value={interactionForm}
            onChange={(val) => onChangedValue(val)}
            service={service}
            forms={[interactionForm]}
          />}
        </Stack>
      </PopupDialog>
    </>
  );
};