import React, { useState, useEffect, createContext, Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { InterceptedFormsObject } from '../../@types';
import { USER_TOKEN_KEY, BASE_URL } from '../../configs/envs';

export interface AxiosInterceptorProps {
  children: any;
}

export interface FormInterceptorContextProps {
  interceptedFormsObject: InterceptedFormsObject | undefined;
  setInterceptedFormsObject: Dispatch<SetStateAction<InterceptedFormsObject | undefined>>;
  hasInterceptedForms?: boolean;
  setHasInterceptedForms: Dispatch<SetStateAction<boolean>>;
}

export const FormInterceptorContext = createContext<FormInterceptorContextProps>({
  interceptedFormsObject: undefined,
  setInterceptedFormsObject: {} as Dispatch<SetStateAction<InterceptedFormsObject | undefined>>,
  hasInterceptedForms: false,
  setHasInterceptedForms: {} as Dispatch<SetStateAction<boolean>>
});

export const AxiosInterceptor: React.FC<AxiosInterceptorProps> = ({ 
  children 
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [interceptedFormsObject, setInterceptedFormsObject] = useState<InterceptedFormsObject>();
  const [hasInterceptedForms, setHasInterceptedForms] = useState<boolean>(false);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(config => {
      const token = localStorage.getItem(USER_TOKEN_KEY);
      
      if (!config?.headers) {
        throw new Error('Expected \'config\' and \'config.headers\' not to be undefined');
      }
      
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      config.headers['Content-Type'] = 'application/json';
      return config;
    }, error => {
      Promise.reject(error);
    });

    const responseInterceptor = axios.interceptors.response.use((response) => {
      if (response.data?.interactionFormData || response.data?.actionTriggers) {
        const interactionFormData = response.data?.interactionFormData;
        if (interactionFormData.length > 0 || response.data?.actionTriggers?.length) {
          const formList = interactionFormData.map((obj: any) => { 
            return { 
              ...obj.templateContent,
              ruleId: obj.id,
              isMandatory: obj.templateIsMandatory,
              isRepeatable: obj.templateIsRepeatable,
              templateDisplayLimit: obj.templateDisplayLimit,
              displayCount: obj.displayCount,
              isDownloadable: obj.templateIsDownloadable,
              isEditable: obj.templateIsEditable
            };
          });
          const originalUrl = response.config?.url?.replace(BASE_URL, '');
          const originalHttpMethod = response.config?.method?.toUpperCase();
          const originalPayload = JSON.parse(response.config?.data || {});
          const formsObject: InterceptedFormsObject = {
            url: originalUrl || '',
            method: originalHttpMethod || 'PUT',
            payload: originalPayload,
            forms: formList,
            actionTriggers: response.data?.actionTriggers
          };

          setInterceptedFormsObject(formsObject);
        } else {
          setInterceptedFormsObject(undefined);
        }
        setHasInterceptedForms(true);
      }

      return response;
    }, async (error) => {
      const config = error?.config;

      if (error?.response?.status === 401 && !config?.sent) {
        config.sent = true;

        const token = await getAccessTokenSilently();

        if (token) {
          localStorage.setItem(USER_TOKEN_KEY, token);

          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          };
        }

        return axios(config);
      }
    
      return Promise.reject(error);
    });

    return () => { 
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return (
    <FormInterceptorContext.Provider value={{ interceptedFormsObject, setInterceptedFormsObject, hasInterceptedForms, setHasInterceptedForms }}>
      {children}
    </FormInterceptorContext.Provider>
  );
};