import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { Typography } from '../../../components/atoms/Typography';
import { COLORS } from '../../../configs/colors';

export const GridContainer = styled('div')((props: { isMobile?: boolean }) =>`
  height: ${props.isMobile ? '86vh' : '92vh'};
  width: 100%;
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-viewport,
  .MuiDataGrid-row,
  .MuiDataGrid-renderingZone {
    max-height: fit-content !important;
  }
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-cell {
    max-height: fit-content !important;
    overflow: auto;
    white-space: initial !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
};`
);

export const LogoImageBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
`;
export const ImageWrapper = styled(Grid)`
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Image = styled('img')`
  background-color: ${COLORS.White};
`;

export const SwitchGrid = styled(Grid)`
  margin-left: 30px;
`;

export const HeaderWrapper = styled(Typography)`
  width: 100%;
  text-align: center;
  color: #25849D;
  margin: -10px 0;
`;