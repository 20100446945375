import React, { useState, useEffect } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Add } from '@mui/icons-material';
import { CapacityBox, StructureCapacityAddGrid, StyledTypography } from './CapacityList.styles';
import { CapacityListProps } from './CapacityList.props';
import { TypographyVariantProps } from '../../atoms/Typography';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { Capacity, CapacityData } from '../../organisms/Capacity';
import { SizeProps } from '../../../@types';

export const CapacityList: React.FC<CapacityListProps> = ({
  heading,
  capacityValue,
  capacityDefaultValue,
  onCapacityChange,
  onCapacityDelete
}) => {
  const [capacityData, setCapacityData] = useState<CapacityData[]>(capacityValue);

  useEffect(() => {
    capacityValue && setCapacityData(capacityValue);
  }, [capacityValue]);

  const addNewForm = () => setCapacityData([...capacityData, capacityDefaultValue]);

  const handleCapacityChange = (updatedCapacityData: CapacityData, index: number) => {
    const newCapacityData = capacityData.map((capacityDataItem, itemIndex) => index === itemIndex ? updatedCapacityData : capacityDataItem);

    setCapacityData(newCapacityData);
    onCapacityChange(newCapacityData);
  };

  const handleCapacityDelete = (index: number) => {
    onCapacityDelete(index);
    const updatedCapacityData = [...capacityData];
    index > -1 && updatedCapacityData.splice(index, 1);

    setCapacityData(updatedCapacityData);
  };

  return (
    <CapacityBox>
      <Grid container>
        <Grid container justifyContent="space-between" mx={1}>
          <Grid>
            <StyledTypography variant={TypographyVariantProps.H6}>{heading}</StyledTypography>
          </Grid>
          <StructureCapacityAddGrid>
            <Button
              variant={ButtonVariantProps.Secondary}
              startIcon={<Add />}
              onClick={() => addNewForm()}
            >
              Add
            </Button>
          </StructureCapacityAddGrid>
        </Grid>
        <TableContainer component={Paper}>
          <Table size={SizeProps.Small}>
            <TableBody>
              {capacityData?.map((capacityDataItem, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Grid pt="5px">
                      <Capacity
                        value={capacityDataItem}
                        onChange={(updatedData) => handleCapacityChange(updatedData, index)}
                        onDelete={() => handleCapacityDelete(index)}
                      />
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </CapacityBox>
  );
};