import { 
  useQuery, 
  useMutation, 
  useQueryClient, 
  UseQueryResult, 
  UseMutationResult 
} from '@tanstack/react-query';
import { 
  createInteractionTemplate, 
  getAllInteractionTemplates, 
  getInteractionsPartRequestFormPartQuantities, 
  getInteractionsPartRequestFormPartTypes, 
  getInteractionsPartRequestFormPartTypesChildrenWithParent, 
  updateInteractionTemplate,
  submitFormsDataWithOriginalPayload,
  getParameterData,
  getServiceSearchData,
  downloadNmiCertificate,
  editInteraction
} from '../api/interactions';
import {
  InteractionTemplateKey, 
  InteractionsGetParameterFormParameterDataKey, 
  InteractionsPartRequestFormPartQuantitiesKey, 
  InteractionsPartRequestFormPartTypesChildrenWithParentKey,
  InteractionsPartRequestFormPartTypesKey,
  InteractionsServiceSearchFormDataKey
} from '../configs/interactions';
import { 
  GetParameterResponseType,
  InteractionsPartRequestFormInitialPartType,
  InteractionsPartRequestFormPartQty,
  InteractionsPartRequestFormPartResponse,
  TemplateListResponse, 
  queryOptionType 
} from '../@types/interactions.type';
import { ServiceTypeKey } from '../configs/service-type';
import { 
  CheckInKey, 
  GetServiceKey, 
  ServiceCheckInKey, 
  ServiceLogAllKey 
} from '../configs/services';
import { PendingInteractionKey } from '../configs/pendingInteraction';
import { UploadedFilesKey } from '../configs/uploaded-files';
import { Folders } from '../@types/uploaded-files.type';
import { ServiceObject, ServiceSearchData } from '../@types';

export const useGetAllInteractionTemplates = (queryOptions: queryOptionType): UseQueryResult<TemplateListResponse> => {
  return useQuery([InteractionTemplateKey], () => getAllInteractionTemplates(queryOptions), {
    enabled: false,
    cacheTime: 0
  });
};

export const useAddInteractionTemplate = (): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (data: any) => {
    const response = await createInteractionTemplate(data);
    return response;
  }, {
    onSuccess: async (response: any) => {
      await queryClient.invalidateQueries([InteractionTemplateKey, response.id]);
      await queryClient.invalidateQueries([InteractionTemplateKey]);
    },
  });
};

export const useUpdateInteractionTemplate = (id?: number): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation((data: any) => updateInteractionTemplate(data.id, data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([InteractionTemplateKey]);
      await queryClient.invalidateQueries([InteractionTemplateKey, id]);
    },
  }); 
};

export const useSubmitFormsDataWithOriginalPayload = (id: number): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation(async (data: any) => {
    return await submitFormsDataWithOriginalPayload(data.url, data.method, {
      ...data.payload,
      interactionForms: data.forms || [],
      actionTriggers: data.actionTriggers || []
    });
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceTypeKey, id]);
      await queryClient.invalidateQueries([ServiceLogAllKey, id]);
      await queryClient.invalidateQueries([GetServiceKey, id]);
      await queryClient.invalidateQueries([ServiceCheckInKey, id]);
      await queryClient.invalidateQueries([CheckInKey, id]);
      await queryClient.invalidateQueries([PendingInteractionKey, id]);
      await queryClient.invalidateQueries([UploadedFilesKey, id, Folders.ServiceAttachments]);
    },
  });
};

export const useGetInteractionsPartRequestFormPartTypes = (serviceId: number): UseQueryResult<Array<InteractionsPartRequestFormInitialPartType>> => {
  return useQuery([InteractionsPartRequestFormPartTypesKey, serviceId], () => getInteractionsPartRequestFormPartTypes(serviceId));
};

export const useGetInteractionsPartRequestFormPartTypesChildrenWithParent = (partTypeId: number): UseQueryResult<InteractionsPartRequestFormPartResponse> => {
  return useQuery([InteractionsPartRequestFormPartTypesChildrenWithParentKey, partTypeId], () => getInteractionsPartRequestFormPartTypesChildrenWithParent(partTypeId));
};

export const useGetInteractionsPartRequestFormPartQuantities = (partTypeId: number, serviceId: number, serialised: boolean): UseQueryResult<InteractionsPartRequestFormPartQty> => {
  return useQuery([InteractionsPartRequestFormPartQuantitiesKey, partTypeId, serviceId], () => getInteractionsPartRequestFormPartQuantities(partTypeId, serviceId, serialised));
};

export const useGetParameterData = (serviceId: number, selectedParameter: string, mainLocationNodeId: number, subLocationNodeId: number, clientId: number): UseQueryResult<GetParameterResponseType> => {
  return useQuery([InteractionsGetParameterFormParameterDataKey, serviceId, selectedParameter], () => selectedParameter && getParameterData(serviceId, selectedParameter, mainLocationNodeId, subLocationNodeId, clientId));
};

export const useGetServiceSearchData = (serviceId: number, serviceSearchData: ServiceSearchData): UseQueryResult<ServiceObject[]> => {
  return useQuery([InteractionsServiceSearchFormDataKey, serviceId], () => serviceSearchData && getServiceSearchData(serviceId,serviceSearchData));
};

export const useDownloadNmiCertificate = (): UseMutationResult<any> => {
  return useMutation(async (data: any) => {
    return await downloadNmiCertificate(data);
  });
};

export const useEditInteraction = (): UseMutationResult<any> => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await editInteraction(data);
  }, {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries([ServiceLogAllKey, response.serviceId]);
    },
  });
};