import React, { useEffect, useState } from 'react';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Edit } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { ContentWrapperBox, Footer, HeadingWrapper, StyledButton } from './SLARuleList.styles';
import { DataGrid } from '../../../components/atoms/DataGrid';
import { TextField } from '../../../components/atoms/TextField';
import { DropdownItem, SelectMenu } from '../../../components/atoms/SelectMenu';
import { Checkbox, CheckboxColorProps } from '../../../components/atoms/Checkbox';
import { SelectionButton } from '../../../components/molecules/SelectionButton';
import { actionTypesWithAnyWildcard, availableStatusesWithAnyWildcard, prioritiesWithAnyWildcard, processTypesWithAnyWildcard, serviceTypesWithAnyWildcard } from '../../../utils/services';
import { NodeAttributeType, SLATargetType, StructureTypeCategory } from '../../../configs/enums';
import { SLAConfigMetaData, SLARuleListObject, SLARuleType } from '../../../@types/sla.type';
import { useGetNodeAttributesByCustomRange, useGetNodeChildrenByNodeType, useGetPrioritySelection, useGetStructuresByTypeCategoryCodeAndTypeCode } from '../../../queries/structure-query';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { InlineGroup, HorizontalAlignProps } from '../../../components/atoms/InlineGroup';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { Button, ButtonVariantProps } from '../../../components/atoms/Button';
import { ServiceStatus } from '../../../@types/service.type';
import { MultiSelectCheckmarksMenu } from '../../../components/atoms/MultiSelectCheckmarksMenu';
import { Switch } from '../../../components/atoms/Switch';
import { ActionPermissions, ColorProps, CustomPermission, NodeType, PrioritySelectionObjectType, StructureResponse } from '../../../@types';
import { SLATypeSelector } from '../../../components/molecules/SLATypeSelector';
import { useCreateSLAConfig, useGetAllSLAConfigs, useUpdateSLAConfig } from '../../../queries/sla-config-query';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { useGetServiceTypeAvailableStatuses } from '../../../queries/service-type-query';
import { Modal } from '../../../components/atoms/Modal';
import { MultiClientSelector } from '../../../components/molecules/MultiClientSelector';
import { convertToArray, isDropDownEmpty, isEmptyString } from '../../../utils/common';
import { ANY_KEYWORD } from '../../../constants/common';
import { GridContainer, Wrapper } from '../../../styled/common.styles';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { CloseIcon } from '../../../components/atoms/CloseIcon';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { useGetSLAConfigMetaData } from '../../../queries/sla-query';
import { SAMPLE_PRIORITY_SELECTION_OBJECT, SAMPLE_SLA_CONFIG_DATA } from '../../../constants/sla';

export const SLARuleList: React.FC = () => {
  const anyItem = [{ value: 'any', label: 'Any' }];
  const [openParentSelector, setOpenParentSelector] = useState(false);
  const [openSLATypeSelector, setOpenSLATypeSelector] = useState(false);
  const [openAddEditSLAConfig, setOpenAddEditSLAConfig] = useState(false);
  const [slaTypeSelectionData, setSlaTypeSelectionData] = useState({ slaType: '', value: '' });
  const [selectedParentId, setSelectedParentId] = useState('');
  const [queryNodeType, setQueryNodeType] = useState('client');
  const [parentId, setParentId] = useState('');
  const [nodeAttributeTypes, setNodeAttributeTypes] = useState<NodeAttributeType[]>([]);
  const [slaConfigData, setSlaConfigData] = useState<SLARuleType[]>([]);
  const [serviceTypeCode] = useState('');
  const [clients, setClients] = useState<DropdownItem[]>(anyItem);
  const [contracts, setContracts] = useState<DropdownItem[]>(anyItem);
  const [brands, setBrands] = useState<DropdownItem[]>(anyItem);
  const [slaConfigMetaData, setSLAConfigMetaData] = useState<SLAConfigMetaData>({ serviceTypes: [], actionTypes: [], processTypes: [], regionTypeList: [], slaScopeList: [], slaTypeList: [], slaTargetTypeList: [] });
  const [availableStatusList, setAvailableStatusList] = useState<DropdownItem[]>([]);
  const [availablePriorityList, setAvailablePriorityList] = useState<DropdownItem[]>([]);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [selectedSlaConfigRowData, setSelectedSlaConfigRowData] = useState<SLARuleType>(SAMPLE_SLA_CONFIG_DATA);
  const [prioritySelectionObject, setPrioritySelectionObject] = useState<PrioritySelectionObjectType>(SAMPLE_PRIORITY_SELECTION_OBJECT);
  const [slaRuleListData, setSlaRuleListData] = useState<SLARuleListObject>();
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [validate, setValidate] = useState(false);

  const getNodeChildrenByNodeType = useGetNodeChildrenByNodeType(selectedParentId, queryNodeType);
  const getAttributesInNodeQuery = useGetNodeAttributesByCustomRange(parentId ?? '', nodeAttributeTypes);
  const getStructuresByTypeCategoryCodeAndTypeCode = useGetStructuresByTypeCategoryCodeAndTypeCode(StructureTypeCategory.System, NodeType.Client);
  const getServiceTypeAvailableStatusesQuery = useGetServiceTypeAvailableStatuses();
  const getPrioritySelection = useGetPrioritySelection();
  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const getSLAConfigMetaDataQuery = useGetSLAConfigMetaData();

  const slaConfigsQuery = useGetAllSLAConfigs();
  const createSLAConfig = useCreateSLAConfig();
  const updateSLAConfig = useUpdateSLAConfig();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.05
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.3
    },
    {
      field: 'scopeName',
      headerName: 'Scope',
      flex: 0.2
    },
    {
      field: 'nodeHierarchy',
      headerName: 'Client',
      flex: 0.35
    },
    {
      field: 'serviceTypeNames',
      headerName: 'Service Type',
      flex: 0.2
    },
    {
      field: 'actionTypeNames',
      headerName: 'Action Type',
      flex: 0.2
    },
    {
      field: 'processTypeNames',
      headerName: 'Process Type',
      flex: 0.2
    },
    {
      field: 'priorityNames',
      headerName: 'Priority',
      flex: 0.2
    },
    {
      field: 'regionNames',
      headerName: 'Region',
      flex: 0.2
    },
    {
      field: 'targetText',
      headerName: 'Target',
      flex: 0.2
    },
    {
      field: 'slaTypeText',
      headerName: 'SLA Type',
      flex: 0.2
    },
    {
      field: 'ph',
      headerName: 'PH',
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) => params.row.ph ? 'Yes' : 'No'
    },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 0.3,
      valueGetter: (params: GridValueGetterParams) => params.row.notes
    },
    {
      field: 'isActive',
      headerName: 'Active',
      flex: 0.1,
      valueGetter: (params: GridValueGetterParams) => params.row.isActive ? 'Yes' : 'No'
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      width: 50,
      flex: 0.1,
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => (<Grid>
        {isUserHasPermission(ActionPermissions.Configure_Sla_Rules_Edit, permissions) && <IconButton aria-label="edit" onClick={() => {
          setSelectedSlaConfigRowData({ ...params.row, onStatusChangeStatuses: params.row.serviceStatuses?.length ? params.row.serviceStatuses[0].map((status: ServiceStatus) => status?.code) : [] });
          if (params.row?.client.length === 1) {
            setSelectedParentId(params.row?.client[0]);
            setQueryNodeType(NodeType.Contract);
            onChangeNodeType(NodeType.Contract, params.row?.client[0]);
          }
          onChangePriorities({ ...prioritySelectionObject, client: params.row?.client, contract: params.row?.contract, brand: params.row?.brand, serviceTypes: params.row?.serviceType });
          setOpenAddEditSLAConfig(true);
        }}>
          <Edit />
        </IconButton>}
      </Grid>)
    }
  ];

  TabHandler(`SLA Rules | ${PLATFORM_NAME}`);

  useEffect(() => {
    if (slaConfigsQuery.data) {
      setSlaConfigData(slaConfigsQuery.data || []);
    }
  }, [slaConfigsQuery.data]);

  useEffect(() => {
    if (parentId && nodeAttributeTypes && serviceTypeCode) {
      getAttributesInNodeQuery.refetch();
    }
  }, [parentId, nodeAttributeTypes, serviceTypeCode]);

  useEffect(() => {
    if (getStructuresByTypeCategoryCodeAndTypeCode.data) {
      setClients(anyItem.concat(getStructuresByTypeCategoryCodeAndTypeCode.data.map(item => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive }))));
    }
    else {
      setClients([]);
    }

  }, [getStructuresByTypeCategoryCodeAndTypeCode.data]);

  useEffect(() => {
    if (getNodeChildrenByNodeType.data) {
      mapData(getNodeChildrenByNodeType.data);
    }
  }, [getNodeChildrenByNodeType.data]);

  useEffect(() => {
    const getServiceStatus = async () => {
      if (selectedSlaConfigRowData?.serviceType) {
        const statuses: ServiceStatus[] = await getServiceTypeAvailableStatusesQuery.mutateAsync({ serviceTypes: selectedSlaConfigRowData?.serviceType });
        const mappedStatuses = statuses?.map((item) => { return { value: item.code, label: item.name }; });
        setAvailableStatusList(mappedStatuses);
      }
    };
    getServiceStatus();
  }, [selectedSlaConfigRowData]);

  const mapData = async (data: StructureResponse[]) => {
    if (queryNodeType == NodeType.Contract) {
      setContracts(anyItem.concat(data.map(item => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive }))));
      setSelectedParentId(selectedSlaConfigRowData?.contract[0]);
      setQueryNodeType(NodeType.Brand);
    }
    else if (queryNodeType == NodeType.Brand) {
      setBrands(anyItem.concat(data.map(item => ({ value: item.id.toString(), label: item.name, disabled: !item.isActive }))));
    }
  };
  
  useEffect(() => {
    if(getSLAConfigMetaDataQuery.data) {
      const data = getSLAConfigMetaDataQuery.data;
      setSLAConfigMetaData({
        ...data,
        regionTypeList: [...anyItem, ...data.regionTypes.map(item => { 
          return { value: item.code, label: item.name }; })],
        slaScopeList: data.slaScopes.map(item => { 
          return { value: item.code, label: item.name }; }),
        slaTypeList: data.slaTimerTypes.map(item => { 
          return { value: item.code, label: item.name }; }),
        slaTargetTypeList: data.slaTargets.map(item => { 
          return { value: item.code, label: item.name }; })
      });
    }
  }, [getSLAConfigMetaDataQuery.data]);

  useEffect(() => {
    if (selectedParentId && queryNodeType && selectedParentId !== 'any' && selectedParentId.split(',').length === 1) {
      refetchData();
    }
  }, [selectedParentId, queryNodeType]);

  useEffect(() => {
    if (getUserPermissionsQuery.data) {
      setPermissions(getUserPermissionsQuery.data);
    }
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  const refetchData = async () => {
    await getNodeChildrenByNodeType.refetch();
  };

  const addNewRule = () => {
    setPrioritySelectionObject(SAMPLE_PRIORITY_SELECTION_OBJECT);
    setAvailablePriorityList([]);
    setParentId('');
    setSelectedParentId('');
    setOpenAddEditSLAConfig(true);
  };

  const getPriorities = async (value: PrioritySelectionObjectType) => {
    if(!value.client.includes(ANY_KEYWORD)) {
      const priorities: any = await getPrioritySelection.mutateAsync(value);
      setAvailablePriorityList(priorities ? priorities : []);
    } else setAvailablePriorityList([]);
  };

  useEffect(() => {
    if (prioritySelectionObject?.client && prioritySelectionObject?.contract && prioritySelectionObject?.brand && prioritySelectionObject?.serviceTypes) {
      getPriorities(prioritySelectionObject);
    }
  }, [prioritySelectionObject]);

  const onChangePriorities = async (prioritySelectionValue: PrioritySelectionObjectType) => {
    setPrioritySelectionObject(prioritySelectionValue);
  };

  const onChangeNodeType = (nodeType: NodeType, parentId: string) => {
    const nodeAttributeTypes = [NodeAttributeType.PriorityAndServices];
    setSelectedParentId(parentId);
    setQueryNodeType(nodeType);
    setParentId(parentId);
    setNodeAttributeTypes(nodeAttributeTypes);
  };

  const handleClose = () => {
    setOpenAddEditSLAConfig(false);
    setSelectedSlaConfigRowData(SAMPLE_SLA_CONFIG_DATA);
    setSlaRuleListData({});
    setValidate(false);
  };

  const arrayValueValidator = (value: string[]) => {
    if (value?.length <= 0) {
      return true;
    } else {
      return false;
    }
  };

  const onSave = async () => {
    const validateName = isEmptyString(selectedSlaConfigRowData?.name) || !selectedSlaConfigRowData?.name;
    const validateSLAScope = isDropDownEmpty(selectedSlaConfigRowData?.scope);
    const validateClientContractBrand = !selectedSlaConfigRowData?.client || !selectedSlaConfigRowData?.contract || !selectedSlaConfigRowData?.brand;
    const validateServiceType = arrayValueValidator(selectedSlaConfigRowData?.serviceType) || !selectedSlaConfigRowData?.serviceType;
    const validateActionType = arrayValueValidator(selectedSlaConfigRowData?.actionType) || !selectedSlaConfigRowData?.actionType;
    const validateProcessType = arrayValueValidator(selectedSlaConfigRowData?.processType) || !selectedSlaConfigRowData?.processType;
    const validatePriority = arrayValueValidator(selectedSlaConfigRowData?.priority) || !selectedSlaConfigRowData?.priority;
    const validateRegion = arrayValueValidator(selectedSlaConfigRowData?.region) || !selectedSlaConfigRowData?.region;
    const validateTargetType = isDropDownEmpty(selectedSlaConfigRowData?.targetType);
    let validateTargetTypeOnStatusChangeStatuses = false;
    if (selectedSlaConfigRowData?.targetType === SLATargetType.OnStatusChange && selectedSlaConfigRowData?.onStatusChangeStatuses) {
      validateTargetTypeOnStatusChangeStatuses = arrayValueValidator(selectedSlaConfigRowData?.onStatusChangeStatuses) || selectedSlaConfigRowData?.onStatusChangeStatuses === undefined;
    }
    const validateSLATypeSelection = selectedSlaConfigRowData?.slaType === undefined || isDropDownEmpty(selectedSlaConfigRowData?.slaType);

    setSlaRuleListData({
      ...slaRuleListData,
      isName: !!validateName,
      isClientContractBrand: !!validateClientContractBrand,
      isServiceType: !!validateServiceType,
      isActionType: !!validateActionType,
      isProcessType: !!validateProcessType,
      isPriority: !!validatePriority,
      isRegion: !!validateRegion,
      isTargetTypeOnStatusChangeStatuses: !!validateTargetTypeOnStatusChangeStatuses,
      isSLATypeSelection: !!validateSLATypeSelection
    });

    if (validateName || validateSLAScope || validateClientContractBrand || validateServiceType || validateActionType || validateProcessType || validatePriority || validateRegion || validateTargetType || validateTargetTypeOnStatusChangeStatuses || validateSLATypeSelection) {
      setValidate(true);
      return;
    }

    const finalResult = {
      id: selectedSlaConfigRowData?.id,
      actionType: selectedSlaConfigRowData.actionType,
      brand: selectedSlaConfigRowData.brand,
      client: selectedSlaConfigRowData.client,
      contract: selectedSlaConfigRowData.contract,
      isActive: selectedSlaConfigRowData.isActive ? selectedSlaConfigRowData.isActive : false,
      notes: selectedSlaConfigRowData.notes,
      ph: selectedSlaConfigRowData.ph ? selectedSlaConfigRowData.ph : false,
      priority: selectedSlaConfigRowData.priority,
      processType: selectedSlaConfigRowData.processType,
      region: selectedSlaConfigRowData.region,
      scope: selectedSlaConfigRowData.scope,
      serviceType: selectedSlaConfigRowData.serviceType,
      slaType: selectedSlaConfigRowData.slaType,
      slaTypeValue: selectedSlaConfigRowData.duration,
      targetType: selectedSlaConfigRowData.targetType,
      targetTypeOnStatusChangeStatuses: selectedSlaConfigRowData.onStatusChangeStatuses,
      name: selectedSlaConfigRowData.name
    };

    if (finalResult.id === -1) {
      await createSLAConfig.mutateAsync(finalResult);
      setOpenToast(true);
      handleClose();
    } else {
      await updateSLAConfig.mutateAsync(finalResult);
      setOpenToast(true);
      handleClose();
    }
  };

  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            SLA Rules
          </Typography>
          {
            isUserHasPermission(ActionPermissions.Configure_Sla_Rules_Create, permissions) && <Button variant={ButtonVariantProps.Primary} startIcon={<AddIcon />} onClick={() => addNewRule()}>
              ADD NEW RULE
            </Button>
          }
        </InlineGroup>
        {isUserHasPermission(ActionPermissions.Configure_Sla_Rules_Search_View, permissions) && <GridContainer>
          <DataGrid
            columns={columns}
            rows={slaConfigData}
            getRowId={(row) => row?.id}
            disableColumnFilter={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            loading={slaConfigsQuery.isFetching}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: false
              }
            }}
            getRowHeight={() => 'auto'}
          />
          <Modal
            open={openAddEditSLAConfig}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <ContentWrapperBox>
              <CloseIcon handleClose={handleClose} />
              <HeadingWrapper variant={TypographyVariantProps.H6}>
                SLA Config #{`${selectedSlaConfigRowData?.id === -1 ? 'New' : selectedSlaConfigRowData?.id}`}
              </HeadingWrapper>
              <Grid container>
                <Grid mt={2} xs={5} mr={4} ml={2}>
                  <TextField
                    label="Name *"
                    fullWidth
                    error={!!slaRuleListData?.isName}
                    value={selectedSlaConfigRowData?.name}
                    onChange={(value) => {
                      setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, name: value });
                      setSlaRuleListData({ ...slaRuleListData, isName: isEmptyString(value) });
                    }}
                  />
                </Grid>
                <Grid xs={5} mt={2}>
                  <SelectMenu
                    id="scope-select"
                    label="SLA Scope *"
                    required={true}
                    items={slaConfigMetaData.slaScopeList}
                    selectedValue={selectedSlaConfigRowData?.scope || '-1'}
                    onChange={(value) => {
                      setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, scope: value });
                    }}
                    validate={validate}
                    optionalLabelEnabled={true}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid mt={2} xs={5} ml={2} mr={4}>
                  <SelectionButton
                    label="Client Selection *"
                    value={selectedSlaConfigRowData?.nodeHierarchy || ''}
                    error={!!slaRuleListData?.isClientContractBrand}
                    onClick={() => {
                      setOpenParentSelector(true);
                      getStructuresByTypeCategoryCodeAndTypeCode.refetch();
                    }}
                  />
                </Grid>
                <Grid xs={5} mt={2}>
                  <MultiSelectCheckmarksMenu
                    id="service-type-options"
                    label="Service Type *"
                    selectedValue={selectedSlaConfigRowData?.serviceType}
                    error={!!slaRuleListData?.isServiceType}
                    onChange={(serviceTypes) => {
                      setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, serviceType: convertToArray(serviceTypes) });
                      onChangePriorities({ ...prioritySelectionObject, serviceTypes: convertToArray(serviceTypes) });
                      setSlaRuleListData({ ...slaRuleListData, isServiceType: arrayValueValidator(convertToArray(serviceTypes)) });
                      if (convertToArray(serviceTypes).some((serviceType: string) => serviceType === ANY_KEYWORD)) {
                        setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, serviceType: [ANY_KEYWORD] });
                      }
                    }}
                    wildCard={false}
                    items={
                      selectedSlaConfigRowData?.serviceType?.some((serviceType: string) => serviceType === ANY_KEYWORD) ?
                        serviceTypesWithAnyWildcard(slaConfigMetaData.serviceTypes).filter((serviceType) => serviceType.value === ANY_KEYWORD) :
                        serviceTypesWithAnyWildcard(slaConfigMetaData.serviceTypes)
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid xs={5} mr={4} mt={2} ml={2}>
                  <MultiSelectCheckmarksMenu
                    id="action-type-options"
                    label="Action Type *"
                    error={!!slaRuleListData?.isActionType}
                    selectedValue={selectedSlaConfigRowData?.actionType}
                    onChange={(actionTypes) => {
                      setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, actionType: convertToArray(actionTypes) });
                      setSlaRuleListData({ ...slaRuleListData, isActionType: arrayValueValidator(convertToArray(actionTypes)) });
                      if (convertToArray(actionTypes).some((actionType: string) => actionType === ANY_KEYWORD)) {
                        setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, actionType: [ANY_KEYWORD] });
                      }
                    }}
                    items={
                      selectedSlaConfigRowData?.actionType?.some((actionType: string) => actionType === ANY_KEYWORD) ?
                        actionTypesWithAnyWildcard(slaConfigMetaData.actionTypes).filter((actionType) => actionType.value === ANY_KEYWORD) :
                        actionTypesWithAnyWildcard(slaConfigMetaData.actionTypes)
                    }
                  />
                </Grid>
                <Grid xs={5} mr={2} mt={2}>
                  <MultiSelectCheckmarksMenu
                    label="Process Type *"
                    id="process-type-options"
                    error={!!slaRuleListData?.isProcessType}
                    selectedValue={selectedSlaConfigRowData?.processType}
                    onChange={(processTypes) => {
                      setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, processType: convertToArray(processTypes) });
                      setSlaRuleListData({ ...slaRuleListData, isProcessType: arrayValueValidator(convertToArray(processTypes)) });
                      if (convertToArray(processTypes).some((processType: string) => processType === ANY_KEYWORD)) {
                        setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, processType: [ANY_KEYWORD] });
                      }
                    }}
                    items={
                      selectedSlaConfigRowData?.processType?.some((processType: string) => processType === ANY_KEYWORD) ?
                        processTypesWithAnyWildcard(slaConfigMetaData.processTypes).filter((processType) => processType.value === ANY_KEYWORD) :
                        processTypesWithAnyWildcard(slaConfigMetaData.processTypes)
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid mt={2} xs={5} mr={4} ml={2}>
                  <MultiSelectCheckmarksMenu
                    label="Priority *"
                    id="priority-select-options"
                    error={!!slaRuleListData?.isPriority}
                    selectedValue={selectedSlaConfigRowData?.priority}
                    onChange={(priority) => {
                      setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, priority: convertToArray(priority) });
                      setSlaRuleListData({ ...slaRuleListData, isPriority: arrayValueValidator(convertToArray(priority)) });
                      if (convertToArray(priority).some((priority: string) => priority === ANY_KEYWORD)) {
                        setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, priority: [ANY_KEYWORD] });
                      }
                    }}
                    items={
                      selectedSlaConfigRowData?.priority?.some((priority: string) => priority === ANY_KEYWORD) ?
                        prioritiesWithAnyWildcard(availablePriorityList).filter((priority) => priority.value === ANY_KEYWORD) :
                        prioritiesWithAnyWildcard(availablePriorityList)
                    }
                  />
                </Grid>
                <Grid xs={5} mt={2}>
                  <MultiSelectCheckmarksMenu
                    label="Region *"
                    id="region-select"
                    error={!!slaRuleListData?.isRegion}
                    selectedValue={selectedSlaConfigRowData?.region}
                    onChange={(region) => {
                      setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, region: convertToArray(region) });
                      setSlaRuleListData({ ...slaRuleListData, isRegion: arrayValueValidator(convertToArray(region)) });
                      if (convertToArray(region).some((region: string) => region === ANY_KEYWORD)) {
                        setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, region: [ANY_KEYWORD] });
                      }
                    }}
                    items={selectedSlaConfigRowData?.region?.some((region: string) => region === ANY_KEYWORD) ? slaConfigMetaData.regionTypeList.filter((region) => region.value === ANY_KEYWORD) : slaConfigMetaData.regionTypeList}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid xs={10.5} mt={2} mr={2} ml={2}>
                  <SelectionButton
                    label="SLA Type Selection *"
                    error={!!slaRuleListData?.isSLATypeSelection}
                    value={selectedSlaConfigRowData?.slaTypeText || ''}
                    onClick={() => {
                      setOpenSLATypeSelector(true);
                      setSlaTypeSelectionData({
                        slaType: selectedSlaConfigRowData.slaType,
                        value: selectedSlaConfigRowData.duration || selectedSlaConfigRowData.timer.cutoffTime || ''
                      });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid mt={2} xs={5} mr={3.4} ml={2}>
                  <SelectMenu
                    id="target-type-select"
                    label="Target Type *"
                    required={true}
                    items={slaConfigMetaData.slaTargetTypeList}
                    selectedValue={selectedSlaConfigRowData?.targetType || '-1'}
                    onChange={(value) => {
                      setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, targetType: value });
                    }}
                    validate={validate}
                    optionalLabelEnabled={true}
                  />
                </Grid>
                {selectedSlaConfigRowData?.targetType === SLATargetType.OnStatusChange && <Grid xs={5} mt={2} mr={2} ml={1}>
                  <MultiSelectCheckmarksMenu
                    label="On Change Statuses *"
                    id="on-change-statuses-select"
                    error={!!slaRuleListData?.isTargetTypeOnStatusChangeStatuses}
                    selectedValue={selectedSlaConfigRowData?.onStatusChangeStatuses}
                    onChange={(status) => {
                      setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, onStatusChangeStatuses: convertToArray(status) });
                      setSlaRuleListData({ ...slaRuleListData, isTargetTypeOnStatusChangeStatuses: arrayValueValidator(convertToArray(status)) });
                      if (convertToArray(status).some((status: string) => status === ANY_KEYWORD)) {
                        setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, onStatusChangeStatuses: [ANY_KEYWORD] });
                      }
                    }}
                    items={
                      selectedSlaConfigRowData?.onStatusChangeStatuses?.some((status: string) => status === ANY_KEYWORD) ?
                        availableStatusesWithAnyWildcard(availableStatusList).filter((status) => status.value === ANY_KEYWORD) :
                        availableStatusesWithAnyWildcard(availableStatusList)
                    }
                  />
                </Grid>}
              </Grid>
              <Grid container xs={10.5} mb={2} mt={2} mr={2} ml={2}>
                <TextField
                  label="Notes"
                  fullWidth
                  multiline
                  rows={3}
                  value={selectedSlaConfigRowData?.notes}
                  onChange={(value) => {
                    setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, notes: value });
                  }}
                />
              </Grid>
              <Grid mb={2} mr={2} ml={2}>
                <Checkbox
                  color={CheckboxColorProps.Primary}
                  checked={selectedSlaConfigRowData?.ph}
                  onChange={(event) => {
                    setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, ph: event.target.checked });
                  }}
                /> Applicable for Public Holiday
              </Grid>
              <Grid mb={1} mr={2} ml={2}>
                <Switch
                  checked={selectedSlaConfigRowData?.isActive}
                  color={ColorProps.Success}
                  onChange={(event) => {
                    setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, isActive: event.target.checked });
                  }}
                /> isActive
              </Grid>
              <Grid container mb={2}>
                <Footer>
                  <StyledButton variant={ButtonVariantProps.Secondary} onClick={handleClose}>Cancel</StyledButton>
                  <StyledButton variant={ButtonVariantProps.Primary} onClick={onSave}>Save</StyledButton>
                </Footer>
              </Grid>
              <MultiClientSelector
                open={openParentSelector}
                onChangeNodeType={onChangeNodeType}
                onClose={() => setOpenParentSelector(false)}
                handleParentSelection={(selectedClients: string[], selectedContracts: string[], clientHierarchy: string, selectedBrands?: string[]) => {
                  setOpenParentSelector(false);
                  setSelectedSlaConfigRowData({ ...selectedSlaConfigRowData, client: selectedClients, contract: selectedContracts, brand: selectedBrands, nodeHierarchy: clientHierarchy, priority: [] });
                  onChangePriorities({ ...prioritySelectionObject, client: selectedClients, contract: selectedContracts, brand: selectedBrands || [] });
                  setSlaRuleListData({ ...slaRuleListData, isClientContractBrand: arrayValueValidator(selectedClients) || arrayValueValidator(selectedContracts) || arrayValueValidator(selectedBrands ? selectedBrands : []) });
                }}
                clients={clients}
                contracts={contracts}
                brands={brands}
                selectedMultiClients={selectedSlaConfigRowData?.client}
                selectedMultiContracts={selectedSlaConfigRowData?.contract}
                selectedMultiBrands={selectedSlaConfigRowData?.brand}
                hasBrandSelection={true}
              />
              <SLATypeSelector
                open={openSLATypeSelector}
                handleClose={() => { 
                  setOpenSLATypeSelector(false);
                  setSlaTypeSelectionData({ slaType: '', value: '' });
                }}
                selectedSlaType={slaTypeSelectionData.slaType || '-1'}
                selectedValue={slaTypeSelectionData.value}
                handleSlaTypeSelection={() => setOpenSLATypeSelector(false)}
                onChange={(selectedSlaType, value, slaTypeHierarchy) => {
                  setSelectedSlaConfigRowData(prev => ({ ...prev, slaType: selectedSlaType, duration: value, slaTypeText: slaTypeHierarchy } ) );
                  setSlaRuleListData({ ...slaRuleListData, isSLATypeSelection: isEmptyString(selectedSlaType) });
                }}
                slaTypeData={slaConfigMetaData.slaTypeList}
              />
            </ContentWrapperBox>
          </Modal>
          <Snackbar
            open={openToast}
            autoHideDuration={2000}
            message="Successfully Saved"
            onClose={() => { setOpenToast(false); }}
          />
        </GridContainer>
        }
      </Wrapper>
    </PageContainer>
  );
};