import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import {
  PartObject,
  PartConditionDropdown,
  PartLocationObject,
  PartListResponse,
  PartHistoryDataType,
  NSPartLocationList,
  SerailizedPartsList,
  PartHistoryMovementListResponse,
  PartHistoryMovementListFilterOptions,
  MerchantDamageTemplateQuery,
  MerchantDamagePartSerialQueryData,
  DuplicatePartsDataType,
  PartHistory,
  UpdatePartLocationObject,
} from '../@types/part.type';
import {
  getAllWarrantySummary,
  getPartById,
  getPartConditions,
  getPartExistanceBySerials,
  getNSPartExistanceBySerials,
  getPartHistoryByPartId,
  getPartLocationDataById,
  updatePart,
  updatePartLocation,
  getPartLocationDataByServiceId,
  updateBulkPart,
  getPartMovementHistory,
  getPartHistoryByServiceIdAndHistoryTypeCodes,
  getDuplicatePartsBySerial1AndPartType,
  mergeDuplicateParts,
  createExceptionalPart,
  updateExceptionalPart,
  getPartRepairHistory,
  getLatestPartMomentHistory,
  getPartByIdForService,
  getPartConditionsForService,
  getPartLocationDataByIdForService,
  updatePartForService,
  processPartBulkUpdateFile,
  getPartLocationDataByPartId
} from '../api/part';
import { WarrantyPanalType } from '../@types/warrantySummary.type';
import { QueryOption } from '../@types';
import { PartTypeLocationItemData } from '../components/molecules/PartTypeLocationItem';
import {
  getAllParts,
  getNSPartByPartTypeAndLocation,
  getPartsLogAgainstService,
  getMerchantDamagePartSerialData,
  updatePartAdditionalData
} from '../api/parts';
import {
  PartKey,
  PartConditionKey,
  WarrantySummaryKey,
  PartLocationKey,
  PartsKey,
  PartHistoryKey,
  PartExistanceKey,
  NSPartExistanceKey,
  NSPartLocationListKey,
  PartLocationsListKey,
  MerchantDamagePartSerialDataKey,
  PartHistoryListForServiceKey,
  DuplicatePartsKey,
  LatestPartMomentHistory
} from '../configs/part';
import { ServiceLogAllKey } from '../configs/services';
import { PartActionHistoryDataType } from '../@types/partHistory.type';
import { getSelectedColumnNames } from '../utils/columnSelectionOperations';
import { ColumnSelectionItem } from '../components/templates/ColumnSelection';

export const useGetOnePart = (id: number): UseQueryResult<PartObject> => {
  return useQuery([PartKey, id], () => id && getPartById(id));
};

export const useGetOnePartForService = (id: number): UseQueryResult<PartObject> => {
  return useQuery([PartKey, id], () => id && getPartByIdForService(id));
};

export const useGetPartConditions = (): UseQueryResult<[PartConditionDropdown]> => {
  return useQuery([PartConditionKey], () => getPartConditions(), { staleTime: 5 * 60 * 60 * 1000 });
};

export const useGetPartConditionsForService = (): UseQueryResult<[PartConditionDropdown]> => {
  return useQuery([PartConditionKey], () => getPartConditionsForService(), { staleTime: 5 * 60 * 60 * 1000 });
};

export const useUpdatePart = (id: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updatePart(data.id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([PartKey, id]);
      await queryClient.invalidateQueries([PartHistoryKey, id]);
      await queryClient.invalidateQueries([WarrantySummaryKey, id]);
    },
  });
};

export const useUpdatePartForService = (id: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updatePartForService(data.id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([PartKey, id]);
      await queryClient.invalidateQueries([PartHistoryKey, id]);
    },
  });
};

export const useGetAllWarrantySummary = (id: number): UseQueryResult<[WarrantyPanalType]> => {
  return useQuery([WarrantySummaryKey, id], () => id && getAllWarrantySummary(id));
};

export const useGetPartLocationDataById = (id: number): UseQueryResult<PartLocationObject> => {
  return useQuery([PartLocationKey, id], () => id && getPartLocationDataById(id));
};

export const useGetPartLocationDataByPartId = (id: number, locationData: UpdatePartLocationObject): UseQueryResult<PartLocationObject> => {
  return useQuery([PartLocationKey, id], () => id && getPartLocationDataByPartId(id, locationData), { enabled: false });
};

export const useGetPartLocationDataByIdForService = (id: number): UseQueryResult<PartLocationObject> => {
  return useQuery([PartLocationKey, id], () => id && getPartLocationDataByIdForService(id));
};

export const useGetPartLocationDataByServiceId = (partTypeId: number, serviceId: number): UseQueryResult<[PartTypeLocationItemData]> => {
  return useQuery([PartLocationsListKey, partTypeId, serviceId], () => getPartLocationDataByServiceId(partTypeId, serviceId));
};

export const useUpdatePartLocation = (id: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updatePartLocation(data.id, data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([PartLocationKey, id]);
    },
  });
};

export const useGetAllParts = (queryOption: QueryOption, serialSearch?: string, columnSelectionItems?: ColumnSelectionItem[]): UseQueryResult<PartListResponse> => {
  return useQuery([PartsKey, queryOption], () => getAllParts(queryOption, serialSearch, getSelectedColumnNames(columnSelectionItems) ), { cacheTime: 0, enabled: false });
};

export const useGetPartHistoryByPartId = (id: number): UseQueryResult<[PartHistoryDataType]> => {
  return useQuery([PartHistoryKey, id], () => getPartHistoryByPartId(id));
};

export const useGetPartExistanceBySerials = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    const response = await getPartExistanceBySerials(data);
    return response;
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([PartExistanceKey]);
    },
  });
};

export const useGetNSPartExistanceBySerials = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    const response = await getNSPartExistanceBySerials(data);
    return response;
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([NSPartExistanceKey]);
    },
  });
};

export const useGetNSPartByPartTypeAndLocation = (partTypeId: number, locationId: number, isReturn?: boolean): UseQueryResult<NSPartLocationList[]> => {
  return useQuery([NSPartLocationListKey, partTypeId, locationId],
    () => getNSPartByPartTypeAndLocation(partTypeId, locationId, isReturn),
    {
      enabled: false
    }
  );
};

export const useUpdateBulkParts = (): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updateBulkPart(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([PartsKey]);
    },
  });
};

export const useGetPartsLogAgainstService = (serviceId: number, locationTypeCode: string): UseQueryResult<[SerailizedPartsList]> => {
  return useQuery([PartKey, serviceId], () => getPartsLogAgainstService(serviceId, locationTypeCode));
};

export const useGetPartMovementHistory = (queryOption: PartHistoryMovementListFilterOptions): UseQueryResult<PartHistoryMovementListResponse> => {
  return useQuery([PartHistoryKey, queryOption], () => ((queryOption.startDate && queryOption.endDate) || queryOption.partId) && getPartMovementHistory(queryOption), { enabled: false, cacheTime: 0 });
};

export const useGetMerchantDamagePartSerialDataQuery = (queryOption: MerchantDamageTemplateQuery): UseQueryResult<[MerchantDamagePartSerialQueryData]> => {
  return useQuery([MerchantDamagePartSerialDataKey], () => getMerchantDamagePartSerialData(queryOption), { cacheTime: 0 });
};

export const useUpdatePartAdditionalData = (partId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await updatePartAdditionalData(data, partId);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([ServiceLogAllKey]);
    },
  });
};
export const useGetPartHistoryByServiceIdAndHistoryTypeCodes = (serviceId: number, historyTypes: string[]): UseQueryResult<Array<PartActionHistoryDataType>> => {
  return useQuery([PartHistoryListForServiceKey, serviceId], () => getPartHistoryByServiceIdAndHistoryTypeCodes(serviceId, historyTypes));
};

export const useGetDuplicatePartsBySerial1AndPartType = (mainPartId: number, serial1: string, partTypeId: number): UseQueryResult<Array<DuplicatePartsDataType>> => {
  return useQuery([DuplicatePartsKey, mainPartId], () => mainPartId && serial1 && partTypeId && getDuplicatePartsBySerial1AndPartType(mainPartId, serial1, partTypeId));
};

export const useGetPartRepairHistory = (partId: number): UseQueryResult<PartHistory[]> => {
  return useQuery([PartsKey, partId], () => getPartRepairHistory(partId));
};

export const useGetLatestPartMomentHistory = (partId: number): UseQueryResult<PartHistory> => {
  return useQuery([LatestPartMomentHistory, partId], () => getLatestPartMomentHistory(partId));
};

export const useMergeDuplicateParts = (mainPartId: number): UseMutationResult => {
  const queryClient = useQueryClient();

  return useMutation(async (data: any) => {
    return await mergeDuplicateParts(data);
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([WarrantySummaryKey, mainPartId]);
      await queryClient.invalidateQueries([PartHistoryKey]);
      await queryClient.invalidateQueries([DuplicatePartsKey, mainPartId]);
    },
  });
};

export const useCreateExceptionalPart = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    return await createExceptionalPart(data);
  });
};

export const useUpdateExceptionalPart = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    return await updateExceptionalPart(data.id, data);
  });
};

export const useProcessPartBulkUpdateFile = (): UseMutationResult => {
  return useMutation(async (fileList: any) => {
    return await processPartBulkUpdateFile(fileList);
  });
};