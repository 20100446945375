export enum FileUploadActionType {
  Archive = 'archive',
  Download = 'download',
  View = 'view'
}

export enum Folders {
  Attachments = 'attachments',
  PartTypesImages = 'partTypesImages',
  PartTypesAttachments = 'partTypesAttachments',
  UserProfiles = 'userProfiles',
  ClientContractLogos = 'clientContractLogos',
  ServiceAttachments = 'serviceAttachments',
  ShipmentAddStockAttachments = 'shipmentAddStockAttachments',
  InteractionForms = 'InteractionForms',
  PartImages='part_images',
  ServiceImage = 'serviceImage',
}

export enum FileTypes {
  PDF = 'application/pdf',
  Image = 'image/*',
  MSExcel = 'application/vnd.ms-excel',
  OpenXml = 'application/vnd.openxmlformats',
  OpenXmlExtend = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  TextCSV = 'text/csv',
  MP4 = 'video/mp4'
}

export interface UploadedFile {
  id: number;
  fileName: string;
  mimeType: string;
  key?: string;
  url: string;
  createdBy: string;
  createdAt: string;
}

export interface UploadFileAditionalData {
  id: number;
  isMain: boolean;
}

export interface UploadedCsvFile {
  url: string;
  fileName: string;
}

export interface UploadedFileActionData {
  id: number;
  contentDispositionType: string;
  mimeType: string;
}

export enum FileMimeTypes {
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PDF = 'application/pdf',
  MSExcel = 'application/vnd.ms-excel',
  OpenXml = 'application/vnd.openxmlformats',
  OpenXmlExtend = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  TextCSV = 'text/csv'
}

export interface FileAttachmentURLType {
  key: string;
  mimeType: string;
  contentDispositionType: string;
  fileName?: string;
}