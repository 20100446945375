import { styled } from '@mui/material/styles';
import { Typography } from '../../../components/atoms/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Link } from '../../atoms/Link';
import { IconButton } from '@mui/material';

export const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: #f7f7f7;
`;

export const BoldLabel = styled(Typography)`
  text-align: center;
  padding: 1px;
  font-weight: bold;
`;

export const ItalicLabel = styled(Typography)`
  text-align: center;
  padding: 1px;
  font-style: italic;
`;

export const SmallItalicLabel = styled(Typography)`
  text-align: center;
  padding: 1px;
  font-style: italic;
  font-size: smaller;
`;

export const Label = styled(Typography)`
  text-align: center;
  padding: 1px;
`;

export const SmallLabel = styled(Typography)`
  text-align: center;
  padding: 1px;
  font-style: italic;
  font-size: x-small;
`;

export const SmallShipmentLabel = styled(Typography)`
  padding: 1px;
  font-style: italic;
  font-size: x-small;
`;

export const GridContainer = styled('div')((props: { isMobile?: boolean; rowLength: number }) =>`
    height: ${props.rowLength === 1 ? '200px' : props.rowLength === 2 ? '300px' : props.rowLength > 2 ? '350px' : '100px'};
    width: 100%;
    margin-top: ${props.isMobile && '-25px'};
    .MuiDataGrid-main {
      border: ${props.isMobile && 'none !important'};
    }
    /* Allows the MUI grid rows to expand to child height */
    .MuiDataGrid-viewport,
    .MuiDataGrid-row,
    .MuiDataGrid-renderingZone {
      max-height: fit-content !important;
      border: ${props.isMobile && 'none !important'};
    }
    /* Allows the MUI grid rows to expand to child height */
    .MuiDataGrid-cell {
      max-height: fit-content !important;
      overflow: auto;
      white-space: initial !important;
      line-height: 16px !important;
      display: flex !important;
      align-items: start;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      border: ${props.isMobile && 'none !important'};
    }
    .MuiDataGrid-columnHeaderTitle {
      font-size: smaller;
      display: ${props.isMobile && 'none !important'};
    }
    .MuiDataGrid-columnHeaders {
      visibility: ${props.isMobile && 'hidden !important'};
    }
    .MuiDataGrid-root {
      border: none !important;
    }
    .MuiDataGrid-footerContainer {
      display: none;
    }
    .MuiDataGrid-toolbarContainer {
      display: none !important;
    }
  };`
);

export const StyledLink = styled(Link)`
  padding-top: 3px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 1px;
`;