import React from 'react';
import Grid from '@mui/material/Grid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@material-ui/core';
import { PartHistoryProps } from './PartHistory.props';
import { GridContainer, SmallPartLabel, StyledWrapper } from './PartHistory.styles';
import { Accordian } from '../../atoms/Accordian';
import { formatDate } from '../../../utils/common';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { Link } from '../../atoms/Link';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { ScreenSize } from '../../../@types/display.types';
import { MOBILE_DATA_GRID_COLUMN } from '../../../configs/ui-constants';
import { MobileDataGridItem } from '../../organisms/MobileDataGridItem';
import { CustomDataGrid } from '../../layouts/CustomDataGrid';

export const PartHistory: React.FC<PartHistoryProps> = ({
  partId,
  partHistoryData,
}) => {
  const isMobileView = useMediaQuery(ScreenSize.MOBILE);

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Date/Timestamp',
      minWidth: 200,
      renderCell: (params) => (
        <Typography variant={TypographyVariantProps.Body2}>{formatDate(params.row.createdAt)}</Typography>
      )
    },
    {
      field: 'createdBy',
      headerName: 'User',
    },
    {
      field: 'historyTypeCode',
      headerName: 'Code',
    },
    {
      field: 'condition',
      headerName: 'Condition',
    },
    {
      field: 'toNode',
      headerName: 'To',
    },
    {
      field: 'fromNode',
      headerName: 'From',
    },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 1,
    },
    {
      field: 'serviceId',
      headerName: 'Service',
      renderCell: (params: GridRenderCellParams) =>
        params.row.serviceId && <Link href={`/services/${params.row.serviceId}`}>{params.row.serviceId}</Link>
    },
    {
      field: 'shipmentId',
      headerName: 'Shipment',
      renderCell: (params: GridRenderCellParams) =>
        params.row.shipmentId && <Link href={`/shipments/${params.row.shipmentId}`}>{params.row.shipmentId}</Link>
    },
    {
      field: MOBILE_DATA_GRID_COLUMN,
      headerName: '',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <MobileDataGridItem data={[
            {
              title: 'Date/Timestamp',
              value: <Typography variant={TypographyVariantProps.Body2}>{formatDate(row.createdAt)}</Typography>,
            },
            {
              title: 'User',
              value: <SmallPartLabel>{row.createdBy}</SmallPartLabel>,
            },
            {
              title: 'Code',
              value: <SmallPartLabel>{row.historyTypeCode}</SmallPartLabel>,
            },
            {
              title: 'Condition',
              value: <SmallPartLabel>{row.condition}</SmallPartLabel>,
            },
            {
              title: 'To',
              value: <SmallPartLabel>{row.toNode}</SmallPartLabel>,
            },
            {
              title: 'From',
              value: <SmallPartLabel>{row.fromNode}</SmallPartLabel>,
            },
            {
              title: 'Service',
              value: row.serviceId && <Link href={`/services/${row.serviceId}`}>{row.serviceId}</Link>,
            },
            {
              title: 'Shipment',
              value: row.shipmentId && <Link href={`/shipments/${row.shipmentId}`}>{row.shipmentId}</Link>,
            },
          ]}
          />
        );
      }
    }
  ];

  return (
    <StyledWrapper>
      <Accordian title={
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography>Part History</Typography>
          </Grid>
          <Grid item mr={2}>
            <Link href={`/parts/movements?partId=${partId}`} target="_blank">
              <Button variant={ButtonVariantProps.Secondary} onClick={(e) => e.stopPropagation()}>
                All Records
              </Button>
            </Link>
          </Grid>
        </Grid>
      } defaultExpanded={true}>
        <GridContainer>
          <CustomDataGrid
            columns={columns}
            isMobile={isMobileView}
            rows={partHistoryData}
            getRowId={(row) => row?.id}
            disableColumnFilter={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            pagination={false}
            enableCSVExport={false}
            exportFileName={''}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: false
              },
            }}
          />
        </GridContainer>
      </Accordian>
    </StyledWrapper>
  );
};