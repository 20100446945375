import React, { ChangeEvent, useState } from 'react';
import { Grid } from '@mui/material';
import { SelectMenu } from '../../atoms/SelectMenu';
import { CapacityProps, CapacityHourTypes } from './Capacity.props';
import { CapacityDelete, SwitchGrid } from './Capacity.styles';
import { ToogleButtonTextAlignment } from '../../atoms/ToogleButtonGroup';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { Switch } from '../../atoms/Switch';
import { ColorProps } from '../../../@types';
import { MultiClientSelector } from '../../molecules/MultiClientSelector';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../atoms/FormControlLabel';
import { TextField, TextFieldTypeProps } from '../../atoms/TextField';
import { MultiSelectCheckmarksMenu } from '../../atoms/MultiSelectCheckmarksMenu';

export const Capacity: React.FC<CapacityProps> = ({
  value,
  disableElement,
  onChange,
  onDelete
}) => {
  const [open, setOpen] = useState(false);
  const [selectedClientHierarchy, setSelectedClientHierarchy] = useState('');
  const itemSelectionData = Object.values(CapacityHourTypes).map(text => ({ value: text, label: text }));

  const handleType = (selectedValue: string) => {
    const item = Object.entries(CapacityHourTypes).find(obj => obj[1] === selectedValue);
    const getKeyByValue = item ? item[1] : CapacityHourTypes.AllDays;
    onChange({ ...value, type: getKeyByValue });
  };

  const handleParentSelection = (
    selectedClients: string[],
    selectedContracts: string[],
    clientHierarchy: string
  ) => {
    onChange({ ...value, selectedClients, selectedContracts });
    setSelectedClientHierarchy(clientHierarchy);
  };

  return (
    <Grid container spacing={GRID_SPACING} alignItems={ToogleButtonTextAlignment.Center}>
      <Grid item xs={2}>
        <MultiSelectCheckmarksMenu id="multi-select"
          label="Service Types" items={value.itemsData} selectedValue={value.selectedItems}
          onChange={(selectItem: string) => onChange({ ...value, selectedItems: selectItem.split(',') })} />
      </Grid>

      <Grid item xs={2}>
        <Button onClick={() => setOpen(true)} fullWidth={true} variant={ButtonVariantProps.Secondary}>
          {selectedClientHierarchy === `${undefined} -> ${undefined}` ? 'Client/Contract' : selectedClientHierarchy || 'Client/Contract'}
        </Button>

        <MultiClientSelector
          open={open}
          onClose={() => setOpen(false)}
          handleParentSelection={handleParentSelection}
          clients={value.clientsData}
          contracts={value.contractsData}
          selectedMultiClients={value.selectedClients}
          selectedMultiContracts={value.selectedContracts}
          onChange={(clients: string[], contracts: string[], brands?: string[], clientHierarchy?: string) => clientHierarchy && setSelectedClientHierarchy(clientHierarchy)}
        />
      </Grid>

      <Grid item xs={2}>
        <SelectMenu
          id="capacity-hour-type"
          label="Day Type"
          items={itemSelectionData}
          selectedValue={value.type}
          onChange={(type: string) => handleType(type)}
        />
      </Grid>

      <Grid item xs={1}>
        <TextField
          label="Max"
          type={TextFieldTypeProps.Number}
          value={value.max}
          onChange={(maxValue: string) => onChange({ ...value, max: maxValue })} />
      </Grid>

      <SwitchGrid item xs={2.35}>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Primary}
              checked={value.checkInventory}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ ...value, checkInventory: event.target.checked })} />
          }
          label="Check Inventory"
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>

      <SwitchGrid item xs={2.15}>
        <FormControlLabel
          control={
            <Switch
              color={ColorProps.Primary}
              checked={value.allowOveride}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ ...value, allowOveride: event.target.checked })} />
          }
          label="Allow Overide"
          labelPlacement={FormControlLabelPlacementProps.Top}
        />
      </SwitchGrid>

      <Grid item xs={0.5}>
        {disableElement || <CapacityDelete onClick={() => onDelete()} />}
      </Grid>
    </Grid>
  );
};
