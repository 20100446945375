import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GridColDef, GridFeatureModeConstant, GridFilterModel, GridRenderCellParams, GridValueGetterParams, GridSelectionModel, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { Grid, Stack } from '@mui/material';
import { LocationSearchBox } from './AttendanceReportList.styles';
import { useAddNewReport, useGetAllAttendanceReports, useGetDefaultReportByReportType, useGetReportByCode, useUpdateReportByCode } from '../../../queries/report-query';
import { ColumnSelectionItem } from '../../../components/templates/ColumnSelection';
import { AttendanceReportListResponse, ColorProps, ListType, LocationNodeData, SizeProps, ActionPermissions, CustomPermission, NewReportDataType, OverwriteReportDataType, ReportResponse, PropReportDataType } from '../../../@types';
import { CustomDataGrid } from '../../../components/layouts/CustomDataGrid';
import { InlineGroup, HorizontalAlignProps } from '../../../components/atoms/InlineGroup';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { LogoImageBox, LogoImage } from '../../ClientStructure/ClientContractList/ClientContractList.styles';
import { SearchCode } from '../../../@types/part.type';
import { LiveSearchBox, LiveSearchListItem } from '../../../components/atoms/LiveSearchBox';
import { LocationSearchItem } from '../../../components/molecules/LocationSearchItem';
import { useSearchNodeByParentId } from '../../../queries/live-search-query';
import { FormControlLabel, FormControlLabelPlacementProps } from '../../../components/atoms/FormControlLabel';
import { Switch } from '../../../components/atoms/Switch';
import { useGetUserSystemPermissions } from '../../../queries/user-query';
import { isUserHasPermission } from '../../../configs/permissions';
import { formatDate } from '../../../utils/common';
import { CustomDatepicker } from '../../../components/molecules/CustomDatepicker';
import { extractDateByCode } from '../../../utils/attendanceReport';
import { GridContainer, Wrapper } from '../../../styled/common.styles';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';
import { SavedReportTypeList } from '../../../configs/enums';
import { generateReportURL } from '../../../utils/report';
import { Snackbar } from '../../../components/atoms/Snackbar';

export const AttendanceReportList: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const reportCode = searchParams.get('savedReportCode');

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [attendanceReports, setAttendanceReports] = useState<AttendanceReportListResponse>();
  const [locationId, setLocationId] = useState<number>(0);
  const [locationName, setLocationName] = useState<string>();
  const [includeChilds, setIncludeChilds] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const [reportName, setReportName] = useState('');
  const [reportColumnSelectionItems, setReportColumnSelectionItems] = useState<Array<ColumnSelectionItem>>();
  const [newReportData, setNewReportData] = useState<PropReportDataType>();
  const [savedReportMessage, setSavedReportMessage] = useState('');
  const [reportViewCode, setReportViewCode] = useState<string>();
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [fetchDataGrid, setFetchDataGrid] = useState<boolean>(false);
  const [openToast, setOpenToast] = useState<boolean>(false);

  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const searchNodeByParentId = useSearchNodeByParentId();
  const getAllAttendanceReports = useGetAllAttendanceReports({ filterModel, page, pageSize, sortModel }, {
    ...(locationId !== 0 && { locationId: locationId }),
    includeChilds: includeChilds,
    startDate: extractDateByCode(startDate) ?? '',
    endDate: extractDateByCode(endDate) ?? ''
  });
  const useAddNewReportQuery = useAddNewReport();
  const useOverwriteReportQuery = useUpdateReportByCode(reportCode || '');
  const { data: reportQueryData, refetch: refetchGetReportByCodeQuery } = useGetReportByCode(reportCode || '');
  const defaultReportData = useGetDefaultReportByReportType(SavedReportTypeList.AttendanceReportList);

  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'serviceId',
      headerName: 'Service ID',
      headerAlign: 'left',
      align: 'left',
      width: 300,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value.toString(),
      valueGetter: (params: GridValueGetterParams) => params.row.serviceId,
    },
    {
      field: 'attendanceId',
      headerName: 'Attendance ID',
      headerAlign: 'left',
      align: 'left',
      width: 300,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => params.value.toString(),
      valueGetter: (params: GridValueGetterParams) => params.row.attendanceId,
    },{
      field: 'serviceType',
      headerName: 'Service Type',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.serviceType,
    },{
      field: 'serviceActionType',
      headerName: 'Service Action Type',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.serviceActionType,
    },{
      field: 'serviceProcessType',
      headerName: 'Service Process Type',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.serviceProcessType,
    },{
      field: 'clientContract',
      headerName: 'Client/Contract',
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.clientContractLogo) {
          return (
            <LogoImageBox>
              <LogoImage src={params.row.clientContractLogo} />
            </LogoImageBox>
          );
        } else {
          return params.row.clientContract;
        }
      },
    },{
      field: 'serviceLocation',
      headerName: 'Service Location',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.serviceLocation,
    },{
      field: 'siblingNodeName',
      headerName: 'User',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.siblingNodeName,
    },{
      field: 'parentNodeName',
      headerName: 'Parent',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.parentNodeName,
    },{
      field: 'reportedCheckInDateTime',
      headerName: 'Reported CheckIn DateTime',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.reportedCheckInDateTime),
    },{
      field: 'reportedCheckOutDateTime',
      headerName: 'Reported CheckOut DateTime',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.reportedCheckOutDateTime),
    },{
      field: 'onSiteTime',
      headerName: 'On-Site Time',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.onSiteTime,
    },{
      field: 'checkOutStatus',
      headerName: 'CheckOut Status',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.checkOutStatus,
    },{
      field: 'actualCheckInTimeStamp',
      headerName: 'Actual CheckIn TimeStamp',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.actualCheckInTimeStamp),
    },{
      field: 'actualCheckOutTimeStamp',
      headerName: 'Actual CheckOut TimeStamp',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => formatDate(params.row.actualCheckOutTimeStamp),
    },{
      field: 'serviceLocationSuburb',
      headerName: 'Service Location Suburb',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.serviceLocationSuburb,
    },{
      field: 'serviceLocationState',
      headerName: 'Service Location State',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.serviceLocationState,
    },{
      field: 'serviceLocationPostcode',
      headerName: 'Service Location Postcode',
      width: 300,
      valueGetter: (params: GridValueGetterParams) => params.row.serviceLocationPostcode,
    }
  ]);

  TabHandler(`Attendance | ${PLATFORM_NAME}`);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    getAllAttendanceReports.refetch();
  }, [page, pageSize, filterModel, sortModel, locationId, includeChilds, startDate, endDate]);

  useEffect(() => {
    getAllAttendanceReports.data && setAttendanceReports(getAllAttendanceReports.data);
  }, [getAllAttendanceReports.data]);

  useEffect(() => {
    !reportCode && defaultReportData.refetch();
  }, [SavedReportTypeList.AttendanceReportList]);

  useEffect(() => {
    if (!reportCode && defaultReportData.data && defaultReportData.data?.length > 0) {
      setFilterModel(defaultReportData.data[0].dataGridProperties?.filterModel);
      setSortModel(defaultReportData.data[0].dataGridProperties?.sortModel);
      setReportColumnSelectionItems(defaultReportData.data[0].columnSelection);
      setPageSize(defaultReportData.data[0].dataGridProperties?.pageSize);
      setStartDate(defaultReportData.data[0].dataGridProperties?.startDate || '');
      setEndDate(defaultReportData.data[0].dataGridProperties?.endDate || '');
      setLocationId(defaultReportData.data[0].dataGridProperties?.locationId || 0);
      setLocationName(defaultReportData.data[0].dataGridProperties?.locationName ||'');
      setIncludeChilds(defaultReportData.data[0].dataGridProperties?.includeChilds || false);
    }   
  }, [defaultReportData.data, reportCode]);

  useEffect(() => {
    if (reportCode) {
      refetchGetReportByCodeQuery();
      setReportViewCode(reportCode);
    }
  }, [reportCode, fetchDataGrid]);


  useEffect(() => {
    if (reportQueryData) {
      document.title = `${reportQueryData.name} (${reportCode}) | List Structure | ${PLATFORM_NAME}`;

      setFilterModel(reportQueryData.dataGridProperties?.filterModel);
      setSortModel(reportQueryData.dataGridProperties?.sortModel);
      setReportColumnSelectionItems(reportQueryData.columnSelection);
      setPageSize(reportQueryData.dataGridProperties?.pageSize);
      setStartDate(reportQueryData.dataGridProperties?.startDate || '');
      setEndDate(reportQueryData.dataGridProperties?.endDate || '');
      setLocationId(reportQueryData.dataGridProperties?.locationId || 0);
      setLocationName(reportQueryData.dataGridProperties?.locationName ||'');
      setIncludeChilds(reportQueryData.dataGridProperties?.includeChilds || false);
      setReportName(reportQueryData.name);
      setIsDefault(reportQueryData.isDefault);
    }   
  }, [reportQueryData, reportViewCode]);

  useEffect(() => {
    setNewReportData({
      dataGridProperties: {
        filterModel: filterModel,
        pageSize: pageSize,
        sortModel: sortModel || [],
        startDate: startDate,
        endDate: endDate,
        locationId: locationId,
        locationName: locationName,
        includeChilds: includeChilds
      },
      reportTypeName: SavedReportTypeList.AttendanceReportList,
      reportName: reportName,
      isDefault: reportQueryData?.isDefault,
      id: reportQueryData?.id,
      reportOwner: reportQueryData?.createdBy,
      columnSelection: reportColumnSelectionItems
    });
  }, [filterModel, pageSize, isDefault, reportName, sortModel, startDate, endDate, locationId, includeChilds]);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setFilterModel({ ...filterModel });
  }, []);
  
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleSelectionModelChange = useCallback((selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  }, []);

  const handleColumnsChange = (items: Array<ColumnSelectionItem>) => {
    setReportColumnSelectionItems(items);
  };
  
  const savedReport = async (newReportData: NewReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useAddNewReportQuery.mutateAsync(newReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Created');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true);
    navigate(reportURL, { replace: true });
  };

  const overwriteReport = async (overwriteReportData: OverwriteReportDataType) => {
    setSavedReportMessage('');
    const response: ReportResponse = await useOverwriteReportQuery.mutateAsync(overwriteReportData);
    setSavedReportMessage(response?.message ? response?.message : 'Successfully Updated');
    const reportURL = generateReportURL(window.location.pathname, response.code);
    setOpenToast(true);
    navigate(reportURL, { replace: true });
  };

  const columnSelectionItems: Array<ColumnSelectionItem> = [
    {
      title: 'Attendance Report',
      value: [
        {
          title: 'Service ID',
          field: 'serviceId',
          value: true
        },
        {
          title: 'Attendance ID',
          field: 'attendanceId',
          value: true
        },
        {
          title: 'Service Type',
          field: 'serviceType',
          value: false
        },
        {
          title: 'Service Action Type',
          field: 'serviceActionType',
          value: false
        },
        {
          title: 'Service Process Type',
          field: 'serviceProcessType',
          value: false
        },
        {
          title: 'Client/Contract',
          field: 'clientContract',
          value: true
        },
        {
          title: 'Service Location',
          field: 'serviceLocation',
          value: true
        },
        {
          title: 'User',
          field: 'siblingNodeName',
          value: true
        },
        {
          title: 'Parent',
          field: 'parentNodeName',
          value: true
        },
        {
          title: 'Reported CheckIn DateTime',
          field: 'reportedCheckInDateTime',
          value: true
        },
        {
          title: 'Reported CheckOut DateTime',
          field: 'reportedCheckOutDateTime',
          value: true
        },
        {
          title: 'On-Site Time',
          field: 'onSiteTime',
          value: true
        },
        {
          title: 'CheckOut Status',
          field: 'checkOutStatus',
          value: true
        },
        {
          title: 'Actual CheckIn TimeStamp',
          field: 'actualCheckInTimeStamp',
          value: false
        },
        {
          title: 'Actual CheckOut TimeStamp',
          field: 'actualCheckOutTimeStamp',
          value: false
        },
        {
          title: 'Service Location Suburb',
          field: 'serviceLocationSuburb',
          value: false
        },
        {
          title: 'Service Location State',
          field: 'serviceLocationState',
          value: false
        },
        {
          title: 'Service Location Postcode',
          field: 'serviceLocationPostcode',
          value: false
        },
      ]
    }
  ];
  
  return (
    <PageContainer>
      <Wrapper>
        <InlineGroup horizontal={HorizontalAlignProps.Between}>
          <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
            Attendance
          </Typography>
        </InlineGroup>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <Stack direction="row" alignItems="center" width="50%">
            <LocationSearchBox>
              <LiveSearchBox
                title="Structure Search"
                timeOffset={400}
                value={locationName || ''}
                onClearValue={() => {
                  setLocationId(0);
                  setLocationName('');
                }}
                renderItem={(props: any, option: any) => { 
                  return (
                    <LiveSearchListItem {...props}>
                      <LocationSearchItem data={option} isAmLidSearch={option.name?.includes('AM-LID-')}/>
                    </LiveSearchListItem>
                  );
                }}
                onChange={(obj: LocationNodeData) => {
                  setLocationId(obj.id);
                  setLocationName(obj.name.includes('AM-LID-') ? obj.code : obj.name);
                }}
                onApiInvoke={async (name: string) => {
                  return await searchNodeByParentId.mutateAsync({
                    name: name,
                    hasCode: name.toLocaleLowerCase().includes(SearchCode.AM_LID.toLocaleLowerCase())
                  });
                }}
              />
            </LocationSearchBox>
            <Stack width="100%">
              <FormControlLabel
                control={
                  <Switch
                    color={ColorProps.Success}
                    size={SizeProps.Small}
                    checked={includeChilds}
                    onChange={(event) => setIncludeChilds(event.target.checked)}
                  />
                }
                label={
                  <Typography variant={TypographyVariantProps.Subtitle2} >Include Children</Typography>
                }
                labelPlacement={FormControlLabelPlacementProps.Top}
              />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <CustomDatepicker name="Start Date" setDate={setStartDate} dateValue={startDate} />
            <Typography>
              to
            </Typography>
            <CustomDatepicker name="End Date" setDate={setEndDate} dateValue={endDate} />
          </Stack>
        </Stack>
        <GridContainer>
          <Grid>
            <Snackbar
              open={openToast}
              autoHideDuration={4000}
              message={savedReportMessage ? savedReportMessage : 'Successfully Created'}
              onClose={() => setOpenToast(false)}
            />
          </Grid>
          <CustomDataGrid
            columns={columns}
            rows={attendanceReports?.data?.map((item, index) => ({ id: index + 1, ...item })) || []}
            rowCount={attendanceReports?.total}
            exportFileName={ListType.AttendanceReportList}
            enableCSVExport={isUserHasPermission(ActionPermissions.Attendance_Report_Export, permissions)}
            columnSelectionItems={reportColumnSelectionItems ? reportColumnSelectionItems : columnSelectionItems}
            filterMode={GridFeatureModeConstant.server}
            filterModel={filterModel}
            onFilterModelChange={(filterModel: GridFilterModel) => {
              onFilterChange(filterModel);
            }}
            loading={getAllAttendanceReports.isLoading}
            rowsPerPageOptions={[25, 50, 100]}
            pagination={true}
            page={page}
            pageSize={pageSize}
            paginationMode={GridFeatureModeConstant.server}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            sortModel={sortModel}
            sortingMode={GridFeatureModeConstant.server}
            onSortModelChange={handleSortModelChange}
            selectionModel={selectionModel}
            onSelectionModelChange={handleSelectionModelChange}
            handleColumnsChange={handleColumnsChange}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  parentNodeId: false,
                  referenceNumbers: false,
                  status: false,
                },
              },
            }}
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true }
              }
            }}
            getRowHeight={() => 'auto'}
            setColumns={setColumns}
            enableSavedReport
            newReportData={newReportData}
            handleSavedReport={(newReportData: NewReportDataType) => savedReport(newReportData)}
            handleOverwriteReport={(overwriteReportData: OverwriteReportDataType) => overwriteReport(overwriteReportData)}
            reportCode={reportViewCode}
            handleRefetch={() => setFetchDataGrid(!fetchDataGrid)}
            startDate={extractDateByCode(startDate) || ''}
            endDate={extractDateByCode(endDate) || ''}
            locationId={locationId}
            includeChilds={includeChilds}
          />
        </GridContainer>
      </Wrapper>
    </PageContainer>
  );
};