import { httpClient, HttpMethod } from './http-client';
import {
  BulkEditServiceDataType,
  ServiceLogHideData,
  ServiceUpdateRequest,
  ServiceTemplateResponse,
  ServiceTypeResponse,
  AllocatedService,
  ServiceBillingInfo,
  ServiceNodeAllocationData,
  ServiceLog,
  EstimatedTimeOfArrivalQuery,
  ServiceAttribute,
  BillingType
} from '../@types/service.type';
import { ColumnSelectionItem } from '../components/templates/ColumnSelection';

export const createService = (service: any) => {
  return httpClient('/services', HttpMethod.Post, service);
};

export const getActionTypes = (): any => {
  return httpClient('/actionTypes', HttpMethod.Get, null);
};

export const getProcessTypes = (): any => {
  return httpClient('/processTypes', HttpMethod.Get, null);
};

export const getServiceActionProcessTypes = (activeOnly?: boolean): any => {
  return httpClient(`/service/action/process/types?activeOnly=${activeOnly}`, HttpMethod.Get, null);
};

export const createServiceLogs = (serviceId: number, service: ServiceLog) => {
  return httpClient(`/serviceLogs/${serviceId}`, HttpMethod.Post, service);
};

export const createServiceLogsForService = (serviceId: number, service: ServiceLog) => {
  return httpClient(`/serviceLogs/${serviceId}/forService`, HttpMethod.Post, service);
};

export const updateServiceLogsById = (id: number, serviceLog: ServiceTypeResponse) => {
  return httpClient(`/serviceLogs/${id}`, HttpMethod.Put, serviceLog);
};

export const getAllServiceTargets = (): any => {
  return httpClient('/serviceTargets', HttpMethod.Get, null);
};

export const getServiceById = (id: number) => {
  return httpClient(`/services/${id}`, HttpMethod.Get, null);
};

export const updateServiceById = (id: number, data: ServiceUpdateRequest) => {
  return httpClient(`/services/${id}`, HttpMethod.Put, data);
};

export const updateReportedInformationByServiceId = (id: number, data: ServiceUpdateRequest) => {
  return httpClient(`/services/reportedInformation/${id}`, HttpMethod.Put, data);
};

export const getAllServices = (queryOptions: any, columnSelectionItems: ColumnSelectionItem[]) => {
  const { filterModel, page, pageSize, sortModel, locationId, includeChildRecords } = queryOptions;
  let url = `/servicesList?page=${page + 1}&limit=${pageSize}`;

  if (sortModel && sortModel[0] && sortModel[0].field) {
    url += `&order_by=${sortModel[0].field}&order_direction=${sortModel[0].sort}`;
  }

  filterModel.items?.forEach((filter: any) => {
    url += `&${filter.columnField}=${filter.operatorValue},${filter.value}`;
  });

  if (locationId) {
    url += `&locationId=${locationId}`;
  }

  if (includeChildRecords) {
    url += `&includeChildRecords=${includeChildRecords}`;
  }

  return httpClient(url, HttpMethod.Post, { columnSelectionItems: columnSelectionItems });
};

export const getServiceLogsByServiceId = (id: number) => {
  return httpClient(`/services/${id}/logs`, HttpMethod.Get, null);
};

export const getServiceLogsById = (id: number) => {
  return httpClient(`/serviceLogs/${id}`, HttpMethod.Get, null);
};

export const hideServiceLog = (id: number, nodeType: ServiceLogHideData) => {
  return httpClient(`/servicelogs/${id}/hide`, HttpMethod.Put, nodeType);
};

export const updateBulkServiceList = (serviceType: BulkEditServiceDataType) => {
  return httpClient('/serviceListBulkUpdate', HttpMethod.Patch, serviceType);
};

export const updateBulkServiceEditList = (serviceType: BulkEditServiceDataType) => {
  return httpClient('/serviceEditListBulkUpdate', HttpMethod.Patch, serviceType);
};

export const getBulkServicesByIds = (affectedIds: object) => {
  return httpClient('/services/bulkservicedetails', HttpMethod.Post, affectedIds);
};

export const getNextServiceStatuses = (code: string, serviceType: string) => {
  return httpClient(`/possibleNextserviceStatuses/${code}/${serviceType}`, HttpMethod.Get, null);
};

export const saveServiceStatus = (id: number, status: any) => {
  return httpClient(`/services/${id}/status`, HttpMethod.Put, status);
};

export const getServiceTemplates = (): ServiceTemplateResponse[] => {
  return httpClient('/servicetemplates', HttpMethod.Get, null);
};

export const getOtherServicesUnderMainLocation = (nodeId: number) => {
  return httpClient(`/nodes/${nodeId}/otherservices`, HttpMethod.Get, null);
};

export const getLinkedServices = (serviceId: number) => {
  return httpClient(`/services/getlinkedservicesbyserviceid/${serviceId}`, HttpMethod.Get, null);
};

export const updateParentService = (id: number, parentService: any) => {
  return httpClient(`/services/parentservice/${id}`, HttpMethod.Put, parentService);
};

export const getAllServiceAttendance = (serviceId: number) => {
  return httpClient(`/attendance/${serviceId}`, HttpMethod.Get, null);
};

export const getCurrentUserAllocatedServices = (locationNodeId: number, serviceId: number): AllocatedService => {
  return httpClient(`/services/technicians/${locationNodeId}/allocation/${serviceId}`, HttpMethod.Get, null);
};

export const updateBillingInfo = (serviceId: number, billing: ServiceBillingInfo) => {
  return httpClient(`/services/billingInfo/${serviceId}`, HttpMethod.Put, billing);
};

export const getServicesUnderNodeId = (nodeId: number, includeChildRecords: boolean) => {
  const url = `/nodes/${nodeId}/services?includeChildRecords=${includeChildRecords}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const updateServiceNodeAllocation = (data: ServiceNodeAllocationData) => {
  return httpClient('/servicenodeallocation', HttpMethod.Post, data);
};

export const getEstimatedTimeOfArrival = (queryOptions: EstimatedTimeOfArrivalQuery) => {
  const { nodeId, origin, date, serviceId } = queryOptions;
  const url = `/getEstimatedTimeOfArrival/${nodeId}/${serviceId}/data?origin=${origin}&date=${date}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const getServicesNodesLocationRelatedAttributes = (serviceId: number, mainLocationNodeId: number, subLocationNodeId: number) => {
  const url = `/serviceAttributes/${serviceId}/nodeAttributes/mainLocation/${mainLocationNodeId}/subLocation/${subLocationNodeId}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const createOrUpdateServiceAttributes = (serviceAttribute: ServiceAttribute) => {
  return httpClient('/serviceAttributesBulkCreateOrUpdate', HttpMethod.Post, serviceAttribute);
};

export const getBillingTypes = (): BillingType[] => {
  return httpClient('/billingTypes', HttpMethod.Get, null);
};

export const getServiceCancelOrReopenStatuses = (option: string, statusCode: string, serviceType: string, serviceId: number) => {
  const url = `/getServiceCloseOrReOpenStatuses?option=${option}&statusCode=${statusCode}&serviceType=${serviceType}&serviceId=${serviceId}`;
  return httpClient(url, HttpMethod.Get, null);
};

export const getBillingInfo = (serviceId: number) => {
  return httpClient(`/services/billingInfo/${serviceId}`, HttpMethod.Get, null);
};

export const getServiceActivityMonitorData = () => {
  return httpClient('/getServiceActivityMonitor', HttpMethod.Get, null);
};