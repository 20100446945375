import { useMutation, useQueryClient, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { createSKUConfig, getAllSKUConfigs, getSKUConfigById, getSKUConfigByName, updateSKUConfig } from '../api/sku';
import { SKUConfigKey, SKUConfigNameUniqueKey } from '../configs/sku';
import { SKUConfigListResponse, SKUDataType, SKUNameUniqueCheckType } from '../@types/sku.type';
import { QueryOption } from '../@types';

export const useGetSKUConfigs = (queryOption: QueryOption): UseQueryResult<SKUConfigListResponse> => {
  return useQuery([SKUConfigKey, queryOption.page, queryOption.pageSize], () => getAllSKUConfigs(queryOption));
};

export const useGetSKUConfigById = (id: number): UseQueryResult<SKUDataType> => {
  return useQuery([SKUConfigKey, id], () => id && getSKUConfigById(id));
};

export const useCreateSKUConfig = (): UseMutationResult => {
  const queryClient = useQueryClient();
  
  return useMutation(async (data: any) => {
    const queryResult = await createSKUConfig(data);
    return queryResult;
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([SKUConfigKey]);
    },
  });
};

export const useUpdateSKUConfig = (): UseMutationResult => {
  const queryClient = useQueryClient();
  return useMutation((data: any) => updateSKUConfig(data.id, data), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([SKUConfigKey]);
    },
  }); 
};

export const useGetSKUConfigByName = (data: any): UseQueryResult<SKUNameUniqueCheckType> => {
  return useQuery([SKUConfigNameUniqueKey, data.name, data.id], () => getSKUConfigByName(data.name, data.id), { enabled: false });
};