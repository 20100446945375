import { ServiceNodeAllocation } from './allocation.type';
import { Tag } from './tag.type';
import { AddressAttributeResponseType, LabelValue, OutcomeTypeCode, StructureResponse } from '.';
import { ContactData } from '../components/molecules/Contact';
import { ContactDataType } from '../components/templates/StructureAddressContactLayout';

export interface ServiceObject {
  id: number;
  serviceStatus: ServiceStatus;
  serviceTypeCode: string;
  serviceStatusCode: string;
  serviceType: ServiceType[];
  actionTypeCode: string;
  actionType: ActionType[];
  processTypeCode: string;
  processType: ProcessType[];
  priorityCode: string;
  location: string;
  tags: Tag[];
  referenceNo1: string;
  referenceNo2: string;
  referenceNo3: string;
  userDepotAllocation?: string;
  userDepotAllocationNodeId?: number;
  scheduledAt: string;
  notes: string;
  nodeId: number;
  reportedSerialNumber: string;
  reportedSerialNumberPartId: number;
  reportedModel: string;
  reportedModelId: number;
  reportedFaultAndDescription: string;
  reportedFaultAndDescriptionId: number;
  elementForDisplaying: Array<ContactData>;
  attributes: Attributes[];
  client: string;
  contract: string;
  clientCode: string;
  contractCode: string;
  brand: string;
  createdAt: string;
  mainLocation: string;
  mainLocationNodeId?: number;
  subLocationNodeId?: number;
  subLocation: string;
  loggedBy: string;
  followUpAt: string;
  priorityId: string;
  priority: Priority;
  parentId: string;
  clientId: number;
  contractId: number;
  brandId: number;
  travelStartTime: string;
  selectedServiceCreationTypeValue: Array<string>;
  isHelpdeskUser: boolean;
  adminDetails?: AdminDetails;
  primaryAllocation?: ServiceNodeAllocation;
  secondaryAllocation?: ServiceNodeAllocation;
  siteSchema: string;
  clientHierarchy?: string;
  serviceAddress?: ServiceAddressAttributeValue;
  tagCreationData?: any;
  contractName?: string;
  clientSLA?: SLASummary;
  opsSLA?: SLASummary;
  creationNotes?: string;
  contactData?: ContactDataType[];
}

export interface SLASummary {
  timeoutAt: string;
  slaOutcome: Outcome;
}

export interface Outcome {
  outcomeTypeCode: OutcomeTypeCode;
}

export interface ServiceCreationObject {
  serviceTypeCode: string;
  actionTypeCode: string;
  processTypeCode: string;
  clientHierarchyId: number;
  priorityCode: number;
  subLocationNodeId: number;
  mainLocationNodeId: number;
  scheduledAt: string;
  notes: string;
  serviceAttributes: Array<any>;
  userDepotAllocationNodeId: number;
}

export interface ServiceUpdateRequest {
  serviceTypeCode: string;
  serviceStatuCode: string;
  actionTypeCode: string;
  processTypeCode: string;
  nodeId: string;
  priorityCode: string;
  scheduledAt: string;
}

export interface ServiceStatus {
  code: string;
  name: string;
  isClosed?: boolean;
}

export enum ServiceStatusCode {
  New = 'new',
  OnTheWay = 'otw',
  OnSite = 'ons',
  Complete = 'compl',
  OnHold = 'onhold',
  Futile = 'futil',
  Cancelled = 'cxl',
  Assigned = 'asgn',
  Scheduled = 'schd',
  Available = 'avail',
  WithLogistics = 'wlogi',
  Dispatched = 'disp',
  WaitingReturn = 'wretu',
  PartsRequired = 'part-p',
  PartsInRepair = 'part-r',
  PartsInOrder = 'part-o',
  PartsInTransit = 'part-t',
  PartsAvailable = 'part-a',
  Escalated = 'escl',
  CallbackRequired = 'callb',
  ReadyForBooking = 'book',
  Exception = 'excp',
  Testing = 'test',
  Repairing = 'repr',
  LegacyClosed = 'legacy'
}

export enum ServiceStatusLabel {
  OnSite = 'On Site'
}

export interface Priority {
  shortName: string;
  color: string;
}

export interface Attributes {
  id?: number;
  code: string;
  name: string;
  value: any;
}

export enum ServiceTargetOptions {
  StructureLocationUnderClient ='structure-under-client',
  AnyStructureLocation = 'structure-any',
  NewStructureLocation = 'structure-new',
  SerialNumberUnderClientStructure = 'serial-under-client',
  SerialNumberAtAnyLocation = 'serial-any',   
}

interface ServiceTarget {
  code: ServiceTargetOptions;
  name: string;
}

interface ServiceTargetOption {
  isDefault: boolean;
  serviceTarget: ServiceTarget;
  serviceTypeCode: string;
}

export interface ServiceType {
  code: string;
  name: string;
  isCheckInAvailable: boolean;
  isActive: boolean;
  serviceTargetOptions?: Array<ServiceTargetOptions>;
  targetOptions?: Array<ServiceTargetOption>;
}

export interface ActionType {
  code: string;
  name: string;
  isActive: boolean;
}

export interface ProcessType {
  code: string;
  name: string;
  isActive: boolean;
}

export interface ServiceCommonType {
  serviceTypes: ServiceType[];
  actionTypes: ActionType[];
  processTypes: ProcessType[];
}

export interface NodeAttributeValueType {
  shortName: string;
  description: string;
  color: string;
  isActive: boolean;
}

export interface PriorityType {
  id: number;
  propertyTypeCode: string;
  value: NodeAttributeValueType;
}

export interface ServiceTypeResponse {
  name: string;
  code: string;
  config: any;
  targetOptions: Array<any>;
  statusOptions: Array<any>;
  isCheckInAvailable: boolean;
  isActive: boolean;
}

export interface ServiceTargetResponse {
  code: string;
  name: string;
}

export interface ServiceStatusResponse {
  id: number;
  code: string;
  name: string;
  isActive: boolean;
  isSystem: boolean;
  isClosed: boolean;
  isShortCodeExist: boolean;
  blockStatuses: Array<string>;
  nextStatuses: Array<string>;
}

export interface ServiceStatusHelper {
  nameHelperText: string;
  shortNameHelperText: string;
}

export enum InputCategoryProps {
  Name = 'name',
  ShortName = 'shortName',
  Code = 'code'
}

export interface ServiceLogResponse {
  id: number;
  notes: string;
  data: any;
  isHidden: boolean;
  time: Date;
  logTypeName: string;
  logTypeCode?: string;
  logTypeAttributes: any;
  logTypeRequiredData: any;
  logTypeDisplayTemplate: string;
  serviceStatusName: string;
  userName: string;
}

export interface ServiceLogHideData {
  isHidden: boolean;
}

export interface BulkEditServiceDataType {
  affectedServiceIds?: any[];
  actionType?: string;
  processType?: string;
  referenceNo1?: string;
  referenceNo2?: string;
  referenceNo3?: string;
  userDepotAllocation?: string;
  secondaryUserDepotAllocation?: string;
  scheduledDateTime?: string;
  followUpDateTime?: string;
  notes?: string;
  status?: string;
  parentServiceId?: string;
  linkedField?: string;
}

export interface ServiceTemplateDataType {
  id: number;
  name: string;
  icon: string;
  description: string;
  data: any;
}

export interface ServiceTemplateResponse {
  name: string;
  data: any;
  nodeAssociations: ServiceTemplateNodeAssoc[];
}

export interface ServiceTemplateNodeAssoc {
  name: string;
  serviceTemplateId?: number;
  nodeId?: number;
}

export interface NextServiceStatusesResponse {
  possibleNextStatuses: Array<ServiceType>;
  preferedStatuses: Array<ServiceType>;
}

export interface CheckInObject {
  serviceId: number;
  checkInTime: string;
  travelStartTime: string;
  locationNodeId: number;
  notes: string;
  selectedServiceIds?: Array<number>;
}

export interface CheckOutObject {
  nextStatus: string;
  note: string;
  checkOutTime: string;
}

export interface ReportedInformationData {
  id: number;
  loggedBy: string;
  createdAt: string;
  referenceNo1: string;
  referenceNo2: string;
  referenceNo3: string;
  reportedSerialNumberPartId: number;
  reportedSerialNumber: string;
  reportedModel: string;
  reportedModelId: number;
  reportedFaultAndDescription: string;
  reportedFaultAndDescriptionId: number;
  clientHierarchy?: string;
  mainLocation?: string;
  mainLocationNodeId?: number;
}

export interface LinkObject {
  serviceId: number;
}

export interface ServiceAttendanceObject {
  no: number;
  attendanceId: number;
  siblingNodeName: string;
  checkInDateTime: Date;
  checkOutDateTime: Date;
  onSiteTime: string;
}

export interface Technician {
  id: number;
  name: string;
}

export interface AllocatedService {
  id: number;
  serviceId: number;
  serviceActionProcessTypes: string;
  nodeName: string;
  serviceStatusName: string;
  isUserCheckedIn: boolean;
}

export interface Invoice {
  id?: number;
  invoiceNo: string;
  amount: string;
  isActive?: boolean;
  invoiceType?: string;
}

export interface ServiceBillingInfo {
  billingTypeCode: string;
  isReviewed: boolean;
  isClientInvoiced: boolean;
  billingNotes: string;
  privateNotes: string;
  invoices: Invoice[];
  updated?: boolean;
}

export interface AdminDetails {
  billingInfo: ServiceBillingInfo;
}

export interface AvailableStatusesForServiceTypes {
  possibleStatuses: Array<ActionType>;
}

export interface StatusChangeData {
  code: string;
  serviceTypes: string[];
}

export interface ServiceListResponse {
  previousPage?: number;
  currentPage?: number;
  nextPage?: number;
  total?: number;
  limit?: number;
  data?: ServiceListDataType[];
}

export interface ServiceListData {
  actionTypeCode: string;
  attributes: any[];
  id: number;
  nodeId?: number;
  mainLocationNodeId?: number;
  subLocationNodeId?: number;
  nodeTree?: StructureResponse[];
  notes?: string;
  priorityCode?: string;
  processTypeCode?: string;
  serviceStatus: ServiceStatusData;
  serviceStatusCode: string;
  serviceType: ServiceTypeData;
  serviceTypeCode: string;
  logoPic: string;
}

export interface ServiceStatusData {
  name: string;
  isClosed: boolean;
}

export interface ServiceTypeData {
  name: string;
}

export interface OtherServiceData {
  id: number;
  serviceType: string;
  serviceStatus: string;
  isClosed: boolean;
  parentServiceId: number;
  subLocationName: string;
  observedFaultCode: string;
  observedFaultCategory: string;
  createdAt: string;
}

export interface LinkedChildServiceData {
  id: number;
  hierarchy: number[];
  serviceTypeCode: string;
  detail: string;
}

export interface LinkedParentServiceData {
  targetServiceId: number;
}

export interface LinkedServiceData {
  parentService: LinkedParentServiceData;
  childServices: LinkedChildServiceData[];
}

export interface ServiceAccordianData {
  id: number;
  subLocationName?: string;
  priorityCode: string;
  serviceReference1?: string;
  serviceStatus: ServiceStatusData;
  clientHierarchy: string;
  logoPic?: string;
  createdAt: string;
  closedAt?: string;
}

export interface ServiceNodeAllocationData {
  serviceId: number;
  userDepotAllocationNodeId: number;
  allocationTypeCode: string;
}

export enum ServiceAccordianRelation {
  Logged = 'logged',
  Allocated = 'allocated',
}

export interface ServiceLog {
  id?: number | null;
  notes: string;
}

export interface EstimatedTimeOfArrivalQuery { 
  origin: string;
  date: string;
  nodeId: number;
  serviceId: number;
}

export interface ServicesNodesAttributes {
  serviceContacts: Array<ServiceContactAttribute>;
  serviceAddress: ServiceAddressAttribute;
  selectedNodeAddress: NodeAddressAttribute;
  nodeAddresses: Array<AddressAttributeResponseType>;
}

export interface ServiceContactAttribute {
  id: number;
  serviceId: string;
  attributeTypeCode: string;
  name: string;
  value?: ServiceContactAttributeValue;
}

export interface ServiceAddressAttribute {
  id: number;
  serviceId: string;
  attributeTypeCode: string;
  name: string;
  value?: ServiceAddressAttributeValue;
}

export interface NodeAddressAttribute {
  id: number;
  nodeId: string;
  propertyTypeCode: string;
  name: string;
  value?: ServiceAddressAttributeValue;
}

export interface ServiceContactAttributeValue {
  contactName?: string;
  organization?: string;
  phone1?: string;
  phone2?: string;
  email?: string;
  notes?: string;
  isActive?: boolean;
}

export interface ServiceAddressAttributeValue {
  addressType?: string;
  traditionalPlaceName?: string;
  line1?: string;
  line2?: string;
  suburb?: string;
  city?: string;
  state?: string;
  postCode?: string;
  country?: string;
  addressName?: string;
  placeName?: string;
  stateName?: string;
}

export interface ServiceAttribute {
  id?: number;
  contactType?: string;
  email?: string;
  isActive?: boolean;
  notes?: string;
  organization?: string;
  phone1?: string;
  phone2?: string;
}

export enum LogType {
  ReportedNotes = 'reported-notes',
  NOTES = 'notes',
  InteractionEvent = 'interaction-events'
}

export interface BillingType {
  code: string;
  name: string;
}

export interface ServiceSummaryObject {
  id?: number;
  serviceTypeCode?: string;
  serviceStatusCode?: string;
  actionTypeCode?: string;
  processTypeCode?: string;
  nodeId?: number;
  priorityCode?: string;
  referenceNo1?: string;
  subLocationNodeId?: number;
  scheduledAt?: string;
  followUpAt?: string;
}

export interface CustomNodeAttributeType {
  id: number;
  propertyTypeCode: string;
  value: any;
}

export interface ServiceListDataType {
  id: number;
  serviceId?: number;
  serviceTypeName?: string;
  serviceTypeCode?: string;
  actionTypeName?: string;
  processTypeName?: string;
  serviceParams?: string;
  clientHierarchy?: string;
  clientName?: string;
  clientCode?: string;
  contractName?: string;
  contractCode?: string;
  brandName?: string;
  priorityCode?: string;
  priorityDescription?: string;
  reportedSerial?: string;
  reportedModel?: string;
  reportedModelSerial?: string;
  reportedFault?: string;
  reportedFaultDescription?: string;
  reportedFaultDescriptionCombine?: string;
  creationNotes?: string;
  createdAt?: string;
  createdBy?: string;
  reference1?: string;
  reference2?: string;
  reference3?: string;
  tags?: string;
  serviceStatusName?: string;
  primaryAllocation?: string;
  primaryAllocationNode?: string;
  secondaryAllocation?: string;
  secondaryAllocationNode?: string;
  scheduledDate?: string;
  followUpDate?: string;
  updatedAt?: string;
  updatedBy?: string;
  lastUpdatedNote?: string;
  specialScheduled?: string;
  locationName?: string;
  structureNodeName?: string;
  structureNodeId?: number;
  structureNodeNotes?: string;
  subLocationName?: string;
  subLocationId?: number;
  line1?: string;
  line2?: string;
  suburb?: string;
  city?: string;
  stateName?: string;
  postCode?: string;
  countryName?: string;
  mainAddress?: string;
  logisticsAddress?: string;
  regionType?: string;
  serviceContact?: string;
  mainContact?: string;
  nodeReference1?: string;
  nodeReference2?: string;
  nodeReference3?: string;
  nodeSiteSchema?: string;
  tradingHours?: string;
  partTypeId?: number;
  partTypeName?: string;
  partNo?: number;
  partSerial1?: string;
  partSerial2?: string;
  partSerial3?: string;
  softwareVersion?: string;
  hardwareRevision?: string;
  firmwareVersion?: string;
  fleetTag?: string;
  linkedServices?: string;
  linkedShipments?: string;
  linkedShipmentConnotes?: string;
  daysOpen?: number;
  reopensCount?: number;
  closedAt?: string;
  attendanceCount?: number;
  uniqueTechniciansCount?: number;
  observedFault?: string;
  observedFaultDescription?: string;
  solutionAction?: string;
  solutionActionPartType?: string;
  serial1sIn?: string;
  serialisedPartTypesIn?: string;
  serial1sOut?: string;
  serialisedPartTypesOut?: string;
  nonSerialisedPartTypesIn?: string;
  attachments?: string;
  clientSLASummary?: string;
  opsSLASummary?: string;
  interactionTextbox?: string;
  interactionRichTextbox?: string;
  interactionDropdown?: string;
  interactionRadiobutton?: string;
  interactionCheckbox?: string;
  interactionGetParameter?: string;
  pendedParts?: string;
  pendedNotes?: string;
  reviewed?: boolean;
  billableType?: string;
  clientInvoiced?: boolean;
  billingNotes?: string;
  internalInvoices?: string;
  externalInvoices?: string;
  allNotes?: string;
  importSessionId?: string;
  logoPic?: string;
  isClosedServiceStatus: boolean;
  isCheckedIn?: boolean;
  isAllocated?: boolean;
  isAllocatedAboveMe?: boolean;
  isHeaderRow?: boolean;
  rowHeaderTitle?: string;
  fieldName?: string;
}

export interface ServiceDispatchShipmentData {
  openFragment: boolean;
  serviceId: number;
  mainLocation?: string;
  mainLocationNodeId?: number;
  subLocationNodeId?: number;
  primaryAllocationNodeId?: number;
  secondaryAllocationNodeId?: number;
  exited?: boolean;
  isFromServiceEdit?: boolean;
}

export interface ServiceFilterMultiSelectType {
  serviceTypes: LabelValue[];
  actionTypes: LabelValue[];
  processTypes: LabelValue[];
}

export interface ServiceActivityResponseDataType {
  id?: number;
  rowHeading: string;
  clientCode: string;
  serviceTypeName: string;
  newStatus: number;
  bookingStatus: number;
  fieldReadyStatus: number;
  otwStatus: number;
  onsiteStatus: number;
  partsRequiredStatus: number;
  partsProcessedStatus: number;
  logisticsStatus: number;
  dispatchedStatus: number;
  escalatedStatus: number;
}

export enum ServiceListColumnItemTitle {
  Service = 'Service',
  StatuAndAllocation = 'Status & Allocation',
  Structure = 'Structure',
  ReportedPart = 'Reported Part',
  LinkedRecords = 'Linked Records',
  Progress = 'Progress',
  SLA = 'SLA',
  Interactions = 'Interactions',
  BillingAndAdmin = 'Billing & Admin',
  Misc = 'Misc',
}

export interface BulkServiceEdit {
  actionType: string;
  processType: string;
  referenceNo1: string;
  referenceNo2: string;
  referenceNo3: string;
  userDepotAllocation: string;
  secondaryUserDepotAllocation: string;
  userDepotAllocationName: string;
  secondaryUserDepotAllocationName: string;
  scheduledDateTime: string;
  followUpDateTime: string;
  notes: string;
  status: string;
  parentServiceId: string;
  linkedField: string;
}

export interface BulkServiceEditCheck {
  isCheckActionType: boolean;
  isCheckProcessType: boolean;
  isCheckReferenceNo1: boolean;
  isCheckReferenceNo2: boolean;
  isCheckReferenceNo3: boolean;
  isCheckUserDepotAllocation: boolean;
  isCheckSecondaryUserDepotAllocation: boolean;
  isCheckScheduledDateTime: boolean;
  isCheckFollowUpDateTime: boolean;
  isCheckNotes: boolean;
  isCheckStatus: boolean;
  isCheckParentServiceId: boolean;
  isCheckLinkedField: boolean;
}

export interface BulkServiceEditCheckEmpty {
  isEmptyActionType: boolean;
  isEmptyProcessType: boolean;
  isEmptyUserDepotAllocation: boolean;
  isEmptySecondaryUserDepotAllocation: boolean;
  isEmptyScheduledDateTime: boolean;
  isEmptyFollowUpDateTime: boolean;
  isEmptyNotes: boolean;
  isEmptyStatus: boolean;
  isEmptyLinkedField: boolean;
}

export interface BulkServiceEditValidate {
  validateActionType: boolean;
  validateProcessType: boolean;
  validateUserDepotAllocation: boolean;
  validateSecondaryUserDepotAllocation: boolean;
  validateScheduledDateTime: boolean;
  validateFollowUpDateTime: boolean;
  validateNotes: boolean;
  validateStatus: boolean;
  validateLinkedField: boolean;
}