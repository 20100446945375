import { styled } from '@mui/material/styles';
import { Box as MUIBox } from '@mui/system';
import { Typography } from '../../atoms/Typography';
import { Box, BoxProps } from '../../atoms/Box';

export const StyledTableWrapper = styled('div')`
  overflow: auto;
  width: 100%;
  height:auto;
  & .super-app-theme--notes {
    background-color: #ffffff
  };
  & .super-app-theme--uploads {
    background-color: #ffffff
  };
  & .super-app-theme--part-actions-and-logistics-events {
    background-color: #ffffff
  };
  & .super-app-theme--tasks {
    background-color: #ffffff
  };
  & .super-app-theme--sla-events {
    background-color: #ffffff
  };
  & .super-app-theme--service-events {
    background-color: #ffffff
  };
  & .super-app-theme--notification-events {
    background-color: #ffffff
  };
  & .super-app-theme--assignment-events {
    background-color: #ffffff
  };
  & .super-app-theme--tbc-interactions-data {
    background-color: #ffffff
  };
`;

export const GridContainer = styled('div')`
  height:auto;
  width: 100%;
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-viewport,
  .MuiDataGrid-row,
  .MuiDataGrid-renderingZone {
    max-height: fit-content !important;
  }
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-cell {
    max-height: fit-content !important;
    overflow: auto;
    white-space: initial !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
`;

export const StyledTypography = styled(Typography)`
  color: #c0bfc0;
`;

export const ActionColumnWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const NotesWrapper = styled(MUIBox)`
  white-space: pre-wrap;
`;

export const StyledActionMenu = styled(Box)((props: BoxProps & { visible: string }) => `
  display: flex;
  align-content: flex-end;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  position: absolute;
  right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  align-self: center;
  background-color: white;
  visibility: ${props.visible}
`);

export const EditIconWrapper = styled(Box)`
  display: flex;
`;

export const DeleteIconWrapper = styled(Box)`
  align-self: center;
  padding-right: 5px;
`;

interface StyledCellProps {
  isMobile: boolean;
}

export const StyledCell = styled('div')<StyledCellProps>(({ isMobile }) => ({
  fontSize: isMobile ? '10px' : 'inherit'
}));

export const StyledHeaderCell = styled('div')<StyledCellProps>(({ isMobile }) => ({
  fontSize: isMobile ? '10px' : 'inherit'
}));