import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Typography } from '../../atoms/Typography';
import { Box, BoxProps } from '../../atoms/Box';
import { COLORS } from '../../../configs/colors';
import { LogType } from '../../../@types';

enum colorParamsEnum {
  notes = '#ffffff',
  uploads = '#ffffff',
  partActionsAndLogisticsEvents = '#ffffff',
  tasks = '#ffffff',
  slaEvents = '#ffffff',
  serviceEvents = '#ffffff',
  notificationEvents = '#ffffff',
  assignmentEvents = '#ffffff',
  tbcInteractionsData = '#ffffff'
}

export const StyledTableWrapper = styled('div')((props: { isMobile?: boolean }) =>`
  overflow: auto;
  ${props.isMobile && 'height: 400px;'}
  width: 100%;
  & .super-app-theme--notes {
    background-color: ${colorParamsEnum.notes}
  };
  & .super-app-theme--uploads {
    background-color: ${colorParamsEnum.uploads}
  };
  & .super-app-theme--part-actions-and-logistics-events {
    background-color: ${colorParamsEnum.partActionsAndLogisticsEvents}
  };
  & .super-app-theme--tasks {
    background-color: ${colorParamsEnum.tasks}
  };
  & .super-app-theme--sla-events {
    background-color: ${colorParamsEnum.slaEvents}
  };
  & .super-app-theme--service-events {
    background-color: ${colorParamsEnum.serviceEvents}
  };
  & .super-app-theme--notification-events {
    background-color: ${colorParamsEnum.notificationEvents}
  };
  & .super-app-theme--assignment-events {
    background-color: ${colorParamsEnum.assignmentEvents}
  };
  & .super-app-theme--tbc-interactions-data {
    background-color: ${colorParamsEnum.tbcInteractionsData}
  };
  

  .MuiDataGrid-main {
    margin-top: -40px;
  }
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-viewport,
  .MuiDataGrid-row,
  .MuiDataGrid-renderingZone {
    max-height: fit-content !important;
    border: ${props.isMobile && 'none !important'};
  }
  /* Allows the MUI grid rows to expand to child height */
  .MuiDataGrid-cell {
    max-height: fit-content !important;
    overflow: auto;
    white-space: initial !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border: ${props.isMobile && 'none !important'};
  }
  .MuiDataGrid-columnHeaderTitle {
    display: ${props.isMobile && 'none !important'};
  }
  .MuiDataGrid-columnHeaders {
    visibility: ${props.isMobile && 'hidden !important'};
  }
  .MuiDataGrid-toolbarContainer {
    visibility: hidden !important;
  }
  .MuiDataGrid-footerContainer {
    display: ${props.isMobile && 'none !important'};
  }
  .MuiDataGrid-root {
    border: ${props.isMobile && 'none !important'};
  }
};`
);

export const StyledTypography = styled(Typography)`
  color: #c0bfc0;
`;

export const ActionColumnWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const ActionColumnMobileWrapper = styled(Box)((props: { logType?: string }) =>`
  display: flex;
  flex-direction: row;
  ${props?.logType === LogType.ReportedNotes &&
    `
      margin-top: -12px;
      margin-bottom: -10px;
    `}
};`
);

export const MarkupWrapper = styled('p')`
  margin-top: -12px;
  margin-bottom: -10px;
`;

export const StyledActionMenu = styled(Box)((props: BoxProps & { visible: string }) => `
  display: flex;
  align-content: flex-end;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  position: absolute;
  right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  align-self: center;
  background-color: white;
  visibility: ${props.visible};
`);

export const EditIconWrapper = styled(Box)`
  display: flex;
`;

export const DeleteIconWrapper = styled(Box)`
  align-self: center;
  padding-right: 5px;
`;

export const ImageWrapper = styled(Grid)`
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Image = styled('img')`
  background-color: ${COLORS.White};
`;

export const SmallTrackingHistoryLabel = styled(Typography)`
  font-size: x-small;
`;