import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ButtonVariantProps } from '../../../../components/atoms/Button';
import { nameValidation } from '../../../../utils/roles';
import { PageContainer } from '../../../../components/atoms/PageContainer';
import { TextField } from '../../../../components/atoms/TextField';
import { Typography, TypographyVariantProps } from '../../../../components/atoms/Typography';
import { FormControlLabel } from '../../../../components/atoms/FormControlLabel';
import { Switch } from '../../../../components/atoms/Switch';
import { ColorProps, PartType, Tag } from '../../../../@types';
import { Snackbar } from '../../../../components/atoms/Snackbar';
import { GRID_SPACING } from '../../../../configs/ui-constants';
import { NAME_FIELD_HELPER_TEXT } from '../../../../constants/common';
import { StyledSelectMenu, TLCBox, TLCTypography } from './AddEditTemplate.styles';
import { RadioGroup } from '../../../../components/atoms/RadioGroup';
import { BEHAVIOUR_OPTIONS, CHECKED_DATA, IS_REQUIRED_FIELDS, RESTRICTION_DATA, TEMPLATE_ACTION_DATA, TLC_TYPES } from '../../../../constants/tlc';
import { CheckedData, DropDownItems, IsRequiredFields, RestrictionData, TemplateActionData, TemplateResponse } from '../../../../@types/tlc.type';
import { TLCRestriction } from '../../../../components/templates/TLCRestriction/TLCRestriction';
import { TLCTemplateAction } from '../../../../components/templates/TLCTemplateAction/TLCTemplateAction';
import { Behaviour, EntityType, NotePromptBehaviour, TLCActionTemplateType } from '../../../../configs/enums';
import { useGetOnePartType } from '../../../../queries/part-type-query';
import { useGetPartConditions } from '../../../../queries/part-query';
import { getAllTags, useAddTags } from '../../../../queries/tags-query';
import { useAddTLCTemplate, useGetActionTemplateTypes, useGetTLCTemplateById, useUpdateTLCTemplate } from '../../../../queries/tlc-query';
import { getNewTagsMapped, getSelectedExistingTagsMapped, mapSavedTags } from '../../../../components/organisms/Tags';
import { RoutePath } from '../../../../@types/route.type';
import { Node } from '../../../../@types/part.type';
import { DropdownItem } from '../../../../components/atoms/SelectMenu';
import { arrayItemsToInt, arrayItemsToString, canHaveSWFWHWData } from '../../../../utils/tlc';
import { SAMPLE_PART_TYPE } from '../../../../constants/partType';

export const AddEditTemplate: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [behaviour, setBehaviour] = useState<string>(Behaviour.Batch);
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [restrictionData, setRestrictionData] = useState<RestrictionData>(RESTRICTION_DATA);
  const [templateActionData, setTemplateActionData] = useState<TemplateActionData>(TEMPLATE_ACTION_DATA);
  const [open, setOpen] = useState(false);
  const [nameHelperText, setNameHelperText] = useState('');
  const [tlcType, setTLCType] = useState<string>('');
  const [isHavingTLCType, setIsHavingTLCType] = useState<boolean>(true);
  const [newTags, setNewTags] = useState<Tag[]>([]);
  const [tlcTypesOptions, setTLCTypesOptions] = useState<DropdownItem[]>(TLC_TYPES);
  const [isChecked, setIsChecked] = useState<CheckedData>(CHECKED_DATA);
  const [partTypeId, setPartTypeId] = useState<number>(NaN);
  const [partType, setPartType] = useState<PartType>(SAMPLE_PART_TYPE);
  const [render, setRender] = useState<string>('');
  const [isRequired, setIsRequired] = useState<IsRequiredFields>(IS_REQUIRED_FIELDS);
  const isNew = id === 'create';

  const getOnePartType = useGetOnePartType(partTypeId);
  const getPartConditions = useGetPartConditions();
  const userTagsQuery = getAllTags(EntityType.TYPE_TLC);
  const addNewTemplateQuery = useAddTLCTemplate();
  const updateTemplateQuery = useUpdateTLCTemplate();
  const getTemplate = useGetTLCTemplateById(parseInt(id || ''));
  const updateTagsQuery = useAddTags();
  const getTemplateActionTypes = useGetActionTemplateTypes();

  useEffect(() => {
    getTemplate.refetch();
    getPartConditions.refetch();
    getTemplateActionTypes.refetch();
    setTemplateActionData({ ...templateActionData, mainBehaviour: behaviour });
  }, [id]);

  useEffect(() => {
    if (getPartConditions.data) {
      setRestrictionData({ ...restrictionData, conditions: getPartConditions.data });
      setTemplateActionData((templateActionData) => ({
        ...templateActionData,
        options: {
          ...templateActionData.options,
          conditions: getPartConditions.data
        },
      }));
    }
  }, [getPartConditions.data]);

  useEffect(() => {
    if (getTemplateActionTypes.data) {
      setTLCTypesOptions(getTemplateActionTypes.data);
    }
  }, [getTemplateActionTypes.data, tlcType]);

  useEffect(() => {
    if (getTemplate.data) {
      setName(getTemplate.data.name);
      setDescription(getTemplate.data.description);
      setIsActive(getTemplate.data.isActive);
      setBehaviour(getTemplate.data.behavior);
      setTLCType(getTemplate.data.templateActionTypeCode); 
      setRestrictionData({ 
        ...restrictionData, 
        locationSearchData: getTemplate.data.restrictionLiveSearchLocations,
        isIncludeChilds: getTemplate.data.data.restrictions.location.includeChild,
        selectedSearchPartTypes: getTemplate.data.restrictionLiveSearchPartType,
        selectedConditions: getTemplate.data.data.restrictions.conditions,
        versions: {
          sw: arrayItemsToString(getTemplate.data.data.restrictions.softwareVersions),
          hw: arrayItemsToString(getTemplate.data.data.restrictions.hardwareRevisions), 
          fw: arrayItemsToString(getTemplate.data.data.restrictions.firmwareVersions),
        },
        selectedFleetTags: arrayItemsToString(getTemplate.data.data.restrictions.fleetTags),
      });
      setTemplateActionData({ 
        ...templateActionData, 
        partTypeSearch: getTemplate.data.partTypeLiveSearchResponse,
        locationSearch: getTemplate.data.locationLiveSearch,
        actionCondition: getTemplate.data.data.templateAction.condition || '',
        actionVersions: {
          sw: getTemplate.data.data.templateAction.softwareVersion?.toString() || '',
          hw: getTemplate.data.data.templateAction.hardwareRevision?.toString() || '',
          fw: getTemplate.data.data.templateAction.firmwareVersion?.toString() || ''
        },
        partActionArray: getTemplate.data.data.templateAction.partActions || [],
        actionNote: getTemplate.data.data.templateAction.note || '',
        actionBehaviour: getTemplate.data.data.templateAction.notePrompt.behavior || NotePromptBehaviour.Batch,
        selectedTags: mapSavedTags(getTemplate.data.tags || []) || [],
        mainBehaviour: getTemplate.data.behavior,
        restrictionPartTypes: getTemplate.data.restrictionLiveSearchPartType
      });

      setIsChecked({
        ...isChecked,
        isCheckCondition: getTemplate.data.data.templateAction.condition ? true : false,
        isCheckLocationSearch: getTemplate.data.data.templateAction.location ? true : false,
        isCheckNote: getTemplate.data.data.templateAction.note ? true : false,
        isCheckPartAction: getTemplate.data.data.templateAction.partActions ? true : false,
        isCheckPartTypeSearch: getTemplate.data.data.templateAction.partType ? true : false,
        isCheckHWRevision: getTemplate.data.data.templateAction.hardwareRevision ? true : false,
        isCheckSWVersions: getTemplate.data.data.templateAction.softwareVersion ? true : false,
        isCheckFWVersions: getTemplate.data.data.templateAction.firmwareVersion ? true : false,
        isCheckTags: getTemplate.data.tags ? true : false,
        isCheckLocationPrompt: getTemplate.data.data.templateAction.locationPrompt ? true: false,
        isCheckNotePrompt: getTemplate.data.data.templateAction.notePrompt.prompt ? true: false
      });
      setRender('Render');
    }
  }, [getTemplate.data, tlcType, templateActionData.options.conditions, restrictionData.conditions]);

  useEffect(() => {
    userTagsQuery.data && setTemplateActionData({ ...templateActionData, allTags:  userTagsQuery.data });
  }, [userTagsQuery.data]);

  useEffect(() => {
    restrictionData.selectedSearchPartTypes.length > 0 && setPartTypeId(restrictionData.selectedSearchPartTypes[0].id);
    setTemplateActionData((templateActionData) => ({
      ...templateActionData,
      restrictionPartTypes: restrictionData.selectedSearchPartTypes
    }));
  }, [restrictionData.selectedSearchPartTypes]);

  useEffect(() => {
    partTypeId && getOnePartType.refetch();
  }, [partTypeId]);

  useEffect(() => {
    getOnePartType.data && setPartType(getOnePartType.data);
  }, [getOnePartType.data]);

  useEffect(() => {
    if (partType) {
      const hw = partType.hardwares?.map(hardware => ({
        value: hardware?.id?.toString() || '',
        label: hardware?.version || ''
      }));
      const sw = partType.softwares?.map(software => ({
        value: software?.id?.toString() || '',
        label: software?.version || ''
      }));
      const fw = partType.firmwares?.map(firmware => ({
        value: firmware?.id?.toString() || '',
        label: firmware?.version || ''
      }));

      const fleetTags = partType.fleetTag?.map(tag => ({
        value: tag?.value.toString() || '',
        label: tag?.label.toString() || ''
      }));

      const dropDown: DropDownItems = {
        hw: hw,
        sw: sw,
        fw: fw
      };
      
      setRestrictionData({ ...restrictionData, dropDownItems: dropDown, fleetTags: fleetTags });
      setTemplateActionData((templateActionData) => ({
        ...templateActionData,
        options: {
          ...templateActionData.options,
          dropDownItems: dropDown,
        },
      }));
  
    }
  }, [partType]);

  const handleNameChange = (name: string) => {
    setNameHelperText(nameValidation(name, NAME_FIELD_HELPER_TEXT));
    setName(name);
  };

  const handleSave = async() => {
    let template: TemplateResponse;
    const isHavingRestrictionPartType = restrictionData.selectedSearchPartTypes.length > 0;
    const isHavingConditions = restrictionData.selectedConditions.length > 0;
    const isHavingLocations = restrictionData.locationSearchData.length > 0;
    const isHavingTemplatePartType = templateActionData.restrictionPartTypes?.length === 1;
    const isHavingOneSelectedPartType = restrictionData.selectedSearchPartTypes.length === 1;

    const selectedPartTypeIds = isHavingRestrictionPartType ? restrictionData.selectedSearchPartTypes.map((selectedPartType: PartType) => (selectedPartType.id)) : [];
    const selectedLocationIds = restrictionData.locationSearchData.length > 0 ? restrictionData.locationSearchData.map((selectedLocation: Node) => (selectedLocation.id)) : [];

    const restrictions = {
      partType: selectedPartTypeIds,
      location: {
        includeChild: restrictionData.isIncludeChilds,
        id: selectedLocationIds
      },
      conditions: restrictionData.selectedConditions,
      hardwareRevisions: isHavingOneSelectedPartType ? arrayItemsToInt(restrictionData.versions.hw) : [],
      softwareVersions: isHavingOneSelectedPartType ? arrayItemsToInt(restrictionData.versions.sw) : [],
      firmwareVersions: isHavingOneSelectedPartType ? arrayItemsToInt(restrictionData.versions.fw) : [],
      fleetTags: isHavingRestrictionPartType ? arrayItemsToInt(restrictionData.selectedFleetTags) : []
    };

    const actionTemplate = {
      type: tlcType,
      partType: isChecked.isCheckPartTypeSearch && templateActionData.restrictionPartTypes && isHavingTemplatePartType ? templateActionData.restrictionPartTypes[0]?.id : null,
      condition: isChecked.isCheckCondition ? templateActionData.actionCondition : null,
      location: isChecked.isCheckLocationSearch && templateActionData.locationSearch ? templateActionData.locationSearch?.id : null,
      locationPrompt: isChecked.isCheckLocationPrompt,
      hardwareRevision: canHaveSWFWHWData(isChecked.isCheckHWRevision, isHavingTemplatePartType, templateActionData.actionVersions.hw) ? parseInt(templateActionData.actionVersions.hw) : null,
      softwareVersion: canHaveSWFWHWData(isChecked.isCheckSWVersions, isHavingTemplatePartType, templateActionData.actionVersions.sw) ? parseInt(templateActionData.actionVersions.sw) : null,
      firmwareVersion: canHaveSWFWHWData(isChecked.isCheckFWVersions, isHavingTemplatePartType, templateActionData.actionVersions.fw) ? parseInt(templateActionData.actionVersions.fw) : null,
      partActions: isChecked.isCheckPartAction ? templateActionData.partActionArray : [],
      note: isChecked.isCheckNote && templateActionData.actionNote ? templateActionData.actionNote : null,
      notePrompt: {
        prompt: isChecked.isCheckNotePrompt,
        behavior: isChecked.isCheckNotePrompt ? templateActionData.actionBehaviour : null,
      },
    };

    const templatedata = {
      id: id,
      name: name,
      description: description,
      behavior: behaviour,
      isActive: isActive,
      templateActionTypeCode: tlcType,
      data: {
        restrictions: restrictions,
        templateAction: actionTemplate
      }
    };

    if (name !== '' && isHavingRestrictionPartType && isHavingConditions && isHavingLocations && tlcType !== '') {
      if (isNew) {
        template = await addNewTemplateQuery.mutateAsync(templatedata);
      } else {
        template = await updateTemplateQuery.mutateAsync(templatedata);
      }

      const existingTags = getSelectedExistingTagsMapped(templateActionData.selectedTags);
      const freshTags = getNewTagsMapped(newTags);
  
      await updateTagsQuery.mutateAsync({
        entityTypeId: EntityType.TYPE_TLC,
        entityId: template.id,
        freshTags,
        existingTags
      });
  
      setOpen(true);
      navigate(`${RoutePath.UpdateTLCTemplate}/${template.id}`);

    } else {
      setIsRequired({ ...isRequired, partType: !isHavingRestrictionPartType, condition: !isHavingConditions, location: !isHavingLocations });
      name === '' && setNameHelperText(nameValidation(name, NAME_FIELD_HELPER_TEXT));
      tlcType === '' && setIsHavingTLCType(false);
    } 
  };

  return (
    <PageContainer>
      <TLCTypography variant={TypographyVariantProps.H5} fontWeight={600}>
        {`${isNew ? 'ADD' : 'UPDATE'} TEMPLATE`}
      </TLCTypography>
      <TLCBox>
        <Grid>
          <Grid container mt="1px" spacing={GRID_SPACING}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                error={!!nameHelperText}
                helperText={nameHelperText}
                label="Name"
                value={name}
                onChange={handleNameChange}
              />
            </Grid>
            <Grid item ml={2} xs={4}>
              <RadioGroup
                id={`${id}-TLCBehaviour`}
                title="Behaviour"
                options={BEHAVIOUR_OPTIONS}
                value={behaviour}
                onChange={(behaviourValue) => {
                  setBehaviour(behaviourValue);
                  setTemplateActionData({ ...templateActionData, mainBehaviour: behaviourValue });
                }}
              />
            </Grid>
          </Grid>
          <Grid container mt="1px" spacing={GRID_SPACING}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={4}
                value={description}
                onChange={(textDescription) => setDescription(textDescription)}
              />
            </Grid>
            <Grid item ml={2} xs={4}>
              <FormControlLabel
                control={<Switch color={ColorProps.Success} onChange={(event) => setIsActive(event.target.checked)} checked={isActive} defaultChecked />}
                label="Active"
              /> 
            </Grid>
          </Grid>
          <Grid mt={4}>
            <Typography variant={TypographyVariantProps.H6}>Restrictions</Typography>
          </Grid>
          <TLCRestriction
            key={render}
            isRequired={isRequired}
            data={restrictionData}
            onChange={(restrictionValues, errorCheck) => {
              setRestrictionData(restrictionValues);
              errorCheck && setIsRequired(errorCheck);
            }}
          /> 
          <Grid mt={4}>
            <Typography variant={TypographyVariantProps.H6}>Template Action</Typography>
          </Grid>
          <Grid container mt="1px" spacing={GRID_SPACING}>
            <Grid item xs={6}>
              <StyledSelectMenu
                id="tlc-type-menu"
                label="Select TLC Type"
                validate={!isHavingTLCType}
                minWidth={240}
                required
                selectedValue={tlcType}
                onChange={(tlcType: string) => {
                  setIsHavingTLCType(true);
                  setTLCType(tlcType);
                }}
                items={tlcTypesOptions}
              />
            </Grid>
          </Grid>
          {tlcType === TLCActionTemplateType.Serialized_Part_Update && 
          <TLCTemplateAction
            key={render}
            checkedData={isChecked}
            newTag={newTags}
            templateData={templateActionData}
            onChangeChecked={(checkedValues) => setIsChecked(checkedValues)}
            onChangeData={(actionData, newTags) =>{
              setTemplateActionData(actionData);
              newTags && setNewTags(newTags);
            }}
          />
          }
          <Grid item container mt={3} xs={12} spacing={2}>
            <Grid item>
              <Button variant={ButtonVariantProps.Primary} onClick={handleSave}>
                {`${isNew ? 'Create' : 'update'} Template`}
              </Button>
            </Grid>
          </Grid>
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={() => setOpen(false)}
            message="Successfully Saved"
          />
        </Grid>
      </TLCBox>
    </PageContainer>
  );
};
