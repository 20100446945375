import React, { useEffect, useState, useCallback } from 'react';
import { Stack } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { GridColDef, GridFeatureModeConstant, GridFilterModel, GridValueGetterParams, GridRenderCellParams, GridSortModel, GridSelectionModel } from '@mui/x-data-grid-pro';
import { ColumnSelectionItem } from '../../../../components/templates/ColumnSelection';
import { Button, ButtonVariantProps } from '../../../../components/atoms/Button';
import { CustomDataGrid } from '../../../../components/layouts/CustomDataGrid';
import { useDeleteReportAccessDataById, useGetSavedReports, useUpdateReportAccessDataById } from '../../../../queries/report-query';
import { ReportAccessData, SavedReportsList, SavedReportsListResponse, SizeProps } from '../../../../@types';
import { formatDate } from '../../../../utils/common';
import { Link } from '../../../../components/atoms/Link';
import { generateListPageReportURL } from '../../../../utils/report';
import { ReportEditFragment } from './ReportEditFragment/ReportEditFragment';
import { useGetLoggedInSiblingParentDetails } from '../../../../queries/structure-query';
import { PopupDialog } from '../../../../components/molecules/PopupDialog';
import { Snackbar } from '../../../../components/atoms/Snackbar';
import { GridContainer } from '../../../../styled/common.styles';
import { StyledChip } from './MyReportsListTable.styles';
import { defaultColors } from '../../../../configs/colors';

export const MyReportsListTable: React.FC = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [openModal, setOpenModal] = useState(false);
  const [reportRowData, setReportRowData] = useState<SavedReportsList>();
  const [savedReportList, setSavedReportList] = useState<SavedReportsListResponse>();
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [editedColumnSelectionItems, setEditedColumnSelectionItems] = useState<Array<ColumnSelectionItem>>();

  const getLoggedInSiblingParentDetailsQuery = useGetLoggedInSiblingParentDetails();
  const getSavedReports = useGetSavedReports({ filterModel, page, pageSize, sortModel });

  const updateTemplateQuery = useUpdateReportAccessDataById();
  const deleteReportQuery = useDeleteReportAccessDataById();

  useEffect(() => {
    getSavedReports.refetch();
  }, [filterModel, page, pageSize, sortModel]);

  useEffect(() => {
    getLoggedInSiblingParentDetailsQuery.refetch();
    getSavedReports.data && setSavedReportList(getSavedReports.data);
  }, [getSavedReports.data]);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setFilterModel({ ...filterModel });
  }, []);
  
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleSelectionModelChange = useCallback((selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  }, []);

  const handleColumnsChange = (items: Array<ColumnSelectionItem>) => {
    setEditedColumnSelectionItems(items);
  };

  const closeModel = () => {
    setOpenModal(false);
  };

  const handleRemoveReport = async() => {
    setOpenConfirmationPopup(false);
    if (getLoggedInSiblingParentDetailsQuery.data?.siblingDetails.id === reportRowData?.reportOwner.id) {
      await deleteReportQuery.mutateAsync(reportRowData?.id);
    } else if (reportRowData?.savedReportAccess.some((access: ReportAccessData) => access.entityId === getLoggedInSiblingParentDetailsQuery.data?.siblingDetails.id)) {
      const siblingAccessData: ReportAccessData[] = reportRowData.savedReportAccess.filter((item: ReportAccessData) => item.entityId === getLoggedInSiblingParentDetailsQuery.data?.siblingDetails.id);
      
      const removedReportAccessData = {
        id: siblingAccessData[0].id,
        data: {
          entityId: siblingAccessData[0].entityId,
          canEdit: siblingAccessData[0].canEdit,
          canCustomizeColumns: siblingAccessData[0].canCustomizeColumns,
          canCustomizeSearch: siblingAccessData[0].canCustomizeSearch,
          isActive: false
        }
      };
      await updateTemplateQuery.mutateAsync(removedReportAccessData);
    }
    getSavedReports.refetch();
    setOpenToast(true);
  };

  const columnSelectionItems: Array<ColumnSelectionItem> = [
    {
      title: 'My Reports',
      value: [
        {
          field: 'name',
          title: 'Report Name',
          value: true
        },
        {
          field: 'pageName',
          title: 'Page Name',
          value: true
        },
        {
          field: 'reportOwner',
          title: 'Report Owner',
          value: true
        },
        {
          field: 'createdAt',
          title: 'Created At',
          value: true
        },
        {
          field: 'sharedWith',
          title: 'Shared With',
          value: false
        },
        {
          field: 'scheduledDetails',
          title: 'Scheduled Details',
          value: false
        },
        {
          field: 'edit', 
          title: 'Edit', 
          value: true
        },
        {
          field: 'delete', 
          title: 'Delete', 
          value: true
        },
      ]
    }
  ];

  useEffect(() => {
    setColumns([
      {
        field: 'name',
        headerName: 'Report Name',
        minWidth: 500,
        renderCell: (params: GridRenderCellParams) =>
          <Stack direction="row" spacing={2}>
            <Link href={generateListPageReportURL(params.row.savedReportType?.name, params.row.code)}>{params.row.name}</Link>
            {params.row.isDefault && getLoggedInSiblingParentDetailsQuery.data?.siblingDetails.id === params.row.reportOwner.id && <StyledChip bgColor={defaultColors[0]} size={SizeProps.Small} variant="outlined" label="Default" />}
            {params.row.sharedWithIds && <StyledChip bgColor={defaultColors[3]} size={SizeProps.Small} isSystem variant="filled" label="Shared" />}
            {params.row.scheduledDetails?.includes('Active: true') && <StyledChip bgColor={defaultColors[5]} size={SizeProps.Small} isSystem variant="filled" label="Scheduled" />}
          </Stack>,
        valueGetter: (params: GridValueGetterParams) => params.row.name
      },
      {
        field: 'pageName',
        headerName: 'Page Name',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.savedReportType?.name
      },
      {
        field: 'reportOwner',
        headerName: 'Report Owner',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => params.row.reportOwner.name
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        width: 200,
        valueGetter: (params: GridValueGetterParams) => formatDate(params.row.createdAt)
      },
      {
        field: 'sharedWith',
        headerName: 'Shared With',
        width: 300,
        valueGetter: (params: GridValueGetterParams) => params.row.sharedWith
      },
      {
        field: 'scheduledDetails',
        headerName: 'Scheduled Details',
        width: 650,
        valueGetter: (params: GridValueGetterParams) => params.row.scheduledDetails
      },
      {
        field: 'edit', 
        headerName: '',
        filterable: false,
        renderCell: (params: GridRenderCellParams) => ( 
          getLoggedInSiblingParentDetailsQuery.data?.siblingDetails.id === params.row.reportOwner.id && (<Button
            variant={ButtonVariantProps.Icon}
            onClick={() => {
              setOpenModal(true);
              setReportRowData(params.row);
            }}>
            <Edit />
          </Button>)
        )
      },
      {
        field: 'delete', 
        headerName: '',
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          (getLoggedInSiblingParentDetailsQuery.data?.siblingDetails.id === params.row.reportOwner.id || params.row.savedReportAccess.some((access: ReportAccessData) => access.entityId === getLoggedInSiblingParentDetailsQuery.data?.siblingDetails.id)) && 
          (<Button
            variant={ButtonVariantProps.Icon}
            onClick={() => {
              setOpenConfirmationPopup(true);
              setReportRowData(params.row);
            }}>
            <Delete />
          </Button>)
        )
      }
    ]);
  }, [getLoggedInSiblingParentDetailsQuery.data]);
  
  return (
    <GridContainer>
      <CustomDataGrid
        columns={columns}
        rows={savedReportList?.data || []}
        getRowId={(row) => row?.id}
        rowCount={savedReportList?.total}
        columnSelectionItems={editedColumnSelectionItems || columnSelectionItems}
        filterMode={GridFeatureModeConstant.server}
        filterModel={filterModel}
        enableCSVExport={false}
        exportFileName=""
        loading={getSavedReports.isLoading}
        onFilterModelChange={(filterModel: GridFilterModel) => {
          onFilterChange(filterModel);
        }}
        rowsPerPageOptions={[25, 50, 100]}
        pagination={true}
        page={page}
        pageSize={pageSize}
        paginationMode={GridFeatureModeConstant.server}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        sortingMode={GridFeatureModeConstant.server}
        onSortModelChange={handleSortModelChange}
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionModelChange}
        handleColumnsChange={handleColumnsChange}
        initialState={{
          columns: {
            columnVisibilityModel: {
              parentNodeId: false,
              referenceNumbers: false,
              status: false,
            },
          },
        }}
        componentsProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true }
          }
        }}
        enableSavedReport={false}
        setColumns={setColumns}
      />
      <ReportEditFragment open={openModal} closeModel={closeModel} rowData={reportRowData} refetchReports={(refetch) => refetch && getSavedReports.refetch()}/>
      <Snackbar
        open={openToast} 
        autoHideDuration={4000} 
        message="Successfully removed the report"
        onClose={() => setOpenToast(false)}
      />
      <PopupDialog
        maxWidth="450px"
        title="Confirm the action"
        isOpen={openConfirmationPopup}
        actionLabel="Yes"
        onClose={(event?: any, reason?: string) => (reason !== 'backdropClick') && setOpenConfirmationPopup(false)}
        onSave={() => handleRemoveReport()}
      >
        Are you sure you want to remove this report?
      </PopupDialog>
    </GridContainer>
  );
};