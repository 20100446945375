export enum CapacityHourTypes {
  AllDays = 'All Days',
  Weekdays = 'Weekdays',
  Weekends = 'Weekends',
}

export interface DropdownItem {
  value: string;
  label: string;
  disabled?: boolean;
}

export interface CapacityData {
  type: CapacityHourTypes;
  checkInventory: boolean;
  allowOveride: boolean;
  max: string;
  itemsData: DropdownItem[];
  selectedItems?: string[];
  selectedClients?: string[];
  selectedContracts?: string[];
  clientHierarchy?: string;
  clientsData: DropdownItem[];
  contractsData: DropdownItem[];
}

export interface CapacityProps {
  value: CapacityData;
  onChange: (value: CapacityData) => void;
  onDelete: () => void;
  disableElement?: boolean;
}
