import React, { useState, useEffect } from 'react';
import {
  GridDensityTypes,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExportContainer
} from '@mui/x-data-grid-pro';
import Grid from '@mui/material/Grid';
import Button, { ButtonProps } from '@mui/material/Button';
import { ViewColumn, AppRegistration } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { DataGrid } from '../../atoms/DataGrid';
import { ExcelExportMenuItem } from '../../atoms/ExcelExportMenuItem';
import { Switch } from '../../atoms/Switch';
import { Typography } from '../../atoms/Typography';
import { DataGridMenu } from '../../atoms/DataGridMenu';
import { SavedReports } from '../../organisms/SavedReports/SavedReports';
import { BulkServiceEditor } from '../../organisms/BulkServiceEditor';
import { ColumnSelection, ColumnSelectionItem } from '../../templates/ColumnSelection';
import { CustomDataGridProps } from './CustomDataGrid.props';
import {
  BulkEditServiceDataType,
  ColorProps,
  CustomDensityOption,
  FileType,
  NewReportDataType,
  OverwriteReportDataType,
  PropReportDataType,
  ReportViewAccessType,
  SizeProps
} from '../../../@types';
import { CONFIRMATION_MESSAGES, GRID_MENU_REPORT_OPTIONS1, GRID_MENU_REPORT_OPTIONS2, GRID_MENU_REPORT_OPTIONS3, GridMenuOption, REPORT_ACCESS_DATA } from '../../../constants/report';
import { useGetDefaultReportByReportType, useGetReportAccessByReportCode, useUpdateBulkReports, useUpdateReportByCode } from '../../../queries/report-query';
import { PopupDialog } from '../../molecules/PopupDialog';
import { Snackbar } from '../../atoms/Snackbar';
import { useGetLoggedInSiblingParentDetails } from '../../../queries/structure-query';
import { generateUniqueCode } from '../../../utils/common';
import { MOBILE_DATA_GRID_COLUMN } from '../../../configs/ui-constants';
import { CustomDataGridMenu } from '../../atoms/CustomDataGridMenu';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import { DataGridDensityMenuItems } from '../../atoms/DataGridDensityMenuItems';

export const CustomDataGrid: React.FC<CustomDataGridProps> = ({
  columns,
  rows,
  exportFileName,
  columnSelectionItems,
  density = GridDensityTypes.Compact,
  getRowId,
  componentsProps,
  disableColumnFilter,
  disableColumnMenu,
  disableColumnSelector,
  disableDensitySelector = true,
  filterMode,
  onFilterModelChange,
  loading,
  rowCount,
  rowsPerPageOptions,
  pagination,
  page,
  pageSize,
  paginationMode,
  onPageChange,
  onPageSizeChange,
  initialState,
  filterModel,
  onSortModelChange,
  sortingMode,
  getRowClassName,
  structureTypeCategory = '',
  selectionModel,
  onSelectionModelChange,
  bulkEditServiceDropDownsDataset,
  selectedSevicesForBulkUpdate,
  bulkUpdateToastMessage,
  enableBulkUpdate,
  enableCSVExport = true,
  handleBulkUpdateSave,
  handleBulkUpdateImportedData,
  enableReplenishmentReportFilters,
  replenishmentFilterData,
  handleReplenishmentReportFilters,
  enableSavedReport,
  newReportData,
  handleSavedReport,
  handleOverwriteReport,
  handleColumnsChange,
  reportCode,
  handleRefetch,
  justifyContent = 'flex-start',
  getRowHeight,
  onColumnOrderChange,
  sortModel,
  setColumns,
  startDate,
  endDate,
  isMobile = false,
  onDensityChange,
  onCellClick,
  onCellEditStop,
  apiRef,
  experimentalFeatures,
  autoHeight,
  serialSearch,
  serviceColumns,
  locationId,
  includeChilds,
  defaultExcludedColumns,
  serialPartsManifestData,
  nonSerialPartsManifestData
}) => {
  const [open, setOpen] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [openBulkUpdate, setOpenBulkUpdate] = useState(false);
  const [openSavedReports, setOpenSavedReports] = useState(false);
  const [columnSelectionData, setColumnSelectionData] = useState<Array<ColumnSelectionItem>>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [gridMenuOptions, setGridMenuOptions] = useState<string[]>([]);
  const [updatedNewReportData, setUpdatedNewReportData] = useState<PropReportDataType>();
  const [reportViewAccess, setReportViewAccess] = useState<ReportViewAccessType>(REPORT_ACCESS_DATA);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [exportStatus, setExportStatus] = useState('');

  const { data: reportAccessQueryData, refetch: refetchGetReportAccessByCodeQuery } = useGetReportAccessByReportCode(reportCode || '');
  const defaultReportData = useGetDefaultReportByReportType(newReportData?.reportTypeName || '');
  const useUpdateReportQuery = useUpdateReportByCode(reportCode || '');
  const useUpdateDefaultReport = useUpdateBulkReports();
  const getLoggedInSiblingParentDetailsQuery = useGetLoggedInSiblingParentDetails();

  useEffect(() => {
    if (reportCode) {
      refetchGetReportAccessByCodeQuery();
      defaultReportData.refetch();
    }
    getLoggedInSiblingParentDetailsQuery.refetch();
  }, [reportCode]);

  useEffect(() => {
    setIsOwner(getLoggedInSiblingParentDetailsQuery.data?.siblingDetails.id === newReportData?.reportOwner);
  }, [getLoggedInSiblingParentDetailsQuery.data, newReportData]);

  useEffect(() => {
    if (reportCode && reportAccessQueryData) {
      if (reportAccessQueryData.global) {
        setReportViewAccess({
          enableEdit: true,
          enableColumnSelection: true,
          enableFiltering: true
        });
      } else {
        setReportViewAccess({
          enableEdit: reportAccessQueryData?.canEdit || false,
          enableColumnSelection: reportAccessQueryData?.canCustomizeColumns || false,
          enableFiltering: reportAccessQueryData?.canCustomizeSearch || false
        });
      }
    }
  }, [reportAccessQueryData]);

  useEffect(() => {
    columnSelectionItems && setColumnSelectionData(columnSelectionItems);
  }, [columnSelectionItems]);

  useEffect(() => {
    if (!reportCode) {
      setGridMenuOptions(GRID_MENU_REPORT_OPTIONS3);
    } else if (reportCode && reportViewAccess.enableEdit && reportViewAccess.enableColumnSelection) {
      setGridMenuOptions(GRID_MENU_REPORT_OPTIONS1);
    } else if (reportCode && reportViewAccess.enableEdit && !reportViewAccess.enableColumnSelection) {
      setGridMenuOptions(GRID_MENU_REPORT_OPTIONS2);
    }
  }, [reportViewAccess.enableColumnSelection, reportViewAccess.enableEdit, reportViewAccess.enableFiltering]);

  useEffect(() => {
    setUpdatedNewReportData({ columnSelection: columnSelectionData });
    
    if (isMobile) { 
      const columnVisibility = columns.reduce((acc: any, column: any) => { 
        return { ...acc, [column.field]: column.field === MOBILE_DATA_GRID_COLUMN }; 
      }, {});

      setColumnVisibilityModel(columnVisibility);
    } else {
      if (columnSelectionData.length) {
        const columnVisibility = columnSelectionData.reduce((acc1: any, obj1: any) => {
          const items = obj1.value.reduce((acc2: any, obj2: any) => {
            return {
              ...acc2,
              [obj2.field]: obj2.value,
            };
          }, {});
          return { ...acc1, ...items, [MOBILE_DATA_GRID_COLUMN]: false };
        }, {});
        setColumnVisibilityModel(columnVisibility);
      } else {
        const columnVisibility = columns.reduce((acc: any, column: any) => { 
          return { ...acc, [column.field]: column.field !== MOBILE_DATA_GRID_COLUMN && column.hide !== true }; 
        }, {});
  
        setColumnVisibilityModel(columnVisibility);
      }
    }
  }, [columnSelectionData, isMobile]);

  useEffect(() => {
    if (exportStatus !== '' && exportStatus !== 'Export Inprogress!') {
      setTimeout(() => {
        setExportStatus('');
      }, 5000);
    }
  }, [exportStatus]);

  const CustomExportButton = (props: ButtonProps) => (
    <GridToolbarExportContainer disabled={exportStatus === 'Export Inprogress!'} {...props}>
      <ExcelExportMenuItem
        label="Download as CSV"
        fileName={exportFileName}
        setExportStatus={setExportStatus}
        fileType={FileType.CSV}
        structureTypeCategory={structureTypeCategory}
        columnSelectionData={columnSelectionData}
        sortModel={sortModel}
        startDate={startDate}
        endDate={endDate}
        serialSearch={serialSearch}
        locationId={locationId}
        includeChilds={includeChilds}
        rowCount={rowCount}
        defaultExcludedColumns={defaultExcludedColumns}
        serialPartsManifestData={serialPartsManifestData}
        nonSerialPartsManifestData={nonSerialPartsManifestData}
      />
      <ExcelExportMenuItem
        label="Download as EXCEL"
        fileName={exportFileName}
        setExportStatus={setExportStatus}
        fileType={FileType.XLSX}
        structureTypeCategory={structureTypeCategory}
        columnSelectionData={columnSelectionData}
        sortModel={sortModel}
        startDate={startDate}
        endDate={endDate}
        serialSearch={serialSearch}
        locationId={locationId}
        includeChilds={includeChilds}
        rowCount={rowCount}
        defaultExcludedColumns={defaultExcludedColumns}
        serialPartsManifestData={serialPartsManifestData}
        nonSerialPartsManifestData={nonSerialPartsManifestData}
      />
    </GridToolbarExportContainer>
  );

  const ReplenishmentReportSection = () => (
    <Stack direction="row" spacing={2} ml={2}>
      <Stack direction="row">
        <Typography>In Transit</Typography>
        <Switch
          size={SizeProps.Small}
          color={ColorProps.Success}
          checked={replenishmentFilterData?.inTransit}
          onChange={() => {
            replenishmentFilterData && handleReplenishmentReportFilters && handleReplenishmentReportFilters({
              ...replenishmentFilterData,
              inTransit: !replenishmentFilterData.inTransit
            });
          }}
        />
      </Stack>
      <Stack direction="row">
        <Typography>Services In Train</Typography>
        <Switch
          size={SizeProps.Small}
          color={ColorProps.Success}
          checked={replenishmentFilterData?.servicesInTrain}
          onChange={() => {
            replenishmentFilterData && handleReplenishmentReportFilters && handleReplenishmentReportFilters({
              ...replenishmentFilterData,
              servicesInTrain: !replenishmentFilterData.servicesInTrain
            });
          }}
        />
      </Stack>
      <Stack direction="row">
        <Typography>Show All Results</Typography>
        <Switch
          size={SizeProps.Small}
          color={ColorProps.Success}
          checked={replenishmentFilterData?.showAllResults}
          onChange={() => {
            replenishmentFilterData && handleReplenishmentReportFilters && handleReplenishmentReportFilters({
              ...replenishmentFilterData,
              showAllResults: !replenishmentFilterData.showAllResults
            });
          }}
        />
      </Stack>
    </Stack>
  );

  const handleReportOptions = (selectedOption: string) => {
    if (selectedOption === GridMenuOption.ColumnSelection) {
      setOpen(!open);
    } else if (selectedOption === GridMenuOption.SaveReport) {
      setOpenSavedReports(!openSavedReports);
    } else if (selectedOption === GridMenuOption.SetAsDefault) {
      setOpenConfirmationPopup(true);
    }
  };

  const onConfirmationSave = async () => {
    setOpenConfirmationPopup(false);
    if (getLoggedInSiblingParentDetailsQuery.data?.siblingDetails.id !== newReportData?.reportOwner) {
      const uniqueCode = generateUniqueCode();

      const reportNew = {
        name: newReportData?.reportName || '',
        code: uniqueCode,
        reportTypeName: newReportData?.reportTypeName || '',
        isDefault: true,
        dataGridProperties: newReportData?.dataGridProperties,
        columnSelection: newReportData?.columnSelection
      };

      handleSavedReport && handleSavedReport(reportNew);
      setOpenToast(true);

    } else if (newReportData?.isDefault) {
      const updateReportData = {
        name: newReportData?.reportName,
        isDefault: false
      };
      await useUpdateReportQuery.mutateAsync(updateReportData);
      setOpenToast(true);

    } else if (defaultReportData.data && defaultReportData.data?.length > 0) {
      const updateDefaultReport = [
        {
          id: defaultReportData.data[0].id,
          isDefault: false
        },
        {
          id: newReportData?.id,
          isDefault: true
        }
      ];
      await useUpdateDefaultReport?.mutateAsync(updateDefaultReport);
      setOpenToast(true);

    } else {
      await useUpdateReportQuery.mutateAsync({
        name: newReportData?.reportName,
        isDefault: true
      });
      setOpenToast(true);
    }
    handleRefetch && handleRefetch();
  };

  const CustomToolBar = () => (
    <GridToolbarContainer style={{ justifyContent }}>
      <Stack direction="row" spacing={2}>
        {((!reportCode && enableSavedReport) || (reportViewAccess.enableEdit)) && !isMobile &&
          <DataGridMenu
            id="reporting"
            title="Reporting"
            isDefault={newReportData?.isDefault}
            owner={getLoggedInSiblingParentDetailsQuery.data?.siblingDetails.id === newReportData?.reportOwner}
            options={gridMenuOptions}
            onSelectOption={(value) => handleReportOptions(value)}
          />
        }
        {((!enableSavedReport && !reportCode) || (reportCode && !reportViewAccess.enableEdit && reportViewAccess.enableColumnSelection)) && !isMobile && !disableColumnSelector && (
          <Button startIcon={<ViewColumn />} onClick={() => setOpen(!open)}>Column Selection</Button>
        )}
        {(!reportCode || reportViewAccess.enableFiltering) && (
          <GridToolbarFilterButton />
        )}
        {!disableDensitySelector && (
          <CustomDataGridMenu id={'data-grid-custom-menu'} title={'Density'} menuButtonIcon={FormatAlignJustifyIcon}>
            <DataGridDensityMenuItems selectedDesityOption={isMobile ? CustomDensityOption.Mobile : CustomDensityOption.Default} onDensityChange={(option) => onDensityChange && onDensityChange(option)} />
          </CustomDataGridMenu>
        )}
        {enableCSVExport && (
          <CustomExportButton />
        )}
        {!isMobile && enableBulkUpdate && (
          <Button startIcon={<AppRegistration />} onClick={() => setOpenBulkUpdate(!openBulkUpdate)}>Bulk Update</Button>
        )}
      </Stack>
      {enableReplenishmentReportFilters && (
        <ReplenishmentReportSection />
      )}
    </GridToolbarContainer>
  );

  return (
    <>
      <Grid>
        <Snackbar
          open={openToast}
          autoHideDuration={4000}
          message="Successfully updated"
          onClose={() => setOpenToast(false)}
        />
        <Snackbar
          open={exportStatus !== ''}
          message={exportStatus}
          onClose={(_,reason) => {
            if (reason === 'clickaway' || reason === 'escapeKeyDown') {
              return;
            } else {
              setExportStatus('');
            }
          }}
        />
      </Grid>
      <DataGrid
        autoHeight={autoHeight}
        columns={columns}
        rows={rows}
        density={density}
        getRowId={getRowId}
        componentsProps={componentsProps}
        columnVisibilityModel={columnVisibilityModel}
        disableColumnFilter={disableColumnFilter}
        disableColumnMenu={isMobile || disableColumnMenu}
        disableColumnSelector={disableColumnSelector}
        disableDensitySelector={disableDensitySelector}
        filterMode={filterMode}
        onFilterModelChange={onFilterModelChange}
        loading={loading}
        rowCount={rowCount}
        rowsPerPageOptions={rowsPerPageOptions}
        pagination={pagination}
        page={page}
        pageSize={pageSize}
        paginationMode={paginationMode}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        initialState={initialState}
        customToolbar={CustomToolBar}
        filterModel={filterModel}
        onSortModelChange={onSortModelChange}
        sortingMode={sortingMode}
        getRowClassName={getRowClassName}
        selectionModel={selectionModel}
        onSelectionModelChange={onSelectionModelChange}
        getRowHeight={getRowHeight}
        onColumnOrderChange={onColumnOrderChange}
        setColumns={setColumns}
        onCellClick={onCellClick}
        onCellEditStop={onCellEditStop}
        apiRef={apiRef}
        experimentalFeatures={experimentalFeatures}
        serviceColumns={serviceColumns}
      />
      <ColumnSelection
        open={open}
        title="Column Selection"
        value={columnSelectionData}
        onChange={(val) => {
          setColumnSelectionData(val);
          handleColumnsChange && handleColumnsChange(val);
        }}
        onClose={() => setOpen(false)}
      />
      <BulkServiceEditor
        open={openBulkUpdate}
        BulkEditServiceDropDownsDataset={bulkEditServiceDropDownsDataset}
        selectedServices={selectedSevicesForBulkUpdate}
        toastMessage={bulkUpdateToastMessage}
        handleClose={() => setOpenBulkUpdate(false)}
        handleSave={(BulkServiceUpdateData: BulkEditServiceDataType) => {
          handleBulkUpdateSave && handleBulkUpdateSave(BulkServiceUpdateData);
        }}
        handleImportedData={(data: any[]) => {
          handleBulkUpdateImportedData && handleBulkUpdateImportedData(data);
        }}
      />
      {enableSavedReport && newReportData && updatedNewReportData &&
        (<SavedReports
          open={openSavedReports}
          enableOverWrite={isOwner || reportViewAccess.enableEdit}
          handleClose={() =>
            setOpenSavedReports(false)
          }
          newReportData={newReportData}
          updateColumnData={updatedNewReportData}
          handleSave={(newReportData: NewReportDataType) => {
            handleSavedReport && handleSavedReport(newReportData);
            setOpenSavedReports(false);
          }}
          handleOverwrite={(overWriteReportData: OverwriteReportDataType) => {
            handleOverwriteReport && handleOverwriteReport(overWriteReportData);
            setOpenSavedReports(false);
          }}
        />)
      }
      <PopupDialog
        maxWidth="450px"
        title="Confirm the action"
        isOpen={openConfirmationPopup}
        actionLabel="Yes"
        onClose={(event?: any, reason?: string) => (reason !== 'backdropClick') && setOpenConfirmationPopup(false)}
        onSave={() => onConfirmationSave()}
      >
        {newReportData?.isDefault && (isOwner || reportViewAccess.enableEdit) ? CONFIRMATION_MESSAGES.removeDefaultMode : (defaultReportData.data && defaultReportData.data?.length > 0 ? CONFIRMATION_MESSAGES.alreadyExist : CONFIRMATION_MESSAGES.setAsDefault)}
      </PopupDialog>
    </>
  );
};
