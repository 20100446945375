import React from 'react';
import { TextField as MaterialTextField } from '@mui/material';
import { SizeProps } from '../../../@types';
import { TextFieldProps } from './TextField.props';

export const TextField: React.FC<TextFieldProps> = ({
  variant,
  className,
  required = false,
  multiline = false,
  autoFocus = false,
  placeholder,
  rows,
  label,
  defaultValue,
  value,
  helperText,
  error,
  disabled = false,
  fullWidth = false,
  onChange,
  onBlur,
  InputLabelProps,
  InputProps,
  inputProps,
  size = SizeProps.Small,
  inputRef,
  type,
  onKeyDown
}) => {
  return (
    <MaterialTextField
      className={className}
      variant={variant}
      required={required}
      multiline={multiline}
      placeholder={placeholder}
      label={label}
      defaultValue={defaultValue}
      value={value}
      rows={rows}
      helperText={helperText}
      error={error}
      disabled={disabled}
      fullWidth={fullWidth}
      onChange={(event: any) => onChange && onChange(event.target.value)}
      onBlur={(event: any) => onBlur && onBlur(event.target.value)}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
      inputProps={inputProps}
      size={size}
      inputRef={inputRef}
      type={type}
      onKeyDown={onKeyDown}
      autoFocus={autoFocus}
    >
    </MaterialTextField>
  );
};
