import React from 'react';
import { Stack } from '@mui/material';
import { GridColDef, GridDensityTypes, GridRowClassNameParams } from '@mui/x-data-grid-pro';
import { PartChildrenListProps } from './PartChildrenList.props';
import { StyledTableWrapper, StyledTypography } from './PartChildrenList.styles';
import { Accordian } from '../../atoms/Accordian';
import { DataGrid } from '../../atoms/DataGrid';
import { calculateDateTimeDuration, formatDate } from '../../../utils/common';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { Link } from '../../atoms/Link';
import { useMediaQuery } from '@material-ui/core';
import { ScreenSize } from '../../../@types/display.types';

export const PartChildrenList: React.FC<PartChildrenListProps> = ({
  partChildrenListData
}) => {

  const isMobileView = useMediaQuery(ScreenSize.MOBILE);

  const columns: GridColDef[] = [
    {
      field: 'serial1',
      headerName: 'Serials 1',
      flex: 1,
      renderCell: (params) => (<Link href={`/parts/${params.row.id}`}>{params.row.serial1}</Link>)
    },
    {
      field: 'serial2',
      headerName: 'Serials 2',
      flex: 1,
      hide:isMobileView,
      renderCell: (params) => (<Link href={`/parts/${params.row.id}`}>{params.row.serial2}</Link>)
    },
    {
      field: 'serial3',
      headerName: 'Serials 3',
      flex: 1,
      hide:isMobileView,
      renderCell: (params) => (<Link href={`/parts/${params.row.id}`}>{params.row.serial3}</Link>)
    },
    {
      field: 'partTypeName',
      headerName: 'Part Type Name',
      minWidth: 200,
      flex: 1
    },
    {
      field: 'partTypeNumber',
      headerName: 'Part Type Number',
      minWidth: 200,
      flex: 1,
      hide:isMobileView,
    },
    {
      field: 'updatedAt',
      headerName: 'Datestamp',
      minWidth: 200,
      flex: 1,
      hide:isMobileView,
      renderCell: (params) => (
        <Stack>
          <Typography variant={TypographyVariantProps.Body2}>{formatDate(params.row.updatedAt)}</Typography>
          <StyledTypography variant={TypographyVariantProps.Body2}>{calculateDateTimeDuration(params.row.updatedAt)}</StyledTypography>
        </Stack>
      )
    },
  ];

  return (
    <Accordian title="Child Parts" defaultExpanded={true}>
      <StyledTableWrapper>
        <DataGrid
          density={GridDensityTypes.Standard}
          columns={columns}
          rows={partChildrenListData}
          getRowId={(row) => row?.id}
          disableColumnFilter={true}
          disableColumnSelector={true}
          disableDensitySelector={true}
          componentsProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
              showQuickFilter: false
            }
          }}
          getRowClassName={(params: GridRowClassNameParams) => `super-app-theme--${params.row.logTypeCode}`}
          autoHeight={true}
          getRowHeight={() => 'auto'}
        />
      </StyledTableWrapper>
    </Accordian>
  );
};