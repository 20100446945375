import React, { useEffect, useState, useCallback } from 'react';
import { GridFeatureModeConstant, GridFilterModel, GridValueGetterParams, GridSelectionModel, GridSortModel, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { ColumnSelectionItem } from '../../../../components/templates/ColumnSelection';
import { CustomDataGrid } from '../../../../components/layouts/CustomDataGrid';
import { ActionPermissions, ColorProps, CustomPermission } from '../../../../@types';
import { Chip } from '../../../../components/atoms/Chip';
import { useGetAllTlcTemplates } from '../../../../queries/tlc-query';
import { TlcTemplatesListResponse } from '../../../../@types/tlc.type';
import { useGetUserSystemPermissions } from '../../../../queries/user-query';
import { isUserHasPermission } from '../../../../configs/permissions';
import { Link } from '../../../../components/atoms/Link';
import { getDropdownItemsWithAnyWildCard } from '../../../../utils/array-utils';
import { DropdownItem } from '../../../../components/atoms/SelectMenu';
import { GridContainer } from '../../../../styled/common.styles';

export const TlcTemplatesList: React.FC = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [tlcTemplate, setTlcTemplates] = useState<TlcTemplatesListResponse>();
  const [templateTypeList, setTemplateTypeList] = useState<DropdownItem[]>([]);
  const [permissions, setPermissions] = useState<CustomPermission[]>();
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const getUserPermissionsQuery = useGetUserSystemPermissions();
  const getAllTlcTemplates = useGetAllTlcTemplates({ filterModel, page, pageSize, sortModel });

  const [columns, setColumns] = useState<GridColDef[]>([]);

  useEffect(() => {
    setColumns([
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        type: 'number',
        valueFormatter: (params: GridValueFormatterParams) => params.value.toString(),
        renderCell: (params: GridRenderCellParams) => 
          isUserHasPermission(ActionPermissions.Part_Template_Tlc, getUserPermissionsQuery.data) ?
            <Link href={`parts/tlcTemplates/${params.row.id}`}>{params.row.id}</Link> :
            params.row.id
      },
      {
        field: 'name',
        headerName: 'Name',
        type: 'string',
        width: 200,
      },
      {
        field: 'description',
        headerName: 'Description',
        type: 'string',
        width: 300,
      },
      {
        field: 'templateActionTypeCode',
        headerName: 'Template Action Type',
        width: 200,
        type: 'singleSelect',
        valueOptions: templateTypeList,
        renderCell: (params: GridRenderCellParams) => params.row.templateActionType.name
      },
      {
        field: 'isActive',
        headerName: 'Active',
        type: 'boolean',
        width: 150,
        renderCell: (params: GridRenderCellParams) => params.row.isActive ? <Chip label="YES" color={ColorProps.Success} /> : <Chip label="NO" color={ColorProps.Error} />,
        valueGetter: (params: GridValueGetterParams) => params.row.isActive
      },
    ]);
  }, [permissions, templateTypeList]);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    setFilterModel(filterModel);
  }, []);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const handleSelectionModelChange = useCallback((selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  }, []);

  useEffect(() => {
    getUserPermissionsQuery.data && setPermissions(getUserPermissionsQuery.data);
  }, [getUserPermissionsQuery.data]);

  useEffect(() => {
    getAllTlcTemplates.data && setTlcTemplates(getAllTlcTemplates.data);
    if (getAllTlcTemplates.data?.data?.length) {
      const templateActionTypes = getAllTlcTemplates.data?.data.map(template => template.templateActionType)
        .filter((actionType, index, array) =>
          array.findIndex(templateActionType => templateActionType.code === actionType.code) === index
        );
      setTemplateTypeList(getDropdownItemsWithAnyWildCard(templateActionTypes));
    }
  }, [getAllTlcTemplates.data]);

  useEffect(() => {
    getAllTlcTemplates.refetch();
  }, [page, pageSize, filterModel, sortModel]);

  const columnSelectionItems: Array<ColumnSelectionItem> = [
    {
      title: 'TLC Template',
      value: [
        {
          title: 'ID',
          field: 'id',
          value: true
        },
        {
          title: 'Name',
          field: 'name',
          value: true
        },
        {
          title: 'Description',
          field: 'description',
          value: true
        },
        {
          title: 'Template Action Type',
          field: 'templateActionTypeCode',
          value: true
        },
        {
          title: 'Acive',
          field: 'isActive',
          value: true
        }
      ]
    }
  ];

  return (
    <GridContainer>
      <CustomDataGrid
        columns={columns}
        rows={tlcTemplate?.data || []}
        rowCount={tlcTemplate?.total}
        enableCSVExport={false}
        exportFileName=""
        columnSelectionItems={columnSelectionItems}
        filterMode={GridFeatureModeConstant.server}
        filterModel={filterModel}
        onFilterModelChange={(filterModel: GridFilterModel) => onFilterChange(filterModel)}
        loading={getAllTlcTemplates.isLoading}
        rowsPerPageOptions={[25, 50, 100]}
        pagination={true}
        page={page}
        pageSize={pageSize}
        paginationMode={GridFeatureModeConstant.server}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        sortingMode={GridFeatureModeConstant.server}
        onSortModelChange={handleSortModelChange}
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionModelChange}
        componentsProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true }
          }
        }}
        getRowHeight={() => 'auto'}
      />
    </GridContainer>
  );
};