import React from 'react';
import { Stack, TableRow } from '@mui/material';
import { SiteSchemaRowProps } from '.';
import { ColorProps } from '../../../@types';
import { Button, ButtonSizeProps, ButtonVariantProps } from '../../atoms/Button';
import { Switch } from '../../atoms/Switch';
import { StyledTableCell } from './SiteSchemaRow.styles';
import { TextField } from '../../atoms/TextField';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { Edit } from '@mui/icons-material';

export const SiteSchemaRow: React.FC<SiteSchemaRowProps> = ({
  hasEditPermissions,
  siteSchemaRowData,
  onEditClick
}) => {
  return (
    <TableRow>
      <StyledTableCell width="20%">
        <TextField
          fullWidth
          disabled
          label="Name"
          value={siteSchemaRowData.name}
        />
      </StyledTableCell>
      <StyledTableCell width="*%">
        <TextField
          fullWidth
          disabled
          label="Description"
          value={siteSchemaRowData.description}
        />
      </StyledTableCell>
      <StyledTableCell width="60px">
        <Stack>
          <Typography variant={TypographyVariantProps.Caption}>Is Active</Typography>
          <Switch
            color={ColorProps.Success}
            checked={siteSchemaRowData.isActive}
            disabled
          />
        </Stack>
      </StyledTableCell>
      <StyledTableCell width="50px">
        {hasEditPermissions &&
        <Button 
          variant={ButtonVariantProps.Icon}
          size={ButtonSizeProps.Small}
          onClick={() => onEditClick && onEditClick(siteSchemaRowData.id || -1)} 
        >
          <Edit/>
        </Button>
        }
      </StyledTableCell>
    </TableRow>
  );
};
