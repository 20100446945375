import { PartConditionOptions } from '../@types/part.type';
import { DropdownItem } from '../components/atoms/SelectMenu';
import { CustomShippingPackageType } from '../configs/enums';

export const SAMPLE_ADD_STOCK_DETAIL = {
  id: 0,
  source: '',
  sourceNodeId: 0,
  destination: '',
  destinationNodeId: 0,
  shipmentStatusCode: 'pending',
  clientHierarchy: '',
  clientHierarchyName: '',
  client: '',
  contract: ['any'],
  customerId: '',
  connoteNo: '',
  expectedArrivalDate: '',
  shippingCost: NaN,
  referenceNumber1: '',
  referenceNumber2: '',
  referenceNumber3: '',
  note: '',
  shipmentTypeCode: 'add-stock'
};

export const ADD_STOCK_DETAILS_HELPER_TEXT = {
  sourceHelperText: '',
  destinationHelperText: '',
  clientContractHelperText: ''
};

export const SAMPLE_DISPATCH_DETAIL = {
  source: '',
  sourceNodeId: 0,
  destination: '',
  destinationNodeId: 0,
  shipmentStatusCode: 'in-transit',
  clientHierarchy: '',
  clientHierarchyName: '',
  client: '',
  contract: [],
  serviceId: 0,
  bypassReceipt: false,
  overrideConsumption: false,
  connoteNo: '',
  note: '',
  shippingCost: 0,
  shipmentTypeCode: 'dispatch'
};

export const DISPATCH_DETAILS_HELPER_TEXT = {
  sourceHelperText: '',
  destinationHelperText: '',
  clientContractHelperText: ''
};

export const SFH_OPTIONS = {
  softwares: [],
  firmwares: [],
  hardwares: []
};

export const SAMPLE_MANIFEST_SERIAL_DETAIL = {
  id: 0,
  partTypeId: 0,
  sfhOptions: SFH_OPTIONS,
  fleetTagOptions: [],
  conditionOptions: [],
  softwareVersion: '',
  firmwareVersion: '',
  hardwareRevision: '',
  fleetTag: '',
  condition: PartConditionOptions.GOOD,
  smartInput: '',
  serialsDataSet: []
};

export const SAMPLE_NON_SERIALISED_ATTRIBUTES = {
  condition: 'good',
  fleetTag: ''
};

export const SAMPLE_MANIFEST_ATTRIBUTE_DETAIL = {
  partTypeId: 0,
  partTypeName: '',
  partTypeNo: '',
  attributes: SAMPLE_NON_SERIALISED_ATTRIBUTES,
  serials: SAMPLE_MANIFEST_SERIAL_DETAIL
};

export const SAMPLE_DISPATCH_MANIFEST_DETAIL = {
  serialisedManifestData: [{
    partTypeId: 2,
    partTypeName: 'Aldi Key board',
    partTypeNo: '478',
    quantity: 1,
    items: [{
      selected: true,
      condition: 'Good',
      serial: 'X12345',
      softwareVersion: 'V1.2',
      hardwareVersion: 'V1.4',
      firmwareVersion: 'V1.9',
      fleetTag: 'Fleet Tag 2',
      location: 'Melbourne',
      locationId: 120
    }, {
      selected: false,
      condition: 'Good',
      serial: 'X12346',
      softwareVersion: 'V1.0',
      hardwareVersion: 'V1.4',
      firmwareVersion: 'V4.9',
      fleetTag: 'Fleet Tag 2',
      location: 'Melbourne',
      locationId: 120
    }],
    serialisation: 'Serialised'
  }],
  nonSerializedManifestData: [{
    partTypeId: 1,
    partTypeName: 'USB Cable Connectors',
    partTypeNo: '224',
    quantity: 20,
    items: [{
      selected: true,
      condition: 'Good',
      fleetTag: 'Fleet Tag 1',
      location: 'Melbourne',
      locationId: 120
    }],
    serialisation: 'Consumable'
  }] 
};

export const S_HASH = 's%23';

export const INITIAL_DISPATCH_MANIFEST = {
  serialisedManifestData: [],
  nonSerializedManifestData: []
};

export const MANUAL_SHIPPING_DATA = {
  connoteNo: '',
  shippingProviderId: 0
};

export const INTEGRATED_FREIGHT_SHIPPING_DATA = {
  shippingProviderId: 0,
  serviceType: '',
  selectedPackages:[],
  deliveryNote: '',
  isSafeDrop: false,
  isAuthToLeave: false,
  isCustomAddress: false
};

export const CUSTOM_PACKAGE_TYPES: DropdownItem[] = [
  {
    value: CustomShippingPackageType.Carton,
    label: CustomShippingPackageType.Carton
  },
  {
    value: CustomShippingPackageType.Pallet,
    label: CustomShippingPackageType.Pallet
  },
  {
    value: CustomShippingPackageType.Satchel,
    label: CustomShippingPackageType.Satchel
  },
];

export const SAMPLE_SHIPMENT_BATCH_RECEIVAL = {
  partTypeId: 0,
  partTypeName: '',
  isPartTypePopup: false,
  putAwayLocationId: 0,
  putAwayLocationName: '',
  isPartTypeLiveSearch: false,
  isOther: false,
  smartInput: '',
  nonSerialOptions: [],
  nonSerializedShipmentManifestItem: [],
  serializedShipmentManifestItem: [],
  selectedSerializedShipmentManifestItem: [],
  nonSerializedShipmentManifestItemCount: 0,
  serializedShipmentManifestItemCount: 0
};

export const SAMPLE_SHIPMENT_INDIVIDUAL_RECEIVAL = {
  nonSerializedShipmentManifestItem: [],
  selectedNonSerializedShipmentManifestItem: [],
  serializedShipmentManifestItem: [],
  selectedSerializedShipmentManifestItem: [],
};

export const SAMPLE_SERIAL_POPUP = {
  id: 0,
  partTypeId: 0,
  sfhOptions: SFH_OPTIONS,
  fleetTagOptions: [],
  conditionOptions: [],
};