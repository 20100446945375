import React, { useState, useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import FunctionsIcon from '@mui/icons-material/Functions';
import AbcIcon from '@mui/icons-material/Abc';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import InsertSignatureIcon from '@mui/icons-material/Fingerprint';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../atoms/Button';
import { FormBuilderContainer } from '../FormBuilderContainer';
import { getFormFieldTitle } from '../../../utils/form-builder';
import { FormConditionItem, FormConditionJoinOption, FormContainerItem, FormFieldItem, FormFieldType } from '../../../@types';
import { FormBuilderProps } from './FormBuilder.props';
import { QUESTION_DATA_INITIAL_VALUE } from '../../../constants/form-builder';

export const FormBuilder: React.FC<FormBuilderProps> = ({
  subFieldTypes,
  showErrors,
  value,
  onChange
}) => {
  const [containerIndex, setContainerIndex] = useState(1);
  const [isDecisionPrompt, setIsDecisionPrompt] = useState(false);

  useEffect(() => { 
    isDecisionPrompt && handleAddField(FormFieldType.DecisionPrompt);
  }, [isDecisionPrompt]);

  const handleAddContainer = (type?: FormFieldType) => {
    if (value.containers && value.containers.length > 0) {
      const sortedContainers = value.containers.sort((a, b) => a.id - b.id);
      const index = sortedContainers[sortedContainers.length - 1].id;
      const nextIndex = index + 1;
      
      const newContainer: FormContainerItem = {
        id: nextIndex,
        fields: [],
        conditions: []
      };

      if (type === FormFieldType.DecisionPrompt) {
        newContainer.isDecisionPrompt = true,
        newContainer.questionData = {
          id: nextIndex,
          questionText: '',
          isYes: false 
        };
      }

      setContainerIndex(nextIndex);
  
      onChange({
        ...value,
        containers: [...sortedContainers, newContainer]
      });
    } else {  
      onChange({
        ...value,
        containers: [
          {
            id: 1,
            fields: [],
            conditions: [],
            isDecisionPrompt: type === FormFieldType.DecisionPrompt || undefined,
            questionData: type === FormFieldType.DecisionPrompt ? QUESTION_DATA_INITIAL_VALUE : undefined
          }
        ]
      });
    }
  };

  const handleAddField = (type: FormFieldType) => {
    const title = getFormFieldTitle(type);
    const container = value.containers.find((obj) => obj.id === containerIndex);
    if (container) {
      if (container.fields.length > 0) {
        const sortedFields = container.fields.sort((a, b) => a.id - b.id);
        const index = sortedFields[sortedFields.length - 1].id;
        const newField: FormFieldItem = {
          id: index + 1,
          title,
          options: [],
          isRequired: false,
          type
        };

        container.fields = [...sortedFields, newField];

        const newContainers = value.containers.map((obj) => {
          return (obj.id === containerIndex) ? container : obj;
        });

        onChange({
          ...value,
          containers: newContainers
        });
      } else {
        const newContainers = value.containers.map((obj) => {
          if (obj.id === containerIndex) {
            return {
              ...container,
              fields: [
                {
                  id: 1,
                  title,
                  options: [],
                  isRequired: false,
                  type
                }
              ]
            };
          } else {
            return obj;
          }
        });

        onChange({
          ...value,
          containers: newContainers
        });
      }
    }
    type === FormFieldType.DecisionPrompt && setIsDecisionPrompt(false);
  };

  const handleAddCondition = () => {
    const container = value.containers.find((obj) => obj.id === containerIndex);
    if (container) {
      if (container.conditions.length > 0) {
        const sortedConditions = container.conditions.sort((a, b) => a.id - b.id);
        const index = sortedConditions[sortedConditions.length - 1].id;
        const newField: FormConditionItem = {
          id: index + 1,
          joinOption: FormConditionJoinOption.None,
          value: ''
        };

        container.conditions = [...sortedConditions, newField];

        const newContainers = value.containers.map((obj) => {
          return (obj.id === containerIndex) ? container : obj;
        });

        onChange({
          ...value,
          containers: newContainers
        });
      } else {
        const newContainers = value.containers.map((obj) => {
          if (obj.id === containerIndex) {
            return {
              ...container,
              conditions: [
                {
                  id: 1,
                  joinOption: FormConditionJoinOption.None,
                  value: ''
                }
              ]
            };
          } else {
            return obj;
          }
        });

        onChange({
          ...value,
          containers: newContainers
        });
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <Grid container spacing={2} onClick={(event) => {
          event.stopPropagation();
          setContainerIndex(0);
        }}>
          {value.containers.map((container) => (
            <Grid key={container.id} item xs={12}>
              <FormBuilderContainer
                subFieldTypes={subFieldTypes}
                isSelected={containerIndex === container.id} 
                showErrors={showErrors} 
                value={container} 
                onChange={(val) => {
                  const modifiedContainers = value.containers.map((obj) => {
                    return (obj.id === val.id) ? val : obj;
                  });
                  onChange({ ...value, containers: modifiedContainers });
                }} 
                onSelectContainer={(id) => setContainerIndex(id)}    
                onRemove={(id) => {
                  const modifiedContainers = value.containers.filter((obj) => obj.id !== id);
                  onChange({ ...value, containers: modifiedContainers });
                }}            
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Stack direction="column" spacing={1}>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Info}
            startIcon={<ViewComfyIcon/>}
            onClick={handleAddContainer}
          >
            Container
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Info}
            startIcon={<FunctionsIcon/>}
            onClick={handleAddCondition}
          >
            Condition
          </Button>
          <br/>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Success}
            startIcon={<AbcIcon/>}
            onClick={() => handleAddField(FormFieldType.Label)}
          >
            {FormFieldType.Label}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Success}
            startIcon={<TextFieldsIcon/>}
            onClick={() => handleAddField(FormFieldType.Text)}
          >
            {FormFieldType.Text}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Success}
            startIcon={<TextFieldsIcon/>}
            onClick={() => handleAddField(FormFieldType.RichTextbox)}
          >
            {FormFieldType.RichTextbox}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Success}
            startIcon={<ArrowDropDownIcon/>}
            onClick={() => handleAddField(FormFieldType.Dropdown)}
          >
            {FormFieldType.Dropdown}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Success}
            startIcon={<RadioButtonCheckedIcon/>}
            onClick={() => handleAddField(FormFieldType.Radio)}
          >
            {FormFieldType.Radio}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Success}
            startIcon={<CheckBoxIcon/>}
            onClick={() => handleAddField(FormFieldType.Checkbox)}
          >
            {FormFieldType.Checkbox}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Success}
            startIcon={<CalendarMonthIcon/>}
            onClick={() => handleAddField(FormFieldType.Date)}
          >
            {FormFieldType.Date}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Success}
            startIcon={<AttachFileIcon/>}
            onClick={() => handleAddField(FormFieldType.File)}
          >
            {FormFieldType.File}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Success}
            startIcon={<InsertPhotoIcon/>}
            onClick={() => handleAddField(FormFieldType.Photo)}
          >
            {FormFieldType.Photo}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Success}
            startIcon={<InsertSignatureIcon/>}
            onClick={() => handleAddField(FormFieldType.Signature)}
          >
            {FormFieldType.Signature}
          </Button>
          <br/>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Warning}
            startIcon={<ViewComfyIcon/>}
            onClick={() => handleAddField(FormFieldType.FaultSelection)}
          >
            {FormFieldType.FaultSelection}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Warning}
            startIcon={<ViewComfyIcon/>}
            onClick={() => handleAddField(FormFieldType.SolutionAction)}
          >
            {FormFieldType.SolutionAction}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Warning}
            startIcon={<ViewComfyIcon/>}
            onClick={() => handleAddField(FormFieldType.PartsRequest)}
          >
            {FormFieldType.PartsRequest}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Warning}
            startIcon={<ViewComfyIcon/>}
            onClick={() => handleAddField(FormFieldType.ServiceCreation)}
          >
            {FormFieldType.ServiceCreation}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Warning}
            startIcon={<ViewComfyIcon/>}
            onClick={() => handleAddContainer(FormFieldType.DecisionPrompt)}
          >
            {FormFieldType.DecisionPrompt}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Warning}
            startIcon={<ViewComfyIcon/>}
            onClick={() => handleAddField(FormFieldType.GetGeoLocation)}
          >
            {FormFieldType.GetGeoLocation}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Warning}
            startIcon={<ViewComfyIcon/>}
            onClick={() => handleAddField(FormFieldType.EstimatedTimeOfArrival)}
          >
            {FormFieldType.EstimatedTimeOfArrival}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Warning}
            startIcon={<ViewComfyIcon/>}
            onClick={() => handleAddField(FormFieldType.MerchantDamageTemplate)}
          >
            {FormFieldType.MerchantDamageTemplate}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Warning}
            startIcon={<ViewComfyIcon/>}
            onClick={() => handleAddField(FormFieldType.ModifyStructure)}
          >
            {FormFieldType.ModifyStructure}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Warning}
            startIcon={<ViewComfyIcon/>}
            onClick={() => handleAddField(FormFieldType.ActionTrigger)}
          >
            {FormFieldType.ActionTrigger}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Warning}
            startIcon={<ViewComfyIcon/>}
            onClick={() => handleAddField(FormFieldType.SetParameter)}
          >
            {FormFieldType.SetParameter}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Warning}
            startIcon={<ViewComfyIcon/>}
            onClick={() => handleAddField(FormFieldType.SetParameterNoUI)}
          >
            {FormFieldType.SetParameterNoUI}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Warning}
            startIcon={<ViewComfyIcon/>}
            onClick={() => handleAddField(FormFieldType.GetParameter)}
          >
            {FormFieldType.GetParameter}
          </Button>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Warning}
            startIcon={<ViewComfyIcon/>}
            onClick={() => handleAddField(FormFieldType.ServiceSearch)}
          >
            {FormFieldType.ServiceSearch}
          </Button>
          <br/>
          <Button
            variant={ButtonVariantProps.Secondary} 
            color={ButtonColorProps.Error}
            onClick={() => onChange({
              ...value,
              containers: []
            })}
          >
            Clear
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};
