import React, { useEffect, useState } from 'react';
import { TypographyVariantProps } from '../../atoms/Typography';
import { ContentWrapperBox, HeadingWrapper, SelectionWrapper, Footer, StyledButton } from './SLATypeSelector.styles';
import { SelectMenu } from '../../atoms/SelectMenu';
import { TextField } from '../../atoms/TextField';
import { ButtonVariantProps } from '../../atoms/Button';
import { SLAType, SLATypeSelectorProps } from './SLATypeSelector.props';
import { TimePicker } from '../../atoms/TimePicker';
import { Modal } from '../../atoms/Modal';
import { CloseIcon } from '../../atoms/CloseIcon';
import { isDropDownEmpty } from '../../../utils/common';

export const SLATypeSelector: React.FC<SLATypeSelectorProps> = ({
  open,
  slaTypeData,
  selectedSlaType,
  selectedValue,
  handleSlaTypeSelection,
  onChange,
  handleClose
}) => {
  const [slaType, setSlaType] = useState<string>('-1');
  const [value, setValue] = useState<string>('');
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    setSlaType(selectedSlaType);
    setValue(selectedValue || '');
  }, [selectedSlaType, selectedValue]);

  const onSave = () => {
    if (isDropDownEmpty(slaType) || slaType === undefined || ((slaType === SLAType.WholeDayTimerSamedayWithCutoffTime || slaType === SLAType.WholeDayTimerNextdayWithCutoffTime || slaType === SLAType.DurationTimer) && value === '')) {
      setValidate(true);
      return;
    }

    handleSlaTypeSelection(getSlaTypeHierarchy());
    onChange && onChange(slaType, value, getSlaTypeHierarchy());
    handleClose();
  };

  const onCancel = () => {
    handleClose();
  };

  const getSlaTypeHierarchy = () => {
    let hierarchyText = slaTypeData.find((typeItem) => typeItem.value === slaType)?.label;
    const eligibiltyTypes: string[] = [SLAType.WholeDayTimerSamedayWithCutoffTime, SLAType.WholeDayTimerNextdayWithCutoffTime, SLAType.DurationTimer, SLAType.ScheduledDateTimerScheduledEventEnd, SLAType.ScheduledDateTimerScheduledDateOnly];
    if (value && eligibiltyTypes.includes(slaType)) {
      return hierarchyText += ` > ${value}`;
    }
    return hierarchyText || '';
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ContentWrapperBox>
        <CloseIcon handleClose={handleClose} />
        <HeadingWrapper variant={TypographyVariantProps.H6}>
          SLA Types
        </HeadingWrapper>
        <SelectionWrapper>
          <SelectMenu
            label="Select SLA Type"
            id="sla-type-select"
            items={slaTypeData}
            selectedValue={slaType}
            onChange={(value) => { 
              setSlaType(value);
              setValue('');
            }}
            validate={validate}
            optionalLabelEnabled={true}
          />
        </SelectionWrapper>
        {(slaType === SLAType.WholeDayTimerSamedayWithCutoffTime || slaType === SLAType.WholeDayTimerNextdayWithCutoffTime) && 
          <SelectionWrapper>
            <TimePicker
              normalSize
              label="Cutoff Time"
              value={value}
              onChange={(value) => setValue(value)}
            />
          </SelectionWrapper>
        }
        {slaType === SLAType.DurationTimer && 
          <SelectionWrapper>
            <p>Duration Timer</p>
            <TextField
              fullWidth
              required
              error={value.trim() === ''}
              label="Duration"
              value={value}
              onChange={(text) => setValue(text)}
            />
          </SelectionWrapper>
        }
        <Footer>
          <StyledButton variant={ButtonVariantProps.Secondary} onClick={onCancel}>Cancel</StyledButton>
          <StyledButton variant={ButtonVariantProps.Primary} onClick={onSave}>Save</StyledButton>
        </Footer>
      </ContentWrapperBox>
    </Modal>
  );
};