import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { AddStockDetailsFragment } from './AddStockDetailsFragment';
import { Button, ButtonColorProps, ButtonVariantProps } from '../../../components/atoms/Button';
import { Div } from './AddEditStock.styles';
import { Typography, TypographyVariantProps } from '../../../components/atoms/Typography';
import { Shipment, AddStockDetailFields, AddStockDetailHelperText, ShipmentStatusCodes } from '../../../@types/shipment.type';
import { ADD_STOCK_DETAILS_HELPER_TEXT, SAMPLE_ADD_STOCK_DETAIL } from '../../../constants/shipment';
import { getDateFromTimestamp, getTimestamp, nameValidation, sleep } from '../../../utils/common';
import { hasErrorInAddStockDetails } from '../../../utils/shipment';
import { useCreateAddStockManifestShipment, useCreateNonSerializedManifestShipment, useCreateShipment, useGetOneShipment, useUpdateShipment } from '../../../queries/shipment-query';
import { Snackbar } from '../../../components/atoms/Snackbar';
import { AddStockManifestFragment } from './AddStockManifestFragment/AddStockManifestFragment';
import { ConfirmationPopup } from '../../../components/molecules/ConfirmationPopup';
import { AddStockManifestRowItemValueType } from '../../../components/organisms/AddStockManifestRowItem';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';

export const AddEditStock: React.FC = () => {
  const { id }: any = useParams();
  const isNew = id === 'create';
  const navigate = useNavigate();
  const chunkSize = 200;

  const [addStockDetail, setAddStockDetail] = useState<Shipment>(SAMPLE_ADD_STOCK_DETAIL);
  const [helperText, setHelperText] = useState<AddStockDetailHelperText>(ADD_STOCK_DETAILS_HELPER_TEXT);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [serializedManifest, setSerializedManifest] = useState<AddStockManifestRowItemValueType[]>([]);
  const [nonSerializedManifest, setNonSerializedManifest] = useState<AddStockManifestRowItemValueType[]>([]);
  const [toastMessageTime, setToastMessageTime] = useState<number>(5000);

  const hasError = hasErrorInAddStockDetails(
    addStockDetail.source,
    addStockDetail.destination,
    addStockDetail.clientHierarchyName
  );

  const emptyManifest = isNew && !serializedManifest.length && !nonSerializedManifest.length;

  const createShipment = useCreateShipment();
  const updateShipment = useUpdateShipment();
  const getOneShipment = useGetOneShipment(id === 'create' ? 0 : id);
  const createAddStockManifestShipment = useCreateAddStockManifestShipment();
  const createNonSerializedManifestShipment = useCreateNonSerializedManifestShipment();

  TabHandler(isNew ? `Add Stock | ${PLATFORM_NAME}` : `Add Stock: ${id} | Add Stock | ${PLATFORM_NAME}`);

  useEffect(() => {
    setToastMessage(isNew ? 'Successfully Saved' : 'Successfully Updated');
  }, []);

  useEffect(() => {
    if (!isNew && getOneShipment.data) {
      setAddStockDetail({
        ...getOneShipment.data,
        expectedArrivalDate: getOneShipment.data.expectedArrivalDate && getDateFromTimestamp(getOneShipment.data?.expectedArrivalDate || '')
      });
    }
  }, [isNew, getOneShipment.data]); 
  
  const handleSaveOrUpdate = async () => {
    setHelperText({ 
      ...helperText, 
      sourceHelperText: nameValidation(addStockDetail.source, AddStockDetailFields.SourceField),
      destinationHelperText: nameValidation(addStockDetail.destination, AddStockDetailFields.DestinationField),
      clientContractHelperText: nameValidation(addStockDetail.clientHierarchyName, AddStockDetailFields.DestinationField)
    });

    if (hasError) {
      return;
    }

    setToastMessageTime(5000);

    if (isNew) {
      const stock: any = await createShipment.mutateAsync(addStockDetail);
      
      if (serializedManifest.length > 0) {
        serializedManifest.forEach((obj) => {
          obj.shipmentId = stock.id;
        });
        for (const manifestItem of serializedManifest) {
          const serialsDataSet = manifestItem.serials?.serialsDataSet;
          let manifestGroupId = 0;
          for (let i = 0;i < serialsDataSet?.length;i += chunkSize) {
            const chunk = serialsDataSet.slice(i, i + chunkSize);
            const chunkedManifest = { ...manifestItem, manifestGroupId, serials: { ...manifestItem.serials, serialsDataSet: chunk } };
            const response: any = await createAddStockManifestShipment.mutateAsync([chunkedManifest]);

            if (!manifestGroupId && response?.length) {
              manifestGroupId = response[0].manifestGroupId;
            }
          }
        }
      }

      if (nonSerializedManifest.length > 0) {
        nonSerializedManifest.forEach((obj) => {
          obj.shipmentId = stock.id;
        });
        await createNonSerializedManifestShipment.mutateAsync(nonSerializedManifest);
      }

      setOpenToast(true);
      navigate(`/shipments/${stock.id}`);
    } else {
      if (addStockDetail.shipmentStatusCode === ShipmentStatusCodes.Pending) {
        if (serializedManifest.length > 0) {
          serializedManifest.forEach((obj) => {
            obj.shipmentId = id;
          });
          
          const response: any = await createAddStockManifestShipment.mutateAsync(serializedManifest);
          response?.length > 0 && setIsUpdated(true);
        }
        
        if (nonSerializedManifest.length > 0) {
          nonSerializedManifest.forEach((obj) => {
            obj.shipmentId = id;
          });
          const response: any = await createNonSerializedManifestShipment.mutateAsync(nonSerializedManifest);
          response?.length > 0 && setIsUpdated(true);
        }
      }

      await updateShipment.mutateAsync({ ...addStockDetail, expectedArrivalDate: addStockDetail.expectedArrivalDate && getTimestamp(addStockDetail.expectedArrivalDate) });

      setOpenToast(true);
    }
  };

  const cancelConfirmation = async () => {
    setToastMessageTime(5000);
    if (isNew) {
      window.location.reload();
    } else {
      await updateShipment.mutateAsync({ ...addStockDetail, expectedArrivalDate: addStockDetail.expectedArrivalDate && getTimestamp(addStockDetail.expectedArrivalDate), shipmentStatusCode: ShipmentStatusCodes.Cancelled });
      setOpenToast(true);
    }
    setIsOpen(false);
  };

  const completeNoActionButtonAction = async () => {
    setHelperText({ 
      ...helperText, 
      sourceHelperText: nameValidation(addStockDetail.source, AddStockDetailFields.SourceField),
      destinationHelperText: nameValidation(addStockDetail.destination, AddStockDetailFields.DestinationField),
      clientContractHelperText: nameValidation(addStockDetail.clientHierarchyName, AddStockDetailFields.DestinationField)
    });

    if (hasError) {
      return;
    }

    setToastMessageTime(5000);

    if (isNew) {
      const stock: any = await createShipment.mutateAsync({ ...addStockDetail, shipmentStatusCode: ShipmentStatusCodes.Complete, isCompleteNoAction: true });
      
      if (serializedManifest.length > 0) {
        serializedManifest.forEach((obj) => {
          obj.shipmentId = stock.id;
        });
        for (const manifestItem of serializedManifest) {
          const serialsDataSet = manifestItem.serials?.serialsDataSet;
          let manifestGroupId = 0;
          for (let i = 0;i < serialsDataSet?.length;i += chunkSize) {
            const chunk = serialsDataSet.slice(i, i + chunkSize);
            const chunkedManifest = { ...manifestItem, manifestGroupId, serials: { ...manifestItem.serials, serialsDataSet: chunk } };
            const response: any = await createAddStockManifestShipment.mutateAsync([chunkedManifest]);

            if (!manifestGroupId && response?.length) {
              manifestGroupId = response[0].manifestGroupId;
            }
          }
        }
      }

      if (nonSerializedManifest.length > 0) {
        nonSerializedManifest.forEach((obj) => {
          obj.shipmentId = stock.id;
        });
        await createNonSerializedManifestShipment.mutateAsync(nonSerializedManifest);
      }

      setOpenToast(true);
      await sleep(2000);
      navigate(`/shipments/${stock.id}`);
    } else {
      if (addStockDetail.shipmentStatusCode === ShipmentStatusCodes.Pending) {
        if (serializedManifest.length > 0) {
          serializedManifest.forEach((obj) => {
            obj.shipmentId = id;
          });
          
          const response: any = await createAddStockManifestShipment.mutateAsync(serializedManifest);
          response?.length > 0 && setIsUpdated(true);
        }

        if (nonSerializedManifest.length > 0) {
          nonSerializedManifest.forEach((obj) => {
            obj.shipmentId = id;
          });
          const response: any = await createNonSerializedManifestShipment.mutateAsync(nonSerializedManifest);
          response?.length > 0 && setIsUpdated(true);
        }
      }

      await updateShipment.mutateAsync({ ...addStockDetail, expectedArrivalDate: addStockDetail.expectedArrivalDate && getTimestamp(addStockDetail.expectedArrivalDate), shipmentStatusCode: ShipmentStatusCodes.Complete, isCompleteNoAction: true });


      setOpenToast(true);
    }
  };

  const completeAddStockButtonAction = async () => {
    setHelperText({ 
      ...helperText, 
      sourceHelperText: nameValidation(addStockDetail.source, AddStockDetailFields.SourceField),
      destinationHelperText: nameValidation(addStockDetail.destination, AddStockDetailFields.DestinationField),
      clientContractHelperText: nameValidation(addStockDetail.clientHierarchyName, AddStockDetailFields.DestinationField)
    });

    if (hasError) {
      return;
    }

    setToastMessage('Action saved. This shipment is creating a large number of parts, and may take several minutes to complete.  This is an automated background process.');
    setToastMessageTime(30000);

    if (isNew) {
      const stock: any = await createShipment.mutateAsync({ ...addStockDetail, shipmentStatusCode: ShipmentStatusCodes.Pending, isCompleteAddStock: true });
      
      if (serializedManifest.length > 0) {
        serializedManifest.forEach((obj) => {
          obj.shipmentId = stock.id;
        });
        for (const manifestItem of serializedManifest) {
          const serialsDataSet = manifestItem.serials?.serialsDataSet;
          let manifestGroupId = 0;
          for (let i = 0;i < serialsDataSet?.length;i += chunkSize) {
            const chunk = serialsDataSet.slice(i, i + chunkSize);
            const chunkedManifest = { ...manifestItem, manifestGroupId, serials: { ...manifestItem.serials, serialsDataSet: chunk } };
            const response: any = await createAddStockManifestShipment.mutateAsync([chunkedManifest]);

            if (!manifestGroupId && response?.length) {
              manifestGroupId = response[0].manifestGroupId;
            }
          }
        }
      }

      if (nonSerializedManifest.length > 0) {
        nonSerializedManifest.forEach((obj) => {
          obj.shipmentId = stock.id;
        });

        await createNonSerializedManifestShipment.mutateAsync(nonSerializedManifest);
      }

      await updateShipment.mutateAsync({ ...addStockDetail, id: stock.id, expectedArrivalDate: addStockDetail.expectedArrivalDate ? getTimestamp(addStockDetail.expectedArrivalDate) : null, shipmentStatusCode: ShipmentStatusCodes.Complete, isCompleteAddStock: true });

      setOpenToast(true);
      await sleep(4000);
      navigate(`/shipments/${stock.id}`);
    } else {
      if (addStockDetail.shipmentStatusCode === ShipmentStatusCodes.Pending) {
        if (serializedManifest.length > 0) {
          serializedManifest.forEach((obj) => {
            obj.shipmentId = id;
          });
          
          const response: any = await createAddStockManifestShipment.mutateAsync(serializedManifest);
          response?.length > 0 && setIsUpdated(true);
        }

        if (nonSerializedManifest.length > 0) {
          nonSerializedManifest.forEach((obj) => {
            obj.shipmentId = id;
          });
          const response: any = await createNonSerializedManifestShipment.mutateAsync(nonSerializedManifest);
          response?.length > 0 && setIsUpdated(true);
        }
      }

      await updateShipment.mutateAsync({ ...addStockDetail, expectedArrivalDate: addStockDetail.expectedArrivalDate && getTimestamp(addStockDetail.expectedArrivalDate), shipmentStatusCode: ShipmentStatusCodes.Complete, isCompleteAddStock: true });

      setOpenToast(true);
    }
  };

  return (
    <PageContainer>
      <Typography variant={TypographyVariantProps.H5} fontWeight={600}>
        {isNew ? 'Add Stock' : `Edit Stock - ${id}`}
      </Typography>
      <AddStockDetailsFragment
        addStockDetail={addStockDetail}
        setAddStockDetail={setAddStockDetail}
        isNew={isNew}
        setHelperText={setHelperText}
        helperText={helperText}
      />
      <AddStockManifestFragment
        shipmantId={parseInt(id)} 
        applicableNodeId={addStockDetail.clientHierarchy}
        setAddStockDetailClientData={(client, clientHierarchy, contract, clientHierarchyName ) => setAddStockDetail({ ...addStockDetail, client, clientHierarchy, contract, clientHierarchyName }) }
        isUpdated={isUpdated}
        isPending={(isNew || !isNew) && addStockDetail.shipmentStatusCode !== ShipmentStatusCodes.Pending}
        setSerializedManifestValues={(serializedValues: any) => {
          setSerializedManifest(serializedValues);
        }}
        serializedManifestValues={serializedManifest}
        setNonSerializedManifestValues={(nonSerializedValues: any) => {
          setNonSerializedManifest(nonSerializedValues);
        }}
        nonSerializedManifestValues={nonSerializedManifest}
        isShipmentPending={addStockDetail.shipmentStatusCode === ShipmentStatusCodes.Pending}
        setIsUpdated={setIsUpdated}
      />
      {(isNew || !isNew) && !(addStockDetail.shipmentStatusCode === ShipmentStatusCodes.Complete || addStockDetail.shipmentStatusCode === ShipmentStatusCodes.Cancelled) && (
        <Div direction="row" spacing={2}>
          <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Error} onClick={() => setIsOpen(true)}>
            Cancel
          </Button>
          <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Warning} onClick={completeNoActionButtonAction}>
            Complete + No Action
          </Button>
          <Button variant={ButtonVariantProps.Primary} color={ButtonColorProps.Info} onClick={completeAddStockButtonAction}>
            Complete + Add Stock
          </Button>
          <Button 
            variant={ButtonVariantProps.Primary} 
            color={ButtonColorProps.Success} 
            onClick={handleSaveOrUpdate}
            disabled={emptyManifest}
          >
            {isNew ? 'Save' : 'Update'}
          </Button>
        </Div>
      )}
      <Snackbar
        open={openToast}
        autoHideDuration={toastMessageTime}
        message={toastMessage}
        onClose={() => setOpenToast(false)}
      />
      <ConfirmationPopup
        open={isOpen}
        mainHeader="Warning"
        description="Are you sure to cancel the Shipment?"
        leftButtonName="Ignore"
        rightButtonName="Yes, Canceled"
        leftButtonOnClick={() => setIsOpen(false)}
        rightButtonOnClick={() => cancelConfirmation()}
      />
    </PageContainer>
  );
};