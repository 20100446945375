import React from 'react';
import { Grid } from '@mui/material';
import {
  RoleBox,
  ContactGridContainer,
} from './StructureLayout.styles';
import { TextField } from '../../atoms/TextField';
import { SelectMenu } from '../../atoms/SelectMenu';
import { StructureLayoutProps } from './StructureLayout.props';
import { SizeProps } from '../../../@types';
import { Tags } from '../Tags';
import { GRID_SPACING } from '../../../configs/ui-constants';
import { getSiteSchemas } from '../../../utils/structure';
import { EMPTY_NAME_FIELD_MESSAGE } from '../../../constants/common';

export const StructureLayout: React.FC<StructureLayoutProps> = ({
  structureLayoutSchemaType,
  onChange,
  error,
  helperText,
  structureLayoutData,
  allTags,
  renderNotes=true,
  assignedSelectedTags,
  assignedNewTags,
  enableSiteSchemaType,
  enableReferenceNumbers,
  onChangeSelectedTags,
  onChangeNewTags,
  setHelperText
}) => {
  return (
    <RoleBox>
      <ContactGridContainer container spacing={GRID_SPACING}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            error={!!helperText?.nameHelperText}
            helperText={helperText?.nameHelperText}
            label="Name"
            value={structureLayoutData.name}
            onChange={(structureName) => {
              onChange({
                ...structureLayoutData,
                name: structureName
              });

              setHelperText({
                ...helperText,
                nameHelperText: !structureName ? EMPTY_NAME_FIELD_MESSAGE : ''
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Tags
            selectedTags={assignedSelectedTags} 
            newTags={assignedNewTags} 
            setSeletedTags={onChangeSelectedTags} 
            setNewTags={onChangeNewTags}
            allTags={allTags}
          />
        </Grid>
        {enableSiteSchemaType && <Grid item xs={12}>
          <SelectMenu
            size={SizeProps.Small}
            id="page-size-menu"
            minWidth="100%"
            items={getSiteSchemas(structureLayoutSchemaType)}
            selectedValue={structureLayoutData.siteSchemaId || '-1'}
            label="Site Schema"
            onChange={(schemaId) => {
              onChange({
                ...structureLayoutData,
                siteSchemaId: schemaId
              });
            }}
            optionalLabelEnabled={true}
          />
        </Grid>}
        {renderNotes && 
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Notes"
            multiline
            rows={3}
            value={structureLayoutData?.notes}
            onChange={(text) => onChange({
              ...structureLayoutData,
              notes: text
            })}
          />
        </Grid>}
        {enableReferenceNumbers &&
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={error}
                label="Reference No 1"
                value={structureLayoutData?.referenceNo1}
                onChange={(text) => onChange({
                  ...structureLayoutData,
                  referenceNo1: text
                })} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={error}
                label="Reference No 2"
                value={structureLayoutData?.referenceNo2}
                onChange={(text) => onChange({
                  ...structureLayoutData,
                  referenceNo2: text
                })} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                error={error}
                label="Reference No 3"
                value={structureLayoutData?.referenceNo3}
                onChange={(text) => onChange({
                  ...structureLayoutData,
                  referenceNo3: text
                })} />
            </Grid>
          </>
        }
      </ContactGridContainer>
    </RoleBox>
  );
};
